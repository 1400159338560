import { AllowedEvent, ILogCommerceEvent } from '@rbilabs/mparticle-client';

import { ICartEntry, IServerOrder, ServiceMode } from '@rbi-ctg/menu';
import { IStaticPageRoute } from 'remote/queries/static-page';
import { StoreProxy } from 'state/store/hooks/types';

import { EventTypes, SignInPhases } from './constants';

export interface ILogPageView {
  pathname: string;
  store: StoreProxy;
}

export interface ILogRBIEvent {
  (event: AllowedEvent): void;
}

export interface ISignInEventOptions {
  phase: SignInPhases;
  success: boolean;
}

export interface ISignUpEventOptions {
  success: boolean;
}

type AppflowEvent = 'started' | 'downloaded' | 'extracted' | 'applied';

export interface IAppflowUpdateEventOptions {
  isBlockingUpdate?: boolean;
  phase: AppflowEvent;
  success: boolean;
  updateVersion?: string;
  totalTimeMs?: number;
}

export interface IMParticleCtx {
  init: () => void;
  login: any;
  logout: any;
  deviceId: string;
  sessionId: string;
  signInEvent: (options: ISignInEventOptions) => void;
  signUpEvent: (options: ISignUpEventOptions) => void;
  updateUniversalAttributes: (universalAttributes: Partial<IMParticleUniversalAttributes>) => void;
  updateStaticRoutes: (newStaticRoutes: IStaticPageRoute[]) => void;
  updateUserAttributes: any;
  updateUserLocationPermissionStatus: () => void;
  updateUserIdentities: (
    userIdentity?: {
      email?: string;
      customerid?: string;
      ccToken?: string;
    },
    additional?: {
      callback?: () => void;
      tryAgain?: boolean;
    }
  ) => void;
  logPageView: (pathname: string, store: StoreProxy) => void;
  logCommercePageView: (
    menuData: { id: string; name: string; menuType: string },
    attrs?: {}
  ) => void;

  //eCommerce events
  addToCart(
    cartEntry: ICartEntry,
    serviceMode: ServiceMode,
    previousCartEntries: ICartEntry[],
    selectionAttrs?: IAddToCartSelectionAttributes
  ): void;
  updateItemInCart(
    cartEntry: ICartEntry,
    originalCartEntry: ICartEntry,
    serviceMode: ServiceMode
  ): void;
  removeFromCart(cartEntry: ICartEntry): void;
  logPurchase(
    cartEntries: ICartEntry[],
    store: StoreProxy,
    serviceMode: ServiceMode,
    serverOrder: IServerOrder,
    attrs?: {
      currencyCode: string;
      fireOrderInMinutes: number;
    }
  ): void;

  //custom events
  logRBIEvent: ILogRBIEvent;
  selectServiceMode: (mode: ServiceMode) => void;
  logCheckoutEvent(serviceMode: ServiceMode, cartEntries: ICartEntry[]): void;
  logUpsellAddedEvent: (item: ICartEntry, itemPosition?: number) => void;
  marketingTileClickEvent: (title: string, position: number, cardID: string) => void;
  logNavBarClickEvent: (text: string, componentKey?: string) => void;
  setUTMParamsFromUrl: (url: string) => void;
}

// booleans need to be stringified for mParticle
export interface IMParticleAttributes extends IUtmParamsAttributes {
  $FirstName?: string;
  $LastName?: string;
  $Zip?: string;
  $Age?: number;
  $Gender?: string;
  $City?: string;
  $State?: string;
  $Mobile?: string;
  brand?: string;
  region?: string;
  env?: string;
  'Date of Birth'?: string;
  'Email Opt In'?: string;
  favoriteStores?: string;
  'Join Date'?: string;
  language?: string;
  Locale?: string;
  'Legacy User'?: string;
  'Location Services'?: string;
  marketingEmail?: string;
  marketingPush?: string;
  orderStatus?: string;
  'RBI Cognito ID'?: string;
  rewardsEmail?: string;
  rewardsPush?: string;
  Timezone?: string;
  'Type Preference'?: string;
  'Snack Preference'?: string;
  'Size Preference'?: string;
  'Time Preference'?: string;
  'IOS Location Permissions'?: string;
  'Android Location Permissions'?: string;
}

export interface IMParticleCart {
  /**
   * Adds a cart product to the user cart
   * @method add
   * @param {Object} product the product
   * @param {Boolean} [logEvent] a boolean to log adding of the cart object. If blank, no logging occurs.
   */
  add: (product: IMParticleProduct, logEvent: boolean) => void;
  /**
   * Clears all products from the user cart
   * @method clear
   */
  clear: () => void;
  /**
   * Removes a cart product from the current user cart
   * @method remove
   * @param {Object} product the product
   * @param {Boolean} [logEvent] a boolean to log adding of the cart object. If blank, no logging occurs.
   */
  remove: (product: IMParticleProduct, logEvent: boolean) => void;
  /**
   * Returns all cart products
   * @method getCartProducts
   * @return {Array} array of cart products
   */
  getCartProducts: () => IMParticleProduct[];
}

interface ILoggedOutMParticleUserIdentity {}

export interface IMParticleUserIdentity {
  email: string;
  customerid: string;
  other3?: string;
}

export interface IMParticleUser {
  /**
   * Get user identities for current user
   * @method getUserIdentities
   * @return {Object} an object with userIdentities as its key
   */
  getUserIdentities: () => {
    userIdentities: IMParticleUserIdentity;
  };
  // todo finish this interface
  getAllUserAttributes: () => IMParticleAttributes;
  setUserAttributes: (attributes: IMParticleAttributes) => void;
  isLoggedIn: () => boolean;
  getMPID?: () => string;
  /**
   * Returns the cart object for the current user
   * @method getCart
   * @return a cart object
   */
  getCart: () => IMParticleCart;
}

export enum HTTPCode {
  SUCCESS = 200,
  NO_HTTP_COVERAGE = -1,
  ACTIVE_IDENTITY_REQUEST = -2,
  ACTIVE_SESSION = -3,
  VALIDATION_ISSUE = -4,
  NATIVE_IDENTITY_REQUEST = -5,
  LOGGING_DISABLED_OR_MISSING_API_KEY = -6,
  TOO_MANY_REQUESTS = 42,
}

export interface IMParticleIdentityModifyCallback {
  (args: { httpCode: HTTPCode; body: string | null }): void;
}
export interface IMParticleCallback {
  (args: {
    httpCode: HTTPCode;
    body: string;
    getUser: () => IMParticleUser;
    getPreviousUser: () => IMParticleUser;
  }): void;
}

export interface IMparticleIdentityApiData {
  userIdentities: IMParticleUserIdentity | ILoggedOutMParticleUserIdentity;
}
interface IMParticleIdentityApiCallback {
  (identityApiData: IMparticleIdentityApiData, callback: IMParticleCallback): void;
}

interface IMParticleIdentityApiModifyCallback {
  (identityApiData: IMparticleIdentityApiData, callback: IMParticleIdentityModifyCallback): void;
}
export interface IMParticleProduct {
  Name: string;
  Sku: string;
  Price: number;
  Quantity: number;
  Brand: string;
  Variant: string;
  Category: string;
  Position: number;
  CouponCode: string;
  TotalAmount: number;
  Attributes: { [key: string]: string | number | boolean };
  SubProducts: IMParticleProduct[];
}

export enum ProductItemType {
  Parent = 'Parent',
  Child = 'Child',
}

export interface IMParticlePurchaseEventAttributes {
  brand: string;
  env: string;
  region: string;
  'Pickup Mode': string;
  'Service Mode': string;
  branch_service_mode: string;
  customer_event_alias: string;
  'CC Token': string | null;
  'Coupon ID': string;
  'Coupon Applied': string;
  Currency: string;
  'Tax Amount': number;
  'Total Amount': number;
  'Value Threshold 20 Met'?: boolean;
  'Value Threshold 15 Met'?: boolean;
  'Value Threshold 10 Met'?: boolean;
  'Value Threshold 5 Met'?: boolean;
  'Timed Fire Minutes': number;
  'Transaction Order Number ID': string;
  'Transaction POS': string;
  'Transaction RBI Cloud Order ID': string;
  'Restaurant ID': string | null;
  'Restaurant Name': string | null;
  'Restaurant Number': string | null;
  'Restaurant Address': string | null;
  'Restaurant City': string | null;
  'Restaurant State/Province Name': string | null;
  'Restaurant Postal Code': string | null;
  'Restaurant Country': string | null;
  'Restaurant Latitude': number | null;
  'Restaurant Longitude': number | null;
  'Restaurant Status': string | null;
  'Restaurant Drink Station Type': string | null;
  'Restaurant Drive Thru Lane Type': string | null;
  'Restaurant Franchise Group Id': number | null;
  'Restaurant Franchise Group Name': string | null;
  'Restaurant Front Counter Closed': boolean | null;
  'Restaurant Has Breakfast': boolean | null;
  'Restaurant Has Burgers For Breakfast': boolean | null;
  'Restaurant Has Curbside': boolean | null;
  'Restaurant Has Front Counter Closed': boolean | null;
  'Restaurant Has Catering': boolean | null;
  'Restaurant Has Dine In': boolean | null;
  'Restaurant Has Drive Thru': boolean | null;
  'Restaurant Has Home Delivery': boolean | null;
  'Restaurant Has Mobile Ordering': boolean | null;
  'Restaurant Has Parking': boolean | null;
  'Restaurant Has Playground': boolean | null;
  'Restaurant Has Take Out': boolean | null;
  'Restaurant Has Wifi': boolean | null;
  'Restaurant Number Drive Thru Windows': number;
  'Restaurant Parking Type': string | null;
  'Restaurant Playground Type': string | null;
  'Restaurant POS': string | null;
  'Restaurant POS Version': string | null;
  'Is Kiosk': boolean;
  'Card Type': string;
  'Payment Type': string;
  'Has Upsell': boolean;
  'Upsell Total': number;
  'Recommender Provider': string;
  Chef: string | null;
  'Device Time': string;
  'Source Page': string;
  deliveryDiscountAmount?: number;
  deliveryFeeAmount?: number;
  deliveryGeographicalFeeAmount?: number;
  deliverySmallCartFeeAmount?: number;
  deliveryServiceFeeAmount?: number;
  deliverySurchargeFeeAmount?: number;
  totalDeliveryOrderFeeAmount?: number;
  baseDeliveryFeeAmount?: number;
  quotedFeeAmount?: number;
  'Cart Data': string;
  Rewards: string | null;
  'Is Loyalty': boolean;
  roundUpAmount: number;
}

export interface IMParticleUniversalAttributes extends IUtmParamsAttributes {
  'Service Mode': string;
  'Pickup Mode': string;
  'Source Page': string;
  isSmallScreen: boolean;
  isLoyaltyUser: boolean;
  currentBuild: string;
}

export interface IMParticleSublevelItem {
  id: string;
  quantity: number;
}

export abstract class IMParticleIdentityAdapter {
  static modify: IMParticleIdentityApiModifyCallback;
  static login: IMParticleIdentityApiCallback;
}

export interface IMParticleAdapter {
  createProduct: (
    name: string,
    sku: string,
    price: number,
    quantity: number
  ) => IMParticleProduct | null;
  logEvent: (
    name: string,
    eventTypes: EventTypes,
    attributes?: object,
    customFlags?: object
  ) => void;
  logProductAction: (params: ILogCommerceEvent) => void;

  getDeviceId: () => string | void;

  getSession: () => Promise<{ sessionId: string }>;
  setOptOut: (optOut: boolean) => void;

  Identity: IMParticleIdentityAdapter;
}

// Track the modification of item, combo slot and picker aspect.
// These values are initially false and is set to true if the user
// changes these values away from the initial state.
export interface IAddToCartSelectionAttributes {
  pickerAspectSelection: boolean;
  comboSlotSelection: boolean;
  itemModified: boolean;
}

export interface IUtmParams {
  utm_source?: string;
  utm_medium?: string;
  utm_campaign?: string;
  utm_term?: string;
  utm_content?: string;
}

interface IUtmParamsAttributes {
  'UTM Source'?: string;
  'UTM Medium'?: string;
  'UTM Campaign'?: string;
  'UTM Term'?: string;
  'UTM Content'?: string;
}
