import React from 'react';

import { Icon, Pressable, Text } from '@rbilabs/universal-components';
import { useIntl } from 'react-intl';

import { theme } from 'styles/configure-theme';
import { hiddenAccessibilityPlatformProps } from 'utils/accessibility';

const Container = Pressable.withConfig({
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'center',
  color: theme.token('text-default'),
  marginBottom: '$4',
});

const ReadMoreButton: React.FC<React.PropsWithChildren<{
  collapsed: boolean;
  onPress: () => void;
}>> = ({ collapsed, onPress }) => {
  const { formatMessage } = useIntl();
  return (
    <Container testID="read-more" onPress={onPress} marginTop="$4">
      <Text fontFamily="alternative" fontSize="xs">
        {collapsed ? formatMessage({ id: 'readMore' }) : formatMessage({ id: 'readLess' })}
      </Text>
      <Icon
        variant={collapsed ? 'select' : 'collapse'}
        size="$6"
        {...hiddenAccessibilityPlatformProps}
      />
    </Container>
  );
};

export default ReadMoreButton;
