import { Box } from '@rbilabs/universal-components';

export const MapContainer = Box.withConfig({
  height: 'full',
  _web: {
    height: {
      base: 'full',
      md: '600px',
    },
  },
});
