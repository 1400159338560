import React from 'react';

import { IBaseProps } from '@rbi-ctg/frontend';
import Modal, { ModalSize } from 'components/modal';
import { useNavigation } from 'hooks/navigation/use-navigation';
import { LaunchDarklyFlag, useFlag } from 'state/launchdarkly';
import { useLocationContext } from 'state/location';
import { ServiceMode, useOrderContext } from 'state/order';
import { useStoreContext } from 'state/store';
import noop from 'utils/noop';
import { routes } from 'utils/routing';

import PickupModeContent from './pickup-mode-content';

interface IPickupModeModalProps extends IBaseProps {
  allowsDismiss?: boolean;
}

const PickupModeModal = ({ allowsDismiss = true }: IPickupModeModalProps) => {
  const { resetStore } = useStoreContext();

  const {
    selectServiceMode,
    reorder: { pendingReorder, handleReorder },
  } = useOrderContext();

  const enableNewFlow = useFlag(LaunchDarklyFlag.ENABLE_NEW_SERVICE_MODE_FLOW);
  const { goBack, navigate } = useNavigation();

  const { storeLocatorCallbackUrl } = useLocationContext();
  const redirectUrl = storeLocatorCallbackUrl || routes.menu;

  const handleSelectServiceMode = (mode: ServiceMode) => {
    selectServiceMode(mode).then(() => {
      if (pendingReorder) {
        handleReorder(pendingReorder, redirectUrl);

        return;
      }

      navigate(redirectUrl);
    });
  };

  return (
    <Modal
      size={enableNewFlow ? ModalSize.LANDSCAPE : ModalSize.SMALL}
      allowsDismiss={allowsDismiss}
      onDismiss={
        allowsDismiss
          ? () => {
              resetStore();
              goBack();
            }
          : noop
      }
      mParticleEventData={{
        modalAppearanceEventMessage: 'Select Pickup Mode',
      }}
    >
      <PickupModeContent selectServiceMode={handleSelectServiceMode} />
    </Modal>
  );
};

export default PickupModeModal;
