import { StaticMenuExperimentVariants } from 'experiments/static-menu/staticMenuExperimentVariants';
import { RunningExperiments, useExperiments } from 'state/experiments';
import { isNative } from 'utils/environment';

export const useSingleSelectCtaInLocator = () => {
  const { variant } = useExperiments();

  return (
    isNative &&
    variant(RunningExperiments.SingleSelectCTAinLocator, StaticMenuExperimentVariants.Treatment)
  );
};
