import React from 'react';

import { theme } from 'styles/configure-theme';

import LottieView from '../lottie-view';

import initialAnimation from './json/initial.json';
import loadingAnimation from './json/loading.json';
import transitionAnimation from './json/transition.json';

export const LoadingAnimation = () => (
  <LottieView
    testID="reset-location-loading-animation"
    source={loadingAnimation}
    autoPlay
    colorFilters={[
      {
        keypath: 'Dots',
        color: theme.token('icon-default'),
      },
    ]}
  />
);

export const TransitionAnimation = ({ onComplete }: any) => (
  <LottieView
    testID="reset-location-transition-animation"
    source={transitionAnimation}
    loop={false}
    autoPlay
    onAnimationFinish={() => onComplete()}
    colorFilters={[
      {
        keypath: 'Arrows-dots',
        color: theme.token('icon-default'),
      },
    ]}
  />
);

export const InitialAnimation = () => (
  <LottieView
    testID="reset-location-initial-animation"
    source={initialAnimation}
    loop={false}
    autoPlay
    colorFilters={[
      {
        keypath: 'contours',
        color: theme.token('icon-default'),
      },
    ]}
  />
);
