import React, { VFC, useCallback, useMemo } from 'react';

import { Box, Icon } from '@rbilabs/universal-components';
import { useIntl } from 'react-intl';

import { ListItemGroup } from 'components/list-item-group';
import { ProductListItemSeparator, ProductNoteText } from 'components/product-detail/styled';
import { useProductPropsSelections } from 'components/product-detail/use-product-props-selections';
import { ProductListItem } from 'components/product-list-item';
import { FallbackImageSvg } from 'components/svgs/fallback-image';
import { useMenuCalories } from 'hooks/menu/use-menu-calories';
import { useMenuContext } from 'state/menu';
import { useProductWizardContext } from 'state/product-wizard';
import { useProductWizardFlow } from 'state/product-wizard/hooks/use-product-wizard-flow';

import { IProductHomeProps } from '../product-home';

import { transformUserSelectionsToSummaryItems } from './utils';

const OrderSummary: VFC<IProductHomeProps> = () => {
  const { selectedProduct, userSelections } = useProductWizardContext();
  const { goToCustomizeProduct } = useProductWizardFlow();
  const { priceForComboSlotSelection } = useMenuContext();

  const { buildProductPropsSelections } = useProductPropsSelections();
  const { formatMessage } = useIntl();
  const { formatCalories } = useMenuCalories();

  const mappedSummaryItems = useMemo(() => {
    if (!selectedProduct) {
      return [];
    }
    return transformUserSelectionsToSummaryItems({
      buildProductPropsSelections,
      priceForComboSlotSelection,
      selectedProduct,
      userSelections,
    });
  }, [buildProductPropsSelections, priceForComboSlotSelection, selectedProduct, userSelections]);

  const showCustomizableTitle = useMemo(
    () => mappedSummaryItems.some(item => item.productListItemProps.isClickable),
    [mappedSummaryItems]
  );

  const showImages = mappedSummaryItems.some(
    item => !!item.productListItemProps.productProps.image
  );

  const handleSummaryLineClick = useCallback(
    (slotKey: string) => {
      goToCustomizeProduct(slotKey);
    },
    [goToCustomizeProduct]
  );

  if (!selectedProduct) {
    return null;
  }

  return (
    <Box testID="order-summary">
      <ListItemGroup
        header={formatMessage({
          id: showCustomizableTitle
            ? 'productDetailSummaryCustomizableTitle'
            : 'productDetailSummaryTitle',
        })}
        items={mappedSummaryItems.map(summaryItem => (
          <ProductListItem
            key={summaryItem.slotKey}
            {...summaryItem.productListItemProps}
            productProps={{
              ...summaryItem.productListItemProps.productProps,
              // @ts-expect-error TS(2322) FIXME: Type '((props: Omit<SvgProps, "outline" | "outline... Remove this comment to see the full error message
              fallbackImage: showImages ? FallbackImageSvg : undefined,
              calories: formatCalories(summaryItem.productListItemProps.productProps.calories),
              note: summaryItem.productListItemProps.productProps.note && (
                <ProductNoteText>
                  {summaryItem.productListItemProps.productProps.note}
                </ProductNoteText>
              ),
            }}
            controls={
              summaryItem.productListItemProps.isClickable && (
                <Icon variant="next" testID="next-icon" />
              )
            }
            onProductClick={() => handleSummaryLineClick(summaryItem.slotKey)}
          />
        ))}
        ItemSeparator={<ProductListItemSeparator />}
      />
    </Box>
  );
};

export default OrderSummary;
