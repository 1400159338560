import React, { FC } from 'react';

import { Center, Text } from '@rbilabs/universal-components';
import { TabBar as RNTabBar } from 'react-native-tab-view';

import { theme } from 'styles/configure-theme';

import { ColorOptions, ITabBarProps } from './types';

const getIndicatorColor = (color?: ColorOptions) => {
  return color === 'inverted' ? Styles.color.white : theme.token('background-active');
};

const getTextColor = (color?: ColorOptions, disabled?: boolean) => {
  if (disabled) {
    return theme.token(color === 'inverted' ? 'text-disabled-reversed' : 'text-disabled');
  } else if (color === 'inverted') {
    return Styles.color.white;
  } else if (color === 'secondary') {
    return Styles.color.accent;
  }
  return theme.token('text-default');
};

const TabBar: FC<React.PropsWithChildren<ITabBarProps>> = ({
  color,
  roundTopCorners,
  tabBarBackgroundColor,
  ...props
}) => (
  <RNTabBar
    {...props}
    onTabPress={ev => {
      if (ev.route.disabled) {
        ev.preventDefault();
      }
    }}
    pressColor="transparent"
    style={[
      roundTopCorners && {
        borderTopStartRadius: 30,
        borderTopEndRadius: 30,
      },
      {
        backgroundColor: tabBarBackgroundColor,
      },
    ]}
    contentContainerStyle={{
      borderBottomWidth: 1,
      borderBottomColor: theme.token('border-color-default'),
    }}
    indicatorStyle={{ backgroundColor: getIndicatorColor(color), height: 3 }}
    renderLabel={({ route, focused }) => {
      return (
        <Center>
          <Text
            variant="copyOne"
            margin={0}
            minWidth="180px"
            textAlign="center"
            color={[
              focused ? theme.token('background-active') : getTextColor(color, route.disabled),
            ]}
            fontWeight={focused ? '700' : '400'}
          >
            {route.title}
          </Text>
          {route.subTitle && (
            <Text variant="copyOne" color={getTextColor(color, route.disabled)}>
              {route.subTitle}
            </Text>
          )}
        </Center>
      );
    }}
  />
);

export default TabBar;
