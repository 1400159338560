/**
 * Old capacitor implementation for reference
 */

import React, {
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import mParticle from '@mparticle/web-sdk';
import { AllowedEvent, IGlobalAttributes } from '@rbilabs/mparticle-client';
import { compact, isEqual, isUndefined, merge, omitBy } from 'lodash-es';
import { centsToDollars } from 'utils';
import uuidv4 from 'uuid/v4';

import { IBackendCartEntries, ICartEntry, IServerOrder } from '@rbi-ctg/menu';
import { IUserOffersFeedbackEntry } from '@rbi-ctg/offers';
import { useIsMobileBp } from 'hooks/breakpoints';
import useReadyQueue from 'hooks/use-ready-queue';
import { getRoundUpDonations } from 'pages/cart/your-cart/totals/utils';
import { HttpErrorCodes } from 'remote/constants';
import { IStaticPageRoute } from 'remote/queries/static-page';
import { useLocale } from 'state/intl';
import { useLDContext } from 'state/launchdarkly';
import { ServiceMode } from 'state/order';
import { StoreProxy } from 'state/store';
import { CartEntryType } from 'utils/cart/types';
import { ENABLE_IDENTITY_SYNC_ON_LOGIN } from 'utils/constants';
import { addContext as addLoggerContext } from 'utils/datadog';
import { getDeviceId } from 'utils/device-id';
import { brand, env, getCurrentCommitHash, isNative } from 'utils/environment';
import { EventName, addEventListener, removeEventListener } from 'utils/event-hub';
import LocalStorage from 'utils/local-storage';
import { Keys } from 'utils/local-storage/constants';
import logger from 'utils/logger';
import { convertQueryStringToObject } from 'utils/navigation';
import noop from 'utils/noop';
import { getInCodeLocalizedRouteForPath, routes } from 'utils/routing';

import {
  ClickEventComponentNames,
  CustomEventNames,
  EventTypes,
  ONE_INDEX_OFFSET,
  ProductActionTypes,
  SignInPhases,
  TRACKED_PAGES,
} from './constants';
import { createMparticleIdentity } from './create-mparticle-identity';
import { MParticleAdapter } from './mparticle-adapter';
import { updateMParticleATTStatus } from './mparticle-att-plugin';
import { getSessionId } from './session-manager';
import setUserAttributes, {
  setUserUTMAttributes,
  updateLocationPermissionStatus,
} from './set-user-attributes';
import {
  HTTPCode,
  IAddToCartSelectionAttributes,
  ILogPageView,
  ILogRBIEvent,
  IMParticleCtx,
  IMParticleProduct,
  IMParticlePurchaseEventAttributes,
  IMParticleSublevelItem,
  IMParticleUniversalAttributes,
  ISignInEventOptions,
  ISignUpEventOptions,
  IUtmParams,
  ProductItemType,
} from './types';
import {
  booleanToString,
  flattenCartEntryItems,
  normalizeBooleans,
  sanitizeValues,
  serializeNumberOfDriveThruWindows,
  serializePaymentType,
  serializePickupMode,
  serializeServiceMode,
} from './utils';

// Export action from index
export { CustomEventNames, EventTypes } from './constants';
export type { IMParticleCtx, IAppflowUpdateEventOptions } from './types';

export const APPFLOW_DISPLAYED_BLOCKING_UI = 'Displayed Update UI';

// returns window location path name
const getSourcePage = () => {
  const pathName = window.location.pathname;
  // removes menu child routes
  const parsedPathName = pathName.replace(/(menu)\/(.*)/i, '$1/');
  return parsedPathName;
};

export const MParticleContext = React.createContext<IMParticleCtx>({
  init: noop,
  login: noop,
  logout: noop,
  deviceId: 'fakeid',
  sessionId: 'fakeid',
  signInEvent: noop,
  signUpEvent: noop,
  updateUniversalAttributes: noop,
  updateStaticRoutes: noop,
  updateUserAttributes: noop,
  updateUserLocationPermissionStatus: noop,
  updateUserIdentities: noop,
  logPageView: noop,
  logCommercePageView: noop,
  addToCart: noop,
  updateItemInCart: noop,
  removeFromCart: noop,
  logPurchase: noop,
  selectServiceMode: noop,
  logCheckoutEvent: noop,
  logUpsellAddedEvent: noop,
  logRBIEvent: noop,
  logNavBarClickEvent: noop,
  marketingTileClickEvent: noop,
  setUTMParamsFromUrl: noop,
});

export const useMParticleContext = () => useContext<IMParticleCtx>(MParticleContext);

export function MParticleProvider(props: { children: ReactNode }) {
  const { enqueueIfNotDrained, drainQueue } = useReadyQueue();
  const { locale, language, region } = useLocale();
  // TODO: RN-WEB - recover cookie banner for WEB
  //const hasAcceptedCookies = useHasAcceptedCookies();
  //const enableCookieBanner = useFlag(LaunchDarklyFlag.ENABLE_COOKIE_BANNER);
  const [sessionId, setSessionId] = useState<string>('');
  const [deviceId, setDeviceId] = useState<string>('');
  const { updateUserDeviceId } = useLDContext();

  const isSmallScreen = useIsMobileBp();

  // store static routes for logging page views
  const staticRoutes = useRef<string[]>([]);
  // Its possible to attempt to log a page view, before sanity loads the static pages
  // This ref holds these values until static pages has loaded, after which the page view can be logged
  const logPageViewParameters = useRef<ILogPageView>();

  const getUserHasLoyalty = () => !!LocalStorage.getItem(Keys.USER)?.loyaltyId;
  // store universal attributes for mParticle event/page views without re-rendering for changes in values
  const universalAttributes = useRef<IMParticleUniversalAttributes>({
    'Service Mode': '',
    'Pickup Mode': '',
    'Source Page': getSourcePage(),
    isLoyaltyUser: getUserHasLoyalty(),
    isSmallScreen,
    currentBuild: getCurrentCommitHash(),
  });
  // set up a backup unique session id in case ad blockers block mParticle
  const uniqueGuid = useRef<string>(uuidv4());

  const updateUserLocationPermissionStatus = useCallback(() => {
    const user = mParticle.Identity.getCurrentUser();
    if (!user) {
      return;
    }
    updateLocationPermissionStatus(user);
  }, []);

  const maybeTrackPage = enqueueIfNotDrained(({ pathname, store }: ILogPageView) => {
    const trackedPages = TRACKED_PAGES.concat(staticRoutes.current);
    const matchedPages = trackedPages.filter(page => pathname.startsWith(page));
    if (matchedPages.length) {
      // Find the longest match by setting it to be the first element
      const matchedPage = matchedPages.sort((a, b) => b.length - a.length)[0];
      logRBIEvent({
        name: CustomEventNames.PAGE_VIEW,
        type: EventTypes.Other,
        attributes: {
          path: matchedPage,
          restaurantId: store?.number ?? '',
          restaurantAddress: store?.physicalAddress?.address1 ?? '',
          restaurantZip: store?.physicalAddress?.postalCode ?? '',
          restaurantCity: store?.physicalAddress?.city ?? '',
          restaurantState: store?.physicalAddress?.stateProvince ?? '',
          restaurantCountry: store?.physicalAddress?.country ?? '',
          'Google.Page': '',
          'Google.DocumentReferrer': '',
          pathname,
          sanityId: '',
          referrer: '',
        },
      });
    }
  });

  const updateStaticRoutes = useCallback(
    (newStaticRoutes: IStaticPageRoute[]) => {
      staticRoutes.current = newStaticRoutes.reduce((acc: string[], route) => {
        const staticPath = route?.localePath?.[language]?.current || route?.path?.current;
        if (staticPath) {
          acc.push(`/${staticPath}`);
        }
        return acc;
      }, []);

      if (staticRoutes.current.length && logPageViewParameters.current) {
        maybeTrackPage({ ...logPageViewParameters.current });
        logPageViewParameters.current = undefined;
      }
    },
    [language, maybeTrackPage]
  );

  const configureSessionId = enqueueIfNotDrained(() =>
    getSessionId()
      .then(({ sessionId: currentSessionUUID }) => {
        const currentSessionId = currentSessionUUID || uniqueGuid.current;

        setSessionId(currentSessionId);
        addLoggerContext('session', currentSessionId);
      })
      .catch(({ error, message }) => {
        // This happens when the web does not have a sessionId yet
        // for iOS to use. We won't bother making it an error
        // since there is nothing for us to do
        if (message === 'No session ID available') {
          logger.warn(message);

          return;
        }

        logger.error({
          message: `Failed to get MParticle SessionID: ${error || message}`,
        });
      })
  );

  const updateDeviceId = enqueueIfNotDrained(async () => {
    const id = await getDeviceId();
    updateUserDeviceId(id);
    setDeviceId(id);
  });

  const deviceTime = useCallback(() => new Date().toLocaleTimeString([], { hour12: false }), []);

  // initializes mParticle
  const init = useCallback(() => {
    const user = mParticle.Identity?.getCurrentUser();
    // @ts-expect-error TS(2554) FIXME: Expected 0 arguments, but got 2.
    setUserUTMAttributes(user, new URLSearchParams(window.location.search));
    updateUserLocationPermissionStatus();
    drainQueue();
  }, [drainQueue, updateUserLocationPermissionStatus]);

  // using state causes this to happen immediately, instead of on next tick.
  useState(() => {
    // Enqueue setting the sessionId and deviceId to prevent race condition
    configureSessionId();
    // @ts-expect-error TS(2554) FIXME: Expected 1 arguments, but got 0.
    updateMParticleATTStatus();
    updateDeviceId();

    // TODO: RN-WEB - recover cookie banner for WEB
    //if (!enableCookieBanner || hasAcceptedCookies) {
    //  init();
    //}

    init();
  });

  //update a user attribute(consent would be a good place to start)

  //Consent mgmt is it's own thing.
  //https://docs.mparticle.com/developers/sdk/web/consent-management/

  const errorIdentityCallback = ({
    identityFn,
    callback,
    result,
    tryAgain = true,
    params,
  }: {
    identityFn: any;
    callback: any;
    result: any;
    tryAgain?: boolean;
    params?: any;
  }) => {
    switch (result.httpCode) {
      case HTTPCode.NATIVE_IDENTITY_REQUEST:
        return;
      case HTTPCode.NO_HTTP_COVERAGE:
        if (tryAgain) {
          return identityFn(params, { callback, tryAgain });
        }
        break;
      case HTTPCode.ACTIVE_IDENTITY_REQUEST:
      case HttpErrorCodes.TooManyRequests:
        if (tryAgain) {
          return identityFn(params, { callback, tryAgain: false });
        }
        break;
      case HTTPCode.VALIDATION_ISSUE:
      case 400:
      default:
        logger.error({ error: result.body });
    }
  };

  const updateUniversalAttributes = useCallback(
    (newAttributes: Partial<IMParticleUniversalAttributes>) =>
      (universalAttributes.current = { ...universalAttributes.current, ...newAttributes }),
    []
  );

  const setUTMParamsFromUrl = useCallback(
    (url: string) => {
      const {
        utm_source = '',
        utm_campaign = '',
        utm_medium = '',
        utm_content = '',
        utm_term = '',
      } = convertQueryStringToObject<IUtmParams>(url);

      if (utm_source || utm_campaign || utm_medium || utm_content || utm_term) {
        updateUniversalAttributes({
          'UTM Source': utm_source,
          'UTM Medium': utm_medium,
          'UTM Campaign': utm_campaign,
          'UTM Term': utm_term,
          'UTM Content': utm_content,
        });
      }
    },
    [updateUniversalAttributes]
  );

  //USER EVENTS
  //https://docs.mparticle.com/developers/sdk/web/idsync/

  const login = enqueueIfNotDrained(
    ({ email, customerid, ...userAttributes } = {}, { callback = noop, tryAgain = true } = {}) => {
      mParticle.Identity.login({ userIdentities: { email, customerid } }, result => {
        const user = result.getUser();

        if (!user.isLoggedIn() && !isNative) {
          return errorIdentityCallback({
            identityFn: login,
            callback,
            result,
            tryAgain,
            params: {
              email,
              customerid,
              ...userAttributes,
            },
          });
        }

        const normalizedAttrs = normalizeBooleans(userAttributes);
        setUserAttributes(user, normalizedAttrs);

        if (ENABLE_IDENTITY_SYNC_ON_LOGIN) {
          updateUserIdentities({ email, customerid });
        }

        configureSessionId();
        callback(result);
      });
    }
  );

  const updateUserAttributes = enqueueIfNotDrained(
    (userAttributes = {}, { callback = noop } = {}) => {
      const user = mParticle.Identity.getCurrentUser();

      const normalizedAttrs = normalizeBooleans(userAttributes);
      // @ts-expect-error TS(2345) FIXME: Argument of type 'IMParticleUser' is not assignabl... Remove this comment to see the full error message
      setUserAttributes(user, normalizedAttrs);

      callback();
    }
  );

  const updateUserIdentities: any = enqueueIfNotDrained(
    ({ email, customerid, ccToken } = {}, { callback = noop, tryAgain = true } = {}) => {
      const user = mParticle.Identity.getCurrentUser();
      const currentUser = user?.getUserIdentities().userIdentities;
      // We should not set customerid to null ever. Will use existing customer id if data
      // being passed in is null
      const newCustomerId = customerid ? customerid : currentUser.customerid;
      const updatedUserIdentities = merge(
        {},
        currentUser,
        createMparticleIdentity(email, newCustomerId, ccToken)
      );

      // values are unchanged
      if (isEqual(currentUser, updatedUserIdentities)) {
        return;
      }
      return MParticleAdapter.Identity.modify({ userIdentities: updatedUserIdentities }, result => {
        if (result.httpCode !== 200) {
          return errorIdentityCallback({
            identityFn: updateUserIdentities,
            callback,
            result,
            tryAgain,
            params: {
              email,
              customerid,
              ccToken,
            },
          });
        }
      });
    }
  );

  // make call to idenitify with empty userIdentities to retrieve anonymous user by device
  // We do this instead of creating a new one on logout so that we don't overstate Monthly users
  const emptyUserIdentityRequest = { userIdentities: {} };

  const logout = enqueueIfNotDrained(({ callback = noop, tryAgain = true } = {}) => {
    mParticle.Identity.identify(emptyUserIdentityRequest, (result: any) => {
      if (result.httpCode !== HTTPCode.SUCCESS) {
        return errorIdentityCallback({
          identityFn: logout,
          callback,
          tryAgain,
          result,
        });
      }

      callback();
    });
  });

  const createSublevelItems = (cartEntry: ICartEntry): IMParticleSublevelItem[] => {
    // Get cart entry sublevel items
    const subItems = flattenCartEntryItems(cartEntry).filter(
      item => item._id !== cartEntry._id && item.type === CartEntryType.item
    );

    // Merge sublevel items by item id
    const mergedItemsById = subItems.reduce<{
      [id: string]: IMParticleSublevelItem | undefined;
    }>((acc, item) => {
      const curItem = acc[item._id];
      if (curItem) {
        curItem.quantity += item.quantity;
      } else {
        acc[item._id] = { id: item._id, quantity: item.quantity };
      }
      return acc;
    }, {});

    return compact(Object.values(mergedItemsById));
  };

  const createProduct = useCallback(
    (cartEntry: ICartEntry | IBackendCartEntries): IMParticleProduct | null => {
      const createSublevelProducts = (cartEntry: ICartEntry): IMParticleProduct[] => {
        const subProducts: IMParticleProduct[] = [];
        // Get cart entry sublevel items
        const subItems = flattenCartEntryItems(cartEntry);

        // Merge sublevel items by item id
        for (const subItem of subItems) {
          if (
            subItem._id !== cartEntry._id &&
            subItem.type === CartEntryType.item &&
            subItem.productHierarchy
          ) {
            const p = createProduct(subItem);
            if (p) {
              p.Attributes.comboChild = booleanToString(true);
              subProducts.push(p);
            }
          }
        }
        return subProducts;
      };

      const cartId = 'lineId' in cartEntry ? cartEntry.lineId : cartEntry.cartId;
      const _id = '_id' in cartEntry ? cartEntry._id : cartEntry.sanityId;
      const { name = '', price, quantity, isDonation = false, isExtra = false } = cartEntry;
      const basePrice = price ? centsToDollars(price / quantity) : 0;
      const product = MParticleAdapter.createProduct(name, _id, basePrice, quantity);

      if (!product) {
        return null;
      }

      const productSublevelItems = createSublevelItems(cartEntry as ICartEntry);
      const itemLevel =
        productSublevelItems.length === 0 ? ProductItemType.Child : ProductItemType.Parent;

      product.Attributes = {
        cartId: cartId || _id,
        sublevelItems: JSON.stringify(productSublevelItems),
        isDonation: booleanToString(isDonation),
        isExtra: booleanToString(isExtra),
        'Item Level': itemLevel,
        comboChild: booleanToString(false),
      };

      if (itemLevel === ProductItemType.Child) {
        product.Attributes = {
          ...product.Attributes,
          L1: cartEntry.productHierarchy?.L1 || '',
          L2: cartEntry.productHierarchy?.L2 || '',
          L3: cartEntry.productHierarchy?.L3 || '',
          L4: cartEntry.productHierarchy?.L4 || '',
          L5: cartEntry.productHierarchy?.L5 || '',
        };
      }

      product.SubProducts =
        productSublevelItems.length > 0 ? createSublevelProducts(cartEntry as ICartEntry) : [];
      return product;
    },
    []
  );

  const getCartDataItems = useCallback(
    (cartEntries: ICartEntry[]): string => {
      const cartData = JSON.stringify({
        items: cartEntries.map(entry => ({
          items: createProduct(entry),
        })),
      });
      const cartDataItemsRegex = /item_\d{3,}/gi;
      return cartData?.match(cartDataItemsRegex)?.join() || '';
    },
    [createProduct]
  );

  const addToCart = enqueueIfNotDrained(
    (
      cartEntry: ICartEntry,
      serviceMode: ServiceMode,
      previousCartEntries: ICartEntry[],
      selectionAttrs?: IAddToCartSelectionAttributes
    ) => {
      const product = createProduct(cartEntry);

      if (!product) {
        return;
      }

      const customAttributes = {
        'Pickup Mode': serializePickupMode(serviceMode),
        'Is Kiosk': booleanToString(false),
        'Device Time': deviceTime(),
        'Source Page': getSourcePage(),
        'Is Update': booleanToString(false),
        'Cart Data': getCartDataItems(previousCartEntries),
        'Picker Aspect Selection': booleanToString(!!selectionAttrs?.pickerAspectSelection),
        'Combo Slot Selection': booleanToString(!!selectionAttrs?.comboSlotSelection),
        'Item Modified': booleanToString(!!selectionAttrs?.itemModified),
      };

      const params = {
        type: mParticle.ProductActionType.AddToCart,
        products: [product],
        attrs: customAttributes,
      };

      MParticleAdapter.logProductAction(params);
    }
  );

  const updateItemInCart = enqueueIfNotDrained(
    (newCartEntry: ICartEntry, originalCartEntry: ICartEntry, serviceMode: ServiceMode) => {
      const oldProduct = createProduct(originalCartEntry);
      const newProduct = createProduct(newCartEntry);

      if (!oldProduct || !newProduct) {
        return;
      }

      const removalAttrs = {
        brand: brand().toUpperCase(),
        env: env() as string,
        region,
        'Is Kiosk': booleanToString(false),
        'Device Time': deviceTime(),
        'Source Page': getSourcePage(),
        'Is Update': booleanToString(true),
      };

      const removalParams = {
        type: ProductActionTypes.RemoveFromCart,
        products: [oldProduct],
        attrs: removalAttrs,
      };

      MParticleAdapter.logProductAction(removalParams);

      const additionAttrs = {
        brand: brand().toUpperCase(),
        env: env() as string,
        region,
        'Pickup Mode': serializePickupMode(serviceMode),
        'Is Kiosk': booleanToString(false),
        'Device Time': deviceTime(),
        'Source Page': getSourcePage(),
        'Is Update': booleanToString(true),
      };

      const additionParams = {
        type: ProductActionTypes.AddToCart,
        products: [newProduct],
        attrs: additionAttrs,
      };

      MParticleAdapter.logProductAction(additionParams);
    }
  );

  const removeFromCart = enqueueIfNotDrained((cartEntry: ICartEntry) => {
    const product = createProduct(cartEntry);

    if (!product) {
      return;
    }

    const customAttributes = {
      brand: brand().toUpperCase(),
      env: env() as string,
      region,
      'Is Kiosk': booleanToString(false),
      'Device Time': deviceTime(),
      'Source Page': getSourcePage(),
      'Is Update': booleanToString(false),
    };

    const params = {
      type: ProductActionTypes.RemoveFromCart,
      products: [product],
      attrs: customAttributes,
    };

    MParticleAdapter.logProductAction(params);
  });

  const logPurchase = enqueueIfNotDrained(
    (
      cartEntries: ICartEntry[],
      store: StoreProxy,
      serviceMode: ServiceMode,
      serverOrder: IServerOrder,
      attrs = {}
    ) => {
      const couponIDs = (serverOrder.cart.offersFeedback || []).map(
        (feedbackEntry: IUserOffersFeedbackEntry) => {
          return feedbackEntry.couponId;
        }
      );

      // Upsells
      // - hasUpsell
      // - upsellTotal
      const upsells = cartEntries.filter(entry => entry.isUpsell);
      const hasUpsell = !!upsells.length;
      const upsellTotal = centsToDollars(
        upsells.reduce((total, entry) => total + (entry.price || 0), 0)
      );

      // Create Chef payload
      const upsellEntry = upsells.find(entry => !!entry.recommendationToken);
      const recommendationToken = upsellEntry?.recommendationToken;
      const recommender = upsellEntry?.recommender;
      const chef =
        hasUpsell && recommendationToken
          ? {
              eventType: 'purchase-complete',
              userInfo: {
                visitorId: mParticle?.Identity?.getCurrentUser()?.getUserIdentities()
                  ?.userIdentities.customerid,
              },
              eventDetail: {
                recommendationToken,
              },
              productEventDetail: {
                productDetails: cartEntries.map(entry => ({
                  id: entry._id,
                  quantity: entry.quantity,
                  displayPrice: entry.price,
                  currencyCode: attrs?.currencyCode || 'USD',
                })),
                purchaseTransaction: {
                  id: serverOrder.rbiOrderId,
                  revenue: centsToDollars(serverOrder.cart.subTotalCents),
                  currencyCode: attrs?.currencyCode || 'USD',
                },
              },
            }
          : null;

      const couponIDString = couponIDs.join();
      const serializedServiceMode = serializeServiceMode(serviceMode);

      const rewardAttributes = serverOrder.cart.rewardsApplied?.map(reward => ({
        'Reward ID': reward.rewardId,
        'Reward Quantity': reward.timesApplied,
      }));

      const transactionAttributes = {
        Id: serverOrder.rbiOrderId,
        Revenue: centsToDollars(serverOrder.cart.subTotalCents),
        Tax: centsToDollars(serverOrder.cart.taxCents),
      };

      const roundUpDonation = getRoundUpDonations(serverOrder);

      // Some of these are duplicates from transactionAttributes,
      // but BI wants to have them under specific property names.
      const additionalAttrs: IMParticlePurchaseEventAttributes = {
        brand: brand().toUpperCase(),
        env: env() as string,
        region,
        'Pickup Mode': serializePickupMode(serviceMode),
        'Service Mode': serializedServiceMode,
        branch_service_mode: serializedServiceMode,
        customer_event_alias: serializedServiceMode,
        'CC Token': serverOrder?.cart?.payment?.panToken ?? null,
        'Coupon ID': couponIDString,
        'Coupon Applied': booleanToString(couponIDs.length > 0),
        Currency: attrs.currencyCode,
        'Tax Amount': transactionAttributes.Tax,
        'Total Amount': transactionAttributes.Revenue,
        'Transaction Order Number ID': serverOrder?.posOrderId ?? '',
        'Transaction POS': serverOrder?.cart?.posVendor ?? null,
        'Transaction RBI Cloud Order ID': serverOrder?.rbiOrderId ?? null,
        'Timed Fire Minutes': attrs.fireOrderInMinutes,
        'Restaurant ID': store.number,
        'Restaurant Name': store.name,
        'Restaurant Number': store.number,
        'Restaurant Address': store.physicalAddress?.address1 ?? null,
        'Restaurant City': store.physicalAddress?.city ?? null,
        'Restaurant State/Province Name': store.physicalAddress?.stateProvince ?? null,
        'Restaurant Postal Code': store.physicalAddress?.postalCode ?? null,
        'Restaurant Country': store.physicalAddress?.country ?? null,
        'Restaurant Latitude': store.latitude,
        'Restaurant Longitude': store.longitude,
        'Restaurant Status': store.status,
        'Restaurant Drink Station Type': store.drinkStationType,
        'Restaurant Drive Thru Lane Type': store.driveThruLaneType ?? null,
        'Restaurant Franchise Group Id': store.franchiseGroupId,
        'Restaurant Franchise Group Name': store.franchiseGroupName,
        'Restaurant Front Counter Closed': store.frontCounterClosed,
        'Restaurant Has Breakfast': store.hasBreakfast,
        'Restaurant Has Burgers For Breakfast': store.hasBurgersForBreakfast,
        'Restaurant Has Curbside': store.hasCurbside,
        'Restaurant Has Front Counter Closed': store.frontCounterClosed,
        'Restaurant Has Catering': store.hasCatering,
        'Restaurant Has Dine In': store.hasDineIn,
        'Restaurant Has Drive Thru': store.hasDriveThru,
        'Restaurant Has Home Delivery': store.hasDelivery,
        'Restaurant Has Mobile Ordering': store.hasMobileOrdering,
        'Restaurant Has Parking': store.hasParking,
        'Restaurant Has Playground': store.hasPlayground,
        'Restaurant Has Take Out': store.hasTakeOut,
        'Restaurant Has Wifi': store.hasWifi,
        'Restaurant Number Drive Thru Windows': serializeNumberOfDriveThruWindows(
          store.driveThruLaneType
        ),
        'Restaurant Parking Type': store.parkingType,
        'Restaurant Playground Type': store.playgroundType,
        'Restaurant POS': store.pos?.vendor ?? null,
        'Restaurant POS Version': store.pos?.version ?? null,
        'Is Kiosk': false,
        'Card Type': serverOrder.cart.payment?.cardType || '',
        'Payment Type': serializePaymentType(serverOrder.cart.payment?.paymentType),
        'Has Upsell': hasUpsell,
        'Upsell Total': upsellTotal,
        'Recommender Provider': recommender || '',
        Chef: chef ? JSON.stringify(chef) : null,
        'Device Time': deviceTime(),
        'Source Page': getSourcePage(),
        'Cart Data': getCartDataItems(cartEntries),
        Rewards: rewardAttributes ? JSON.stringify(rewardAttributes) : null,
        'Is Loyalty': !!serverOrder.loyaltyTransaction,
        roundUpAmount: roundUpDonation?.totalCents ?? 0,
      };

      // Delivery Fees
      if (serializeServiceMode(serviceMode) === 'Delivery') {
        additionalAttrs.deliveryFeeAmount = centsToDollars(attrs.deliveryFeeCents);
        additionalAttrs.deliveryDiscountAmount = centsToDollars(attrs.deliveryFeeDiscountCents);
        additionalAttrs.deliveryGeographicalFeeAmount = centsToDollars(
          attrs.deliveryGeographicalFeeCents
        );
        additionalAttrs.deliveryServiceFeeAmount = centsToDollars(attrs.deliveryServiceFeeCents);
        additionalAttrs.deliverySmallCartFeeAmount = centsToDollars(
          attrs.deliverySmallCartFeeCents
        );
        additionalAttrs.totalDeliveryOrderFeeAmount = centsToDollars(
          attrs.totalDeliveryOrderFeesCents
        );
        additionalAttrs.deliverySurchargeFeeAmount = centsToDollars(
          attrs.deliverySurchargeFeeCents
        );
        additionalAttrs.quotedFeeAmount = centsToDollars(attrs.quotedFeeCents);
        additionalAttrs.baseDeliveryFeeAmount = centsToDollars(attrs.baseDeliveryFeeCents);
      }

      if (transactionAttributes.Revenue >= 20) {
        additionalAttrs['Value Threshold 20 Met'] = true;
      }

      if (transactionAttributes.Revenue >= 15) {
        additionalAttrs['Value Threshold 15 Met'] = true;
      }

      if (transactionAttributes.Revenue >= 10) {
        additionalAttrs['Value Threshold 10 Met'] = true;
      }
      if (transactionAttributes.Revenue >= 5) {
        additionalAttrs['Value Threshold 5 Met'] = true;
      }

      const normalizedTransactionAttrs = normalizeBooleans(transactionAttributes);

      const sanitizedAdditionAttrs = sanitizeValues(additionalAttrs);
      const normalizedAdditionalAttrs = normalizeBooleans(sanitizedAdditionAttrs);

      const products = cartEntries.reduce((accumulator, cartEntry) => {
        const eCommerceProduct = createProduct(cartEntry);

        if (!eCommerceProduct) {
          return accumulator;
        }

        const rewardApplied = serverOrder.cart.rewardsApplied?.find(
          reward => reward.cartId === eCommerceProduct.Attributes.cartId
        );

        accumulator.push({
          ...eCommerceProduct,
          Attributes: {
            ...eCommerceProduct.Attributes,
            rewardItem: booleanToString(!!rewardApplied),
          },
        });

        //now we expand the product list with the SubProducts listed on each product, for ecommerce purchases only
        if (eCommerceProduct.SubProducts.length > 0) {
          accumulator = accumulator.concat(eCommerceProduct.SubProducts);
        }

        return accumulator;
      }, [] as IMParticleProduct[]);

      try {
        // for docs, refer https://docs.mparticle.com/developers/sdk/web/core-apidocs/classes/mParticle.eCommerce.html
        MParticleAdapter.logProductAction({
          type: ProductActionTypes.Purchase,
          products,
          attrs: normalizedAdditionalAttrs,
          transactionalAttrs: normalizedTransactionAttrs,
        });
      } catch (error) {
        logger.error({ error, message: 'mParticle > logPurchase error' });
      }
    }
  );

  const logPageView: IMParticleCtx['logPageView'] = enqueueIfNotDrained((pathname, store) => {
    if (pathname.startsWith(routes.menu)) {
      const onMainMenu = new RegExp(routes.menu.concat('$')).test(pathname);
      if (onMainMenu) {
        return logRBIEvent({
          name: CustomEventNames.PAGE_VIEW,
          type: EventTypes.Other,
          attributes: {
            path: pathname,
            restaurantId: store?.number ?? '',
            restaurantAddress: store?.physicalAddress?.address1 ?? '',
            restaurantZip: store?.physicalAddress?.postalCode ?? '',
            restaurantCity: store?.physicalAddress?.city ?? '',
            restaurantState: store?.physicalAddress?.stateProvince ?? '',
            restaurantCountry: store?.physicalAddress?.country ?? '',
            'Google.Page': '',
            'Google.DocumentReferrer': '',
            pathname,
            sanityId: '',
            referrer: '',
          },
        });
      }
    }
    // fixes issue with home page not being captured
    if (pathname === '/') {
      logRBIEvent({
        name: CustomEventNames.PAGE_VIEW,
        type: EventTypes.Other,
        attributes: {
          path: pathname,
          restaurantId: store?.number ?? '',
          restaurantAddress: store?.physicalAddress?.address1 ?? '',
          restaurantZip: store?.physicalAddress?.postalCode ?? '',
          restaurantCity: store?.physicalAddress?.city ?? '',
          restaurantState: store?.physicalAddress?.stateProvince ?? '',
          restaurantCountry: store?.physicalAddress?.country ?? '',
          'Google.Page': '',
          'Google.DocumentReferrer': '',
          pathname,
          sanityId: '',
          referrer: '',
        },
      });
    } else {
      // Checking if path is local path
      const isLocalRoute = Object.values(routes).some(route => {
        const localizedRoute = getInCodeLocalizedRouteForPath(route, locale, region) || route;
        return route !== '/' && pathname.startsWith(localizedRoute);
      });
      // If staticRoutes have not loaded yet
      // Store them for later when they are available
      if (!isLocalRoute && !staticRoutes.current.length) {
        return maybeTrackWhenStaticRoutesAvailable({ pathname, store });
      }
      maybeTrackPage({ pathname, store });
    }
  });

  const logCommercePageView = useCallback(
    (menuData: { id: string; name: string; menuType: string }, attrs = {}) => {
      const { name, id, menuType } = menuData;
      const product = MParticleAdapter.createProduct(name, id, 0, 1) as IMParticleProduct;

      MParticleAdapter.logProductAction({
        type: ProductActionTypes.ViewDetail,
        products: [product],
        attrs: { menuType, ...attrs },
      });
    },
    []
  );

  const maybeTrackWhenStaticRoutesAvailable = ({ pathname, store }: ILogPageView) => {
    logPageViewParameters.current = {
      pathname,
      store,
    };
  };

  const selectServiceMode = enqueueIfNotDrained(mode => {
    logRBIEvent({
      name: CustomEventNames.SELECT_SERVICE_MODE,
      type: EventTypes.Other,
    });
  });

  const logUpsellAddedEvent = enqueueIfNotDrained((item: ICartEntry, itemPosition?: number) => {
    if (!item.isUpsell) {
      return;
    }
    const { name, price } = item;
    logRBIEvent({
      name: CustomEventNames.UPSELL_ADDED,
      type: EventTypes.Other,
      attributes: {
        name,
        price,
        sanityId: item._id,
        upsellItemPosition: itemPosition,
      },
    });
  });

  const logCheckoutEvent = useCallback(
    (serviceMode: ServiceMode, cartEntries: ICartEntry[]) => {
      const products = cartEntries.map(createProduct).filter(Boolean) as IMParticleProduct[];
      const pickUpMode = serializePickupMode(serviceMode);
      const customAttributes = {
        'Pickup Mode': pickUpMode,
        'Cart Data': getCartDataItems(cartEntries),
      };

      MParticleAdapter.logProductAction({
        type: ProductActionTypes.Checkout,
        products,
        attrs: customAttributes,
      });
    },
    [createProduct, getCartDataItems]
  );

  /**
   * Logs an event to mParticle using the RBI events interface from @rbilabs/mparticle-client.
   */
  const logRBIEvent: ILogRBIEvent = useCallback(
    (event: AllowedEvent) => {
      let universalAttrs = sanitizeValues(universalAttributes.current);
      universalAttrs = normalizeBooleans(universalAttrs);
      const globalAttributes: IGlobalAttributes = {
        brand: brand().toUpperCase(),
        region,
        env: env() as string,
        currentScreen: getSourcePage(),
        deviceTime: deviceTime(),
        serviceMode: universalAttrs['Service Mode'],
        pickupMode: universalAttrs['Pickup Mode'],
        appBuild: universalAttrs.currentBuild,
      };
      MParticleAdapter.logEvent(
        event.name,
        event.type,
        {
          ...omitBy(globalAttributes, isUndefined),
          ...omitBy(event.globalAttributes, isUndefined),
          ...omitBy(event.attributes, isUndefined),
        },
        event.customFlags
      );
    },
    [deviceTime]
  );

  useEffect(() => {
    const listener = (payload: any): void => {
      if (!logRBIEvent) {
        return;
      }
      const error = extractError();
      if (!error) {
        return;
      }

      logRBIEvent({
        name: CustomEventNames.APP_ERROR,
        type: EventTypes.Other,
        attributes: {
          'error.message': error.message ?? '',
          'error.stack': error.stack ?? '',
          'info.componentStack': (payload.context?.info?.componentStack ?? '') as string,
        },
      });

      function extractError(): Error | undefined {
        if (payload instanceof Error) {
          return payload;
        }
        if (payload.error instanceof Error) {
          return payload.error;
        }
        return undefined;
      }
    };

    addEventListener(EventName.LOGGER_ERROR, listener);

    return () => removeEventListener(EventName.LOGGER_ERROR, listener);
  }, [logRBIEvent]);

  const signInEvent = useCallback(
    ({ phase, success }: ISignInEventOptions) => {
      const response = success ? 'Successful' : 'Failure';

      if (phase === SignInPhases.START) {
        logRBIEvent({
          name: 'Sign in with OTP Attempt',
          type: EventTypes.Other,
          attributes: {
            signInType: 'Email',
            response,
          },
        });
      }
    },
    [logRBIEvent]
  );

  const marketingTileClickEvent = useCallback(
    (title: string, position: number, cardId: string) => {
      logRBIEvent({
        name: CustomEventNames.CLICK_EVENT,
        type: EventTypes.Navigation,
        attributes: {
          component: ClickEventComponentNames.MARKETING_TILE,
          text: title,
          position: `Tile ${position + ONE_INDEX_OFFSET}`,
          componentId: cardId,
        },
      });
    },
    [logRBIEvent]
  );

  const signUpEvent = useCallback(
    ({ success }: ISignUpEventOptions) => {
      if (success) {
        logRBIEvent({
          name: 'Sign Up Successful',
          type: EventTypes.Other,
          attributes: {
            signUpType: 'Email',
          },
        });
      }
    },
    [logRBIEvent]
  );

  const logNavBarClickEvent = useCallback(
    (text: string, componentKey?: string) => {
      logRBIEvent({
        name: CustomEventNames.CLICK_EVENT,
        type: EventTypes.Navigation,
        attributes: {
          component: ClickEventComponentNames.NAV_BAR,
          text,
          componentId: componentKey,
        },
      });
    },
    [logRBIEvent]
  );

  const mParticleContext = useMemo<IMParticleCtx>(
    () => ({
      init,
      login,
      logout,
      signInEvent,
      deviceId,
      signUpEvent,
      updateStaticRoutes,
      updateUserAttributes,
      updateUserIdentities,
      updateUserLocationPermissionStatus,

      // pageView data
      logPageView,
      logCommercePageView,

      // eCommerce events
      addToCart,
      updateItemInCart,
      removeFromCart,
      logPurchase,

      // custom events
      selectServiceMode,
      logCheckoutEvent,
      logUpsellAddedEvent,
      marketingTileClickEvent,
      logNavBarClickEvent,
      setUTMParamsFromUrl,

      // initialized sessionId from mParticle
      sessionId: sessionId || uniqueGuid.current,

      // Allows for universal attrs
      updateUniversalAttributes,
      logRBIEvent,
    }),
    [
      addToCart,
      deviceId,
      init,
      logCheckoutEvent,
      logCommercePageView,
      login,
      logNavBarClickEvent,
      logout,
      logPageView,
      logPurchase,
      logRBIEvent,
      logUpsellAddedEvent,
      marketingTileClickEvent,
      removeFromCart,
      selectServiceMode,
      sessionId,
      setUTMParamsFromUrl,
      signInEvent,
      signUpEvent,
      updateItemInCart,
      updateStaticRoutes,
      updateUniversalAttributes,
      updateUserAttributes,
      updateUserIdentities,
      updateUserLocationPermissionStatus,
    ]
  );
  return (
    <MParticleContext.Provider value={mParticleContext}>{props.children}</MParticleContext.Provider>
  );
}

export default MParticleContext.Consumer;
