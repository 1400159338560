import React, { createContext, useContext, useEffect, useMemo } from 'react';

import { IBaseProps } from '@rbi-ctg/frontend';
import {
  IAllFeatureIdsQuery,
  useAllFeatureIdsQuery,
  useFeatureNutritionLazyQuery,
} from 'generated/sanity-graphql';
import { usePrevious } from 'hooks/use-previous';

interface IFeatureIds {
  featureAccountId?: string;
  featureAccountDeletePageId?: string;
  featureAccountFormId?: string;
  featureAccountRequestInfoPageId?: string;
  featureDisclaimersId?: string;
  featureFooterId?: string;
  featureGeolocationMapMarkersId?: string;
  featureGeolocationModalId?: string;
  featureGiftId?: string;
  featureHomePageId?: string;
  featureLayoutId?: string;
  featureLinkPhysicalCardUIId?: string;
  featureMenuId?: string;
  featureNavigationId?: string;
  featureOffersId?: string;
  featureSupportDataId?: string;
  featureStaticMenuId?: string;
  featureLoyaltyUIId?: string;
  featureClaimPointsUIId?: string;
  featureNutritionId?: string;
  featureLoyaltyRewardListCategoriesId?: string;
  featureBeeperId?: string;
  featureDonationId?: string;
  featureFoodwareId?: string;
  // add new feature ids here
}

export interface IFeaturesContext extends IFeatureIds {
  featureIdsLoading: boolean;
  refetchFeatures: () => void;
}

export const FeaturesContext = createContext<IFeaturesContext>({
  featureIdsLoading: true,
  refetchFeatures: () => null,
});

export const useFeaturesContext = () => useContext(FeaturesContext);

export const HOMEPAGE_ID = 'feature-home-page-singleton';

const processAllFeatureIds = (data: IAllFeatureIdsQuery | undefined): IFeatureIds => {
  if (!data) {
    return {};
  }

  const getSingletonId = (id: keyof Omit<IAllFeatureIdsQuery, '__typename'>) => {
    return data?.[id]?.[0]?._id;
  };

  return {
    featureAccountId: getSingletonId('allFeatureAccounts'),
    featureAccountDeletePageId: getSingletonId('allFeatureAccountDeletePages'),
    featureAccountFormId: getSingletonId('allFeatureAccountForms'),
    featureAccountRequestInfoPageId: getSingletonId('allFeatureAccountRequestInfoPages'),
    featureDisclaimersId: getSingletonId('allFeatureDisclaimers'),
    featureGeolocationMapMarkersId: getSingletonId('allFeatureGeolocationMapMarkers'),
    featureFooterId: getSingletonId('allFeatureFooters'),
    featureGeolocationModalId: getSingletonId('allFeatureGeolocationModals'),
    featureGiftId: getSingletonId('allFeatureGifts'),
    featureHomePageId: HOMEPAGE_ID,
    featureLoyaltyUIId: getSingletonId('allLoyaltyUis'),
    featureLinkPhysicalCardUIId: getSingletonId('allLinkPhysicalCardUis'),
    featureClaimPointsUIId: getSingletonId('allClaimPointsUis'),
    featureLayoutId: getSingletonId('allFeatureLayouts'),
    featureMenuId: getSingletonId('allFeatureMenus'),
    featureNavigationId: getSingletonId('allFeatureNavigations'),
    featureNutritionId: getSingletonId('allFeatureNutritions'),
    featureOffersId: getSingletonId('allFeatureOffers'),
    featureSupportDataId: getSingletonId('allSupportData'),
    featureStaticMenuId: getSingletonId('allFeatureStaticMenus'),
    featureLoyaltyRewardListCategoriesId: getSingletonId('allRewardLists'),
    featureBeeperId: getSingletonId('allFeatureBeeperModals'),
    featureDonationId: getSingletonId('allFeatureDonations'),
    featureFoodwareId: getSingletonId('allFeatureFoodwares'),
  };
};

export const FeaturesProvider: React.FC<React.PropsWithChildren<IBaseProps>> = ({ children }) => {
  const { data: allFeatureIds, loading: featureIdsLoading, refetch } = useAllFeatureIdsQuery();
  const featureIds = useMemo(() => processAllFeatureIds(allFeatureIds), [allFeatureIds]);

  const [dispatchFeatureNutritionQuery] = useFeatureNutritionLazyQuery({
    fetchPolicy: 'network-only',
  });
  const { featureNutritionId } = featureIds;
  const previousFeatureNutritionId = usePrevious(featureNutritionId);

  useEffect(() => {
    // pre-fetch critical data after feature nutrition id changes
    if (previousFeatureNutritionId !== featureNutritionId && featureNutritionId) {
      const prefetch = async () => {
        dispatchFeatureNutritionQuery({
          variables: { featureNutritionId },
        });
      };
      prefetch();
    }
  }, [
    dispatchFeatureNutritionQuery,
    featureIdsLoading,
    featureNutritionId,
    previousFeatureNutritionId,
  ]);

  const contextValue = useMemo(() => {
    return { ...featureIds, featureIdsLoading, refetchFeatures: refetch };
  }, [featureIds, featureIdsLoading, refetch]);

  return <FeaturesContext.Provider value={contextValue}>{children}</FeaturesContext.Provider>;
};
