import React, { useEffect, useMemo } from 'react';

import { Box } from '@rbilabs/universal-components';
import delv from 'dlv';

import { BlankScreen } from 'components/blank-screen';
import { Helmet } from 'components/helmet';
import LoadingAnimation from 'components/loading-animation';
import LoadingContainer from 'components/loading-animation/loading-container';
import { BackArrowHistory } from 'components/navigation/back-arrow';
import { useConfigValue } from 'hooks/configs/use-config-value';
import { useNavigation } from 'hooks/navigation/use-navigation';
import { useRoute } from 'hooks/navigation/use-route';
import useEffectOnce from 'hooks/use-effect-once';
import useRoutes from 'hooks/use-routes';
import NotFound from 'pages/not-found';
import { RenderCurrentStaticPage } from 'pages/static/render-current-static-page';
import { IStaticPageRoute } from 'remote/queries/static-page';
import { useLocale } from 'state/intl';
import { PROD_SUPPORTED_LANGUAGES, supportedLanguagesByRegion } from 'state/intl/constants';
import { usePageManagerContext } from 'state/static-page-manager';
import { useGetStaticPageByRoute } from 'state/static-page-manager/hooks/use-get-static-page-by-route';
import { routes as appRoutes } from 'utils/routing';
import { isRedirectedToSimplexUrl } from 'utils/simplex';

import { StaticPageSkeleton } from './static-page.skeleton';

const Container = Box.withConfig({
  flex: 1,
  paddingTop: {
    sm: '0px',
  },
  marginTop: {
    sm: '0px',
  },
});

const StaticPageContainer = () => {
  const { routesLoading, loadRoutes, loadRoutesHasBeenCalled } = usePageManagerContext();
  useEffectOnce(() => {
    // If the loadRoutes query has not been called yet, load the static routes list
    if (!loadRoutesHasBeenCalled) {
      loadRoutes();
    }
  });
  if (!loadRoutesHasBeenCalled || routesLoading) {
    return (
      <LoadingContainer>
        <LoadingAnimation />
      </LoadingContainer>
    );
  }
  return <StaticPage />;
};

export const StaticPage = () => {
  const { currentPage, retrieveStaticPageById, routes, routesLoading } = usePageManagerContext();
  const { getLocalizedRouteForPath } = useRoutes();
  const { language: currentLanguage, region, setCurrentLocale } = useLocale();
  const { navigate } = useNavigation();
  const url = useConfigValue({ key: 'urls', defaultValue: {} })?.webBaseUrl ?? null;

  const { params } = useRoute<{ staticPagePath: string }>();
  const { staticPagePath } = params || {};

  // Remove first and last slash to check the "staticPath"
  const staticPath = (staticPagePath || '').replace(/^\/|\/$/g, '');

  const targetPage = useMemo(() => {
    let result: undefined | { route: IStaticPageRoute; language: string };
    routes.forEach(route => {
      if (routesLoading) {
        result = undefined;
      }

      const staticLocalizedPageLanguage = Object.keys(PROD_SUPPORTED_LANGUAGES).find(
        lang => delv(route, `localePath.${lang}.current`) === staticPath
      );

      if (staticLocalizedPageLanguage) {
        result = { route, language: staticLocalizedPageLanguage };
      }

      if (delv(route, `path.current`) === staticPath) {
        result = {
          route,
          language: supportedLanguagesByRegion[region.toLowerCase()].defaultLanguage,
        };
      }
    });
    return result;
  }, [routes, routesLoading, staticPath, region]);

  useEffect(() => {
    if (!targetPage) {
      return;
    }
    if (targetPage.language !== currentLanguage) {
      setCurrentLocale(targetPage.language, region);
    }

    retrieveStaticPageById(
      targetPage.route._id,
      targetPage.route?.path?.current === appRoutes.nutritionExplorer.slice(1)
    );
  }, [targetPage, currentLanguage, retrieveStaticPageById, region, setCurrentLocale]);

  const is404 = useMemo(() => !routesLoading && !targetPage, [routesLoading, targetPage]);

  if (is404) {
    // If it is a SEO simplex URL navigate home.
    const fullPath = `/${staticPath}`;
    const wasRedirectedToSimplex = isRedirectedToSimplexUrl(fullPath, url);

    if (wasRedirectedToSimplex) {
      navigate(appRoutes.base);
      return (
        <BlankScreen
          context={{
            reason: 'Redirect to simple from static pages',
          }}
        />
      );
    }
    // if the route wasn't found see if we can translate it
    const foundPath = getLocalizedRouteForPath(fullPath);
    // If found redirect
    if (foundPath !== fullPath) {
      navigate(foundPath);
      return (
        <BlankScreen
          context={{
            reason: 'Redirecting to localized route',
          }}
        />
      );
    }
    return <NotFound />;
  }

  return currentPage ? (
    <Box testID="static-page" flex={1}>
      <Helmet title={currentPage.title} />
      <BackArrowHistory />
      <Container>
        <RenderCurrentStaticPage currentPage={currentPage} />
      </Container>
    </Box>
  ) : (
    <LoadingContainer>
      <StaticPageSkeleton />
    </LoadingContainer>
  );
};

/**
 * This function takes a pathname as param and render a Static Page component that can be embedded
 * inside a page / component so we're not limited to using routes for static content.
 */
export const StaticPageEmbedded = ({
  pathname,
  currentPageOverrideCss,
}: {
  pathname: string;
  currentPageOverrideCss?: string;
}) => {
  const { currentPage, getStaticPageByRouteId, is404 } = useGetStaticPageByRoute();

  useEffect(() => {
    getStaticPageByRouteId(pathname);
  }, [getStaticPageByRouteId, pathname]);

  if (is404) {
    return <NotFound />;
  }

  return (
    <Box testID="static-page-embeded" flex={1}>
      {currentPage ? (
        <RenderCurrentStaticPage
          currentPage={currentPage}
          currentPageOverrideCss={currentPageOverrideCss}
        />
      ) : (
        <LoadingContainer>
          <StaticPageSkeleton />
        </LoadingContainer>
      )}
    </Box>
  );
};

export default StaticPageContainer;
