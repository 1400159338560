import React, { FC, memo, useCallback, useEffect, useMemo, useState } from 'react';

// @ts-expect-error TS(2307) FIXME: Cannot find module 'graphql/tsutils/Maybe' or its ... Remove this comment to see the full error message
import Maybe from 'graphql/tsutils/Maybe';
import { keyBy } from 'lodash-es';
import { useIntl } from 'react-intl';

import LoadingAnimation from 'components/loading-animation';
import { withStoreSelected } from 'components/store-selected';
import { useFocusEffect } from 'hooks/navigation/use-focus-effect';
import { useNavigation } from 'hooks/navigation/use-navigation';
import { useRoute } from 'hooks/navigation/use-route';
import { appendObjectToQueryString } from 'hooks/navigation/utils';
import { useRumPageView } from 'hooks/rum/use-rum-page-view';
import { useFeatureLoyaltyRewardListCategories } from 'hooks/use-feature-loyalty-reward-list-categories';
import { useLoyaltyRewardsList } from 'hooks/use-loyalty-rewards-list';
import { useReactNavigationFlag } from 'hooks/use-react-navigation-flag';
import { navigationRef } from 'navigation/navigation-container/navigation-container-ref';
import { useAuthContext } from 'state/auth';
import { useRewardsEvaluation } from 'state/loyalty/hooks/use-loyalty-rewards-evaluation';
import { CustomEventNames, EventTypes, useMParticleContext } from 'state/mParticle';
import { EventName, emitEvent } from 'utils/event-hub';
import { routes } from 'utils/routing';

import { ONLY_SHOW_REDEEM_REWARD_IN_RESTAURANT_CTA } from '../loyalty-incentives-components/incentive-details/constants';
import LoyaltyIncentiveNotAvailableModal from '../loyalty-incentives-components/loyalty-incentive-not-available-modal';
import { LoyaltyNotEnrolled } from '../loyalty-not-enrolled';

import { SHOULD_EXPAND_CATEGORIES } from './constants';
import { LoyaltyLoaderContainer } from './loyalty-rewards.styled';
import { LoyaltyRewardsView } from './loyalty-rewards.view';
import { NonNullableRewardCategory } from './types';

// TODO: remove this code for brands with SHOULD_EXPAND_CATEGORIES === false
/**
 * Determines the category that should be open by default based on the passed pre-selections
 * @param rewardCategories filtered categories
 * @param preselectedCategoryLabel pre-selected category label taken from the current route
 * @param preselectedRewardId pre-selected reward id taken from the current route
 */
export const getRewardCategoriesDefaultSelection = (
  rewardCategories: NonNullableRewardCategory[],
  preselectedCategoryLabel?: string | null,
  preselectedRewardId?: string
): Maybe<NonNullableRewardCategory> => {
  let selectedCategory;
  let selectedRewardParentCategory;
  for (const category of rewardCategories) {
    if (preselectedCategoryLabel === category.label?.en) {
      selectedCategory = category;
    }
    for (const reward of category.rewards ?? []) {
      if (preselectedRewardId === reward?._id) {
        selectedRewardParentCategory = category;
      }
    }
  }

  return selectedRewardParentCategory || selectedCategory || rewardCategories?.find(Boolean);
};

const useFilteredRewardCategories = () => {
  const { data: baseCategories } = useFeatureLoyaltyRewardListCategories();
  const { sanityRewardsMap, engineRewardsMap } = useLoyaltyRewardsList();
  const rewardCategories = useMemo(
    () =>
      baseCategories?.reduce((filteredCategories: NonNullableRewardCategory[], category) => {
        if (category?.rewards?.length && sanityRewardsMap) {
          const filteredRewards = category.rewards.filter(reward => {
            return (
              reward?._id &&
              sanityRewardsMap[reward._id] &&
              engineRewardsMap[reward?.loyaltyEngineId || '']
            );
          });
          if (filteredRewards.length) {
            filteredCategories.push({ ...category, rewards: filteredRewards || [] });
          }
        }
        return filteredCategories;
      }, []),
    [baseCategories, engineRewardsMap, sanityRewardsMap]
  );

  return rewardCategories ?? [];
};

interface ILoyaltyRewardsProps {
  title?: string;
  isWidget?: boolean;
}

/**
 *
 * LoyaltyRewards displays a list of available rewards that can be redeemed at checkout
 *
 */

const LoyaltyRewards: FC<React.PropsWithChildren<ILoyaltyRewardsProps>> = ({
  title,
  isWidget = false,
}) => {
  const enableReactNativeNavigation = useReactNavigationFlag();

  const { user } = useAuthContext();
  const [selectedRewardIdFromWidget, setSelectedRewardIdFromWidget] = useState('');
  const { engineRewardsMap, loading, sanityRewardsMap, refetchRewards } = useLoyaltyRewardsList();

  useFocusEffect(
    useCallback(() => {
      emitEvent(EventName.SCREEN_RENDER, {
        screenId: 'loyaltyRewards',
      });
    }, [])
  );

  const { rewardsEvaluationMap } = useRewardsEvaluation();

  const { logRBIEvent } = useMParticleContext();

  const { linkTo } = useNavigation();
  const { formatMessage } = useIntl();

  const { params } = useRoute<{ rewardId: string; category: string }>();
  const { rewardId, category: preselectedCategoryLabel } = params || {};
  // ReactNavigation: in case a modal is opened above Rewards page, we need to preserve the original reward detail id
  const [memoizedRewardId] = useState(rewardId);

  // If using as widget, the reward ID is saved in state
  const selectedRewardId = isWidget
    ? selectedRewardIdFromWidget
    : enableReactNativeNavigation
    ? memoizedRewardId
    : rewardId;

  // Local state for opened accordions
  const [selectedCategories, setSelectedCategories] = useState<string[]>([]);

  const rewardCategories = useFilteredRewardCategories();

  const handleRewardOnPress = useCallback(
    (incentiveId: string, incentiveName: string, incentiveSanityId: string) => {
      if (selectedRewardId !== incentiveSanityId) {
        logRBIEvent({
          name: CustomEventNames.REWARD_SELECTED,
          type: EventTypes.Other,
          attributes: {
            engineId: incentiveId,
            sanityId: incentiveSanityId,
            name: incentiveName,
          },
        });
        if (isWidget && !enableReactNativeNavigation) {
          setSelectedRewardIdFromWidget(incentiveSanityId);
          return;
        }
      }

      let onlyShowRedeemRewardValue = params[ONLY_SHOW_REDEEM_REWARD_IN_RESTAURANT_CTA];
      if (enableReactNativeNavigation) {
        const currentRoute = navigationRef.getCurrentRoute();
        if (currentRoute?.params) {
          onlyShowRedeemRewardValue =
            currentRoute.params[ONLY_SHOW_REDEEM_REWARD_IN_RESTAURANT_CTA];
        }
      }

      const objectParams =
        ONLY_SHOW_REDEEM_REWARD_IN_RESTAURANT_CTA && onlyShowRedeemRewardValue
          ? {
              [ONLY_SHOW_REDEEM_REWARD_IN_RESTAURANT_CTA]: onlyShowRedeemRewardValue,
            }
          : {};
      const incentiveDetailRoute = appendObjectToQueryString(
        `${routes.loyaltyRewardsList}/${incentiveSanityId}`,
        objectParams
      );
      linkTo(incentiveDetailRoute);
    },
    [enableReactNativeNavigation, isWidget, linkTo, logRBIEvent, params, selectedRewardId]
  );

  // TODO: BK - remove this code for brands with SHOULD_EXPAND_CATEGORIES === false
  // Set default opened accordion based on pre-selections
  const rewardCategoriesMap = keyBy(rewardCategories, cat => cat?.label?.en);
  const defaultCategory = getRewardCategoriesDefaultSelection(
    rewardCategories,
    preselectedCategoryLabel,
    selectedRewardId
  );
  useEffect(() => {
    if (SHOULD_EXPAND_CATEGORIES) {
      const isSelectedCategoryAvailable = selectedCategories.some(cat => rewardCategoriesMap[cat]);
      if (!selectedCategories.length || !isSelectedCategoryAvailable) {
        const categoryLabel = defaultCategory?.label?.en;
        if (categoryLabel) {
          setSelectedCategories([categoryLabel]);
        }
      }
    }
    // selectedCategories should not be in the deps, to prevent setting defaultCategory when all accordions are manually closed
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultCategory]);

  useRumPageView(
    `loyalty-rewards${selectedRewardId ? '-detail' : '-list'}`,
    `Loyalty Rewards${selectedRewardId ? ' Detail' : ' List'}`
  );

  if (loading) {
    return (
      <LoyaltyLoaderContainer>
        <LoadingAnimation />
      </LoyaltyLoaderContainer>
    );
  }

  if (!user?.loyaltyId) {
    return <LoyaltyNotEnrolled />;
  }

  const selectedReward = sanityRewardsMap?.[selectedRewardId || ''];
  if (sanityRewardsMap && selectedRewardId && !selectedReward) {
    return <LoyaltyIncentiveNotAvailableModal />;
  }

  return rewardCategories?.length && sanityRewardsMap ? (
    <LoyaltyRewardsView
      selectedReward={selectedReward}
      handleOnPress={handleRewardOnPress}
      sanityRewardsMap={sanityRewardsMap}
      rewardCategories={rewardCategories}
      engineRewardsMap={engineRewardsMap}
      formatMessage={formatMessage}
      selectedCategories={selectedCategories}
      setSelectedCategories={setSelectedCategories}
      refetchRewards={refetchRewards}
      isRefreshingRewards={loading}
      rewardsEvaluationMap={rewardsEvaluationMap}
      isWidget={isWidget}
      title={title}
      setSelectedRewardIdFromWidget={setSelectedRewardIdFromWidget}
    />
  ) : null;
};

export default memo(withStoreSelected(LoyaltyRewards));
