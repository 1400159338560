import { useEffect, useRef } from 'react';

import { MenuObjectTypes } from 'enums/menu';
import { useMParticleContext } from 'state/mParticle';

const menuObjectTypes = new Set([
  MenuObjectTypes.PICKER.valueOf(),
  MenuObjectTypes.ITEM.valueOf(),
  MenuObjectTypes.COMBO.valueOf(),
]);

interface IUseLogMenuContentHook {
  loggedValues: {
    name: string;
    menuType: string;
    id: string;
  };
  pathname: string;
}

export const useLogMenuContent = ({ loggedValues, pathname }: IUseLogMenuContentHook) => {
  const loggedMenuObjectId = useRef<string | undefined>();
  const { logCommercePageView } = useMParticleContext();

  const { menuType, id } = loggedValues;
  useEffect(() => {
    if (!id || loggedMenuObjectId.current === id) {
      return;
    }

    if (menuObjectTypes.has(menuType)) {
      logCommercePageView(loggedValues, { path: pathname });
    }

    loggedMenuObjectId.current = id;
  }, [id, logCommercePageView, loggedValues, menuType, pathname]);
};
