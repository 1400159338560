import * as React from 'react';
import { FC, ReactNode, useCallback, useMemo, useState } from 'react';

import { useIntl } from 'react-intl';

import ActionButton from 'components/action-button';
import { GeolocationDeniedModal } from 'components/geolocation-denied-modal';
import { EmptyStateActions as ActionOptions } from 'components/store-locator-empty-states/types';
import useRoutes from 'hooks/use-routes';
import { useGeolocation } from 'state/geolocation';

import { Wrapper } from './empty-state-action.styled';

/**
 *
 * EmptyStateAction enables displaying action options, i.e. a Sign In button, for different empty states
 *
 */
export const EmptyStateAction: FC<React.PropsWithChildren<{ action: ActionOptions }>> = ({
  action,
}) => {
  const { isPermissionPrompt, isPermissionDenied, setPermissionChecking } = useGeolocation();
  const { formatMessage } = useIntl();
  const { getLocalizedRouteForPath } = useRoutes();

  const [geolocationDeniedModalOpen, setGeolocationDeniedModalOpen] = useState(false);

  const handleGeolocationPrompt = useCallback(() => {
    if (isPermissionDenied) {
      setGeolocationDeniedModalOpen(true);
    } else if (isPermissionPrompt) {
      setPermissionChecking();
    }
  }, [isPermissionDenied, isPermissionPrompt, setPermissionChecking]);

  const dismissModal = () => setGeolocationDeniedModalOpen(false);

  const ActionComponent: ReactNode | null = useMemo(() => {
    switch (action) {
      case ActionOptions.LOGIN:
        return (
          <ActionButton to={getLocalizedRouteForPath('signin')} testID="signin-button">
            {formatMessage({ id: 'signIn' })}
          </ActionButton>
        );
      case ActionOptions.SHARE_LOCATION:
        return (
          <>
            <GeolocationDeniedModal
              isOpen={geolocationDeniedModalOpen}
              dismissModal={dismissModal}
            />
            <ActionButton testID="share-location-button" onPress={handleGeolocationPrompt}>
              {formatMessage({ id: 'shareMyLocation' })}
            </ActionButton>
          </>
        );
      default:
        return null;
    }
  }, [
    action,
    formatMessage,
    geolocationDeniedModalOpen,
    getLocalizedRouteForPath,
    handleGeolocationPrompt,
  ]);

  if (!ActionComponent) {
    return null;
  }

  return <Wrapper testID="empty-state-action-wrapper">{ActionComponent}</Wrapper>;
};
