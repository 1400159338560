import React from 'react';

import { Box } from '@rbilabs/universal-components';

import { Animation } from 'components/lottie-animations/loyalty-opt-in-modal';

import type { IOptInModalImage } from './types';

const LottieWrapper = Box.withConfig({
  marginBottom: '-40px',
});

export const OptInModalImage: IOptInModalImage = () => (
  <LottieWrapper testID="opt-in-default-animation">
    <Animation />
  </LottieWrapper>
);
