import { useEffect, useMemo } from 'react';

import { IRestaurantNode } from 'generated/rbi-graphql';
import { useConfigValue } from 'hooks/configs/use-config-value';
import useGoogleGeolocationLibrary from 'hooks/geolocation/use-google-geolocation-library';
import { IUseMapHook, MarkerTypes } from 'hooks/use-map/types';
import { useGeolocation } from 'state/geolocation';
import { useLocale } from 'state/intl';
import { LaunchDarklyFlag, useFlag } from 'state/launchdarkly';
import { useServiceModeContext } from 'state/service-mode';
import { isValidPosition } from 'utils/geolocation';
import { isMobileOrderingAvailable, isRestaurantOpen } from 'utils/restaurant';
import { isCatering } from 'utils/service-mode';

// TODO: RN - properly type these props or remove props
export interface IUseMarkersProps {
  createMarker: ReturnType<IUseMapHook>['createMarker'];
  panTo: ReturnType<IUseMapHook>['panTo'];
  onPress: (store: IRestaurantNode) => any;
  storesNearby?: IRestaurantNode[];
  storesFavs?: IRestaurantNode[];
  activeStoreId?: string | null;
}

// TODO: RN - Rename to `useStoreMapMarkers` as this only is to track markers for the nearby stores
export default function useMarkers({
  createMarker,
  panTo,
  onPress,
  storesNearby,
  storesFavs,
  activeStoreId,
}: IUseMarkersProps) {
  const { libraryLoaded: libLoaded } = useGoogleGeolocationLibrary();
  const { activeCoordinates } = useGeolocation();
  const { region } = useLocale();
  const { serviceMode } = useServiceModeContext();
  const isAlphaBetaStoreOrderingEnabled = useFlag(
    LaunchDarklyFlag.ENABLE_ALPHA_BETA_STORE_ORDERING
  );

  const restaurantsConfig = useConfigValue({ key: 'restaurants', defaultValue: {} });
  const validMobileOrderingEnvs = useMemo(() => restaurantsConfig.validMobileOrderingEnvs ?? [], [
    restaurantsConfig.validMobileOrderingEnvs,
  ]);

  const storeFavsMap = useMemo(() => new Set(storesFavs?.map(x => x._id)), [storesFavs]);

  useEffect(() => {
    if (!libLoaded) {
      return;
    }

    (storesNearby ?? []).forEach(store => {
      const id = store._id;

      if (!id) {
        return;
      }

      const location = { lat: store.latitude, lng: store.longitude };
      if (!isValidPosition(location)) {
        return;
      }

      const diningRoomOpen = isRestaurantOpen(store.diningRoomHours);
      const driveThruOpen = isRestaurantOpen(store.driveThruHours);
      const open = driveThruOpen || diningRoomOpen;

      const isCorrectRegion =
        store &&
        store.physicalAddress &&
        store.physicalAddress.country &&
        store.physicalAddress.country.toUpperCase().startsWith(region);

      let storeMarkerType = MarkerTypes.StoreActive;

      if (id === activeStoreId) {
        storeMarkerType = MarkerTypes.StoreInActive;
      }

      const isStoreFavorite = storeFavsMap.has(id);
      if (isStoreFavorite && id !== activeStoreId) {
        storeMarkerType = MarkerTypes.StoreFavOpen;
      }

      const isCateringServiceMode = isCatering(serviceMode);
      const isStoreAvailable =
        isCateringServiceMode ||
        (isMobileOrderingAvailable(
          store,
          isAlphaBetaStoreOrderingEnabled,
          validMobileOrderingEnvs
        ) &&
          open &&
          isCorrectRegion);

      if (!isStoreAvailable) {
        if (isStoreFavorite) {
          storeMarkerType = MarkerTypes.StoreFavClosed;
        } else {
          storeMarkerType = MarkerTypes.StoreDisabled;
        }
      }

      createMarker({
        type: storeMarkerType,
        location,
        onPress: () => {
          panTo(location);
          onPress(store);
        },
      });
    });
  }, [
    activeStoreId,
    createMarker,
    isAlphaBetaStoreOrderingEnabled,
    libLoaded,
    onPress,
    region,
    serviceMode,
    storeFavsMap,
    storesNearby,
    validMobileOrderingEnvs,
    panTo,
  ]);

  // Create a marker for the users coordinates
  useEffect(() => {
    if (libLoaded && activeCoordinates) {
      createMarker({ type: MarkerTypes.User, location: activeCoordinates });
    }
  }, [activeCoordinates, createMarker, libLoaded]);
}
