import React, { ComponentType, useCallback, useContext, useEffect, useMemo, useState } from 'react';

import { makeUclComponent } from '@rbilabs/universal-components';
import { defaultTo, isEqualWith } from 'lodash-es';
import { useIntl } from 'react-intl';
import { Alert } from 'react-native';
import { isFalse } from 'utils';

import { IBaseProps } from '@rbi-ctg/frontend';
import { IStore } from '@rbi-ctg/store';
import { useConfigValue } from 'hooks/configs/use-config-value';
import { useNavigation } from 'hooks/navigation/use-navigation';
import { useRoute } from 'hooks/navigation/use-route';
import useDialogModal, { Props as DialogProps } from 'hooks/use-dialog-modal';
import useEffectOnce from 'hooks/use-effect-once';
import { useServiceModeStatusGenerator } from 'hooks/use-service-mode-status';
import { LaunchDarklyFlag, useFlag } from 'state/launchdarkly';
import { useRestaurantPosConnectivityStatus } from 'state/restaurant-connectivity-status';
import { useServiceModeContext } from 'state/service-mode';
import { DISABLE_STORE_CHANGE_DIALOG } from 'state/store/constants';
import { offerIsAvailable } from 'utils/offers';
import {
  isMobileOrderingAvailable,
  isRestaurantOpen,
  mergeRestaurantData,
  useGetRestaurantFn,
} from 'utils/restaurant';
import { routes } from 'utils/routing';
import { getLocalizedServiceModeCategory, isCatering, isDelivery } from 'utils/service-mode';
import SessionStorage, { SessionStorageKeys } from 'utils/session-storage';

import {
  ISelectStoreOptions,
  IStoreContext,
  OnConfirmStoreChangeParams,
  SelectedStore,
  StorePermissions,
  createStoreProxyFromStore,
  curriedGetStoreStatusV1,
  curriedGetStoreStatusV2,
  useStore,
} from './hooks';
import { useStoreUtils } from './hooks/use-store-utils';

export * from './hooks/types';

export const StoreContext = React.createContext<IStoreContext>({} as IStoreContext);
export const useStoreContext = () => useContext<IStoreContext>(StoreContext);

const styleDialog = (Dialog: ComponentType<React.PropsWithChildren<unknown>>) =>
  makeUclComponent(Dialog).withConfig({
    width: 'full',
    height: 'full',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  });

export const StoreProvider = ({ children }: IBaseProps) => {
  // LD Flags
  const enableOrdering = defaultTo(useFlag(LaunchDarklyFlag.ENABLE_ORDERING), true);
  const enableStoreSelection2_0 = useFlag(LaunchDarklyFlag.ENABLE_STORE_SELECTION_2_0);
  const enableFutureOrdering = useFlag(LaunchDarklyFlag.ENABLE_FUTURE_ORDERING);

  const getRestaurant = useGetRestaurantFn();
  const { formatMessage } = useIntl();
  const { navigate } = useNavigation();
  const { pathname, params } = useRoute<{ 'store-number': string | null }>();
  const { serviceMode } = useServiceModeContext();

  const restaurantsConfig = useConfigValue({ key: 'restaurants', defaultValue: {} });
  const validMobileOrderingEnvs = restaurantsConfig.validMobileOrderingEnvs ?? [];

  const [
    onConfirmStoreChangeParams,
    setOnConfirmStoreChangeParams,
  ] = useState<OnConfirmStoreChangeParams | null>(null);

  const clearOnConfirmStoreChangeParams = () => {
    setOnConfirmStoreChangeParams(null);
  };

  const {
    resetStore,
    setStore,
    store,
    storeProxy,
    selectUnavailableStore,
    onConfirmStoreChange,
    updateUserStoreWithCallback,
  } = useStore();

  const curriedGetStoreStatus = enableStoreSelection2_0
    ? curriedGetStoreStatusV2
    : curriedGetStoreStatusV1;

  const {
    fetchStore,
    email,
    isRestaurantAvailable,
    prices,
    serviceModeStatus,
    resetLastTimeStoreUpdated,
  } = useStoreUtils({
    resetStore,
    store: storeProxy,
  });

  const { generateServiceModeStatusForStore } = useServiceModeStatusGenerator();

  const getStoreStatusFlags = useCallback<IStoreContext['getStoreStatusFlags']>(
    (storeToCheck, selectedServiceMode) => {
      if (!storeToCheck) {
        return {
          isStoreOpenAndAvailable: false,
          isStoreOpenAndUnavailable: false,
          isStoreClosed: false,
          isStoreClosedAndAvailable: false,
          isStoreAvailable: false,
          noStoreSelected: true,
        };
      }
      const serviceModeStatuses = generateServiceModeStatusForStore(
        createStoreProxyFromStore(storeToCheck)
      );
      const status = curriedGetStoreStatus({
        store: storeToCheck,
        serviceModeStatuses,
        selectedServiceMode,
      })();

      return {
        isStoreOpenAndAvailable: status === StorePermissions.OPEN_AND_AVAILABLE,
        isStoreOpenAndUnavailable: status === StorePermissions.OPEN_AND_UNAVAILABLE,
        isStoreClosed: status === StorePermissions.CLOSED,
        isStoreClosedAndAvailable: status === StorePermissions.CLOSED_AND_AVAILABLE,
        isStoreAvailable:
          status === StorePermissions.OPEN_AND_AVAILABLE ||
          (status === StorePermissions.CLOSED_AND_AVAILABLE && enableFutureOrdering),
        noStoreSelected: status === StorePermissions.NO_STORE_SELECTED,
      };
    },
    [generateServiceModeStatusForStore, curriedGetStoreStatus, enableFutureOrdering]
  );

  const {
    isStoreOpenAndAvailable,
    isStoreOpenAndUnavailable,
    isStoreClosed,
    isStoreAvailable,
    noStoreSelected,
  } = useMemo(() => getStoreStatusFlags(store, serviceMode), [
    getStoreStatusFlags,
    store,
    serviceMode,
  ]);

  // If flags are enabled, refresh restaurant availability & poll for the selected store's availability to notify the user when the store becomes unavailable.

  const { isRestaurantPosOnline } = useRestaurantPosConnectivityStatus({
    storeId: storeProxy?.number,
  });

  const onConfirmLocateRestaurants = useCallback(() => {
    resetStore();

    if (pathname !== routes.storeLocator) {
      navigate(routes.storeLocator);
    }
  }, [navigate, pathname, resetStore]);

  const fetchAndSetStore = useCallback(
    async (storeId: string, hasSelection = true) => {
      const fetchedStore = await fetchStore(storeId);

      if (fetchedStore) {
        let formattedStore: SelectedStore | IStore = { ...fetchedStore };

        // add `hasSelection` if in store selection 1.0
        if (!enableStoreSelection2_0 && hasSelection) {
          formattedStore = { ...fetchedStore, hasSelection };
        }

        setStore({ ...formattedStore });
      }
    },
    [enableStoreSelection2_0, fetchStore, setStore]
  );

  const [OrderingUnavailableDialog, openOrderingUnavailableDialog] = useDialogModal({
    onConfirm: onConfirmLocateRestaurants,
    onDismiss: resetStore,
    modalAppearanceEventMessage: 'Ordering is unavailable',
  });

  const [changeStoreMessage, setChangeStoreMessage] = useState(
    formatMessage({ id: 'changeStoreMessage' })
  );

  // TODO: Handle the cancel case properly
  const openStoreChangeDialog = useCallback(
    ({ newStore, callback }: any) => {
      Alert.alert(formatMessage({ id: 'changeStores' }), changeStoreMessage, [
        {
          text: formatMessage({ id: 'okay' }),
          onPress: () => onConfirmStoreChange({ newStore, callback }),
        },
      ]);
    },
    [changeStoreMessage, formatMessage, onConfirmStoreChange]
  );

  const isAlphaBetaStoreOrderingEnabled = useFlag(
    LaunchDarklyFlag.ENABLE_ALPHA_BETA_STORE_ORDERING
  );
  // ENABLE_DELIVERY_CHECKOUT_OUTSIDE_OPENING_HOURS
  const enableDeliveryOutsideOpeningHours = useFlag(
    LaunchDarklyFlag.ENABLE_DELIVERY_CHECKOUT_OUTSIDE_OPENING_HOURS
  );

  const selectStoreDialog = useCallback(
    async ({
      sanityStore,
      hasCartItems,
      callback,
      requestedServiceMode,
      selectedOffer,
      unavailableCartEntries,
    }: ISelectStoreOptions) => {
      // we do not care about POS if ordering is not enabled
      const rbiRestaurant = await getRestaurant(sanityStore.number);
      const isRestaurantPosAvailable = rbiRestaurant?.available;

      // Operating hours now come from our gql layer so we must merge the results
      // with the sanity store.
      // Default to the passed in sanity store if we cannot get a restaurant from
      // the gql layer.
      const newStore = rbiRestaurant
        ? mergeRestaurantData({ rbiRestaurant, sanityStore })
        : sanityStore;

      const selectedServiceMode = requestedServiceMode ?? serviceMode;
      const open =
        isCatering(selectedServiceMode) ||
        (isDelivery(selectedServiceMode) &&
          (isRestaurantOpen(newStore.deliveryHours) || enableDeliveryOutsideOpeningHours)) ||
        isRestaurantOpen(newStore.diningRoomHours) ||
        isRestaurantOpen(newStore.driveThruHours);

      const {
        isStoreAvailable: isNewStoreAvailable,
        isStoreOpenAndUnavailable: isNewStoreOpenAndUnavailable,
      } = getStoreStatusFlags(newStore, selectedServiceMode);

      // ensure store did not close between render and selection
      if (!open && !isNewStoreAvailable) {
        return openOrderingUnavailableDialog();
      }

      if (
        enableOrdering &&
        // restaurant has no posData
        (!newStore.restaurantPosData ||
          // has posData, but heartbeat is false
          !isRestaurantPosAvailable ||
          // store has no mobile ordering available
          !isMobileOrderingAvailable(
            newStore,
            isAlphaBetaStoreOrderingEnabled,
            validMobileOrderingEnvs
          ))
      ) {
        return openOrderingUnavailableDialog({
          message: formatMessage({
            id: 'storeNoOrderingBody',
          }),
          title: formatMessage({
            id: 'storeNoOrderingHeading',
          }),
        });
      }

      if (isNewStoreOpenAndUnavailable) {
        return onConfirmStoreChange({ newStore, callback });
      }

      if (isEqualWith(storeProxy.physicalAddress, newStore.physicalAddress)) {
        return callback();
      }

      if (!hasCartItems) {
        return onConfirmStoreChange({
          newStore,
          callback,
        });
      }
      if (unavailableCartEntries?.length) {
        // The StoreLocator should open a dialog when this state is set
        setOnConfirmStoreChangeParams({
          callback,
          newStore,
          unavailableCartEntries,
        });
        return;
      }

      const hasShownStoreChangeDialog = SessionStorage.getItem(
        SessionStorageKeys.HAS_SHOWN_STORE_CHANGE_DIALOG
      );

      const isSelectedOfferAvailable =
        !selectedOffer ||
        !selectedServiceMode ||
        offerIsAvailable(selectedOffer, selectedServiceMode);

      if (hasShownStoreChangeDialog && isSelectedOfferAvailable) {
        return onConfirmStoreChange({
          newStore,
          callback,
        });
      }

      let bodyMessageId = 'changeStoreSelectedOfferAndItemDisclaimer';
      if (!hasShownStoreChangeDialog) {
        bodyMessageId = isSelectedOfferAvailable
          ? 'changeStoreItemDisclaimer'
          : 'changeStoreSelectedOfferAndItemDisclaimer';
      } else if (!isSelectedOfferAvailable) {
        bodyMessageId = 'changeStoreSelectedOfferDisclaimer';
      }

      setChangeStoreMessage(
        formatMessage(
          { id: bodyMessageId },
          {
            serviceMode:
              selectedServiceMode &&
              getLocalizedServiceModeCategory(selectedServiceMode, formatMessage),
          }
        )
      );

      if (DISABLE_STORE_CHANGE_DIALOG) {
        return onConfirmStoreChange({
          newStore,
          callback,
        });
      }
      openStoreChangeDialog({
        newStore,
        callback,
      });
      SessionStorage.setItem(SessionStorageKeys.HAS_SHOWN_STORE_CHANGE_DIALOG, true);
    },
    [
      enableDeliveryOutsideOpeningHours,
      enableOrdering,
      formatMessage,
      getRestaurant,
      isAlphaBetaStoreOrderingEnabled,
      isStoreOpenAndUnavailable,
      onConfirmStoreChange,
      openOrderingUnavailableDialog,
      openStoreChangeDialog,
      serviceMode,
      storeProxy.physicalAddress,
      validMobileOrderingEnvs,
    ]
  );

  const storeNumber = params['store-number'];
  useEffect(() => {
    if (storeNumber && storeNumber !== (store as IStore)?.number) {
      void fetchAndSetStore(storeNumber);
    }
  }, [fetchAndSetStore, store, storeNumber]);

  useEffectOnce(() => {
    // Fetch store on initial render if a store is selected
    if (!storeNumber && store && 'number' in store && store.number !== null) {
      const storeHasSelection = !!store && 'hasSelection' in store && store.hasSelection;
      fetchAndSetStore(store.number, storeHasSelection);
    }
  });

  useEffect(() => {
    // We need to explicitly check for false since a restaurant's availability always begins as null when refreshing/logging out.
    if (!noStoreSelected && isStoreOpenAndAvailable && isFalse(isRestaurantPosOnline)) {
      return openOrderingUnavailableDialog();
    }
  }, [
    isRestaurantPosOnline,
    isStoreOpenAndAvailable,
    noStoreSelected,
    openOrderingUnavailableDialog,
  ]);

  const StyledOrderingUnavailableDialog = styleDialog(OrderingUnavailableDialog) as ComponentType<
    React.PropsWithChildren<DialogProps<{}>>
  >;

  const value = useMemo(
    () => ({
      email,
      isRestaurantAvailable,
      openOrderingUnavailableDialog,
      prices,
      selectStore: selectStoreDialog,
      fetchStore: fetchAndSetStore,
      selectUnavailableStore,
      serviceModeStatus,
      setStore,
      resetStore,
      resetLastTimeStoreUpdated,
      getStoreStatusFlags,
      isStoreOpenAndAvailable,
      isStoreOpenAndUnavailable,
      isStoreClosed,
      isStoreAvailable,
      noStoreSelected,
      store: storeProxy,
      updateUserStoreWithCallback,
      onConfirmStoreChange,
      onConfirmStoreChangeParams,
      clearOnConfirmStoreChangeParams,
    }),
    [
      email,
      isRestaurantAvailable,
      openOrderingUnavailableDialog,
      prices,
      selectStoreDialog,
      fetchAndSetStore,
      selectUnavailableStore,
      serviceModeStatus,
      setStore,
      resetStore,
      resetLastTimeStoreUpdated,
      getStoreStatusFlags,
      isStoreOpenAndAvailable,
      isStoreOpenAndUnavailable,
      isStoreClosed,
      isStoreAvailable,
      noStoreSelected,
      storeProxy,
      updateUserStoreWithCallback,
      onConfirmStoreChange,
      onConfirmStoreChangeParams,
    ]
  );

  return (
    // @ts-expect-error TS(2322) FIXME: Type '{ email: st... Remove this comment to see the full error message
    <StoreContext.Provider value={value}>
      {children}
      <StyledOrderingUnavailableDialog
        body={formatMessage({
          id: enableOrdering ? 'cannotPlaceOrder' : 'closeMessageToFindNearbyRestaurants',
        })}
        buttonLabel={formatMessage({ id: 'findRestaurants' })}
        heading={formatMessage({ id: 'storeClosed' })}
      />
    </StoreContext.Provider>
  );
};

export default StoreContext.Consumer;
