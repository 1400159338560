import { useCallback, useEffect, useMemo, useState } from 'react';

import { useIntl } from 'react-intl';

import { ILocation } from '@rbi-ctg/frontend';
import { FilterRestaurantType, OperationalStatus } from 'generated/rbi-graphql';
import useAuth from 'hooks/auth/use-auth';
import { useFavoriteStores } from 'hooks/favorite-stores';
import useDialogModal from 'hooks/use-dialog-modal';
import useErrorModal from 'hooks/use-error-modal';
import { LaunchDarklyFlag, useFlag } from 'state/launchdarkly';
import { ServiceMode, useServiceModeContext } from 'state/service-mode';
import { isValidPosition } from 'utils/geolocation';
import DEFAULT_SEARCH_RADIUS_M from 'utils/restaurant/default-search-radius-meters';

import {
  StoreAction,
  searchFavRequested,
  searchFavsError,
  searchFavsSuccess,
  searchNearbyError,
  searchNearbyRequested,
  searchNearbySuccess,
  searchRecentError,
  searchRecentRequested,
  searchRecentSuccess,
} from '../ducks/stores';

import { useRestaurants } from './helpers/use-restaurants';
import { ISearchNearbyRestaurants, SearchParams } from './types';

interface ISearchStoresProps {
  storeLocatorDispatch: StoreAction;
}

const serviceModesMap: { [key in ServiceMode]?: Array<ServiceMode> } = {
  [ServiceMode.CATERING_DELIVERY]: [ServiceMode.CATERING_DELIVERY],
  [ServiceMode.CATERING_PICKUP]: [ServiceMode.CATERING_PICKUP],
};

export default function useSearchStores({ storeLocatorDispatch }: ISearchStoresProps) {
  const { isAuthenticated } = useAuth();
  const [activeStoreId, setActiveStoreId] = useState('');
  const defaultDiagonal =
    useFlag(LaunchDarklyFlag.RADIUS_FOR_STORE_LOCATOR) || DEFAULT_SEARCH_RADIUS_M;
  const { serviceMode } = useServiceModeContext();
  const serviceModes = useMemo(() => {
    return serviceMode ? serviceModesMap[serviceMode] : undefined;
  }, [serviceMode]);

  const { validFavStores } = useFavoriteStores();
  const { formatMessage } = useIntl();
  const [Dialog, openDialog] = useDialogModal({
    modalAppearanceEventMessage: 'Notify user to enable location services',
  });

  const [ErrorDialog, openErrorDialog] = useErrorModal({
    modalAppearanceEventMessage: 'Error: Fetching Restaurants Error',
  });

  // @todo: refactor use-store-locator logic reducer to handle this
  // without the need for this helper function that repeats these 3 calls/effects
  const { fetch: fetchNearbyRestaurants, ...nearbyRestaurants } = useRestaurants(
    FilterRestaurantType.NEARBY
  );
  const { fetch: fetchRecentRestaurants, ...recentRestaurants } = useRestaurants(
    FilterRestaurantType.RECENT
  );
  const { fetch: fetchFavoriteRestaurants, ...favoriteRestaurants } = useRestaurants(
    FilterRestaurantType.FAVORITE
  );

  const restaurantsSearch = useCallback(
    (location: ILocation | null, searchParams: SearchParams, fetchFn: Function) => {
      const isValid =
        searchParams.filter === FilterRestaurantType.NEARBY
          ? isValidPosition({ lat: location?.lat, lng: location?.lng })
          : isAuthenticated;

      if (!isValid) {
        searchParams.invalidSearchCallback();
        return;
      }

      if (location) {
        fetchFn({
          coordinates: {
            userLat: location?.lat,
            userLng: location?.lng,
            searchRadius: searchParams.coordinates?.searchRadius,
          },
          first: searchParams.first,
          status: searchParams.status,
          serviceModes,
        });
      } else {
        fetchFn({
          serviceModes,
        });
      }
    },
    [isAuthenticated, serviceModes]
  );

  const searchRecentRestaurants = useCallback(
    (location: ILocation | null) => {
      restaurantsSearch(
        location,
        {
          invalidSearchCallback: () => storeLocatorDispatch(searchRecentSuccess([])),
          filter: FilterRestaurantType.RECENT,
        },
        fetchRecentRestaurants
      );
    },
    [fetchRecentRestaurants, restaurantsSearch, storeLocatorDispatch]
  );

  const searchFavRestaurants = useCallback(
    (location: ILocation | null) => {
      restaurantsSearch(
        location,
        {
          invalidSearchCallback: () => storeLocatorDispatch(searchFavsSuccess([])),
          filter: FilterRestaurantType.FAVORITE,
        },
        fetchFavoriteRestaurants
      );
    },
    // Using `validFavStores` to recreate this callback. When this callback
    // is recreated we trigger a search which at the time of this writing
    // looked like in `use-store-locator`:
    // useEffect(() => {
    //   searchFavRestaurants(activeCoordinates);
    // }, [activeCoordinates, searchFavRestaurants]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [restaurantsSearch, fetchFavoriteRestaurants, storeLocatorDispatch, validFavStores]
  );

  const searchNearbyRestaurants = useCallback(
    ({ location }: ISearchNearbyRestaurants) => {
      restaurantsSearch(
        location,
        {
          invalidSearchCallback: () =>
            openDialog({
              message: formatMessage({ id: 'messageAllowLocation' }),
              title: formatMessage({ id: 'titleAllowLocation' }),
            }),
          filter: FilterRestaurantType.NEARBY,
          coordinates: {
            searchRadius: defaultDiagonal,
          },
          first: 20,
          status: OperationalStatus.OPEN,
        },
        fetchNearbyRestaurants
      );
    },
    [defaultDiagonal, fetchNearbyRestaurants, formatMessage, openDialog, restaurantsSearch]
  );

  useEffect(() => {
    if (nearbyRestaurants.error) {
      storeLocatorDispatch(searchNearbyError(nearbyRestaurants.error));
      openErrorDialog({
        message: formatMessage({ id: 'errorRetrievingRestaurants' }),
        error: nearbyRestaurants.error,
      });
      return;
    }

    if (nearbyRestaurants.loading) {
      storeLocatorDispatch(searchNearbyRequested());
      return;
    }

    const stores = nearbyRestaurants.data?.restaurants?.nodes || [];
    storeLocatorDispatch(searchNearbySuccess(stores));
  }, [
    formatMessage,
    nearbyRestaurants.data,
    nearbyRestaurants.error,
    nearbyRestaurants.loading,
    openErrorDialog,
    storeLocatorDispatch,
  ]);

  useEffect(() => {
    if (recentRestaurants.error) {
      storeLocatorDispatch(searchRecentError(recentRestaurants.error));
      return;
    }

    if (recentRestaurants.loading) {
      storeLocatorDispatch(searchRecentRequested());
      return;
    }

    const stores = recentRestaurants.data?.restaurants?.nodes || [];
    storeLocatorDispatch(searchRecentSuccess(stores));
  }, [
    formatMessage,
    openErrorDialog,
    recentRestaurants.data,
    recentRestaurants.error,
    recentRestaurants.loading,
    storeLocatorDispatch,
  ]);

  useEffect(() => {
    if (favoriteRestaurants.error) {
      storeLocatorDispatch(searchFavsError(favoriteRestaurants.error));
      return;
    }

    if (favoriteRestaurants.loading) {
      storeLocatorDispatch(searchFavRequested());
      return;
    }

    const stores = favoriteRestaurants.data?.restaurants?.nodes || [];
    storeLocatorDispatch(searchFavsSuccess(stores));
  }, [
    favoriteRestaurants.data,
    favoriteRestaurants.error,
    favoriteRestaurants.loading,
    formatMessage,
    openErrorDialog,
    storeLocatorDispatch,
  ]);

  return {
    activeStoreId,
    ErrorDialog,
    Dialog,
    searchNearbyRestaurants,
    setActiveStoreId,
    searchFavRestaurants,
    searchRecentRestaurants,
  };
}
