import { useReducer } from 'react';

import storeReducer, { IState, StoreAction, initialState } from '../../ducks/stores';

export const useStoresReducer = (): IState & {
  storeLocatorDispatch: StoreAction;
} => {
  const [
    {
      storesNearby,
      storesFavs,
      storesRecent,
      searchingFavs,
      searchingNearby,
      searchingRecent,
      ...state
    },
    storeLocatorDispatch,
  ] = useReducer(storeReducer, initialState);

  return {
    ...state,
    searchingFavs: searchingFavs || !storesFavs,
    searchingNearby: searchingNearby || !storesNearby,
    searchingRecent: searchingRecent || !storesRecent,
    rawIsSearching: searchingFavs || searchingNearby || searchingRecent,
    storeLocatorDispatch,
    storesFavs,
    storesNearby,
    storesRecent,
  };
};
