import { Box, Header, ScrollView, Text, VStack } from '@rbilabs/universal-components';

import { theme } from 'styles/configure-theme';

export const ModalHeader = Header.withConfig({
  margin: '0',
  color: theme.token('text-default'),
  letterSpacing: '0',
  textAlign: 'center',
  fontWeight: 'normal',
  textTransform: 'uppercase',
  accessibilityLevel: 2,
});

export const HeaderPrefix = Text.withConfig({
  marginTop: '8',
  marginX: '0',
  marginBottom: '3',
  fontSize: 'md',
  fontWeight: 'bold',
  textTransform: 'none',
});

export const GeolocationImageContainer = Box.withConfig({
  justifyContent: 'center',
  paddingY: '2',
  paddingX: '0',
  width: '12',
  height: '20',
});

export const ContentContainer = VStack.withConfig({
  fontSize: 'md',
});

export const ModalInner = VStack.withConfig({
  margin: '0',
  textAlign: 'center',
  paddingTop: {
    base: '0',
    md: '12',
  },
  paddingBottom: {
    base: '0',
    md: '8',
  },
  paddingX: {
    base: '6',
    md: '1/5',
  },
});

export const ModalScroller = ScrollView.withConfig({
  paddingTop: '12',
  padding: {
    md: '0',
  },
});
