import { keyBy } from 'lodash-es';

import { useLoyaltyUserPromotionsQuery } from 'generated/graphql-gateway';

import { IUseLoyaltyUserPromotions } from './types';
import { useLoyaltyUser } from './use-loyalty-user';

export const useLoyaltyUserPromotions: IUseLoyaltyUserPromotions = () => {
  const { loyaltyUser } = useLoyaltyUser();
  const loyaltyUserId = loyaltyUser?.id || '';

  const { data } = useLoyaltyUserPromotionsQuery({
    skip: !loyaltyUserId,
    variables: {
      loyaltyId: loyaltyUserId,
      where: {
        omitInvalids: false,
      },
    },
  });

  return {
    availablePromotionsMap: keyBy(data?.loyaltyUserV2?.promotions, 'id'),
  };
};
