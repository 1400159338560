import { useEffect } from 'react';

import { useGeolocation } from 'state/geolocation';
import { OsPrompts, checkForOSPrompts } from 'state/location/routes';
import { useMParticleContext } from 'state/mParticle';

export function useAutoPromptLocationPermission() {
  const { logRBIEvent } = useMParticleContext();
  const { isPermissionKnown } = useGeolocation();

  useEffect(() => {
    if (!isPermissionKnown) {
      // Ask for location permission if user has not already answered previously
      checkForOSPrompts(`/OS/${OsPrompts.location}`, logRBIEvent);
    }
  }, [isPermissionKnown, logRBIEvent]);
}
