import { useCallback, useEffect, useMemo, useState } from 'react';

import { isNil } from 'lodash-es';

import { useConfigValue } from 'hooks/configs/use-config-value';
import { getCurrentSession } from 'remote/auth';
import { QUERY_TYPE, getGraphqlApiUrl } from 'remote/constants';
import { NetworkConnectionError } from 'remote/exceptions';
import {
  getSanityGraphqlQueryUrl,
  getSanityGraphqlQueryUrlV2,
  getSanityGroqQueryUrl,
} from 'remote/index';
import { useErrorContext } from 'state/errors';
import { useLocale } from 'state/intl';
import { LaunchDarklyFlag, useFlag } from 'state/launchdarkly';
import { useMParticleContext } from 'state/mParticle';
import { sanityDataset as defaultSanityDataset, isProduction } from 'utils/environment';
import logger, { addLoggingContext } from 'utils/logger';
import { FetchConfig, fetch } from 'utils/network';
import { INetworkStatus, Network } from 'utils/network/status';

export interface QueryConfig<R, V, C = never> extends Omit<FetchConfig<R, V, C>, 'language'> {
  queryType: QUERY_TYPE;
}

export type Query = <R = any, V = any, C = never>(o: QueryConfig<R, V, C>) => Promise<R | C>;

/*
 * type Config = {
 *   queryType: queryType,
 *   query: string,
 *   variables?: Object,
 *   headers?: Object,
 *   then?: (data: Object) => any,
 *   catch?: (error: Error) => any,
 * }
 */

export default function useNetwork() {
  const { language, region } = useLocale();

  const { sessionId } = useMParticleContext();
  const { handleError } = useErrorContext();

  const [connection, setConnection] = useState<INetworkStatus>({
    // assume user is connected to the internet on app mount.
    connected: true,
    connectionType: 'unknown',
  });

  const [hasNetworkError, setHasNetworkError] = useState(false);
  const [hasNotAuthenticatedError, setHasNotAuthenticatedError] = useState(false);
  const useSanityCdn = useFlag(LaunchDarklyFlag.ENABLE_SANITY_CDN);
  const sanityProjectId = useConfigValue({ key: 'sanityProjectId', isRegionalized: false });

  const sanityEndpoints = useMemo(() => {
    // use cdn by default if LD flag is null or undefined
    const useCdn = isNil(useSanityCdn) ? true : useSanityCdn;

    // TODO: RN - locale switching -- shouldn't this just pull from config?
    const sanityDataset = region
      ? `${defaultSanityDataset()}_${region.toLowerCase()}`
      : defaultSanityDataset().toLowerCase();

    const groq = getSanityGroqQueryUrl({ sanityDataset, useCdn, sanityProjectId });
    const graphql = getSanityGraphqlQueryUrl({ sanityDataset, useCdn, sanityProjectId });
    const graphqlV2 = getSanityGraphqlQueryUrlV2({ sanityDataset, useCdn, sanityProjectId });

    return { graphql, groq, graphqlV2 };
  }, [region, sanityProjectId, useSanityCdn]);

  useEffect(() => {
    // Get the current network status
    Network.getStatus()
      .then(status => {
        addLoggingContext({ networkConnectionStatus: status });
        setConnection(status);
      })
      .catch(() => {
        // should we assume user is not connected if
        // we are unable to get network status?
        const status: INetworkStatus = {
          connected: false,
          connectionType: 'none',
        };
        setConnection(status);
        addLoggingContext({ networkConnectionStatus: status });
      });

    const handler = Network.addListener(status => {
      setConnection(status);
      addLoggingContext({ networkConnectionStatus: status });
      // When the user has no internet, show the network error page and when internet is back up, show the content
      setHasNetworkError(!status.connected);
    });

    return () => {
      handler.remove();
    };
  }, [hasNetworkError]);

  const getEndpointUrl = useCallback(
    (queryType: QUERY_TYPE) => {
      switch (queryType) {
        case QUERY_TYPE.LambdaGraphqlQuery:
          return getGraphqlApiUrl();
        case QUERY_TYPE.SanityGraphqlQuery:
          return sanityEndpoints.graphql;
        case QUERY_TYPE.SanityGraphqlV2Query:
          return sanityEndpoints.graphqlV2;
        case QUERY_TYPE.SanityGroqQuery:
          return sanityEndpoints.groq;
        default:
          throw new Error('Unknown Query Type');
      }
    },
    [sanityEndpoints]
  );

  const query = useCallback<Query>(
    ({ queryType, ...config }) => {
      return getCurrentSession().then(userSession => {
        const token = userSession ? userSession.getIdToken().getJwtToken() : '';
        const headers: { [key: string]: string } = {
          'Content-Type': 'application/json',
          ...config.headers,
        };

        const endpoint = getEndpointUrl(queryType);

        if (queryType === QUERY_TYPE.LambdaGraphqlQuery && token) {
          headers.Authorization = `Bearer ${token}`;
        }

        return fetch(endpoint, {
          ...config,
          connection,
          headers,
          language,
          region,
          sessionId,
        }).catch((err: Error | NetworkConnectionError) => {
          const jwtExpiredError = 'JWT is invalid or expired';
          if (err.message.includes(jwtExpiredError)) {
            setHasNotAuthenticatedError(true);
          }

          if (Object.prototype.hasOwnProperty.call(err, 'connectionError')) {
            logger.error({ error: err, message: 'Network connection error' });
            setHasNetworkError(true);
            throw err;
          }
          handleError(err);
          throw err;
        });
      });
    },
    [connection, language, handleError, sessionId, getEndpointUrl, region]
  );

  const defaultErrorHandler = useCallback(
    (error: Error) => {
      // We already have network issues. Bail out
      if (hasNetworkError) {
        return;
      }

      const message = isProduction ? 'Network Error - Default Error Handler' : error.message;
      logger.error({ error, message });
    },
    [hasNetworkError]
  );

  const ctx = useMemo(
    () => ({
      connection,
      defaultErrorHandler,
      hasNetworkError,
      hasNotAuthenticatedError,
      query,
      sanityEndpoints,
      setHasNetworkError,
      setHasNotAuthenticatedError,
    }),
    [
      query,
      connection,
      hasNetworkError,
      defaultErrorHandler,
      sanityEndpoints,
      hasNotAuthenticatedError,
      setHasNotAuthenticatedError,
    ]
  );

  return ctx;
}
