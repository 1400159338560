import React from 'react';

import { Box } from '@rbilabs/universal-components';
import { useIntl } from 'react-intl';

import ActionButton from 'components/action-button';
import { ServiceMode } from 'state/order';
import { useStoreContext } from 'state/store';

import { ModalContent, ModalHeading } from './styled';
import { OnServiceModeSelected } from './types';

interface IModalContentProps {
  selectServiceMode: OnServiceModeSelected;
}

const StyledModalHeading = ModalHeading.withConfig({
  marginTop: {
    md: '$13.5',
  },
  marginBottom: {
    md: '$10',
  },
  marginX: {
    md: 0,
  },
});

const PickupModeContent: React.FC<React.PropsWithChildren<IModalContentProps>> = props => {
  const { serviceModeStatus } = useStoreContext();
  const { formatMessage } = useIntl();

  const driveThruAvailable = serviceModeStatus.DRIVE_THRU.available;
  const takeOutAvailable = serviceModeStatus.TAKEOUT.available;
  const dineInAvailable = serviceModeStatus.EAT_IN.available;
  const curbsideAvailable = serviceModeStatus.CURBSIDE.available;

  const driveThruDisabled = serviceModeStatus.DRIVE_THRU.disabled;
  const takeOutDisabled = serviceModeStatus.TAKEOUT.disabled;
  const dineInDisabled = serviceModeStatus.EAT_IN.disabled;
  const disableCurbside = serviceModeStatus.CURBSIDE.disabled;

  return (
    <ModalContent>
      <StyledModalHeading testID="modal-heading">
        {formatMessage({ id: 'willPickUpOrder' })}
      </StyledModalHeading>
      <>
        {dineInAvailable && (
          <Box margin="$2">
            <ActionButton
              disabled={dineInDisabled}
              testID="pickupmode-dine-in"
              onPress={() => props.selectServiceMode(ServiceMode.EAT_IN)}
            >
              {dineInDisabled
                ? formatMessage({ id: 'dineInUnavailable' })
                : formatMessage({ id: 'dineIn' }).toUpperCase()}
            </ActionButton>
          </Box>
        )}
        {takeOutAvailable && (
          <Box margin="$2">
            <ActionButton
              disabled={takeOutDisabled}
              testID="pickupmode-take-out"
              onPress={() => props.selectServiceMode(ServiceMode.TAKEOUT)}
            >
              {takeOutDisabled
                ? formatMessage({ id: 'takeOutUnavailable' })
                : formatMessage({ id: 'takeOut' }).toUpperCase()}
            </ActionButton>
          </Box>
        )}
      </>
      {curbsideAvailable && (
        <Box margin="$2">
          <ActionButton
            disabled={disableCurbside}
            testID="pickupmode-curbside"
            onPress={() => props.selectServiceMode(ServiceMode.CURBSIDE)}
          >
            {disableCurbside
              ? formatMessage({ id: 'curbsideUnavailable' })
              : formatMessage({ id: 'curbside' }).toUpperCase()}
          </ActionButton>
        </Box>
      )}
      {driveThruAvailable && (
        <Box margin="$2">
          <ActionButton
            disabled={driveThruDisabled}
            testID="pickupmode-drive-thru"
            onPress={() => props.selectServiceMode(ServiceMode.DRIVE_THRU)}
          >
            {driveThruDisabled
              ? formatMessage({ id: 'driveThruUnavailable' })
              : formatMessage({ id: 'driveThru' }).toUpperCase()}
          </ActionButton>
        </Box>
      )}
    </ModalContent>
  );
};

export default PickupModeContent;
