import { useEffect } from 'react';

import {
  IRemoveUnavailableItemsModalProps,
  RemoveUnavailableItemsModal,
} from 'components/modal-order-unavailable/remove-unavailable-items-modal';
import useDialogModal from 'hooks/use-dialog-modal';
import { OnConfirmStoreChangeParams, useStoreContext } from 'state/store';

export const ItemsUnavailableDialog = () => {
  const {
    onConfirmStoreChange,
    onConfirmStoreChangeParams,
    clearOnConfirmStoreChangeParams,
  } = useStoreContext();

  const [Dialog, openDialog, pendingData, dismissDialog] = useDialogModal<
    OnConfirmStoreChangeParams,
    IRemoveUnavailableItemsModalProps
  >({
    Component: RemoveUnavailableItemsModal,
    onConfirm: onConfirmStoreChange,
  });

  useEffect(() => {
    // When a new store is selected and there are unavailable items then
    // this object should have all the required data to open the dialog
    if (onConfirmStoreChangeParams) {
      clearOnConfirmStoreChangeParams();
      openDialog(onConfirmStoreChangeParams);
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onConfirmStoreChangeParams]);

  return (
    <Dialog
      unavailableCartEntries={pendingData?.unavailableCartEntries || []}
      onDismiss={dismissDialog}
    />
  );
};
