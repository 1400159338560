import React, { useState } from 'react';

import { Box, Link, Stack } from '@rbilabs/universal-components';
import { filter } from 'lodash-es';

import { useAnchorContext } from 'utils/static-page/hooks/useAnchorContext';

const AnchorLinks = Stack.withConfig({
  alignItems: 'center',
  direction: {
    md: 'row',
    sm: 'column',
  },
  space: '$4',
});

const AnchorLinkItem = Link.withConfig({
  isUnderlined: false,
});

const AnchorLinksDivider = Box.withConfig({
  width: '5/6',
  borderTopWidth: '2',
  borderTopColor: Styles.color.grey.five,
});

const AnchorLinksWidget = props => {
  const { scrollToTarget, formatLocaleAnchorName } = useAnchorContext();
  const [currentPressedLink, setcurrentPressedLink] = useState();

  return (
    <>
      <AnchorLinks>
        <AnchorLinksDivider />
        {filter(props.widgets, ['_type', 'anchorWidget']).map(anchor => {
          const anchorName = anchor.anchorName?.locale;

          return (
            <AnchorLinkItem
              // href={`#${formatLocaleAnchorName(anchor.anchorName?.locale)}`} TODO: RN - ensure web/browser anchor navigation works
              key={anchor._key}
              isUnderlined={currentPressedLink === anchor.anchorName?.locale}
              onPress={() => {
                // TODO: RN move to a better place in the navigation/route handling logic? href/router currently creates exception when pressed
                setcurrentPressedLink(anchorName);
                scrollToTarget(formatLocaleAnchorName(anchorName));
              }}
            >
              {anchor.anchorName?.locale}
            </AnchorLinkItem>
          );
        })}
        <AnchorLinksDivider />
      </AnchorLinks>
    </>
  );
};

export default AnchorLinksWidget;
