import { useCallback } from 'react';

import {
  FilterRestaurantType,
  IRestaurantsInput,
  useGetRestaurantsLazyQuery,
} from 'generated/rbi-graphql';

export const useRestaurants = (filter: FilterRestaurantType) => {
  const [fetchRestaurants, { data, loading, error }] = useGetRestaurantsLazyQuery({
    fetchPolicy: 'cache-and-network',
  });

  const fetch = useCallback(
    (input: IRestaurantsInput = {}) => {
      fetchRestaurants({
        variables: { input: { filter, ...input } },
      });
    },
    [fetchRestaurants, filter]
  );

  return { fetch, data, loading, error };
};
