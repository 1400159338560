import React from 'react';

import { LaunchDarklyFlag, useFlag } from 'state/launchdarkly';

import type { ILocatorTileCTAsProps } from '../types';

import { LocatorTileFavoriteButton } from './locator-tile-favorite-button';

export const LocatorTileCTAs = ({ restaurant }: ILocatorTileCTAsProps) => {
  const enableFavorites = useFlag(LaunchDarklyFlag.ENABLE_FAVORITE_STORES);

  return enableFavorites ? <LocatorTileFavoriteButton restaurant={restaurant} /> : null;
};
