// Proxy functions to window.rbiAppBoy if it's initialized.
// Don't want to deal with TS generics because there are a small discrete number of methods

import { IAppboy, IBrazeUser, IDisplayModule } from 'appboy-web-sdk';

export type { ContentCard } from 'react-native-appboy-sdk';

/**
 * Returns the RbiApbboy and BrazeUser instances stored on the window object.
 *
 * If the instances are not initialized yet, it triggers a second init attempt using the values set
 * by use-braze.web hook
 */
const getRbiAppboyInstances = (): {
  rbiAppboy: IAppboy | undefined;
  brazeUser: IBrazeUser | undefined;
} => {
  const getInstancesFromWindow = () => {
    try {
      const rbiAppboy = window.rbiAppboy;
      return { rbiAppboy, brazeUser: rbiAppboy?.getUser() };
    } catch (error) {
      return { rbiAppboy: undefined, brazeUser: undefined };
    }
  };

  // First attempt
  const { rbiAppboy, brazeUser } = getInstancesFromWindow();
  if (rbiAppboy && brazeUser) {
    return { rbiAppboy, brazeUser };
  }

  // If rbiAppboy fails to return the window instances the first time, retry after calling init again
  window.rbiAppboyInit();

  // Second attempt
  return getInstancesFromWindow();
};

export const changeUser: IAppboy['changeUser'] = (...args) => {
  const { rbiAppboy } = getRbiAppboyInstances();
  return rbiAppboy ? rbiAppboy.changeUser(...args) : false;
};

export const getContentCards: IAppboy['getCachedContentCards'] = (...args) => {
  const { rbiAppboy } = getRbiAppboyInstances();
  return rbiAppboy
    ? rbiAppboy.getCachedContentCards(...args)
    : {
        cards: [],
        lastUpdated: '',
      };
};

export const logCustomEvent: IAppboy['logCustomEvent'] = (...args) => {
  const { rbiAppboy } = getRbiAppboyInstances();
  return rbiAppboy ? rbiAppboy.logCustomEvent(...args) : false;
};

export const logPurchase: IAppboy['logPurchase'] = (...args) => {
  const { rbiAppboy } = getRbiAppboyInstances();
  return rbiAppboy ? rbiAppboy.logPurchase(...args) : false;
};

export const requestContentCardsRefresh: IAppboy['requestContentCardsRefresh'] = () => {
  const { rbiAppboy } = getRbiAppboyInstances();
  return rbiAppboy ? rbiAppboy.requestContentCardsRefresh() : false;
};

export const requestImmediateDataFlush: IAppboy['requestImmediateDataFlush'] = () => {
  const { rbiAppboy } = getRbiAppboyInstances();
  return rbiAppboy ? rbiAppboy.requestImmediateDataFlush() : false;
};

export const stopWebTracking: IAppboy['stopWebTracking'] = () => {
  const { rbiAppboy } = getRbiAppboyInstances();
  return rbiAppboy ? rbiAppboy.stopWebTracking() : false;
};

export const resumeWebTracking: IAppboy['resumeWebTracking'] = () => {
  const { rbiAppboy } = getRbiAppboyInstances();
  return rbiAppboy ? rbiAppboy.resumeWebTracking() : false;
};

export const setEmail: IBrazeUser['setEmail'] = (...args) => {
  const { brazeUser } = getRbiAppboyInstances();
  return brazeUser ? brazeUser.setEmail(...args) : false;
};

export const setFirstName: IBrazeUser['setFirstName'] = (...args) => {
  const { brazeUser } = getRbiAppboyInstances();
  return brazeUser ? brazeUser.setFirstName(...args) : false;
};

export const setLastName: IBrazeUser['setLastName'] = (...args) => {
  const { brazeUser } = getRbiAppboyInstances();
  return brazeUser ? brazeUser.setLastName(...args) : false;
};

export const setHomeCity: IBrazeUser['setHomeCity'] = (...args) => {
  const { brazeUser } = getRbiAppboyInstances();
  return brazeUser ? brazeUser.setHomeCity(...args) : false;
};

export const setPhoneNumber: IBrazeUser['setPhoneNumber'] = (...args) => {
  const { brazeUser } = getRbiAppboyInstances();
  return brazeUser ? brazeUser.setPhoneNumber(...args) : false;
};

export const setCustomUserAttribute: IBrazeUser['setCustomUserAttribute'] = (...args) => {
  const { brazeUser } = getRbiAppboyInstances();
  return brazeUser ? brazeUser.setCustomUserAttribute(...args) : false;
};

// Name might look confusing, but that's how its called on the Native SDK
const launchContentCards: IDisplayModule['toggleContentCards'] = (...args) => {
  const { rbiAppboy } = getRbiAppboyInstances();
  return rbiAppboy?.display.toggleContentCards(...args);
};

export default {
  // Proxy functions on rbiAppboy
  changeUser,
  getContentCards,
  logCustomEvent,
  logPurchase,
  requestContentCardsRefresh,
  requestImmediateDataFlush,
  stopWebTracking,
  resumeWebTracking,

  // Proxy functions on brazeUser
  setCustomUserAttribute,
  setEmail,
  setFirstName,
  setHomeCity,
  setLastName,
  setPhoneNumber,

  // Proxy functions to display module
  launchContentCards,
};
