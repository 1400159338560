import React, { useEffect } from 'react';

import { Box } from '@rbilabs/universal-components';

import { ILocation } from '@rbi-ctg/frontend';
import useMap from 'hooks/use-map';
import { MarkerTypes } from 'hooks/use-map/types';

interface IStaticMap {
  initialZoomAndroid?: number;
  initialZoomIOS?: number;
  position: ILocation;
}

const StaticMap: React.FC<React.PropsWithChildren<IStaticMap>> = ({
  initialZoomAndroid,
  initialZoomIOS,
  position,
}) => {
  const { map, createMarker, panTo } = useMap({ initialZoomAndroid, initialZoomIOS, position });

  useEffect(() => {
    // TODO: RN - Define type for location-pin-active.png
    createMarker({
      type: MarkerTypes.StoreInActive,
      location: position,
    });
    panTo(position);
  }, [createMarker, panTo, position]);

  return (
    <Box flex="1" pointerEvents="none">
      {map}
    </Box>
  );
};

export default StaticMap;
