import React, { Suspense } from 'react';

import { useIntl } from 'react-intl';

import { Animation as FavoriteLoading } from 'components/lottie-animations/favorite-loading';
import { ErrorRemovingLastFavoriteStoreModal } from 'components/modal-error-removing-last-favorite-store';
import { useFavoriteStore } from 'hooks/favorite-stores';

import { ILocatorTileFavoriteButtonProps } from '../types';

import {
  LocatorTileActionButton,
  LocatorTileCTAIcon,
  LocatorTileFavoriteLoadingWrapper,
} from './locator-tile-cta.styled';

export const LocatorTileFavoriteButton = ({ restaurant }: ILocatorTileFavoriteButtonProps) => {
  const { formatMessage } = useIntl();
  const {
    ErrorFavoriteStoreDialog,
    favStoreLoading,
    isFavorite,
    toggleFavorite: onFavoriteClick,
    hasErrorRemovingLastFavStore,
    onDismissErrorRemovingLastFavStoreModal,
  } = useFavoriteStore(restaurant._id ?? '', restaurant.number ?? '');

  const favIconVariant = isFavorite ? 'favoriteFill' : 'favorite';

  return (
    <Suspense fallback={<LocatorTileCTAIcon variant={favIconVariant} />}>
      {favStoreLoading ? (
        <LocatorTileFavoriteLoadingWrapper>
          <FavoriteLoading />
        </LocatorTileFavoriteLoadingWrapper>
      ) : (
        <LocatorTileActionButton
          accessibilityLabel={
            isFavorite
              ? formatMessage({ id: 'removeFavorite', description: '' })
              : formatMessage({ id: 'setRestaurantAsFavorite', description: '' })
          }
          onPress={onFavoriteClick}
          icon={<LocatorTileCTAIcon variant={favIconVariant} />}
        />
      )}

      {hasErrorRemovingLastFavStore && (
        <ErrorRemovingLastFavoriteStoreModal
          isOpen={hasErrorRemovingLastFavStore}
          onDismiss={onDismissErrorRemovingLastFavStoreModal}
        />
      )}

      <ErrorFavoriteStoreDialog />
    </Suspense>
  );
};
