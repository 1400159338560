import React from 'react';

import { IconButton, IconButtonProps, makeUclComponent } from '@rbilabs/universal-components';

type StoreCardButtonProps = Pick<
  IconButtonProps,
  'accessibilityLabel' | 'icon' | 'onPress' | 'testID'
>;

export const StoreCardButton = makeUclComponent(
  ({ accessibilityLabel, onPress, icon, testID }: StoreCardButtonProps) => {
    return (
      <IconButton
        variant="ghost"
        accessibilityLabel={accessibilityLabel}
        onPress={onPress}
        icon={icon}
        testID={testID}
      />
    );
  }
);
