import { Platform } from 'react-native';

import { CustomEventNames, EventTypes, IMParticleCtx } from 'state/mParticle';
import logger from 'utils/logger';
import {
  appTrackingTransparency,
  enableLocation,
  enablePush,
  openPushSetting,
} from 'utils/permissions';
import { promptReview } from 'utils/review/prompt-review';

export enum OsPrompts {
  push = 'push',
  location = 'location',
  review = 'review',
  att = 'att',
  pushSetting = 'pushSetting',
}

// checkForOSPrompts checks a URL for a `/OS/*`
// return true if /OS/ link
export const checkForOSPrompts = (
  url: string,
  mparticleLogRBIEvent?: IMParticleCtx['logRBIEvent']
): boolean => {
  // If the link is /OS/* then prompt
  const parts = url.split('/');
  if (parts && parts.length === 3 && parts[1] === 'OS') {
    const routeTo = parts[2];

    switch (routeTo) {
      case OsPrompts.push:
        enablePush().then(response => {
          if (!mparticleLogRBIEvent || !response) {
            return;
          }

          mparticleLogRBIEvent({
            name: CustomEventNames.PUSH_NOTIFICATIONS_RESPONSE,
            type: EventTypes.Other,
            attributes: {
              response: response.status,
            },
          });
        });
        return true;
      case OsPrompts.location:
        enableLocation()
          .then()
          .catch(err => {
            logger.warn(`location: enableLocation error: ${err}`);
          });
        return true;
      case OsPrompts.review:
        promptReview().then(() => {
          if (mparticleLogRBIEvent) {
            mparticleLogRBIEvent({
              name: CustomEventNames.APP_REVIEW_POP_UP,
              type: EventTypes.Other,
              attributes: {},
            });
          }
        });
        return true;
      case OsPrompts.att:
        if (Platform.OS !== 'ios') {
          return true;
        }
        appTrackingTransparency().catch(() => {
          logger.warn(`Error in requesting user authorizaton for App Tracking Transparency`);
        });
        return true;
      case OsPrompts.pushSetting:
        openPushSetting();
        return true;
      default:
        logger.warn(`location: unknown OS/ link: ${url}`);
        return false;
    }
  }
  // No navigation is required so return undefined
  // return the URL
  return false;
};
