import * as React from 'react';

import { Button } from '@rbilabs/universal-components';

import { combineAttributeStrings } from '../get-required-label';

import { heroHeaderId, heroImageId, heroSubheaderId } from './constants';
import {
  ButtonsWrapper,
  ContentWrapper,
  HeroGrid,
  HeroHeader,
  HeroSubheader,
  ImageWrapper,
  Terms,
  TermsLink,
  TermsWrapper,
} from './hero.styled';
import { HeroProps } from './types';

/**
 * the default area at the top of the home page
 */
const Hero: React.FC<React.PropsWithChildren<HeroProps>> = ({
  header,
  subheader,
  image,
  reversed,
  primaryCtaText,
  onPressPrimaryCta,
  complementaryCtaText,
  onPressComplementaryCta,
  termsLinkText,
  onPressTermsLink,
  termsLinkHref,
}) => {
  return (
    <HeroGrid $reversed={reversed}>
      <ImageWrapper testID={heroImageId}>{image}</ImageWrapper>
      <ContentWrapper>
        <HeroHeader $reversed={reversed} testID={heroHeaderId}>
          {header}
        </HeroHeader>

        <HeroSubheader $reversed={reversed} testID={heroSubheaderId}>
          {subheader}
        </HeroSubheader>

        <ButtonsWrapper>
          <Button
            testID="order-button"
            children={primaryCtaText}
            onPress={onPressPrimaryCta}
            variant={reversed ? 'solid-reversed' : 'solid'}
          />
          <Button
            testID="see-full-menu-button"
            children={complementaryCtaText}
            onPress={onPressComplementaryCta}
            variant={reversed ? 'ghost-reversed' : 'ghost'}
          />
        </ButtonsWrapper>

        {termsLinkText ? (
          <TermsWrapper>
            <Terms $reversed={reversed}>*</Terms>
            <TermsLink
              to={termsLinkHref || ''}
              onPress={onPressTermsLink}
              aria-describedby={combineAttributeStrings(heroImageId, heroHeaderId, heroSubheaderId)}
            >
              <Terms $reversed={reversed}> terms text</Terms>
            </TermsLink>
          </TermsWrapper>
        ) : null}
      </ContentWrapper>
    </HeroGrid>
  );
};

export default Hero;
