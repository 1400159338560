import React from 'react';

import { useIntl } from 'react-intl';

import { ModalTitle } from '../styled';

import { NewAddressModalHeading } from './styled';

const DeliveryAddressFormHeader = () => {
  const { formatMessage } = useIntl();

  return (
    <NewAddressModalHeading testID="modal-heading">
      <ModalTitle>{formatMessage({ id: 'enterYourDeliveryAddress' })}</ModalTitle>
    </NewAddressModalHeading>
  );
};

export default DeliveryAddressFormHeader;
