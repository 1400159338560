import * as React from 'react';
import { FC } from 'react';

import { useIntl } from 'react-intl';

import { fullBrandName } from 'utils/environment';

import { GeolocationDeniedModalView } from './geolocation-denied-modal.view';
import { IGeolocationDeniedModalProps } from './types';

const FULL_BRAND_NAME = fullBrandName();
/**
 *
 * GeolocationDeniedModal contains logic required to render UI
 *  view is split from this component to enable separate logic
 *  between web and app -> specifically, logic around opening
 *  native settings. also some copy differences
 *
 */
const GeolocationDeniedModal: FC<React.PropsWithChildren<IGeolocationDeniedModalProps>> = ({
  isOpen,
  dismissModal,
}) => {
  const { formatMessage } = useIntl();
  return (
    <GeolocationDeniedModalView
      isOpen={isOpen}
      dismissModal={dismissModal}
      modalBodyText={formatMessage(
        { id: 'updateBrowserLocationSettings' },
        { brand: FULL_BRAND_NAME }
      )}
      modalHeadingText={formatMessage({ id: 'enableLocationServices' })}
    />
  );
};

export default GeolocationDeniedModal;
