import { IconVariant } from '@rbilabs/universal-components';

import { StoreCardButtonOptions } from 'state/launchdarkly/variations';

/**
 * MAP BUTTON OPTIONS TO ICONS
 */
export const ACTION_BUTTON_ICONS = {
  [StoreCardButtonOptions.INFO]: 'infoFill' as keyof IconVariant,
  [StoreCardButtonOptions.OFFERS]: 'offer' as keyof IconVariant,
  [StoreCardButtonOptions.ORDER]: 'restaurant',
};
