import React, { useState } from 'react';

import { MapButtons } from './map-buttons';
import { MapWrapper } from './map.styled';
import { IStoreLocatorMapProps } from './types';

/*
 * NOTE: We want to only render the map once
 * So we "hide" / `display: none` the map container when not on the map view
 */
export const StoreLocatorMap = ({
  currentViewIsMap,
  handleResetLocation,
  hasPanned,
  isTablet,
  map,
  onPressSearch,
  zoom,
  resetZoom,
}: IStoreLocatorMapProps) => {
  // We need to track when we have shown the map already so we don't load it again
  const [hasBeenMounted, setHasBeenMounted] = useState(currentViewIsMap);
  if (!hasBeenMounted && !currentViewIsMap) {
    return null;
  }
  return (
    <MapWrapper
      ref={() => setHasBeenMounted(true)}
      hide={!currentViewIsMap}
      testID="store-locator-map"
    >
      {map}
      <MapButtons
        currentViewIsMap={currentViewIsMap}
        handleResetLocation={handleResetLocation}
        hasPanned={hasPanned}
        isTablet={isTablet}
        onPressSearch={onPressSearch}
        zoom={zoom}
        resetZoom={resetZoom}
      />
    </MapWrapper>
  );
};

export default StoreLocatorMap;
