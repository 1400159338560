import * as React from 'react';
import { FC, useCallback, useState } from 'react';

import { useIntl } from 'react-intl';
import { Animated } from 'react-native';

import ChangeRegionModal from 'components/language-selector-modal';
import { useStoreClosedActionSheet } from 'components/store-closed-action-sheet';
import { useLocale } from 'state/intl';
import { isNative } from 'utils/environment';

import { useMessage } from './hooks/use-message';
import { useStoreActionOptions } from './hooks/use-store-action-options';
import { getRestaurantCountry2Char } from './store-actions-utils';
import { StoreActionsView } from './store-actions.view';
import { IActionOption, IStoreActionsProps } from './types';

/**
 * StoreCardActions container component. This component is intended to be used
 * on the store locator. Data orchestration should happen at this level while
 * keeping the view layer more presentational.
 */
const StoreActions: FC<React.PropsWithChildren<IStoreActionsProps>> = ({
  restaurant,
  serviceModeCategory,
}) => {
  const { formatMessage } = useIntl();
  const { region } = useLocale();

  const message = useMessage({ restaurant, serviceModeCategory });

  /**
   * SETUP LOGIC FOR FORCING LOCALE CHANGE
   */
  const [showChangeRegionModal, setShowChangeRegionModal] = useState<boolean>(false);

  const handleChangeRegionModalDismiss = useCallback(() => {
    setShowChangeRegionModal(false);
  }, [setShowChangeRegionModal]);

  const restaurantCountry = (restaurant?.physicalAddress?.country || '').toUpperCase();
  const changeRegionModalRegionFilter = useCallback(
    (regionForFilter: string) => {
      return restaurantCountry.startsWith(regionForFilter);
    },
    [restaurantCountry]
  );

  const {
    requestScheduleFutureOrderingConfirmation,
    storeClosedActionSheet,
  } = useStoreClosedActionSheet({ restaurant });

  /**
   * CREATE STORE ACTION OPTIONS
   */
  const actionOptions: IActionOption[] = useStoreActionOptions({
    restaurant,
    setShowChangeRegionModal,
    validateRestaurantSelection: requestScheduleFutureOrderingConfirmation,
  });

  /**
   * FADE-IN ReactNative ANIMATION
   */
  const fadeAnim = React.useRef(new Animated.Value(0));
  React.useEffect(() => {
    Animated.timing(fadeAnim.current, {
      toValue: 1,
      duration: 200,
      useNativeDriver: isNative,
    }).start();
  }, []);

  /**
   * RENDER VIEW
   */
  return (
    <>
      <Animated.View style={[{ opacity: fadeAnim.current }]}>
        <StoreActionsView options={actionOptions} message={message} />
      </Animated.View>
      {storeClosedActionSheet}
      {showChangeRegionModal && (
        <ChangeRegionModal
          redirectToCurrentLocation
          heading={formatMessage({ id: 'locatorChangeRegionHeading' })}
          regionFilter={changeRegionModalRegionFilter}
          onModalDismiss={handleChangeRegionModalDismiss}
          primaryText={formatMessage(
            { id: 'locatorChangeRegionPrimaryText' },
            { userRegion: region, restaurantRegion: getRestaurantCountry2Char(restaurant) }
          )}
          secondaryText={formatMessage({ id: 'locatorChangeRegionSelection' })}
          disclaimer={formatMessage({ id: 'locatorChangeRegionDisclaimer' })}
        />
      )}
    </>
  );
};

export default StoreActions;
