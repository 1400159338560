import * as React from 'react';
import { useCallback, useRef } from 'react';

import { RewardsBadge, VerticalCarouselItem } from '@rbilabs/universal-components';
import { useIntl } from 'react-intl';

import { IStore } from '@rbi-ctg/store';
import Picture from 'components/picture';
import { ResponsiveCarouselSkeletonItem } from 'components/responsive-carousel/responsive-carousel.skeleton';
import { useNavigation } from 'hooks/navigation/use-navigation';
import {
  makeLoyaltyRewardDetailsPath,
  makeUrlForIncentive,
} from 'pages/loyalty/loyalty-incentives-components/incentive-details/utils';
import { useLocale } from 'state/intl';
import { useInRestaurantRedemptionContext } from 'state/loyalty/in-restaurant-redemption';
import { CustomEventNames, EventTypes, useMParticleContext } from 'state/mParticle';
import { useMenuContext } from 'state/menu';
import { useServiceModeContext } from 'state/service-mode';
import { useStoreContext } from 'state/store';
import { theme } from 'styles/configure-theme';
import { maybeLocale } from 'utils/graphql';
import logger from 'utils/logger';

import constantPoints from './constants';
import { IRewardsCarouselCardProps } from './types';

export const RewardsCarouselCard = React.memo<IRewardsCarouselCardProps>(({ reward }) => {
  const image = maybeLocale(reward.image);
  const { language } = useLocale();
  const name = reward?.name?.[language] || '';
  const { noStoreSelected, store } = useStoreContext();
  const { setSelectedStaticMenuItem } = useMenuContext();
  const { logRBIEvent } = useMParticleContext();
  const { formatMessage } = useIntl();
  const { linkTo } = useNavigation();
  const incentiveRef = useRef(reward.incentives?.find(val => val?._id === reward.rewardBenefitId));
  const { serviceMode } = useServiceModeContext();
  const { isInRestaurantRedemptionEnabledAtStore } = useInRestaurantRedemptionContext();

  const handleRewardClick = useCallback(() => {
    async function navigateToReward() {
      const rewardWizardUrl = makeUrlForIncentive(reward);
      if (!rewardWizardUrl) {
        logger.warn(
          `Attempting to make a url for reward that is missing data: reward ${reward._id}`
        );
        return;
      }
      const indexOfSearch = rewardWizardUrl.indexOf('?');
      const search = indexOfSearch !== -1 ? rewardWizardUrl.slice(indexOfSearch) : '';
      if (incentiveRef.current && noStoreSelected) {
        setSelectedStaticMenuItem({
          itemId: `${incentiveRef.current._type}-${incentiveRef.current._id}`,
          itemName: reward?.name?.[language] || '',
          search,
        });
      }

      const shouldNavigateToRewardsDetails =
        reward?._id && (await isInRestaurantRedemptionEnabledAtStore(store as IStore, serviceMode));

      if (shouldNavigateToRewardsDetails) {
        linkTo(makeLoyaltyRewardDetailsPath({ rewardId: reward._id, search }));
        logRBIEvent({
          name: CustomEventNames.REWARD_SELECTED,
          type: EventTypes.Other,
          attributes: {
            engineId: reward.loyaltyEngineId ?? '',
            sanityId: reward._id ?? '',
            name: reward?.name?.[language] || '',
          },
        });
      } else {
        linkTo(rewardWizardUrl);
      }
    }
    navigateToReward();
  }, [
    isInRestaurantRedemptionEnabledAtStore,
    linkTo,
    noStoreSelected,
    reward,
    serviceMode,
    setSelectedStaticMenuItem,
    store,
  ]);

  if (!reward.incentives?.length) {
    return <ResponsiveCarouselSkeletonItem />;
  }

  return (
    <VerticalCarouselItem
      header={name}
      image={
        image && <Picture objectFitContain image={image} alt="" width="126px" alignSelf="center" />
      }
      variant="rewards"
      unavailable={!reward.isAvailabilityLoading && !reward.isAvailable}
      unavailableMessage={formatMessage({ id: 'notAvailableAtStore' })}
      rewardsBadge={
        <RewardsBadge
          isLocked={reward.locked}
          color={theme.token('crown-color')}
          accessibilityLabel={
            reward.locked
              ? `${reward.pointCost.toString()} ${formatMessage({
                  id: constantPoints.points,
                })}, ${formatMessage({ id: 'locked' })}`
              : `${reward.pointCost.toString()} ${formatMessage({
                  id: constantPoints.points,
                })}`
          }
        >
          {reward.pointCost.toString()}
        </RewardsBadge>
      }
      onPress={handleRewardClick}
    />
  );
});
