import React from 'react';

import { useIntl } from 'react-intl';

import { routes } from 'utils/routing';

import type { ILocatorTileInfoButtonProps } from '../types';

import { LocatorTileLink } from './locator-tile-cta.styled';

export const LocatorTileInfoButton = ({ restaurant }: ILocatorTileInfoButtonProps) => {
  const { formatMessage } = useIntl();

  return (
    <LocatorTileLink to={`${routes.store}/${restaurant._id}`} isUnderlined>
      {formatMessage({ id: 'viewDetails' })}
    </LocatorTileLink>
  );
};
