import { FC, PropsWithChildren, ReactNode } from 'react';

import { RunningExperiments, useExperiments } from 'state/experiments';
import { OrderLocatorTab } from 'state/launchdarkly/variations';
import { isNative } from 'utils/environment';

export function useMapViewUnderNearbyStores() {
  const { variant } = useExperiments();

  return isNative && variant(RunningExperiments.MapViewUnderNearbyStores, 'treatment');
}

export const MapViewUnderNearbyStores__MapView__Experiment: FC<{
  mapView: ReactNode;
}> = ({ mapView }) => {
  const isExperimentEnabled = useMapViewUnderNearbyStores();
  if (isExperimentEnabled) {
    return <>{mapView}</>;
  }
  return null;
};

export const MapViewUnderNearbyStores__LocatorHeaderToggle__Experiment: FC<PropsWithChildren> = ({
  children,
}) => {
  const isExperimentEnabled = useMapViewUnderNearbyStores();
  if (isExperimentEnabled) {
    return null;
  }
  return <>{children}</>;
};

export const MapViewUnderNearbyStores__ListView__Experiment: FC<PropsWithChildren<{
  mapView: ReactNode;
  locatorTab: OrderLocatorTab;
}>> = ({ mapView, children, locatorTab }) => {
  const isExperimentEnabled = useMapViewUnderNearbyStores();
  if (isExperimentEnabled && locatorTab === OrderLocatorTab.NEARBY) {
    return <>{mapView}</>;
  }
  return <>{children}</>;
};
