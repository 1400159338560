import React, { useCallback, useEffect, useReducer, useState } from 'react';

import { ScrollView } from '@rbilabs/universal-components';
import { StackActions } from '@react-navigation/native';
import { useIntl } from 'react-intl';

import { IStore } from '@rbi-ctg/store';
import { GraphQLErrorMessages } from 'enums/graphql';
import { DeliveryStatus, useDeliveryRestaurantLazyQuery } from 'generated/rbi-graphql';
import usePlaceIdDetails from 'hooks/geolocation/use-place-id-details';
import { useNavigation } from 'hooks/navigation/use-navigation';
import useDialogModal from 'hooks/use-dialog-modal';
import useErrorModal from 'hooks/use-error-modal';
import { useReactNavigationFlag } from 'hooks/use-react-navigation-flag';
import { navigationRef } from 'navigation/navigation-container/navigation-container-ref';
import {
  DeliveryStoreStatusToStoreStatusMap,
  IGetClosestAvailableDeliveryRestaurantResult,
  useQueryClosestAvailableDeliveryRestaurant,
} from 'remote/api/restaurants';
import { useAuthContext } from 'state/auth';
import { LaunchDarklyFlag, useFlag } from 'state/launchdarkly';
import { useLocationContext } from 'state/location';
import { CustomEventNames, EventTypes, useMParticleContext } from 'state/mParticle';
import { useMenuContext } from 'state/menu';
import { useNetworkContext } from 'state/network';
import { ServiceMode, useOrderContext } from 'state/order';
import { useServiceModeContext } from 'state/service-mode';
import { useStaticMenuRedirect } from 'state/static-menu/use-static-menu-redirect';
import { convertMilesToMeters } from 'utils/distance';
import { platform } from 'utils/environment';
import { IPlaceData } from 'utils/geolocation';
import { routes } from 'utils/routing';

import { DeliveryAddresses } from './delivery-addresses';
import { DeliveryUnavailable } from './delivery-unavailable';
import DeliveryAddressForm from './new-address-form/delivery-address-form';
import { addressReducer } from './reducer';
import { ActionStatus, SelectedDeliveryAddress } from './types';
import { getAddressLabel } from './utils';

export const DeliveryAddressContainer = () => {
  const { formatMessage } = useIntl();
  const [state, dispatch] = useReducer(addressReducer, {
    error: null,
    address: null,
    status: ActionStatus.IDLE,
  });

  const { storeLocatorCallbackUrl } = useLocationContext();
  const redirectUrl = storeLocatorCallbackUrl || routes.menu;
  const enableReactNativeNavigation = useReactNavigationFlag();

  const { user } = useAuthContext();
  const [createAddressView, setCreateAddressView] = useState(false);
  const [
    queryDeliveryRestaurant,
    { data: deliveryRestaurantData },
  ] = useDeliveryRestaurantLazyQuery({ fetchPolicy: 'no-cache' });
  const [
    queryRestaurantsFromBackend,
    storeData,
    deliveryQuoteError,
  ] = useQueryClosestAvailableDeliveryRestaurant();
  const { connection } = useNetworkContext();
  const { setDeliverySurchargeFee } = useServiceModeContext();
  const getDetails = usePlaceIdDetails();
  const staticMenuRedirect = useStaticMenuRedirect();
  const { navigate } = useNavigation();
  const { checkStaticMenuItemAvailability, selectedStaticMenuItemId } = useMenuContext();

  const { logRBIEvent } = useMParticleContext();

  const order = useOrderContext();
  const [AlertDialog, openAlert, alertMessage] = useDialogModal({
    onConfirm: () => navigate(`${routes.account}/info`),
    modalAppearanceEventMessage: 'Alert: Payment Failed',
  });
  const [ErrorDialog, openErrorDialog] = useErrorModal({
    modalAppearanceEventMessage: 'Error: Delivery Not Completed',
  });

  const enableUserSavedDeliveryAddressPhone = useFlag(
    LaunchDarklyFlag.ENABLE_USER_SAVED_DELIVERY_ADDRESS_PHONE
  );
  const enableConsolidatedDeliveryRestaurantLogic = useFlag(
    LaunchDarklyFlag.ENABLE_CONSOLIDATED_DELIVERY_RESTAURANT_BACKEND_LOGIC
  );
  const deliveryRadius = useFlag(LaunchDarklyFlag.DELIVERY_RADIUS_IN_MILES);
  const shouldEnableUserSavedDeliveryAddressPhone = enableUserSavedDeliveryAddressPhone && !!user;

  const showDeliveryAddresses =
    shouldEnableUserSavedDeliveryAddressPhone && Boolean(user?.details?.deliveryAddresses?.length);
  const {
    setDeliveryAddress,
    setDeliveryInstructions,
    setOrderPhoneNumber,
    setQuoteId,
    selectStore,
    selectServiceMode,
  } = order;

  const handleQuoteResult = useCallback(
    async ({
      store,
      deliveryQuote,
      storeStatus,
      nextEarliestOpen,
    }: IGetClosestAvailableDeliveryRestaurantResult) => {
      // if store available, route to store
      if (store && deliveryQuote === DeliveryStatus.QUOTE_SUCCESSFUL) {
        return selectStore(
          store,
          async () => {
            if (state.address) {
              const {
                address,
                shouldSaveAddress,
                deliveryInstructions,
                phoneNumber,
                coordinates,
              } = state.address;

              setDeliveryAddress({
                ...address,
                shouldSave: shouldSaveAddress,
                latitude: coordinates?.lat,
                longitude: coordinates?.lng,
              });

              setDeliveryInstructions(deliveryInstructions);
              setOrderPhoneNumber(phoneNumber);
            }

            const serviceMode = ServiceMode.DELIVERY;
            selectServiceMode(serviceMode);
            // If user selected item from static menu, make sure it is available at store
            if (selectedStaticMenuItemId) {
              await checkStaticMenuItemAvailability(store, ServiceMode.DELIVERY);
              staticMenuRedirect({ restaurant: store, serviceMode });
            } else {
              if (enableReactNativeNavigation) {
                navigationRef.dispatch(StackActions.pop(redirectUrl === routes.cart ? 2 : 1));
              }
              navigate(redirectUrl);
            }
          },
          ServiceMode.DELIVERY
        );
      }

      if (deliveryQuote === DeliveryStatus.QUOTE_UNAVAILABLE) {
        return dispatch({ type: ActionStatus.QUOTE_UNAVAILABLE });
      }

      if (deliveryQuote === DeliveryStatus.QUOTE_ERROR) {
        return dispatch({
          type: ActionStatus.ERROR,
          error: {
            message: formatMessage({ id: 'pleaseTryAgainLater' }),
            modalAppearanceEventMessage: 'Error: Delivery Quote Error',
          },
        });
      }

      // otherwise present current store status modal
      navigate(routes[storeStatus], { state: { nextEarliestOpen } });
    },
    [
      checkStaticMenuItemAvailability,
      formatMessage,
      navigate,
      redirectUrl,
      selectServiceMode,
      selectStore,
      selectedStaticMenuItemId,
      setDeliveryAddress,
      setDeliveryInstructions,
      setOrderPhoneNumber,
      state.address,
      staticMenuRedirect,
    ]
  );

  useEffect(() => {
    const restaurant = deliveryRestaurantData?.deliveryRestaurant?.restaurant || storeData?.store;
    if (!restaurant) {
      return;
    }

    logRBIEvent({
      name: CustomEventNames.DELIVERY_STORE_ASSIGNED,
      type: EventTypes.Other,
      attributes: {
        restaurantId: restaurant._id || '',
        storeId: restaurant.storeId || '',
        restaurantAddress: restaurant.physicalAddress?.address1 || '',
        restaurantZip: restaurant.physicalAddress?.postalCode || '',
        restaurantCity: restaurant.physicalAddress?.city || '',
        restaurantState: restaurant.physicalAddress?.stateProvince || '',
        restaurantCountry: restaurant.physicalAddress?.country || '',
      },
    });
  }, [deliveryRestaurantData, storeData, logRBIEvent]);

  useEffect(() => {
    if (deliveryQuoteError && deliveryQuoteError === GraphQLErrorMessages.INVALID_PHONE_NUMBER) {
      openAlert({
        body: formatMessage({ id: 'areDeliveryDetailsValid' }),
        buttonLabel: formatMessage({ id: 'addPhoneNumber' }),
        heading: formatMessage({ id: 'deliveryPhoneNumberError' }),
      });
      return;
    }

    // TODO: Consider isolating this to it's own effect.
    if (storeData) {
      handleQuoteResult(storeData);
    }

    if (deliveryRestaurantData?.deliveryRestaurant && enableConsolidatedDeliveryRestaurantLogic) {
      const {
        quote,
        restaurant,
        storeStatus,
        nextEarliestOpen,
        deliverySurchargeFeeCents,
        quoteId,
      } = deliveryRestaurantData?.deliveryRestaurant;

      if (deliverySurchargeFeeCents) {
        setDeliverySurchargeFee(deliverySurchargeFeeCents);
      }

      setQuoteId(quoteId);

      handleQuoteResult({
        // @ts-expect-error TS(2322) FIXME: Type 'DeliveryStatus | null | undefined' is not as... Remove this comment to see the full error message
        deliveryQuote: quote,
        store: (restaurant as unknown) as IStore,
        storeStatus: DeliveryStoreStatusToStoreStatusMap[storeStatus],
        nextEarliestOpen: nextEarliestOpen ? new Date(nextEarliestOpen) : undefined,
      });
    }

    /*
     * TODO: `handleQuoteResult` is a created by `useCallback`. It's updated
     * whenever `selectServiceMode` is updated, which is also created by
     * `useCallback`. `selectServiceMode` is updated whenever the `serviceMode`
     * state variable is changed. `selectServiceMode` is called in
     * `handleQuoteResult` causing an infinite render loop.
     *
     * Leaving `handleQuoteResult` out of the dependency array solves the issue
     * for now, but might needs a closer look as we organize state more effectively.
     */
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    enableConsolidatedDeliveryRestaurantLogic,
    deliveryRestaurantData,
    deliveryQuoteError,
    storeData,
  ]);

  const completeDelivery = async (data: SelectedDeliveryAddress) => {
    if (state.status === ActionStatus.PENDING) {
      return;
    }

    if (!data.address) {
      return dispatch({
        type: ActionStatus.ERROR,
        error: { message: 'Missing address information for delivery' },
      });
    }

    const {
      address,
      phoneNumber,
      coordinates: selectedCoordinates,
      placeId: selectedPlaceId,
    } = data;

    dispatch({ type: ActionStatus.PENDING, address: data });

    try {
      const placeId = selectedPlaceId;

      // TODO: RN - In very rare situations you could end up here without a placeId. Add an Address to PlaceId lookup back
      // if (!selectedCoordinates && !selectedPlaceId) {
      //   // only get the placeId if we need it to look up coordinates
      //   placeId = await getPlaceId(getAddressLabel(data));
      // }

      // TODO: RN - Is this extra place lookup really needed?
      const { coordinates } = selectedCoordinates
        ? { coordinates: selectedCoordinates }
        : await getDetailsPlaceData(placeId);

      if (!coordinates) {
        throw new Error('Unable to get coordinates from selected address');
      }

      if (enableConsolidatedDeliveryRestaurantLogic) {
        queryDeliveryRestaurant({
          variables: {
            dropoff: {
              ...address,
              latitude: coordinates.lat,
              longitude: coordinates.lng,
              phoneNumber,
            },
            searchRadius: convertMilesToMeters(deliveryRadius),
            platform: platform(),
          },
        });
      } else {
        queryRestaurantsFromBackend(
          phoneNumber,
          deliveryRadius,
          {
            connection,
            userLat: coordinates.lat,
            userLng: coordinates.lng,
          },
          address
        );
      }
    } catch (error) {
      dispatch({
        type: ActionStatus.ERROR,
        // @ts-expect-error TS(2322) FIXME: Type 'unknown' is not assignable to type 'Error | ... Remove this comment to see the full error message
        error: { message: 'Error using delivery address', error },
      });
    }
  };

  const getDetailsPlaceData = useCallback(
    (pId: string | undefined): Promise<IPlaceData> => {
      return getDetails(pId).then(placeData => {
        if (placeData) {
          return Promise.resolve(placeData);
        }

        return Promise.reject(placeData);
      });
    },
    [getDetails]
  );

  useEffect(() => {
    if (state.status === ActionStatus.ERROR && state.error) {
      openErrorDialog(state.error);
    }
  }, [openErrorDialog, state.error, state.status]);

  return (
    <ScrollView keyboardShouldPersistTaps="handled">
      {state.status === ActionStatus.QUOTE_UNAVAILABLE ? (
        <DeliveryUnavailable
          address={state.address ? getAddressLabel(state.address) : ''}
          close={() => dispatch({ type: ActionStatus.IDLE })}
        />
      ) : (
        <>
          {showDeliveryAddresses && !createAddressView ? (
            <DeliveryAddresses
              addresses={user?.details?.deliveryAddresses || []}
              onCreate={() => setCreateAddressView(true)}
              setAddress={completeDelivery}
              isLoading={state.status === ActionStatus.PENDING}
            />
          ) : (
            <DeliveryAddressForm
              instructions={order.deliveryInstructions}
              getDetailsPlaceData={getDetailsPlaceData}
              setCreateAddressView={setCreateAddressView}
              showDeliveryAddresses={showDeliveryAddresses}
              user={user}
              setAddress={completeDelivery}
              isLoading={state.status === ActionStatus.PENDING}
            />
          )}
        </>
      )}

      <ErrorDialog testID="address-modal-error-dialog" />
      <AlertDialog {...(alertMessage || {})} />
    </ScrollView>
  );
};
