import React, { FC, useCallback } from 'react';

import { Icon, IconButton, Text } from '@rbilabs/universal-components';
import { useIntl } from 'react-intl';
import { Keyboard } from 'react-native';

import { useIsTabletBp } from 'hooks/breakpoints';
import { theme } from 'styles/configure-theme';

import { IToggleViewButtonProps, VIEW_MESSAGE_ID_MAP } from '../../toggle-view-button/types';
import { StoreLocatorViews } from '../../types';

export const ToggleViewButton: FC<React.PropsWithChildren<IToggleViewButtonProps>> = ({
  currentView,
  updateStoreLocatorView,
}) => {
  const { formatMessage } = useIntl();

  const handleToggleLocatorViewClick = useCallback(() => {
    const newView =
      currentView === StoreLocatorViews.LIST_VIEW
        ? StoreLocatorViews.MAP_VIEW
        : StoreLocatorViews.LIST_VIEW;
    updateStoreLocatorView(newView);
    Keyboard.dismiss();
  }, [currentView, updateStoreLocatorView]);

  const currentViewText = formatMessage({ id: VIEW_MESSAGE_ID_MAP[currentView] });

  const isTablet = useIsTabletBp();
  // display map option when they are on the list view
  return (
    <IconButton
      variant={isTablet ? 'ghost' : 'ghost-reversed'}
      icon={
        <Icon
          variant={currentView === StoreLocatorViews.LIST_VIEW ? 'map' : 'list'}
          color={theme.token('text-button-reversed')}
          aria-hidden
        />
      }
      onPress={handleToggleLocatorViewClick}
      testID="toggle-view-button"
      accessibilityLabel={currentViewText}
    >
      <Text variant="copyOne">{currentViewText}</Text>
    </IconButton>
  );
};

export default ToggleViewButton;
