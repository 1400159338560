import { useDefaultMapView__Experiment } from 'experiments/default-map-view-for-store-locator';

import { StoreLocatorViews } from '../types';

export const MPARTICLE_VIEW_EVENT_MAP = {
  [StoreLocatorViews.LIST_VIEW]: 'Selected list view',
  [StoreLocatorViews.MAP_VIEW]: 'Selected map view',
};

export default function useStoreLocatorView() {
  const [currentView, setCurrentView] = useDefaultMapView__Experiment();

  const updateCurrentView = (newView: StoreLocatorViews) => {
    // switch view state
    setCurrentView(newView);
  };

  return {
    currentView,
    currentViewIsList: currentView === StoreLocatorViews.LIST_VIEW,
    currentViewIsMap: currentView === StoreLocatorViews.MAP_VIEW,
    updateStoreLocatorView: updateCurrentView,
  };
}
