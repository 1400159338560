import React, { FC, useCallback, useMemo } from 'react';

import { sumBy } from 'lodash-es';
import { useIntl } from 'react-intl';

import { IItemOptionModifier } from '@rbi-ctg/menu';
import { ListItemGroup } from 'components/list-item-group';
import { NumberInput } from 'components/ucl/number-input';
import { useMParticleContext } from 'state/mParticle';

import { ModifierListItem } from './modifier-list-item';
import { incrementorStyleProps } from './styles';
import { IModifierTypeUIProps } from './types';
import { logProductModifierCustomization } from './utils';

export const ModifierMultipleChoices: FC<IModifierTypeUIProps> = ({
  modifier,
  selections,
  onSelectionsChange,
}) => {
  const { formatMessage } = useIntl();
  const remainedSelectionsQuantity = useMemo(
    () => modifier.maxAmount - sumBy(Object.values(selections), sel => sel.quantity),
    [modifier.maxAmount, selections]
  );
  const mParticle = useMParticleContext();
  const handleOptionQuantityChange = useCallback(
    (option: IItemOptionModifier, newQuantity: number) => {
      onSelectionsChange({
        ...selections,
        [option._key]: {
          ...option,
          quantity: newQuantity,
        },
      });
      logProductModifierCustomization(
        option.name?.locale ?? '',
        modifier.name.locale,
        option._key,
        mParticle
      );
    },
    [onSelectionsChange, selections]
  );

  return (
    <ListItemGroup
      testID="modifier-multiple-choices"
      subHeader={formatMessage(
        { id: 'chooseItemAmount' },
        { amount: modifier.maxAmount, itemName: modifier.name?.locale }
      )}
      items={modifier.options.map(option => {
        const numberInputValue = selections[option._key]?.quantity || 0;
        const numberInputMax = numberInputValue + remainedSelectionsQuantity;
        const numberInput = (
          <NumberInput
            testID="modifier-number-input"
            min={0}
            max={numberInputMax}
            value={numberInputValue}
            onChange={(newQuantity: number) => handleOptionQuantityChange(option, newQuantity)}
            ariaLabel={`Select ${option.name?.locale} quantity`}
            decrementAriaLabel={`Decrement ${option.name?.locale} quantity`}
            incrementAriaLabel={`Increment ${option.name?.locale} quantity`}
            incrementorStyleProps={incrementorStyleProps}
            isReadOnly={true}
          />
        );

        return (
          <ModifierListItem
            key={`${option._key}-${numberInputValue}`}
            content={option}
            controls={numberInput}
          />
        );
      })}
    />
  );
};
