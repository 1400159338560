import * as React from 'react';

import { useIntl } from 'react-intl';

import { ActionButtonVariants } from 'components/action-button';
import { routes } from 'utils/routing';

import { ModalContent, ModalHeading, ModalTitle } from '../styled';

import {
  ButtonContainer,
  Container,
  LoadingText,
  StyledActionButton,
  StyledActionLink,
} from './delivery-unavailable.styled';
import { IDeliveryUnavailableProps } from './types';

/**
 *
 * DeliveryUnavailable informs the guest that the address they entered
 * is unable to receive delivery at this time
 *
 */

const DeliveryUnavailable: React.FC<React.PropsWithChildren<IDeliveryUnavailableProps>> = ({
  address,
  close,
}) => {
  const { formatMessage } = useIntl();
  return (
    <Container testID="delivery-unavailable-for-address">
      <ModalHeading>
        <ModalTitle>{formatMessage({ id: 'deliveryNotAvailable' })}</ModalTitle>
      </ModalHeading>
      <ModalContent>
        <LoadingText>{formatMessage({ id: 'cannotDeliveryTo' })}</LoadingText>
        <LoadingText>{address}</LoadingText>
        <ButtonContainer>
          <StyledActionLink fullWidth to={routes.storeLocator}>
            {formatMessage({ id: 'orderPickup' })}
          </StyledActionLink>
        </ButtonContainer>
        <ButtonContainer>
          <StyledActionButton
            testID="use-different-address"
            fullWidth
            onPress={close}
            variant={ActionButtonVariants.OUTLINE}
          >
            {formatMessage({ id: 'useDifferentAddress' })}
          </StyledActionButton>
        </ButtonContainer>
      </ModalContent>
    </Container>
  );
};

export default DeliveryUnavailable;
