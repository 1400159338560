import * as React from 'react';
import { FC, useEffect } from 'react';

import { useRumPageView } from 'hooks/rum/use-rum-page-view';
import { EventName, emitEvent } from 'utils/event-hub';

import { StoreLocator as StoreLocatorRefresh } from './new-ui';

/**
 *
 * StoreLocator purpose
 *
 */
export const StoreLocator: FC<React.PropsWithChildren<unknown>> = props => {
  useRumPageView('store-locator', 'Store Locator');
  useEffect(() => {
    return () => {
      emitEvent(EventName.STORE_LOCATOR_UNMOUNT);
    };
  }, []);
  return <StoreLocatorRefresh {...props} />;
};

export default StoreLocator;
