import React from 'react';

import { Icon } from '@rbilabs/universal-components';
import { NativeStackNavigationOptions } from '@react-navigation/native-stack';
import { useIntl } from 'react-intl';

import { HeaderLeftContainer } from 'navigation/header/styles';
import { hiddenAccessibilityPlatformProps } from 'utils/accessibility';

import type { NativeStackOptionProps } from './types';

function CloseButtonHeaderLeft({
  options,
  optionProps,
}: {
  options: NativeStackNavigationOptions;
  optionProps: NativeStackOptionProps;
}) {
  const { formatMessage } = useIntl();
  return (
    <HeaderLeftContainer
      onPress={optionProps.navigation.goBack}
      dd-action-name="close modal"
      accessibilityHint={formatMessage({ id: 'close' })}
      accessibilityLabel={formatMessage({ id: 'close' })}
      accessibilityRole="button"
    >
      <Icon
        variant="cancel"
        color={options.headerTintColor}
        size="sm"
        testID="btn-cancel"
        {...hiddenAccessibilityPlatformProps}
      />
    </HeaderLeftContainer>
  );
}

export const withCloseButtonOptions = (options: NativeStackNavigationOptions) => (
  props: NativeStackOptionProps
): NativeStackNavigationOptions => {
  // shollington-rbi
  // WARNING: although this looks like a React component, do not use React Hooks
  return {
    ...options,
    headerLeft: () => <CloseButtonHeaderLeft options={options} optionProps={props} />,
  };
};
