import React from 'react';

import { Box, Text } from '@rbilabs/universal-components';
import { useIntl } from 'react-intl';

import BlockRenderer from 'components/sanity-block-renderer';
import { useFeatureDisclaimers } from 'hooks/use-feature-disclaimers';

const Wrapper = Box.withConfig({
  paddingY: '$4',
  borderTopWidth: 1,
  borderTopColor: 'black',

  _text: {
    fontSize: '2xs',
  },
});

const CalorieDisclaimer = BlockRenderer.withConfig({
  blockContentOverrides: {
    normal: Text.withConfig({ fontSize: '2xs' }),
  },
  linkProps: {
    underline: true,
  },
});

const Disclaimer = () => {
  const { formatMessage } = useIntl();
  const { featureDisclaimers } = useFeatureDisclaimers();
  const { calorie, price, images } = featureDisclaimers || {};

  return (
    <Wrapper>
      <CalorieDisclaimer content={calorie?.localeRaw} />
      {formatMessage({ id: 'trademarkAndCopyrightPopeyes' })}
      {price?.locale}
      {images?.locale}
    </Wrapper>
  );
};

export default Disclaimer;
