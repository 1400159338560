import type { RawTab } from '../../types';

import * as shared from './shared';

export const HomeTab: RawTab = {
  id: shared.id,

  screen: shared.screen,

  link: shared.link,

  iconSource: require('./icons/plk-home-inactive.png'),
  iconActiveSource: require('./icons/plk-home-active.png'),

  text: {
    en: 'Home',
    fr: 'Commencer',
  },
};
