import { Box, Header, ScrollView, Text } from '@rbilabs/universal-components';

import { ModalContent as OriginalModalContent } from 'components/modal';
import { theme } from 'styles/configure-theme';

export const ModalContent = OriginalModalContent.withConfig({
  width: 'full',
  padding: '0',
  flex: 1,
  mb: '$2',
});

export const AddressListContainer = ScrollView.withConfig({
  width: 'full',
});

export const AddressList = Box.withConfig({
  marginTop: '-8px', // this removes the marginBottom: 2 from ..styled/ModalHeading
  marginBottom: '$4',
  marginX: '0',
  width: 'full',
  justifyContent: 'center',
  alignItems: 'center',
  flex: 1,
});

export const AddressContainer = Box.withConfig({
  width: 'full',
  paddingY: '$4',
  paddingX: '$6',
  justifyContent: 'center',
  borderStyle: 'solid',
  borderBottomWidth: '1px',
  borderColor: Styles.color.grey.four,
  backgroundColor: theme.token('background-pattern'),
  flex: 1,
});

export const ButtonContainer = Box.withConfig({
  marginY: '$0',
  paddingX: '$4',
  width: {
    base: 'full',
    md: '50%',
  },
});

export const InfoContainer = Box.withConfig({
  marginLeft: '$4',
});

export const AddressInfoLine = Header.withConfig({
  variant: 'headerThree',
  margin: '0',
});

export const InfoLine = Text.withConfig({
  variant: 'copyOne',
});

export const DeliveryInfoContainer = Text.withConfig({
  maxWidth: '$48',
  marginBottom: '$8',
  marginTop: '$5',
  marginX: 'auto',
});
