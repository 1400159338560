import { createNativeStackNavigator } from '@react-navigation/native-stack';

import StoreInfoModalContainer from 'components/store-info-modal';
import { useLogNavigation } from 'hooks/navigation/use-log-navigation';
import { withCloseButtonOptions } from 'navigation/navigator/with-close-button-options';
import { StoreLocatorHeaderTitle } from 'navigation/store-locator/store-locator-header-title';
import StoreLocator from 'pages/store-locator';
import { theme } from 'styles/configure-theme';
import { relativeRoutes, routes } from 'utils/routing';

const Stack = createNativeStackNavigator();

export const StoreLocatorNavigator = () => {
  const logPageViewOnStateChange = useLogNavigation();

  return (
    <Stack.Navigator
      screenListeners={{
        // @ts-ignore
        state: logPageViewOnStateChange,
      }}
    >
      <Stack.Group
        screenOptions={withCloseButtonOptions({
          presentation: 'modal',
          headerShadowVisible: false,
          headerTitle: () => <StoreLocatorHeaderTitle />,
          headerTitleAlign: 'center',
          headerTintColor: theme.token('text-reversed'),
          headerStyle: {
            backgroundColor: theme.token('background-active'),
          },
          headerBackVisible: false, // hide native back handler on Android
        })}
      >
        <Stack.Screen name={routes.storeLocator} component={StoreLocator} />
        <Stack.Screen name={routes.address} component={StoreLocator} />
        <Stack.Screen
          name={`${routes.storeLocator}/${relativeRoutes.serviceMode}`}
          component={StoreLocator}
        />
        <Stack.Screen
          name={routes.store}
          component={StoreInfoModalContainer}
          options={{ headerTitle: '' }}
        />
      </Stack.Group>
    </Stack.Navigator>
  );
};
