import React, { useState } from 'react';

import isEqual from 'lodash-es/isEqual';
import { useIntl } from 'react-intl';
import { FlatList } from 'react-native';

import { StoreLocatorEmptyStates } from 'components/store-locator-empty-states';
import { useSingleSelectCtaInLocator } from 'experiments/single-select-cta-in-locator';
import { LocatorTile__Experiment } from 'experiments/single-select-cta-in-locator/locator-tile';
import { IRestaurantNode } from 'generated/rbi-graphql';
import { useStoreLocatorPrefetch } from 'pages/store-locator/hooks/use-store-locator-prefetch';
import { useGeolocation } from 'state/geolocation';
import { OrderLocatorTab } from 'state/launchdarkly/variations';
import { useMainMenuContext } from 'state/main-menu';
import { useStoreContext } from 'state/store';
import { ServiceModeCategory } from 'utils/service-mode';
import { STORE_LOCATOR_STORE_LIST_ITEM_AT } from 'utils/test-ids';

import LoadingIndicator from '../../loading-indicator';
import { StoreActions } from '../store-actions';
import { StoreCard } from '../store-card';
import type { StoreCardProps } from '../store-card/types';

import { StoreCardWrapper } from './store-card-list.styled';

interface IStoreCardListProps {
  serviceModeCategory?: ServiceModeCategory;
  currentTabHasError?: boolean;
  isSearching?: boolean;
  restaurants: IRestaurantNode[];
  tab: OrderLocatorTab;
  onCardPress?: (storeId?: string, storeNumber?: string, storeAddress?: string) => void;
}

const StoreCardItem = ({
  isSelected,
  restaurant,
  onCardClick,
  testID,
  serviceModeCategory,
}: StoreCardProps) => {
  const singleSelectCtaInLocator = useSingleSelectCtaInLocator();

  return singleSelectCtaInLocator ? (
    <LocatorTile__Experiment restaurant={restaurant} />
  ) : (
    <StoreCardWrapper>
      <StoreCard
        serviceModeCategory={serviceModeCategory}
        restaurant={restaurant}
        onCardClick={onCardClick}
        isSelected={isSelected}
        testID={testID}
      />
      {isSelected ? (
        <StoreActions restaurant={restaurant} serviceModeCategory={serviceModeCategory} />
      ) : null}
    </StoreCardWrapper>
  );
};

const StoreCardList: React.FC<IStoreCardListProps> = ({
  currentTabHasError = false,
  isSearching,
  restaurants = [],
  tab,
  onCardPress,
  serviceModeCategory,
}) => {
  const { formatMessage } = useIntl();
  const [selectedIndex, setSelectedIndex] = useState<number | null>(null);
  const { coordinatesAvailable, isPermissionGranted } = useGeolocation();
  const { prefetchRestaurantValuesForOrder } = useStoreLocatorPrefetch();
  const { clearStoreMenuCache } = useMainMenuContext();
  const { store } = useStoreContext();

  const handleCardClick = (index: number) => {
    // Only clear the store menu cache when selecting a different store
    if (store._id !== restaurants[index]._id) {
      clearStoreMenuCache();
    }

    if (selectedIndex === index) {
      setSelectedIndex(null);
    } else {
      setSelectedIndex(index);

      prefetchRestaurantValuesForOrder(restaurants[index]);
    }
  };

  if (isSearching) {
    return <LoadingIndicator ariaLabel={formatMessage({ id: 'locationSearch' })} />;
  }

  if (restaurants.length === 0) {
    return (
      <>
        <StoreLocatorEmptyStates
          tab={tab}
          locationPermissionsGranted={isPermissionGranted}
          coordinatesAvailable={coordinatesAvailable}
          currentTabError={currentTabHasError}
        />
      </>
    );
  }

  return (
    <FlatList
      keyExtractor={(item, index) => item?.id || index + '_restaurant'}
      data={restaurants}
      renderItem={({ item, index }) => {
        const handleCardPress = onCardPress
          ? () =>
              onCardPress(
                String(item.id),
                String(item.number),
                String(item.physicalAddress?.address1)
              )
          : () => handleCardClick(index);

        return (
          <StoreCardItem
            serviceModeCategory={serviceModeCategory}
            testID={STORE_LOCATOR_STORE_LIST_ITEM_AT(index)}
            restaurant={item}
            onCardClick={handleCardPress}
            isSelected={index === selectedIndex}
          />
        );
      }}
    />
  );
};

export default React.memo(StoreCardList, (prevProps, nextProps) => {
  if (prevProps.serviceModeCategory !== nextProps.serviceModeCategory) {
    return false;
  }
  if (prevProps.tab !== nextProps.tab) {
    return false;
  }
  if (prevProps.isSearching !== nextProps.isSearching) {
    return false;
  }
  if (prevProps.currentTabHasError !== nextProps.currentTabHasError) {
    return false;
  }
  if (!isEqual(prevProps.restaurants, nextProps.restaurants)) {
    return false;
  }

  return true;
});
