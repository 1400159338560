import React, { useCallback } from 'react';

import { Pressable } from '@rbilabs/universal-components';
import { useIntl } from 'react-intl';

import { Header } from 'pages/store-locator/new-ui/store-locator.styled';
import { LaunchDarklyFlag, useFlag } from 'state/launchdarkly';
import { useOrderContext } from 'state/order';
import { useStoreContext } from 'state/store';

// In React Navigation, the clear store/sm logic will live on the StoreLocator header title - "Locations"
// This is because now the X icon exists on the StoreLocator modal
export const StoreLocatorHeaderTitle = () => {
  const { formatMessage } = useIntl();
  const enableClearStoreAndServiceModeButton = useFlag(
    LaunchDarklyFlag.ENABLE_LOCATOR_CLEAR_STORE_AND_SM_BUTTON
  );
  const { clearServerOrder } = useOrderContext();
  const { resetStore } = useStoreContext();

  const handleClearStoreAndServiceMode = useCallback(() => {
    if (!enableClearStoreAndServiceModeButton) {
      return;
    }
    clearServerOrder();
    resetStore();
  }, [clearServerOrder, enableClearStoreAndServiceModeButton, resetStore]);

  return (
    <Pressable onPress={handleClearStoreAndServiceMode}>
      <Header variant="headerOne" margin={0}>
        {formatMessage({ id: 'locations' })}
      </Header>
    </Pressable>
  );
};
