import React from 'react';

import LottieView, { AnimatedLottieViewProps } from 'lottie-react-native';

import animation from './json/index.plk.json';

type AnimationProps = Omit<AnimatedLottieViewProps, 'options' | 'source'>;

const Animation: React.VFC<AnimationProps> = ({ ...props }) => (
  <LottieView source={animation} autoPlay style={{ width: '100%' }} {...props} />
);

export default Animation;
