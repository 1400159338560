import React, { Suspense } from 'react';

import { HStack } from '@rbilabs/universal-components';
import { useIntl } from 'react-intl';

import { Animation } from 'components/lottie-animations/favorite-loading';
import { LaunchDarklyFlag, useFlag } from 'state/launchdarkly';

import { StoreCardSeparator } from '../store-card-separator';

import { FallbackWrapper, LoadingWrapper } from './store-card-actions.styled';
import { StoreCardButton } from './store-card-button';
import { StoreCardIcon } from './store-card-icon';

interface StoreCardActionsProps {
  onDiagnosticsClick?: () => void;
  onFavoritesClick?: () => void;
  favoriteIcon?: 'favorite' | 'favoriteFill';
  isUpdatingFavorites?: boolean;
  isFavorite?: boolean;
}

export const StoreCardActions: React.VFC<StoreCardActionsProps> = ({
  onDiagnosticsClick,
  onFavoritesClick,
  favoriteIcon = 'favorite',
  isUpdatingFavorites,
  isFavorite,
}) => {
  const { formatMessage } = useIntl();
  const enableFavorites = useFlag(LaunchDarklyFlag.ENABLE_FAVORITE_STORES);

  return (
    <HStack>
      {onDiagnosticsClick && (
        <StoreCardButton
          testID="store-favorite-button"
          accessibilityLabel="Open a diagnostic modal for this store"
          onPress={onDiagnosticsClick}
          icon={<StoreCardIcon variant="settings" />}
        />
      )}

      {onDiagnosticsClick && onFavoritesClick && <StoreCardSeparator />}

      {enableFavorites && onFavoritesClick && (
        <Suspense
          fallback={
            <FallbackWrapper>
              <StoreCardIcon variant={favoriteIcon} />
            </FallbackWrapper>
          }
        >
          {isUpdatingFavorites ? (
            <LoadingWrapper>
              <Animation />
            </LoadingWrapper>
          ) : (
            <StoreCardButton
              accessibilityLabel={
                isFavorite
                  ? formatMessage({ id: 'removeFavorite' })
                  : formatMessage({ id: 'setRestaurantAsFavorite' })
              }
              onPress={onFavoritesClick}
              icon={<StoreCardIcon variant={favoriteIcon} />}
            />
          )}
        </Suspense>
      )}
    </HStack>
  );
};
