import { extendTheme } from '@rbilabs/universal-components/themes';
import { plkTheme } from '@rbilabs/universal-components/themes/plk';

import { designTokens } from '../constants/design-tokens';

export const theme = extendTheme(plkTheme, {
  nativeBaseTheme: {
    colors: {
      // @ts-expect-error TS(2322) FIXME: Type '{ __legacyToken: DesignTokens; }' is not ass... Remove this comment to see the full error message
      __legacyToken: designTokens,
    },
  },
});
