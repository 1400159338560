import { Box, HStack } from '@rbilabs/universal-components';

export const LoadingWrapper = Box.withConfig({
  height: '$10',
  width: '$10',
  justifyContent: 'center',
  alignItems: 'center',
});

export const FallbackWrapper = HStack.withConfig({
  height: '$10',
  width: '$10',
  justifyContent: 'center',
  alignItems: 'center',
});
