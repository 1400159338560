import mParticle from '@mparticle/web-sdk';

import { RBIBrand, brand, getConfigValue } from 'utils/environment';

const isProduction = getConfigValue({ key: 'env' }) === 'prod';

// Note: This does not support multi region applications currently.
// If there is an expectation for this to utilize multi region, we will
// need to adjust this to init within the react runtime, and not crash
// the MparticleAdapters when mParticle has not yet initialized.
const region = getConfigValue({ key: 'buildRegion' });
const apiKeys = getConfigValue({ key: 'apiKeys', region });
const mParticleKey = apiKeys.mParticle;
const planId = getDataPlanId();
export const mParticleConfig = {
  isDevelopmentMode: !isProduction,
  logLevel: isProduction ? 'none' : 'verbose',
  dataPlan: {
    planId: { planId },
  },
};

mParticle.init(mParticleKey, mParticleConfig);

function getDataPlanId() {
  const defaultPlanId = 'data_plan';
  // We currently only publish data plans for FHS, see MPARTICLE_WORKSPACE_CONFIGURATION
  // in CircleCI for @rbilabs/mparticle-client
  if (brand() !== RBIBrand.FHS) {
    return defaultPlanId;
  }

  return getConfigValue({ key: 'mparticleDataPlanId' });
}

export function noop() {}
