import React, { useCallback, useEffect, useState } from 'react';

import { useNavigation } from 'hooks/navigation/use-navigation';
import { useGeolocation } from 'state/geolocation';
import { useOrderContext } from 'state/order';
import { routes } from 'utils/routing';

import { GeolocationModalView } from './geolocation-modal-view';

export const GeolocationModal = () => {
  const { goBack, navigate } = useNavigation();

  if (!navigator.geolocation) {
    navigate(routes.storeLocator, { replace: true });
  }
  // @todo type order context
  const { isDelivery } = useOrderContext();
  const {
    isPermissionKnown,
    isPermissionPrompt,
    setPermissionChecking,
    setPermissionPrompt,
  } = useGeolocation();

  const [waitingForKnownPermission, setWaitingForKnownPermission] = useState(false);

  const reRouteAddress = isDelivery ? routes.address : routes.storeLocator;

  // we are no longer returning coords from loadCurrentPosition
  // so now we need to set some local state to know that we are
  // waiting for coords before navigating
  useEffect(() => {
    if (waitingForKnownPermission && isPermissionKnown) {
      navigate(reRouteAddress);
    }
  }, [isPermissionKnown, navigate, reRouteAddress, waitingForKnownPermission]);

  const handleGeolocationPrompt = useCallback(() => {
    setPermissionChecking();
    setWaitingForKnownPermission(true);
  }, [setPermissionChecking]);

  const handleDismissModal = useCallback(() => {
    goBack();
    setPermissionPrompt();
  }, [goBack, setPermissionPrompt]);

  return !isPermissionPrompt ? null : (
    <GeolocationModalView
      handleDismissModal={handleDismissModal}
      handleGeolocationPrompt={handleGeolocationPrompt}
    />
  );
};
