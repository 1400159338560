import * as React from 'react';

import { Badge, Box, Icon } from '@rbilabs/universal-components';
import { useIntl } from 'react-intl';

import ActionButton, { ActionButtonSizes, ActionButtonVariants } from 'components/action-button';
import { useNavigation } from 'hooks/navigation/use-navigation';
import { CustomEventNames, EventTypes, useMParticleContext } from 'state/mParticle';
import { ClickEventComponentNames } from 'state/mParticle/constants';
import { useOrderContext } from 'state/order';
import { useStoreContext } from 'state/store';
import { useUIContext } from 'state/ui';
import { routes } from 'utils/routing';
import { HEADER_VIEW_CART_BUTTON } from 'utils/test-ids';

export function AppHeaderCartButton() {
  const { formatMessage } = useIntl();
  const mParticle = useMParticleContext();

  const { formatCurrencyForLocale } = useUIContext();
  const { calculateCartTotal, numCartPreviewEntries } = useOrderContext();

  const cartButtonText = formatCurrencyForLocale(calculateCartTotal());

  const cartButtonTextLabel = `${cartButtonText} ${formatMessage({ id: 'cartTotal' })}`;

  const isStoreSelected = Boolean(useStoreContext().store._id);
  const { navigate } = useNavigation();

  const handlePress = () => {
    mParticle.logRBIEvent({
      name: CustomEventNames.CLICK_EVENT,
      type: EventTypes.Other,
      attributes: {
        component: ClickEventComponentNames.BUTTON,
        text: 'View Cart',
      },
    });

    navigate(isStoreSelected ? routes.cart : routes.serviceMode);
  };

  return (
    <Box position="relative">
      <ActionButton
        testID={HEADER_VIEW_CART_BUTTON}
        variant={ActionButtonVariants.PRIMARY}
        onPress={handlePress}
        size={ActionButtonSizes.SMALL}
        startIcon={<Icon variant="cart" aria-hidden />}
        disabled={numCartPreviewEntries < 1}
        accessibilityLabel={cartButtonTextLabel}
      >
        {cartButtonText}
      </ActionButton>
      {numCartPreviewEntries > 0 && (
        <Badge variant="number-solid" position="absolute" right={'4px'} top={'-16px'}>
          {numCartPreviewEntries}
        </Badge>
      )}
    </Box>
  );
}
