import { REGIONS } from 'state/intl/types';

// TODO: RN - need to not access window directly here. Must pass in location from function.
export const domainChangeByRegion = (url: string, regionKey?: REGIONS) => {
  if (regionKey === REGIONS.CA && !window.location.hostname.includes('popeyeschicken')) {
    return url.replace('popeyes', 'popeyeschicken');
  }
  if (regionKey === REGIONS.US && window.location.hostname.includes('popeyes.com')) {
    return url.replace('popeyeschicken', 'popeyes');
  }
  return url;
};
