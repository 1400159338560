import { FC } from 'react';

import { Box, HStack } from '@rbilabs/universal-components';

import { MapViewUnderNearbyStores__LocatorHeaderToggle__Experiment } from 'experiments/map-view-under-nearby-stores';
import { useHeaderStyles } from 'navigation/header/use-header-styles';
import { StoreLocatorHeaderTitle } from 'navigation/store-locator/store-locator-header-title';

import { HeaderBackground, HeaderWrapper, ToggleViewButtonWrapper } from '../store-locator.styled';
import { ToggleViewButton } from '../toggle-view-button';

import { ILocatorHeaderProps } from './types';

export const LocatorHeaderBase: FC<ILocatorHeaderProps> = ({ currentView, onViewChange }) => {
  const { height: headerHeight } = useHeaderStyles();
  return (
    <HeaderBackground>
      <HeaderWrapper height={`${headerHeight}px`}>
        <Box flex={1} />
        <StoreLocatorHeaderTitle />
        <HStack flex={1} justifyContent="flex-end">
          <MapViewUnderNearbyStores__LocatorHeaderToggle__Experiment>
            <ToggleViewButtonWrapper>
              <ToggleViewButton currentView={currentView} updateStoreLocatorView={onViewChange} />
            </ToggleViewButtonWrapper>
          </MapViewUnderNearbyStores__LocatorHeaderToggle__Experiment>
        </HStack>
      </HeaderWrapper>
    </HeaderBackground>
  );
};
