import { ApolloClient, from } from '@apollo/client';

import { getConfiguredCache } from 'state/graphql/cache';
import { httpLink } from 'state/graphql/links/http-link';
import { stripTypenameLink } from 'state/graphql/links/strip-typename';
import { withErrorLogger } from 'state/graphql/links/with-error-logger';
import { withHeaders } from 'state/graphql/links/with-headers';

import { headers } from './headers';

/**
 * Apollo Client instance
 *
 * Client should only be instantiated once per app
 * @see https://github.com/apollographql/apollo-client-devtools/issues/822
 */
export const client = new ApolloClient({
  cache: getConfiguredCache(),
  link: from([withHeaders(headers), withErrorLogger, stripTypenameLink, httpLink]),
  headers,
});
