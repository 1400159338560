import React, { ReactNode } from 'react';

import { useIntl } from 'react-intl';
import { SceneRendererProps } from 'react-native-tab-view';

import { TabView } from 'components/tab-view';
import { MapViewUnderNearbyStores__ListView__Experiment } from 'experiments/map-view-under-nearby-stores';
import { IRestaurantNode } from 'generated/rbi-graphql';
import useStoreLocatorTabs from 'pages/store-locator/hooks/use-store-locator-tabs';
import { OrderLocatorTab } from 'state/launchdarkly/variations';
import { ServiceModeCategory } from 'utils/service-mode';

import { useSearching } from '../hooks/use-searching';
import { StoreCardList } from '../store-card-list';

import { StoreCardListContainer } from './list-view.styled';

const intlIdMap: { [K in OrderLocatorTab]: string } = {
  [OrderLocatorTab.NEARBY]: 'nearby',
  [OrderLocatorTab.RECENT]: 'recents',
  [OrderLocatorTab.FAVORITE]: 'favorite',
  [OrderLocatorTab.DELIVERY]: 'delivery',
};

interface IListViewProps {
  serviceModeCategory: ServiceModeCategory;
  errorFavs: null | Error;
  errorNearby: null | Error;
  errorRecent: null | Error;
  searchingNearby: boolean;
  searchingRecent: boolean;
  searchingFavs: boolean;
  rawIsSearching: boolean | undefined;
  storesFavs?: IRestaurantNode[];
  storesNearby?: IRestaurantNode[];
  storesRecent?: IRestaurantNode[];
  mapView__experiment?: ReactNode;
}

const ListView: React.VFC<IListViewProps> = ({
  serviceModeCategory,
  errorFavs,
  errorNearby,
  errorRecent,
  searchingNearby,
  searchingRecent,
  searchingFavs,
  rawIsSearching,
  storesFavs = [],
  storesNearby = [],
  storesRecent = [],
  mapView__experiment,
}) => {
  const { isSearchingFavorites, isSearchingNearby, isSearchingRecent } = useSearching({
    searchingNearby,
    searchingRecent,
    searchingFavs,
    rawIsSearching,
  });
  const { formatMessage } = useIntl();

  const {
    currentTabHasError,
    currentTabIndex,
    handleTabChange,
    locatorTabs,
  } = useStoreLocatorTabs({ errorFavs, errorNearby, errorRecent });

  const storesMap: {
    [K in OrderLocatorTab]: IRestaurantNode[];
  } = {
    [OrderLocatorTab.NEARBY]: storesNearby,
    [OrderLocatorTab.RECENT]: storesRecent,
    [OrderLocatorTab.FAVORITE]: storesFavs,
    [OrderLocatorTab.DELIVERY]: [],
  };

  const searchingMap: {
    [K in OrderLocatorTab]: boolean;
  } = {
    [OrderLocatorTab.NEARBY]: isSearchingNearby,
    [OrderLocatorTab.RECENT]: isSearchingRecent,
    [OrderLocatorTab.FAVORITE]: isSearchingFavorites,
    [OrderLocatorTab.DELIVERY]: false,
  };

  const tabs = locatorTabs.map(key => ({
    key,
    title: formatMessage({ id: intlIdMap[key] }),
  }));

  // As we need to pass additional props, we have to use a custom renderScene function instead of SceneMap
  // Do not return inline functions because  If you pass inline functions, it'll re-create the component every render, which will cause the entire route to unmount and remount every change. It's very bad for performance and will also cause any local state to be lost.
  const renderScene = ({ route }: SceneRendererProps & { route: { key: OrderLocatorTab } }) => {
    return (
      <StoreCardListContainer>
        <MapViewUnderNearbyStores__ListView__Experiment
          mapView={mapView__experiment}
          locatorTab={route.key}
        >
          <StoreCardList
            serviceModeCategory={serviceModeCategory}
            tab={route.key}
            currentTabHasError={currentTabHasError}
            restaurants={storesMap[route.key]}
            isSearching={searchingMap[route.key]}
          />
        </MapViewUnderNearbyStores__ListView__Experiment>
      </StoreCardListContainer>
    );
  };

  return (
    <TabView
      activeTabIndex={currentTabIndex}
      handleTabIndexChange={handleTabChange}
      tabs={tabs}
      // @ts-expect-error TS(2322) FIXME: Type '({ route }: SceneRendererProps & { route: { ... Remove this comment to see the full error message
      renderScene={renderScene}
    />
  );
};

export default ListView;
