import * as React from 'react';

import { Svg, SvgMask, SvgPath, SvgProps } from '@rbilabs/universal-components';

const StoreMarkerActive = (props: SvgProps) => (
  <Svg width={48} height={60} fill="none" viewBox="0 0 48 60" {...props}>
    <SvgMask id="a" fill="#fff">
      <SvgPath
        fillRule="evenodd"
        clipRule="evenodd"
        d="M31.033 46.953C40.856 43.947 48 34.808 48 24 48 10.745 37.255 0 24 0S0 10.745 0 24c0 10.347 6.548 19.165 15.726 22.536L23.5 60l7.533-13.047Z"
      />
    </SvgMask>
    <SvgPath
      fillRule="evenodd"
      clipRule="evenodd"
      d="M31.033 46.953C40.856 43.947 48 34.808 48 24 48 10.745 37.255 0 24 0S0 10.745 0 24c0 10.347 6.548 19.165 15.726 22.536L23.5 60l7.533-13.047Z"
      fill="#FF7D00"
    />
    <SvgPath
      d="m31.033 46.953-.878-2.869-1.13.346-.59 1.023 2.598 1.5Zm-15.307-.417 2.598-1.5-.543-.942-1.02-.374-1.035 2.816ZM23.5 60l-2.598 1.5L23.5 66l2.598-4.5L23.5 60ZM45 24c0 9.452-6.247 17.453-14.845 20.084l1.755 5.738C42.96 46.44 51 36.163 51 24h-6ZM24 3c11.598 0 21 9.402 21 21h6C51 9.088 38.912-3 24-3v6ZM3 24C3 12.402 12.402 3 24 3v-6C9.088-3-3 9.088-3 24h6Zm13.76 19.72C8.726 40.769 3 33.05 3 24h-6c0 11.645 7.371 21.562 17.692 25.352l2.069-5.632Zm9.338 14.78-7.773-13.464-5.197 3L20.902 61.5l5.196-3Zm2.337-13.047L20.901 58.5l5.196 3 7.533-13.047-5.197-3Z"
      fill="#fff"
      mask="url(#a)"
    />
    <SvgPath
      fillRule="evenodd"
      clipRule="evenodd"
      d="m10 19.522 2.673 3.216s1.429-2.094 3.19-.69c1.353 1.08.94 3.028.604 4.63-.101.483-.198.935-.234 1.32-.206 2.114.316 3.824 1.169 5.182 1.085 1.727 3.605 3.165 6.31 3.673.208.038.406.067.602.092l.282 2.973h2.038l.275-2.904c4.678-.375 11.297-4.938 8.351-12.623-1.354-3.535-4.225-8.814-4.225-8.814l.032-.01c1.137-.287 1.357-1.758.03-2.546l.948-.876-1.387-.567.518-1.891-2.98 1.177.004-.004.004-.004s-.755-1.57-1.904-1.582c-.967-.011-1.424.687-1.424.687s-.258-.718-.92-.914c-.662-.196-1.741.189-1.878 1.387-.154 1.343.935 2.034 1.293 2.148l.102.033c.418.14 1.005.335 2.142-.143l-.006 2.748-.005 2.777c0 .267.002.557.005.863.02 1.835.044 4.251-.642 5.654-.801 1.637-1.714 2.319-2.923 2.178-1.351-.158-1.813-1.42-1.344-3.76.47-2.34.866-4.67-1.104-6.405-1.969-1.735-5.509-1.925-9.596 2.995Zm18.454-7.516c.304-.156.693-.004.868.335.174.34.069.743-.235.897-.303.155-.692.004-.867-.337-.174-.339-.07-.741.235-.895Z"
      fill="#fff"
    />
  </Svg>
);

export const storeActiveMarkerSvg = '';

export default StoreMarkerActive;
