import React from 'react';

import { useIntl } from 'react-intl';

import ActionButton from 'components/action-button';

import { ContentContainer, DialogContainer, ModalBody, ModalHeading } from './styled';
import { IErrorRemovingLastFavoriteStoreModalProps } from './types';

export const ErrorRemovingLastFavoriteStoreModal = ({
  isOpen,
  onDismiss,
}: IErrorRemovingLastFavoriteStoreModalProps) => {
  const { formatMessage } = useIntl();

  return (
    <DialogContainer
      showDialog={isOpen}
      testID="error-remove-last-favorite-store-dialog"
      headingComponent={
        <ModalHeading testID="error-remove-last-favorite-store-heading">
          {formatMessage({ id: 'cannotRemoveStoreFromFavoritesHeader' })}
        </ModalHeading>
      }
      bodyComponent={
        <ContentContainer testID="error-remove-last-favorite-store-body">
          <ModalBody>{formatMessage({ id: 'cannotRemoveStoreFromFavorites' })}</ModalBody>
        </ContentContainer>
      }
      actions={
        <ActionButton
          fullWidth
          testID="error-remove-last-favorite-store-button"
          onPress={onDismiss}
        >
          {formatMessage({ id: 'okayButtonText' })}
        </ActionButton>
      }
      modalAppearanceEventMessage="Cannot Remove Last Favorite Store Modal"
      onDismiss={onDismiss}
    />
  );
};

export default ErrorRemovingLastFavoriteStoreModal;
