import { StoreLocatorViews } from '../types';

export interface IToggleViewButtonProps {
  currentView: StoreLocatorViews;
  updateStoreLocatorView: (newValue: StoreLocatorViews) => void;
}

// the values seem swapped because if we are on the
// list view we want to show the map icon & message
export const VIEW_ICON_MAP = {
  [StoreLocatorViews.LIST_VIEW]: 'map',
  [StoreLocatorViews.MAP_VIEW]: 'list',
};

// the values seem swapped because if we are on the
// list view we want to show the map icon & message
export const VIEW_MESSAGE_ID_MAP = {
  [StoreLocatorViews.LIST_VIEW]: 'map',
  [StoreLocatorViews.MAP_VIEW]: 'list',
};
