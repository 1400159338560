import React, { useCallback } from 'react';

import { useSelectRestaurant } from 'hooks/store-card';
import { actions, useAppDispatch } from 'state/global-state';
import { CustomEventNames, EventTypes, useMParticleContext } from 'state/mParticle';
import { useMenuContext } from 'state/menu';
import { useStoreContext } from 'state/store';
import { EventName, emitEvent } from 'utils/event-hub';
import { AttributeName } from 'utils/performance';
import { useIsMobileOrderingAvailable } from 'utils/restaurant';

import { LocatorTileView } from './locator-tile.view';
import type { ILocatorTileProps } from './types';

export const LocatorTile__Experiment = ({ restaurant }: ILocatorTileProps) => {
  const { store: currentStore } = useStoreContext();
  const { selectedStaticMenuItemId } = useMenuContext();
  const { logRBIEvent } = useMParticleContext();

  const { selectRestaurant } = useSelectRestaurant({
    restaurant,
  });
  const isMobileOrderingAvailable = useIsMobileOrderingAvailable(restaurant);
  const dispatch = useAppDispatch();

  const onPressSelectRestaurant = useCallback(() => {
    // Set shouldRefetchOffers to true on restaurant selection
    dispatch(actions.loyalty.setShouldRefetchOffers(true));

    emitEvent(EventName.RESTAURANT_SELECTED_ON_ORDER, {
      selectedStaticMenuItemId,
      isStoreChange: currentStore?._id !== restaurant?._id,
      attributes: [
        {
          name: AttributeName.RESTAURANT_ID,
          value: restaurant.id || '',
        },
      ],
    });

    logRBIEvent({
      name: CustomEventNames.BUTTON_CLICK_STORE_SELECTED,
      type: EventTypes.Other,
      attributes: {
        restaurantId: restaurant._id || '',
        restaurantAddress: restaurant.physicalAddress?.address1 || '',
        restaurantCity: restaurant.physicalAddress?.city || '',
        restaurantZip: restaurant.physicalAddress?.postalCode || '',
        restaurantState: restaurant.physicalAddress?.stateProvince || '',
        restaurantCountry: restaurant.physicalAddress?.country || '',
        storeId: restaurant.storeId || '',
      },
    });

    selectRestaurant();
  }, [
    restaurant._id,
    restaurant.id,
    restaurant.physicalAddress?.address1,
    restaurant.physicalAddress?.city,
    restaurant.physicalAddress?.postalCode,
    restaurant.physicalAddress?.stateProvince,
    restaurant.physicalAddress?.country,
    restaurant.storeId,
    dispatch,
    selectedStaticMenuItemId,
    currentStore?._id,
    logRBIEvent,
    selectRestaurant,
  ]);

  const isSelected = currentStore._id === restaurant._id;

  return (
    <LocatorTileView
      restaurant={restaurant}
      isSelected={isSelected}
      isMobileOrderingAvailable={isMobileOrderingAvailable}
      onPressButton={onPressSelectRestaurant}
    />
  );
};
