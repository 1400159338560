import { Box, HStack, Header, Stack, Text, VStack } from '@rbilabs/universal-components';

import Link from 'components/link';
import { theme } from 'styles/configure-theme';

export const HeroGrid = Stack.withConfig<{ $reversed?: boolean }>(p => ({
  direction: { base: 'column', md: 'row-reverse' },
  justifyContent: 'space-between',
  padding: { base: '$4', md: '$8' },
  marginBottom: '$4',
  bgColor: p.$reversed ? theme.token('text-hero-light') : theme.token('text-hero-reversed'),
}));

export const ContentWrapper = VStack.withConfig(() => ({
  justifyContent: 'center',
  flex: 1,
}));

export const ImageWrapper = Box.withConfig(() => ({
  overflow: 'hidden',
  width: { base: '100%', md: '50%' },
  // if changing this value, please also change the value in
  // src/components/features/components/hero/hero-container.tsx
  height: '240px',
  marginY: '0',
  alignItems: 'center',
  justifyContent: 'center',
}));

export const HeroHeader = Header.withConfig<{ $reversed?: boolean }>(p => ({
  variant: 'hero',
  marginTop: '$3',
  color: p.$reversed ? theme.token('text-hero-reversed') : theme.token('text-hero-light'),
}));

export const HeroSubheader = Header.withConfig<{ $reversed?: boolean }>(p => ({
  variant: 'headerThree',
  color: p.$reversed ? theme.token('text-hero-reversed') : theme.token('text-hero-light'),
}));

export const ButtonsWrapper = Stack.withConfig(() => ({
  direction: { base: 'column', md: 'row' },
  justifyContent: 'space-between',
  marginTop: '$2',
  width: 'full',
  space: 2,
}));

export const TermsWrapper = HStack.withConfig(() => ({
  alignSelf: 'flex-end',
}));

export const Terms = Text.withConfig<{ $reversed?: boolean }>(p => ({
  fontSize: 'xs',
  color: p.$reversed ? theme.token('text-hero-reversed') : theme.token('text-hero-light'),
}));

export const TermsLink = Link.withConfig(() => ({
  isUnderlined: true,
  isInline: true,
}));
