import React from 'react';

import { Box } from '@rbilabs/universal-components';

import { Body2, StoreCardViewContainer, Subtitle2 } from '../store-card.styled';

interface StoreCardDetailsProps {
  body?: string;
  caption: string | JSX.Element | null;
  errorMessage?: JSX.Element | null;
  title?: string | null;
  isSelected?: boolean;
  availability?: string;
}

export const StoreCardDetails: React.VFC<StoreCardDetailsProps> = ({
  body,
  caption,
  errorMessage,
  title,
  isSelected,
  availability,
}) => {
  const accessibilityLabel = `${title}, ${availability}, ${body}`;

  return (
    <StoreCardViewContainer
      accessibilityState={{ expanded: !!isSelected }}
      accessibilityRole="togglebutton"
      accessibilityLabel={accessibilityLabel}
      accessible
    >
      <Box>
        {title ? <Subtitle2>{title}</Subtitle2> : null}
        <Box flexDirection="row" alignItems="center">
          {caption}
        </Box>
        {body ? <Body2>{body}</Body2> : null}
        {errorMessage ? errorMessage : null}
      </Box>
    </StoreCardViewContainer>
  );
};
