import { StoreLocatorNavigator } from 'navigation/store-locator-navigator';
import { routes } from 'utils/routing';

import type { RawTab } from '../../types';

export const id: RawTab['id'] = 'store-locator';

export const screen: RawTab['screen'] = {
  Component: StoreLocatorNavigator,
  options: {
    getHeaderShown: () => true,
  },
};

export const link: RawTab['link'] = {
  en: routes.storeLocator,
  fr: routes.storeLocator,
};
