import React from 'react';

import { Svg, SvgPath, SvgProps } from '@rbilabs/universal-components';

export const PoppyLoveSvg = (props: SvgProps) => (
  <Svg viewBox="0 0 84 101" fill="none" {...props}>
    <SvgPath
      d="M54.0042 9.54304C54.1549 9.6184 54.2302 9.6184 54.3809 9.69376C54.5317 9.76912 54.6824 9.91983 54.8331 10.0705C55.1345 10.2966 55.5113 10.4473 55.8881 10.5981C56.1142 10.6734 56.491 10.8995 56.6417 10.6734C57.0185 10.1459 57.6213 9.84447 58.0735 9.31697C58.3749 8.94018 58.6764 8.41267 58.9024 7.96052C59.0531 7.65909 59.2039 7.35766 59.2792 7.05623C59.3546 6.90551 59.3546 6.7548 59.3546 6.60408C59.3546 6.37801 59.3546 6.15193 59.2792 6.00122C59.2039 5.62443 59.1285 5.17228 58.9024 4.87085C58.601 4.34334 57.9981 4.04191 57.3953 3.96655C56.9431 3.96655 56.5663 4.04191 56.1895 4.34334C55.9635 4.56942 55.5113 5.02156 55.5113 5.39835C55.2852 5.02156 54.9838 4.64477 54.5317 4.49406C54.0042 4.19263 53.3259 4.26798 52.8738 4.56942C52.7231 4.72013 52.5724 4.79549 52.497 4.94621C52.1956 5.323 51.8941 5.77514 51.8188 6.22729C51.7434 6.7548 51.9695 7.20694 52.2709 7.58373C52.7984 8.1866 53.3259 9.24161 54.0042 9.54304Z"
      fill="#FF7D00"
    />
    <SvgPath
      d="M63.65 17.9078C65.9861 17.6817 68.7743 15.9485 70.7336 15.0442C73.4465 13.8384 76.4608 12.7081 78.9476 10.9748C79.6258 10.5227 83.1677 8.48803 82.5648 7.20695C81.8112 5.77515 78.4201 7.96053 77.7419 8.33732C72.6929 11.1256 67.7193 13.537 63.4239 17.3803C63.1978 17.6063 63.3485 17.9831 63.65 17.9078Z"
      fill="#FF7D00"
    />
    <SvgPath
      d="M63.2732 13.0095C65.6846 10.598 67.87 8.03583 69.6786 5.17223C70.2061 4.26793 71.7133 2.23327 71.5626 1.17825C71.4118 -0.178189 70.4322 1.1029 69.9047 1.70576C67.5686 4.41865 65.3078 7.28225 63.1978 10.2212C62.6703 10.8994 60.8617 13.0848 61.3139 14.1398C61.6907 15.1195 62.6703 13.537 63.2732 13.0095Z"
      fill="#FF7D00"
    />
    <SvgPath
      d="M81.8112 24.3885C78.1941 24.0871 74.5015 23.9364 70.8843 23.9364C69.98 23.9364 67.1918 23.7103 66.5889 24.6899C66.0614 25.5942 67.87 25.5189 68.699 25.5942C72.0901 26.1971 75.4058 26.4232 78.7969 26.1971C79.8519 26.1217 82.3388 26.1217 83.1677 25.3682C84.2981 24.4639 82.6402 24.4639 81.8112 24.3885Z"
      fill="#FF7D00"
    />
    <SvgPath
      d="M73.2958 18.36C72.6176 18.586 69.9047 19.7164 71.3365 20.6207C72.09 21.0729 74.7276 20.6207 75.5565 20.47C76.2347 20.3193 78.9476 18.8875 77.3651 18.2093C76.0087 17.531 74.7276 17.8325 73.2958 18.36Z"
      fill="#FF7D00"
    />
    <SvgPath
      d="M68.3975 29.0607C67.7193 28.6085 65.2325 27.1767 65.6846 29.0607C65.76 29.5128 67.2671 31.1707 67.6439 31.4721C68.0207 31.7735 70.2815 32.8285 70.2061 31.4721C70.1307 30.3417 69.3772 29.7389 68.3975 29.0607Z"
      fill="#FF7D00"
    />
    <SvgPath
      d="M0.5 44.8106L7.80972 53.5521C7.80972 53.5521 11.7283 47.8249 16.4759 51.6681C21.2988 55.5114 17.9077 63.3486 17.4555 67.8701C16.928 73.6727 18.2845 78.2695 20.6206 82.0374C23.5595 86.7849 30.4171 90.7036 37.8022 92.06C38.3297 92.1354 38.9326 92.2107 39.4601 92.2861L40.2136 100.425H45.7148L46.4683 92.5121C59.2038 91.4571 77.2144 79.0231 69.1511 58.0736C65.4585 48.4277 57.6213 34.0344 57.6213 34.0344H57.6967C60.7864 33.2808 61.3892 29.2115 57.772 27.1014L60.3342 24.69L56.5663 23.1075L57.9981 17.9831L49.8595 21.2235C49.8595 21.2235 47.8248 16.9281 44.6598 16.9281C42.0222 16.9281 40.8165 18.8121 40.8165 18.8121C40.8165 18.8121 40.1383 16.8528 38.3297 16.3253C36.5211 15.7977 33.5821 16.8528 33.2054 20.0932C32.7532 23.7857 35.7675 25.6696 36.7472 25.9711C37.8775 26.3479 39.5354 27.1014 42.8512 25.6696C42.8512 29.0608 42.8512 36.8226 42.8512 40.7412C42.8512 45.7902 43.3033 54.0796 41.1179 58.5257C38.9326 62.9718 36.4457 64.8558 33.13 64.479C29.4375 64.0268 28.2317 60.6357 29.4375 54.2303C30.7185 47.8249 31.7736 41.4948 26.4231 36.7473C21.2234 31.9244 11.653 31.3968 0.5 44.8106ZM50.6884 24.3132C51.5173 23.8611 52.5723 24.3132 53.0245 25.2175C53.4766 26.1218 53.2506 27.2522 52.4216 27.7043C51.5927 28.1565 50.5377 27.7043 50.0855 26.8C49.558 25.8204 49.8595 24.69 50.6884 24.3132Z"
      fill="#FF7D00"
    />
  </Svg>
);
