import React, { FC } from 'react';

import { Box, Header } from '@rbilabs/universal-components';
import { useIntl } from 'react-intl';

import ActionButton from 'components/action-button';
import Modal from 'components/modal';
import Picture from 'components/picture';
import { useIsMobileBp } from 'hooks/breakpoints';
import { useFeatureLayout } from 'hooks/use-feature-layout';

import {
  ContentContainer,
  GeolocationImageContainer,
  HeaderPrefix,
  ModalHeader,
  ModalInner,
  ModalScroller,
} from './styled';
import { IGeolocationModalViewProps } from './types';

export const GeolocationModalView: FC<React.PropsWithChildren<IGeolocationModalViewProps>> = ({
  handleDismissModal,
  handleGeolocationPrompt,
}) => {
  const isMobile = useIsMobileBp();
  const { formatMessage } = useIntl();
  const { featureLayout } = useFeatureLayout();
  const imageId = featureLayout?.geolocationModalImage?.locale;
  const modalHeading = formatMessage({ id: 'allowLocationServicesToFind' });
  const allowLocationServices = formatMessage({ id: 'allowLocationServices' });

  return (
    <Modal
      onDismiss={handleDismissModal}
      mParticleEventData={{
        modalAppearanceEventMessage: 'Allow location services',
      }}
      testID="geolocation-modal"
    >
      <ContentContainer>
        <ModalScroller>
          <ModalInner alignItems="center">
            <ModalHeader>
              <HeaderPrefix testID="modal-heading">{modalHeading}</HeaderPrefix>
              <Header>
                {'\n'}
                {formatMessage({ id: 'ourRestaurantsNearYou' })}
              </Header>
            </ModalHeader>
            <GeolocationImageContainer>
              {!!imageId && (
                <Picture image={imageId} alt={formatMessage({ id: 'geolocationImageAlt' })} />
              )}
            </GeolocationImageContainer>
            <Box width="full" alignSelf="center" margin="0" marginLeft="2.5">
              <ActionButton
                // @ts-expect-error TS(2322) FIXME: Type '{ children: string; onlyIcon: boolean; testI... Remove this comment to see the full error message
                onlyIcon={isMobile}
                testID="geolocation-allow"
                onPress={handleGeolocationPrompt}
              >
                {allowLocationServices}
              </ActionButton>
            </Box>
          </ModalInner>
        </ModalScroller>
      </ContentContainer>
    </Modal>
  );
};
