import React, { useCallback, useEffect, useState } from 'react';

import { useIntl } from 'react-intl';

import { GeolocationDeniedModal } from 'components/geolocation-denied-modal';
import {
  InitialAnimation,
  LoadingAnimation,
  TransitionAnimation,
} from 'components/lottie-animations/reset-location';
import { useGeolocation } from 'state/geolocation';

import { Button } from './reset-location-button.styled';
import { IResetLocationButtonProps } from './types';

export const ResetLocationButton: React.FC<React.PropsWithChildren<IResetLocationButtonProps>> = ({
  onButtonPress,
  loading,
}) => {
  const [status, setStatus] = useState<'idle' | 'transitioning'>('idle');
  const { formatMessage } = useIntl();

  const {
    isPermissionGranted,
    isPermissionPrompt,
    isPermissionDenied,
    setPermissionChecking,
    loadingUserCoordinates,
  } = useGeolocation();

  const [geolocationDeniedModalOpen, setGeolocationDeniedModalOpen] = useState(false);

  const dismissModal = () => setGeolocationDeniedModalOpen(false);

  const handlePress = useCallback(() => {
    setStatus('transitioning');
    if (isPermissionDenied) {
      setGeolocationDeniedModalOpen(true);
    } else if (isPermissionPrompt) {
      setPermissionChecking();
    }
  }, [isPermissionDenied, isPermissionPrompt, setPermissionChecking]);

  const getStartIcon = () => {
    if (loading || loadingUserCoordinates) {
      return <LoadingAnimation />;
    }

    if (status === 'transitioning') {
      return (
        <TransitionAnimation
          onComplete={() => {
            onButtonPress();
            setStatus('idle');
          }}
        />
      );
    }

    return <InitialAnimation />;
  };

  useEffect(() => {
    if (isPermissionDenied) {
      setGeolocationDeniedModalOpen(true);
    }
  }, [isPermissionDenied]);

  useEffect(() => {
    if (isPermissionGranted) {
      setGeolocationDeniedModalOpen(false);
    }
  }, [isPermissionGranted]);

  return (
    <>
      <GeolocationDeniedModal isOpen={geolocationDeniedModalOpen} dismissModal={dismissModal} />
      <Button
        variant="unstyled"
        accessibilityLabel={formatMessage({ id: 'resetLocationButtonAria' })}
        testID="reset-location-button"
        onPress={handlePress}
        isDisabled={loading}
        icon={getStartIcon()}
      />
    </>
  );
};

export default ResetLocationButton;
