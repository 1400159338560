import { Box, Icon, IconButton } from '@rbilabs/universal-components';

import Link from 'components/link';
import { hiddenAccessibilityPlatformProps } from 'utils/accessibility';

export const LocatorTileActionButton = IconButton.withConfig({
  variant: 'ghost',
  padding: 0,
});

export const LocatorTileCTAIcon = Icon.withConfig({
  size: 6,
  marginX: '$2',
  ...hiddenAccessibilityPlatformProps,
});

export const LocatorTileLink = Link.withConfig({
  _text: {
    fontSize: 'xs',
  },
});

export const LocatorTileFavoriteLoadingWrapper = Box.withConfig({
  width: '$10',
  height: '$10',
  justifyContent: 'center',
  alignItems: 'center',
});
