import * as React from 'react';
import { FC } from 'react';

import { Spacer } from '@rbilabs/universal-components';

import { useIsTopServiceModeEnabled } from 'experiments/service-mode/use-is-top-servie-mode-enabled';
import { useFocusEffect } from 'hooks/navigation/use-focus-effect';
import { LaunchDarklyFlag, useFlag } from 'state/launchdarkly';
import { EventName, emitEvent } from 'utils/event-hub';

import { Features } from '../features';

import { HomePageSkeleton } from './home-page-skeleton';
import { IHomePageViewProps } from './types';

export const HomePageView: FC<React.PropsWithChildren<IHomePageViewProps>> = ({
  isLoading,
  featureHomePage,
}) => {
  const hasBottomServiceMode = !useIsTopServiceModeEnabled();
  const showLoyaltyBannerV2inHome = useFlag(LaunchDarklyFlag.SHOW_LOYALTY_BANNER_V2_IN_HOME);
  const homePageNumberOfItemsToRenderOnInitialLoad =
    useFlag(LaunchDarklyFlag.HOME_PAGE_NUMBER_OF_ITEMS_TO_RENDER_ON_INITIAL_LOAD) || 3;

  useFocusEffect(
    React.useCallback(() => {
      emitEvent(EventName.SCREEN_RENDER, {
        screenId: 'home',
      });
    }, [])
  );

  if (isLoading || !featureHomePage?.components) {
    return <HomePageSkeleton />;
  }

  const { components } = featureHomePage;

  const firstType = components?.[0]?.__typename;
  const firstItemIsMainHero = firstType === 'MainHero';
  const loyaltyBannerAndHero = firstType === 'Hero' && showLoyaltyBannerV2inHome;
  const shouldShowSpacer = !(loyaltyBannerAndHero || firstItemIsMainHero);
  const spacerSize = hasBottomServiceMode || !showLoyaltyBannerV2inHome;

  return (
    <Features
      headerComponent={shouldShowSpacer ? <Spacer $isSmall={spacerSize} /> : null}
      numberOfItemsToRender={homePageNumberOfItemsToRenderOnInitialLoad}
      components={components}
    />
  );
};
