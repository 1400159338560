import React, { FC, useCallback, useEffect } from 'react';

import { Box } from '@rbilabs/universal-components';

import { ILocation } from '@rbi-ctg/frontend';
import { useIsTabletBp } from 'hooks/breakpoints';
import { useNavigation } from 'hooks/navigation/use-navigation';
import { useRoute } from 'hooks/navigation/use-route';
import { useReactNavigationFlag } from 'hooks/use-react-navigation-flag';
import { useGeolocation } from 'state/geolocation';
import { actions, useAppDispatch } from 'state/global-state';
import { LaunchDarklyFlag, useFlag } from 'state/launchdarkly';
import { useLocationContext } from 'state/location';
import { useServiceModeContext } from 'state/service-mode';
import { isValidPosition } from 'utils/geolocation';
import { ServiceModeCategory } from 'utils/service-mode';
import { STORE_LOCATOR_CONTAINER } from 'utils/test-ids';

import useSearchRestaurants from '../hooks/use-search-restaurants';
import useStoreLocatorView from '../hooks/use-store-locator-view';

import { useAutoPromptLocationPermission } from './hooks/use-auto-prompt-location-permission';
import { useServiceModeCategory } from './hooks/use-service-mode-category';
import { useStoresReducer } from './hooks/use-stores-reducer';
import { ItemsUnavailableDialog } from './items-unavailable-dialog';
import StoreLocatorRoutes from './store-locator-routes';
import { StoreLocatorView } from './store-locator.view';

/**
 *
 * StoreLocator purpose
 *
 */
const StoreLocator: FC<React.PropsWithChildren<unknown>> = props => {
  const isTablet = useIsTabletBp();

  const orderingEnabled = useFlag(LaunchDarklyFlag.ENABLE_ORDERING);
  const enableReactNativeNavigation = useReactNavigationFlag();
  const dispatch = useAppDispatch();
  const { setStoreLocatorCallbackUrl } = useLocationContext();

  // Automatically open native dialog asking for location permission if not already answered
  useAutoPromptLocationPermission();

  // Reset store locator callback url on unmount
  useEffect(() => {
    return () => {
      setStoreLocatorCallbackUrl('');
    };
  }, [setStoreLocatorCallbackUrl]);

  const {
    errorFavs,
    errorNearby,
    errorRecent,
    searchingNearby,
    searchingRecent,
    searchingFavs,
    storesNearby,
    storesFavs,
    storesRecent,
    rawIsSearching,
    storeLocatorDispatch,
  } = useStoresReducer();
  const { serviceMode } = useServiceModeContext();

  const {
    activeCoordinates,
    manualAddress,
    isPermissionGranted,
    resetCoordsAndLoadCurrentPosition,
    setCoordinatesManual,
    setManualAddress,
    updateManualCoordinates,
  } = useGeolocation();

  const {
    ErrorDialog,
    Dialog,
    searchFavRestaurants,
    searchNearbyRestaurants,
    searchRecentRestaurants,
  } = useSearchRestaurants({
    storeLocatorDispatch,
  });

  const { setParams } = useNavigation();
  const { params } = useRoute();

  const { currentView, updateStoreLocatorView } = useStoreLocatorView();

  const {
    serviceModeCategory,
    setServiceModeCategory,
    serviceModeCategoryOptions,
  } = useServiceModeCategory();

  const onServiceModeCategoryChange = useCallback(
    (value: ServiceModeCategory) => {
      if (serviceModeCategory === value) {
        return;
      }

      // TODO: this dispatch looks like a bug - we should call this when a new store is selected
      // Switching tabs on the UI should not trigger this refetch
      // Set shouldRefetchOffers to true when changing service mode
      dispatch(actions.loyalty.setShouldRefetchOffers(true));

      // Keep the serviceModeCategory in the params so it is consistent with the store-locator tabs
      switch (value) {
        case ServiceModeCategory.PICKUP:
        case ServiceModeCategory.DELIVERY:
        case ServiceModeCategory.CATERING:
          setServiceModeCategory(value);
          setParams({ ...params, serviceModeCategory: value });
          break;
        default:
          break;
      }
    },
    [serviceModeCategory, dispatch, params, setServiceModeCategory, setParams]
  );

  const onPressSearch = useCallback(
    (location: ILocation) => {
      setCoordinatesManual({ newCoords: location });
      setManualAddress('');
    },
    [setCoordinatesManual, setManualAddress]
  );

  useEffect(() => {
    if (isValidPosition(activeCoordinates)) {
      searchNearbyRestaurants({
        location: activeCoordinates,
      });
    }
    // Triggers a search when the service mode change to keep the list in sync
  }, [activeCoordinates, searchNearbyRestaurants, serviceMode]);

  useEffect(() => {
    searchFavRestaurants(activeCoordinates);
  }, [activeCoordinates, searchFavRestaurants]);

  useEffect(() => {
    searchRecentRestaurants(activeCoordinates);
  }, [activeCoordinates, searchRecentRestaurants]);

  const onSearchBarValueChange = useCallback(
    (address: string | undefined | null) => {
      setManualAddress(address || '');
    },
    [setManualAddress]
  );

  return (
    <Box height="full" testID={STORE_LOCATOR_CONTAINER}>
      <StoreLocatorView
        activeCoordinates={activeCoordinates}
        errorFavs={errorFavs}
        errorNearby={errorNearby}
        errorRecent={errorRecent}
        handleResetLocation={resetCoordsAndLoadCurrentPosition}
        searchingNearby={searchingNearby}
        searchingRecent={searchingRecent}
        searchingFavs={searchingFavs}
        searchNearbyRestaurants={searchNearbyRestaurants}
        storesFavs={storesFavs}
        storesNearby={storesNearby}
        storesRecent={storesRecent}
        rawIsSearching={rawIsSearching}
        // Map View Props
        onPressSearch={onPressSearch}
        // View Props
        currentView={currentView}
        onViewChange={updateStoreLocatorView}
        // Search Bar Props
        onAddressSelected={updateManualCoordinates}
        searchBarValue={manualAddress}
        onSearchBarValueChange={onSearchBarValueChange}
        shouldFocusSearchBar={!manualAddress && isPermissionGranted && isTablet}
        // Service Mode Category Props
        hideServiceModeCategories={!orderingEnabled}
        selectedServiceModeCategory={serviceModeCategory}
        serviceModeCategoryOptions={serviceModeCategoryOptions}
        // @ts-expect-error TS(2322) FIXME: Type '(value: ServiceModeCategory) => void' is not... Remove this comment to see the full error message
        onServiceModeCategoryChange={onServiceModeCategoryChange}
        {...props}
      />
      {!enableReactNativeNavigation && <StoreLocatorRoutes />}

      <ErrorDialog />
      <Dialog />
      <ItemsUnavailableDialog />
    </Box>
  );
};

export default StoreLocator;
