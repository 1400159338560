import * as React from 'react';
import { FC } from 'react';

import { GeolocationModal } from 'components/geolocation-modal';
import { NoStoresDeliveryModal } from 'components/modal-no-delivery-stores';
import PickupModeModal from 'components/pickup-mode-modal';
import StoreInfoModalContainer from 'components/store-info-modal';
import { LaunchDarklyFlag, useFlag } from 'state/launchdarkly';
import { Route, Routes } from 'state/location';
import { routes } from 'utils/routing';

/**
 *
 * StoreLocatorRoutes contains all internal route logic for the store locator
 *  handles rendering service mode-specific modals, error messages, and store info
 *
 */
const StoreLocatorRoutes: FC<React.PropsWithChildren> = () => {
  const isDeliveryOnlyEnabled = useFlag(LaunchDarklyFlag.ENABLE_DELIVERY_ONLY);
  return (
    <Routes>
      {/* Used in global store card */}
      <Route path={routes.pickupMode} element={<PickupModeModal />} />

      {isDeliveryOnlyEnabled && (
        <Route path={routes.noDeliveryStores} element={<NoStoresDeliveryModal />} />
      )}

      <Route path={routes.locationService} element={<GeolocationModal />} />

      {/* Store info requests data by id and/or storeNumber supplied depending on route. */}
      <Route path={`${routes.store}/:id`} element={<StoreInfoModalContainer />} />
      <Route path={`${routes.store}/:storeNumber/*`} element={<StoreInfoModalContainer />} />
    </Routes>
  );
};

export default StoreLocatorRoutes;
