import React, { FC, useCallback, useLayoutEffect, useRef, useState } from 'react';

import { LayoutChangeEvent, ScrollView, useWindowDimensions } from 'react-native';

import { useLocalStorageState } from 'hooks/use-local-storage-state';
import { useTabbedMenuHeader } from 'hooks/use-tabbed-menu-header';
import { CustomEventNames, EventTypes, useMParticleContext } from 'state/mParticle';
import { ClickEventComponentNames } from 'state/mParticle/constants';
import { StorageKeys } from 'utils/local-storage';

import { TabLink, TabLinkText, Wrapper } from './tabbed-header.styled';
import { ITabbedHeaderProps } from './types';

const TabbedHeader: FC<React.PropsWithChildren<ITabbedHeaderProps>> = ({
  menuData,
  menuDataLoading,
  dayPartMenu,
  activeDayParts,
}) => {
  const [activeTab, setActiveTab] = useState(0);
  const [coordinates, setCoordinates] = useState<{ [key: number]: number } | null>(null);
  const { logRBIEvent } = useMParticleContext();

  const scrollViewRef = useRef<ScrollView | null>(null);

  const dimensions = useWindowDimensions();

  const {
    activeTab: newActiveTab = 0,
    isRouteOnItemPickerAndOutsideTabbedItems,
    isTabbedMenuLoading,
    tabbedHeaderItems,
  } = useTabbedMenuHeader({
    menuData,
    menuDataLoading,
    dayPartMenu,
    activeDayParts,
  });

  const logTabSelected = (entry: string, tabItemId: string): void => {
    logRBIEvent({
      name: CustomEventNames.CLICK_EVENT,
      type: EventTypes.Other,
      attributes: {
        component: ClickEventComponentNames.PRODUCT_SECTION_TABS,
        text: entry,
        componentId: tabItemId,
      },
    });
  };

  const [prevActiveTab, setPrevActiveTab] = useLocalStorageState<number | null>({
    key: StorageKeys.TABBED_MENU_PREVIOUS_ACTIVE_TAB,
    defaultReturnValue: null,
  });

  const scrollTo = useCallback(
    (index: number) => {
      if (coordinates && coordinates[index]) {
        scrollViewRef.current?.scrollTo({ x: coordinates[index], animated: true });
      }
    },
    [coordinates]
  );

  useLayoutEffect(() => {
    if (prevActiveTab && newActiveTab < 0) {
      setActiveTab(prevActiveTab);
    } else {
      setActiveTab(newActiveTab);
    }

    if (newActiveTab >= 0) {
      setPrevActiveTab(newActiveTab);
    }
  }, [prevActiveTab, newActiveTab, setPrevActiveTab]);

  useLayoutEffect(() => {
    scrollTo(activeTab);
  }, [activeTab, scrollTo]);

  if (isRouteOnItemPickerAndOutsideTabbedItems) {
    return null;
  }

  if (menuDataLoading || isTabbedMenuLoading) {
    return null;
  }

  const onItemLayout = (event: LayoutChangeEvent, index: number) => {
    const { x } = event.nativeEvent.layout;
    setCoordinates({ ...coordinates, [index]: x });
  };

  return (
    <Wrapper testID="tabbed-menu-header">
      <ScrollView ref={scrollViewRef} horizontal showsHorizontalScrollIndicator={false}>
        {tabbedHeaderItems.map(({ label, to, tabItemId }, index) => (
          <TabLink
            key={label}
            linkPath={to}
            testID={`tabbed-menu-${label}`}
            active={index === activeTab}
            onPress={() => logTabSelected(label, tabItemId)}
            onLayout={event => onItemLayout(event, index)}
            style={{
              minWidth: dimensions.width / tabbedHeaderItems.length,
            }}
          >
            <TabLinkText active={index === activeTab}>{label}</TabLinkText>
          </TabLink>
        ))}
      </ScrollView>
    </Wrapper>
  );
};

export default TabbedHeader;
