import React from 'react';

import LottieAnimation from 'lottie-react-native';

import { LoyaltyRewardsLogo } from '../../loyalty-lottie-animations';

import { LoyaltyLogoContainer } from './loyalty-logo-widget.styled';

const LoyaltyLogoWidget = () => (
  <LoyaltyLogoContainer testID="loyalty-logo-widget">
    <LottieAnimation source={LoyaltyRewardsLogo} autoPlay loop={false} />
  </LoyaltyLogoContainer>
);

export default LoyaltyLogoWidget;
