import { parse } from 'date-fns';
import delv from 'dlv';
import { IntlShape } from 'react-intl';

import {
  ICartEntry,
  IItem,
  IItemOption,
  IMainMenuPickerView,
  IMainMenuSectionView,
  IOffer,
  IPicker,
  IPickerOption,
  IPrices,
  ISanityCombo,
  ISanityComboSlot,
  ISanityItem,
  ISanityPickerOption,
  IVendorConfig,
  IWithMenuObjectSettings,
  IWithPricingProps,
  MenuObject,
  SanityMenuObject,
  ServiceMode,
} from '@rbi-ctg/menu';
import { IStore } from '@rbi-ctg/store';
import { ItemAvailabilityStatus, MenuObjectTypes } from 'enums/menu';
import { getPosVendorFromStore } from 'hooks/menu/use-pos-vendor';
import { IDayPartBoundary, IValidDayPart } from 'state/day-part/hooks/use-active-day-parts';
import { LoyaltyOffer } from 'state/loyalty/types';
import { CartEntryType, getMenuObjectLimitPerOrder } from 'utils/cart';
import { findBurgersForBreakfastDayPart } from 'utils/daypart';

import { availabilityLogger } from '..';
import {
  IWithVendorConfig,
  PluTypes,
  PosVendors,
  computeCompositeComboSlotPlu,
  concatenateSizePlu,
  getQuantityBasedPlu,
  getVendorConfig,
} from '../vendor-config';

import { itemOptionModifierPluIsAvailable } from './item-option-modifier-plu-is-available';
import { IItemOptionModifierIsAvailable } from './types';

interface IAvailabilityConfig {
  injectDefault?: boolean;
  isMainItem?: boolean;
  quantity?: number;
  checkIsInParent?: boolean;
}

type AvailabilityFn<D> = (
  data: D,
  vendor: PosVendors | null,
  prices: IPrices,
  options?: IAvailabilityConfig
) => boolean;

type CompositeAvailabilityFn<D, P> = (
  data: D,
  vendor: PosVendors | null,
  prices: IPrices,
  parentData?: P,
  options?: IAvailabilityConfig
) => boolean;

const getVendorConfigForData = (
  item: IWithVendorConfig,
  vendor: PosVendors | null
): IVendorConfig | null => {
  if (!item || !item.vendorConfigs) {
    availabilityLogger(item, `Missing item or item has no vendorConfigs`);
    return null;
  }

  const vendorConfig = getVendorConfig(item, vendor);

  if (!vendorConfig) {
    availabilityLogger(item, `Missing vendorConfig for ${vendor}`);
    return null;
  }

  return vendorConfig;
};

export const pluIsAvailable = (plu: string | null, prices: IPrices): boolean =>
  !!plu && !!prices && plu in prices && !isNaN(+prices[plu]);

export const vendorConfigIsAvailable = (
  vendorConfig: IVendorConfig,
  prices: IPrices,
  options: IAvailabilityConfig = {}
): boolean => {
  switch (vendorConfig.pluType) {
    case PluTypes.CONSTANT:
      return pluIsAvailable(vendorConfig.constantPlu, prices);
    case PluTypes.IGNORE:
      return true;
    case PluTypes.MULTI_CONSTANT:
      return (vendorConfig.multiConstantPlus || []).every(({ plu }) => pluIsAvailable(plu, prices));
    case PluTypes.PARENT_CHILD:
      return (
        pluIsAvailable(vendorConfig.parentChildPlu.plu, prices) &&
        pluIsAvailable(vendorConfig.parentChildPlu.childPlu, prices)
      );
    case PluTypes.QUANTITY:
      return pluIsAvailable(
        getQuantityBasedPlu(vendorConfig.quantityBasedPlu, options.quantity),
        prices
      );
    case PluTypes.SIZE_BASED:
      return pluIsAvailable(concatenateSizePlu(vendorConfig.sizeBasedPlu), prices);
    default:
      return false;
  }
};

export const itemIsAvailable: AvailabilityFn<IWithVendorConfig> = (
  item,
  vendor,
  prices,
  options: IAvailabilityConfig = {}
) => {
  const vendorConfig = getVendorConfigForData(item, vendor);

  if (!vendorConfig) {
    return false;
  }

  // In some cases the prices are not available,
  // this should not block the reorder process.
  if (prices) {
    const available = vendorConfigIsAvailable(vendorConfig, prices, options);
    if (!available) {
      availabilityLogger(item, 'Item is currently marked unavailable.');
      return false;
    }
  }

  return true;
};

export const comboSlotOptionIsAvailable: CompositeAvailabilityFn<ISanityItem, ISanityCombo> = (
  item,
  vendor,
  prices,
  combo,
  options: IAvailabilityConfig = {}
) => {
  // if combo passed in, check that the item is available inside the combo
  // check there are prices so we don't block reorder.
  if (prices && combo) {
    // Check the composite plu is available `comboPlu-itemPlu`
    const compositePlu = computeCompositeComboSlotPlu({ parent: combo, child: item, vendor });

    if (!compositePlu) {
      return false;
    }

    return pluIsAvailable(compositePlu, prices);
  }

  // Check the item is available, in all cases (without Combo passed in) if the item is unavailable its always unavailable
  const itemVendorConfig = getVendorConfigForData(item, vendor);

  if (!itemVendorConfig) {
    return false;
  }

  // In some cases the prices are not available,
  // this should not block the reorder process.
  if (prices) {
    const itemAvailable = vendorConfigIsAvailable(itemVendorConfig, prices, options);
    if (!itemAvailable) {
      availabilityLogger(item, 'Item is currently marked unavailable.');
      return false;
    }
  }

  // If all checks pass this comboSlotOption (item) is available for this combo
  return true;
};

export const comboSlotIsAvailable: CompositeAvailabilityFn<ISanityComboSlot, ISanityCombo> = (
  comboSlot,
  vendor,
  prices,
  combo
) => {
  if (!itemIsAvailable(comboSlot, vendor, prices)) {
    return false;
  }

  const minRequirement = comboSlot.minAmount;

  const availableOptions = comboSlot.options.reduce(
    (acc, option) =>
      comboSlotOptionIsAvailable(
        option.option as ISanityItem,
        vendor,
        prices,
        option.isPremium ? combo : undefined,
        {
          quantity: comboSlot.minAmount || 1,
        }
      )
        ? acc + option.maxAmount
        : acc,
    0
  );

  if (availableOptions < minRequirement) {
    availabilityLogger(
      comboSlot,
      `${comboSlot.name?.locale || ''} does not have minimum availability requirements.`
    );
    return false;
  }

  return true;
};

export const comboIsAvailable: AvailabilityFn<ISanityCombo> = (combo, vendor, prices, options) => {
  if (!itemIsAvailable(combo, vendor, prices)) {
    return false;
  }

  if (combo.mainItem && !itemIsAvailable(combo.mainItem, vendor, prices)) {
    availabilityLogger(combo.mainItem, 'Main item missing');
    return false;
  }

  const allComboSlotsAvailable = combo?.options?.every(comboSlot => {
    return comboSlotIsAvailable(
      comboSlot,
      vendor,
      prices,
      options?.checkIsInParent ? combo : undefined
    );
  });

  if (!allComboSlotsAvailable) {
    availabilityLogger(combo, 'Not all combo slots were available.');
    return false;
  }

  return true;
};

export const itemOptionModifierIsAvailable = ({
  item,
  itemOption,
  itemOptionModifier,
  prices,
  vendor,
}: IItemOptionModifierIsAvailable & { itemOption: IItemOption }): boolean => {
  const pluIsAvailableForItemOptionModifier = itemOptionModifierPluIsAvailable({
    item,
    itemOptionModifier,
    prices,
    vendor,
  });

  // for item option modifiers we should only care about plu availability when
  // "inject default selection" is true, OR the item is not the default.
  // if "inject default selection" is false, default modifiers are assumed to
  // be available if the item they belong to is available.
  if (itemOption.injectDefaultSelection) {
    return pluIsAvailableForItemOptionModifier;
  }

  return itemOptionModifier.default || pluIsAvailableForItemOptionModifier;
};

interface IItemOptionIsAvailable extends IWithPricingProps {
  item: IItem;
  itemOption: IItemOption;
}

export const itemOptionIsAvailable = ({
  item,
  itemOption,
  vendor,
  prices,
}: IItemOptionIsAvailable): boolean => {
  if (!itemOption || !itemOption.options || itemOption.options.length === 0) {
    availabilityLogger(itemOption, 'No options found');
    return false;
  }

  const availableOptions: number = itemOption.options.filter(itemOptionModifier =>
    itemOptionModifierIsAvailable({
      item,
      itemOption,
      itemOptionModifier,
      prices,
      vendor,
    })
  ).length;

  return availableOptions > 0;
};

// this "availability" is actually very different from
// availability of IItem and friends
export const cartEntryIsAvailable = (
  cartEntry: ICartEntry,
  vendor: PosVendors | null,
  prices: IPrices,
  item?: ICartEntry
): boolean => {
  const isAvailable = (entry: ICartEntry) => {
    switch (entry.type) {
      case CartEntryType.combo:
        // we don't use comboIsAvailable here
        return itemIsAvailable(entry, vendor, prices);
      case CartEntryType.item:
        return itemIsAvailable(entry, vendor, prices);
      case CartEntryType.itemOptionModifier:
        // we only really need to know if the plu is available
        if (!item || !vendor) {
          return false;
        }
        return itemOptionModifierPluIsAvailable({
          item,
          itemOptionModifier: entry,
          vendor,
          prices,
        });
      case CartEntryType.comboSlot:
      case CartEntryType.itemOption:
        return true;
      case CartEntryType.offerCombo:
      case CartEntryType.offerItem:
        return false;
      default:
        return false; // this shouldn't happen
    }
  };

  // hacking in a way to remember what the associated IItem is at every step
  const i = cartEntry.type === CartEntryType.item ? cartEntry : item;

  return (
    isAvailable(cartEntry) &&
    (cartEntry.children || []).every(entry => cartEntryIsAvailable(entry, vendor, prices, i))
  );
};

const dayPartsContainActiveDayPart = (dayparts: string[], activeDayParts: IDayPartBoundary[]) =>
  activeDayParts.findIndex(({ key }) => dayparts.includes(key.toLowerCase())) !== -1;

export type IMenuItem =
  | SanityMenuObject
  | MenuObject
  | IMainMenuPickerView
  | IMainMenuSectionView
  | IOffer
  | null;

export const getMenuItemDayParts = (menuData: IMenuItem): string[] => {
  if (!menuData) {
    return [];
  }

  // We currently can't rely on HappyHour menuObjects daypart
  if (isHappyHourMenuObject(menuData)) {
    return HAPPY_HOUR_SECTION_DAY_PARTS;
  }

  switch (menuData._type) {
    case 'item': {
      const dayParts: string[] = (
        delv(menuData, 'operationalItem.daypart') || []
      ).map((daypart: string) => daypart.toLowerCase());
      return dayParts;
    }
    case 'combo': {
      const mainItem = menuData.mainItem;
      return getMenuItemDayParts(mainItem);
    }
    case 'offer': {
      // We currently do not set day parts on items from MDM, so allow setting
      // dayparts on coupons
      // @todo Remove once we pull dayparts off items
      const dayParts: string[] = (menuData.daypart || []).map((daypart: string) =>
        daypart.toLowerCase()
      );
      return dayParts;
    }
    case 'section': {
      if (menuData.daypart) {
        const dayParts: string[] = menuData.daypart.map((daypart: string) => daypart.toLowerCase());
        return dayParts;
      }

      return [];
    }
    default:
      return [];
  }
};

/**
 * Determine whether a menu object can be ordered
 * during the given activeDayPart(s).
 */
export function isAvailableForActiveDayParts({
  activeDayParts,
  menuData,
}: {
  activeDayParts: IDayPartBoundary[];
  menuData: IMenuItem;
}): boolean {
  if (!menuData) {
    return false;
  }

  const dayParts = getMenuItemDayParts(menuData);

  const isDayPartsQualify = (dayPartsToCheck: string[]) =>
    !dayPartsToCheck.length || dayPartsContainActiveDayPart(dayPartsToCheck, activeDayParts);

  if (isHappyHourMenuObject(menuData)) {
    return isDayPartsQualify(HAPPY_HOUR_SECTION_DAY_PARTS);
  }

  if (menuData._type === 'offer') {
    const option = menuData.option;
    if (option && option._type !== MenuObjectTypes.OFFER_DISCOUNT) {
      const optionDayParts = getMenuItemDayParts(option as SanityMenuObject);

      return isDayPartsQualify(dayParts) && isDayPartsQualify(optionDayParts);
    }
  }

  if (menuData._type === 'picker' && 'options' in menuData) {
    // @ts-expect-error TS(2322) FIXME: Type 'IPickerOption[] | ISanityPickerOption[] | { ... Remove this comment to see the full error message
    const options: IPickerOption[] | ISanityPickerOption[] = menuData.options || [];

    return options.some((option: IPickerOption | ISanityPickerOption) => {
      const optionDayParts = getMenuItemDayParts(option.option);
      return isDayPartsQualify(optionDayParts);
    });
  }

  return isDayPartsQualify(dayParts);
}

// NOTE: This will only check the main items it is not recursive. Do not use this in the menu. This is used for recent-items. This should not be used to filter items in the menu.
export const getAvailabilityStatus = ({
  data,
  activeDayParts,
  isExtra,
  prices,
  vendor,
  showBurgersForBreakfast,
}: {
  data: IItem | ISanityCombo | IPicker | null;
  activeDayParts: IDayPartBoundary[];
  isExtra: boolean;
  showBurgersForBreakfast?: boolean;
} & IWithPricingProps) => {
  // check if there was actual data in sanity
  if (!data) {
    return ItemAvailabilityStatus.OUT_OF_MENU;
  }

  // check if its a cart extra - extras can only be added from the cart currently
  if (isExtra) {
    return ItemAvailabilityStatus.CART_EXTRA;
  }

  // Burgers for Breakfast
  const burgersForBreakfastDayPart = findBurgersForBreakfastDayPart(activeDayParts);
  if (
    burgersForBreakfastDayPart &&
    isAvailableForActiveDayParts({
      activeDayParts: [burgersForBreakfastDayPart],
      menuData: data,
    }) &&
    !showBurgersForBreakfast
  ) {
    return ItemAvailabilityStatus.OUT_OF_DAYPART;
  }

  // check its available for current dayparts
  if (activeDayParts.length && !isAvailableForActiveDayParts({ activeDayParts, menuData: data })) {
    return ItemAvailabilityStatus.OUT_OF_DAYPART;
  }

  // check if we have some prices or the vendor from the store
  if (!prices || !vendor) {
    return ItemAvailabilityStatus.STORE_NOT_SELECTED;
  }

  // check prices exist
  if (data._type === MenuObjectTypes.COMBO) {
    return comboIsAvailable(data, vendor, prices)
      ? ItemAvailabilityStatus.AVAILABLE
      : ItemAvailabilityStatus.UNAVAILABLE;
  }

  // check if item is picker and has options
  if (data._type === MenuObjectTypes.PICKER) {
    return data.options?.length
      ? ItemAvailabilityStatus.AVAILABLE
      : ItemAvailabilityStatus.UNAVAILABLE;
  }

  return itemIsAvailable(data, vendor, prices)
    ? ItemAvailabilityStatus.AVAILABLE
    : ItemAvailabilityStatus.UNAVAILABLE;
};

export const getNextMatchingDayPart = (
  lookupDayParts: LoyaltyOffer['daypart'],
  dayParts: readonly IValidDayPart[]
): IValidDayPart | undefined => {
  const lookupSet = new Set(lookupDayParts?.map(x => x?.toLowerCase()));
  return dayParts.find(dayPart => dayPart.key && lookupSet.has(dayPart.key.toLowerCase()));
};

export const HAPPY_HOUR_SECTION_DAY_PARTS = ['happy hour'];

export const isHappyHourMenuObject = (menuObject: IWithMenuObjectSettings) =>
  Boolean(getMenuObjectLimitPerOrder(menuObject));

export const timeToString = (time: string, formatTime: IntlShape['formatTime']) => {
  try {
    return formatTime(parse(time, 'HH:mm', new Date()));
  } catch {
    return time;
  }
};

export const getUnavailableCartEntries = (
  cartEntries: ICartEntry[],
  restaurant: IStore,
  prices: IPrices,
  serviceMode?: ServiceMode
) => {
  const vendor = getPosVendorFromStore(restaurant, serviceMode);
  const unavailableEntries = cartEntries.filter(
    (cartEntry: ICartEntry) => !cartEntryIsAvailable(cartEntry, vendor, prices)
  );

  return unavailableEntries;
};
