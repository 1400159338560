import React, { TouchEventHandler, useCallback, useMemo, useState } from 'react';

import { debounce, uniqBy } from 'lodash-es';

import { ILocation } from '@rbi-ctg/frontend';
import { useSingleSelectCtaInLocator } from 'experiments/single-select-cta-in-locator';
import { LocatorTile__Experiment } from 'experiments/single-select-cta-in-locator/locator-tile';
import { IRestaurantNode } from 'generated/rbi-graphql';
import { useIsTabletBp } from 'hooks/breakpoints';
import useMap from 'hooks/use-map';
import { IUseMapArgs } from 'hooks/use-map/types';
import { ServiceModeCategory } from 'utils/service-mode';

import useMarkers from '../../hooks/use-markers';
import { StoreLocatorMap } from '../../map';
import { StoreActions } from '../store-actions';
import { StoreCard } from '../store-card';

import { MapContainer } from './map-view.styled';

export interface IMapViewProps {
  activeCoordinates: ILocation | null;
  currentViewIsMap: boolean;
  handleResetLocation: () => void;
  onPressSearch: (location: ILocation) => void;
  storesFavs: IRestaurantNode[];
  storesRecent: IRestaurantNode[];
  storesNearby: IRestaurantNode[];
  serviceModeCategory: ServiceModeCategory;
}

const setupEvtListeners = (onDragEnd: TouchEventHandler) => ({
  dragend: debounce(onDragEnd, 200),
});

const MapViewStoreCard: React.VFC<{
  restaurant: IRestaurantNode;
  serviceModeCategory: ServiceModeCategory;
}> = ({ restaurant, serviceModeCategory }) => {
  const singleSelectCtaInLocator = useSingleSelectCtaInLocator();

  return singleSelectCtaInLocator ? (
    <LocatorTile__Experiment restaurant={restaurant} />
  ) : (
    <>
      <StoreCard restaurant={restaurant} serviceModeCategory={serviceModeCategory} />
      <StoreActions restaurant={restaurant} serviceModeCategory={serviceModeCategory} />
    </>
  );
};

export const MapView: React.FC<React.PropsWithChildren<IMapViewProps>> = ({
  activeCoordinates,
  currentViewIsMap,
  handleResetLocation,
  onPressSearch,
  storesFavs,
  storesNearby,
  storesRecent,
  serviceModeCategory,
}) => {
  const isTablet = useIsTabletBp();

  const [selectedStore, setSelectedStore] = useState<IRestaurantNode | null>(null);
  const [hasPanned, setHasPanned] = useState(false);

  const eventListeners: IUseMapArgs['eventListeners'] = useMemo(() => {
    return {
      ...setupEvtListeners(() => {
        setHasPanned(true);
      }),
      onPress: () => setSelectedStore(null),
    };
  }, []);

  const { center, createMarker, map, zoom, resetZoom, panTo } = useMap(
    activeCoordinates
      ? {
          eventListeners,
          position: activeCoordinates,
        }
      : { eventListeners }
  );

  const onPressButtonSearch = useCallback(() => {
    setHasPanned(false);

    onPressSearch(center);
  }, [center, onPressSearch]);

  /**
   * TODO:
   *  - should we still include favs & recents in map view, if we have values?
   *  - added recents here bc idk why they weren't there in current implementation
   */
  const storesWithMarkers = useMemo(
    () => uniqBy([...storesNearby, ...storesFavs, ...storesRecent], '_id'),
    [storesFavs, storesNearby, storesRecent]
  );

  const onPress = useCallback(
    (store: IRestaurantNode) => {
      if (!store._id || store._id === selectedStore?._id) {
        return;
      }
      setSelectedStore(store);

      // TODO: don't think we need this - just log the marker click
      // const { latitude: lat, longitude: lng } = store;

      // if (lat && lng) {
      //   return searchNearbyRestaurants({ location: { lat, lng } });
      // }
    },
    [selectedStore?._id]
  );

  useMarkers({
    createMarker,
    storesFavs,
    storesNearby: storesWithMarkers,
    activeStoreId: selectedStore?._id,
    onPress,
    panTo,
  });

  return (
    <MapContainer>
      <StoreLocatorMap
        map={map}
        currentViewIsMap={currentViewIsMap}
        handleResetLocation={handleResetLocation}
        hasPanned={hasPanned}
        isTablet={isTablet}
        onPressSearch={onPressButtonSearch}
        zoom={zoom}
        resetZoom={resetZoom}
      />
      {currentViewIsMap && selectedStore && (
        <MapViewStoreCard restaurant={selectedStore} serviceModeCategory={serviceModeCategory} />
      )}
    </MapContainer>
  );
};

export default MapView;
