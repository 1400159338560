import { disableFragmentWarnings, gql } from '@apollo/client';

import VendorConfigFragment from './vendor-config';

disableFragmentWarnings();

export default gql`
  fragment VendorConfigsFragment on VendorConfigs {
    sicom {
      ...VendorConfigFragment
    }
    sicomDelivery {
      ...VendorConfigFragment
    }
    ncr {
      ...VendorConfigFragment
    }
    ncrDelivery {
      ...VendorConfigFragment
    }
    qst {
      ...VendorConfigFragment
    }
    qstDelivery {
      ...VendorConfigFragment
    }
    rpos {
      ...VendorConfigFragment
    }
    rposDelivery {
      ...VendorConfigFragment
    }
  }

  ${VendorConfigFragment}
`;
