import React, { useEffect } from 'react';

import { createMaterialTopTabNavigator } from '@react-navigation/material-top-tabs';
import { NativeStackScreenProps } from '@react-navigation/native-stack';

import { MenuListLoading } from 'components/menu-list-loading';
import { useRoute } from 'hooks/navigation/use-route';
import { useMenuSectionTabs } from 'hooks/use-menu-section-tabs';
import { usePrevious } from 'hooks/use-previous';
import { CustomEventNames, EventTypes, useMParticleContext } from 'state/mParticle';
import { ClickEventComponentNames } from 'state/mParticle/constants';
import { useMainMenuContext } from 'state/main-menu';
import { useStoreContext } from 'state/store';
import { routes } from 'utils/routing';

import { MenuSectionScreen } from './menu-section-screen';
import { topTabBarItemStyles } from './styles';
import { TopTabLabel } from './top-tab-label';

const MenuSectionTab = createMaterialTopTabNavigator();

export const MenuSectionNavigator: React.FC<NativeStackScreenProps<{}>> = ({ navigation }) => {
  const { data: menuData } = useMainMenuContext();

  const { store } = useStoreContext();
  const storeId = store?.number ?? null;
  const previousStoreId = usePrevious<string | null | undefined>(storeId);

  const { params } = useRoute<{ id: string }>();
  const { id: currentRouteId } = params || {};
  const { logRBIEvent } = useMParticleContext();

  const tabbedMenuData = useMenuSectionTabs({ menuData: menuData?.Menu });

  // We should re-fetch the whole menu everytime that the store changes
  // this re-fetch happens on top screen (MainMenu)
  useEffect(() => {
    const isFirstRender = previousStoreId === undefined;

    if (!isFirstRender && previousStoreId !== storeId) {
      navigation.popToTop();
    }
  }, [navigation, previousStoreId, storeId]);

  //ReactNavigation does not allow to render <MenuSectionTab.Navigator> with empty children
  if (!tabbedMenuData?.length) {
    return null;
  }

  return (
    <MenuSectionTab.Navigator
      screenOptions={{
        tabBarStyle: {
          backgroundColor: Styles.color.white,
        },
        tabBarItemStyle: {
          width: 113,
          ...topTabBarItemStyles,
        },
        tabBarScrollEnabled: true,
        lazy: true,
        lazyPlaceholder: () => <MenuListLoading />,
      }}
      sceneContainerStyle={{
        overflow: 'visible',
      }}
      initialRouteName={`${routes.menu}/${currentRouteId}`}
    >
      {tabbedMenuData.map(({ label, to, tabItemId }) => {
        const sectionId = to.split(`${routes.menu}/`)[1];
        return (
          <MenuSectionTab.Screen
            key={to}
            name={to}
            options={{
              tabBarItemStyle: { width: 'auto', paddingHorizontal: 8 },
              tabBarLabel: ({ focused }) => {
                return <TopTabLabel label={label} focused={focused} />;
              },
            }}
            initialParams={{
              id: sectionId,
            }}
            // @ts-expect-error Type '({ route }: IMenuSectionScreenProps) => JSX.Element' is not assignable to type
            component={MenuSectionScreen}
            listeners={{
              tabPress: () => {
                logRBIEvent({
                  name: CustomEventNames.CLICK_EVENT,
                  type: EventTypes.Other,
                  attributes: {
                    component: ClickEventComponentNames.PRODUCT_SECTION_TABS,
                    text: label,
                    componentId: tabItemId,
                  },
                });
              },
            }}
          />
        );
      })}
    </MenuSectionTab.Navigator>
  );
};
