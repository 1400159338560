import type { RawTab } from '../../types';

import * as shared from './shared';

export const OffersTab: RawTab = {
  id: shared.id,

  screen: shared.screen,

  link: shared.link,

  iconSource: require('./icons/plk-offers-inactive.png'),
  iconActiveSource: require('./icons/plk-offers-active.png'),

  text: shared.text,
};
