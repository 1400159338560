import React, { useEffect, useMemo } from 'react';

import Link from 'components/link';
import { MenuObjectTypes } from 'enums/menu';
import { useRoute } from 'hooks/navigation/use-route';
import { useReactNavigationFlag } from 'hooks/use-react-navigation-flag';
import { navigationRef } from 'navigation/navigation-container/navigation-container-ref';
import { useFlag } from 'state/launchdarkly';
import { useMParticleContext } from 'state/mParticle';
import { ClickEventComponentNames, CustomEventNames, EventTypes } from 'state/mParticle/constants';
import { useMenuContext } from 'state/menu';
import { isExternalLink } from 'utils/is-external-link';
import { LaunchDarklyFlag } from 'utils/launchdarkly';
import logger from 'utils/logger';
import { optionToUrl } from 'utils/menu';
import { useIsBrowseMenu } from 'utils/menu/hooks/use-is-browse-menu';
import { isSectionRoute } from 'utils/routing';

import Tile from '../tile';
import { TileLinkExternal } from '../tile-link';

import { emitTileClickedEvent, findPromotion, getCaloriesAndPrice } from './helpers';
import { useCalories } from './hooks';
import { IMenuTile } from './types';

const SECTION_TILE_STRING = 'section';

const MenuTile = React.memo<IMenuTile>(props => {
  const { item, showStaticMenu, testID, objectFitContain = false } = props;
  const { asyncGetMenuObject } = useMenuContext();
  const enabledReactNativeNavigation = useReactNavigationFlag();
  const enableProductDetailsPrefetching = useFlag(
    LaunchDarklyFlag.ENABLE_PRODUCT_DETAILS_PREFETCHING
  );
  const { pathname, params } = useRoute<{ id?: string }>();
  const { id: menuObjectId } = params;
  const { logRBIEvent } = useMParticleContext();

  const { isBrowseMenu } = useIsBrowseMenu(pathname);
  const getCalories = useCalories();
  const lookupId = item._type + '-' + item._id;

  useEffect(() => {
    if (!enableProductDetailsPrefetching || item._type === MenuObjectTypes.SECTION) {
      return;
    }
    asyncGetMenuObject(lookupId)
      .then(() => {})
      .catch(error => {
        logger.warn({ error, message: `menu-tile: prefetch failed for ${lookupId}` });
      });
  }, [asyncGetMenuObject, item._type, lookupId, enableProductDetailsPrefetching]);

  const { calories, price } = useMemo(
    // we always enable the menu service data here because this is the "new" menu-tile
    () => getCaloriesAndPrice(item, getCalories, undefined),
    [getCalories, item]
  );

  const tile = (
    <Tile
      showStaticMenu={showStaticMenu}
      pathname={pathname}
      hideCalories={!!item.hideCalories}
      calories={calories}
      description={item?.description?.localeRaw}
      id={item._id}
      image={item.image}
      imageDescription={item.imageDescription?.locale}
      name={item.name?.locale}
      objectFitContain={objectFitContain}
      price={price}
      type={item._type}
      promotion={findPromotion(item)}
    />
  );

  const redirectUrl = item._type === MenuObjectTypes.SECTION && item?.redirectLink?.locale;

  if (redirectUrl && isExternalLink(redirectUrl)) {
    return (
      <TileLinkExternal key={item._id} href={redirectUrl} testID={testID}>
        {tile}
      </TileLinkExternal>
    );
  }

  const sanityId = item._id;
  const isSubSection = item._type === MenuObjectTypes.SECTION && isSectionRoute(pathname);
  const linkPath = redirectUrl || optionToUrl(item, isBrowseMenu);

  const logClickEvent = (tileType: string, productId: string, productName: string) => {
    if (tileType === SECTION_TILE_STRING) {
      logRBIEvent({
        name: CustomEventNames.CLICK_EVENT,
        type: EventTypes.Other,
        attributes: {
          component: ClickEventComponentNames.PRODUCT_SECTION_TILES,
          text: productName,
          componentId: productId,
        },
      });
    } else {
      logRBIEvent({
        name: CustomEventNames.CLICK_EVENT,
        type: EventTypes.Other,
        attributes: {
          component: ClickEventComponentNames.PRODUCTS,
          text: productName,
          componentId: productId,
        },
      });
    }
  };

  const handleLinkClick = () => {
    logClickEvent(item._type, item._id, item.name?.locale);
    emitTileClickedEvent({
      pathname,
      sanityId,
    });

    if (isSubSection && enabledReactNativeNavigation) {
      navigationRef.setParams({
        subSectionId: `section-${sanityId}`,
        sectionId: menuObjectId,
      });
    }
  };

  return (
    <Link
      testID={testID}
      onPress={handleLinkClick}
      linkPath={linkPath}
      dd-action-name={item.name?.locale}
    >
      {tile}
    </Link>
  );
});

export default MenuTile;
