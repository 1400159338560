import { FC } from 'react';

import { Text } from '@rbilabs/universal-components';
import { useIntl } from 'react-intl';

import { useIsTabletBp } from 'hooks/breakpoints';

import {
  ProductCustomizationDrawerClose,
  ProductCustomizationDrawerHeaderContainer,
} from './product-customization.styled';
import { IProductCustomizationDrawerHeaderProps } from './types';

export const ProductCustomizationDrawerHeader: FC<IProductCustomizationDrawerHeaderProps> = ({
  onClose,
}) => {
  const { formatMessage } = useIntl();
  const isTablet = useIsTabletBp();
  if (!isTablet) {
    return null;
  }
  return (
    <ProductCustomizationDrawerHeaderContainer>
      <ProductCustomizationDrawerClose
        variant="cancel"
        onPress={onClose}
        accessibilityLabel={formatMessage({ id: 'close' })}
        accessibilityRole="button"
      />
      <Text color="white" fontWeight="bold">
        {formatMessage({ id: 'productDetailSummaryCustomizableTitle' })}
      </Text>
    </ProductCustomizationDrawerHeaderContainer>
  );
};
