import { useEffect, useState } from 'react';

import { StoreLocatorViews } from 'pages/store-locator/types';
import { RunningExperiments, useExperiments } from 'state/experiments';

// This implementation is requring some special logic to handle the exposure logging
// and deciding if a user will see the experiment.
//
// Due to the way state works and the various lifecycles, we've decided its a better
// implementation to not "flicker" if the experiment api resolves late while a user is on the
// store locator view. Therefore, we have to manually track logging exposure
//
// based on what the user gets on the _first_ render.
export function useDefaultMapView__Experiment() {
  const { variant, logExposure } = useExperiments();
  const variantResult = variant(RunningExperiments.DefaultMapView, 'treatment', {
    skipExposureLogging: true,
  });

  const state = useState(variantResult ? StoreLocatorViews.MAP_VIEW : StoreLocatorViews.LIST_VIEW);

  useEffect(() => {
    // We are expecting to only log exposure if the user saw the map view immediately when the variant is true
    if (variantResult === true && state[0] === StoreLocatorViews.MAP_VIEW) {
      logExposure(RunningExperiments.DefaultMapView);
    }
    // or when the user saw the list view and the variant was false explicitly.
    else if (variantResult === false && state[0] === StoreLocatorViews.LIST_VIEW) {
      logExposure(RunningExperiments.DefaultMapView);
    }
  }, []);

  return state;
}
