import React from 'react';

import { useIntl } from 'react-intl';

import ActionButton, { ActionButtonVariants } from 'components/action-button';
import Modal, { ModalContent } from 'components/modal';
import { useNavigation } from 'hooks/navigation/use-navigation';
import { routes } from 'utils/routing';

import defaultConfig, { DELIVERY_IS_UNAVAILABLE, NO_RESTAURANT_DELIVERY_CTA } from './constants';
import { Header } from './modal-header';
import { ActionButtonWrapper, ActionsContainer, Container, P } from './styled';

export const NoStoresDeliveryModal = () => {
  const { formatMessage } = useIntl();
  const { goBack } = useNavigation();

  const modalHeading = formatMessage({ id: 'itsUsNotYou' });
  const modalBody = formatMessage({ id: DELIVERY_IS_UNAVAILABLE });

  return (
    <Modal
      onDismiss={goBack}
      mParticleEventData={{
        modalAppearanceEventMessage: 'Delivery not available in your area',
        modalMessage: modalBody,
      }}
    >
      <ModalContent paddingBottom="$8">
        <Container>
          <Header nativeID="modal-heading">{modalHeading}</Header>
          <P>{modalBody}</P>
        </Container>

        <ActionsContainer>
          <ActionButton to={routes.storeLocator}>
            {formatMessage({ id: NO_RESTAURANT_DELIVERY_CTA })}
          </ActionButton>
          <ActionButtonWrapper show={defaultConfig.hasShowMeDealsButton}>
            <ActionButton to={routes.offers}>{formatMessage({ id: 'showMeDeals' })}</ActionButton>
          </ActionButtonWrapper>
          <ActionButtonWrapper show={defaultConfig.hasDeliveryPartnersButton}>
            <ActionButton to={routes.address} variant={ActionButtonVariants.OUTLINE}>
              {formatMessage({ id: 'useDifferentAddress' })}
            </ActionButton>
          </ActionButtonWrapper>
        </ActionsContainer>
      </ModalContent>
    </Modal>
  );
};
