import { Box, Text, makeUclComponent } from '@rbilabs/universal-components';

import ActionButton, { ActionLink } from 'components/action-button';

export const Container = Box.withConfig({
  alignItems: 'center',
});

export const LoadingText = Text.withConfig({
  textAlign: 'center',
  fontSize: 'md',
  lineHeight: 'md',
  fontFamily: 'body',
  paddingY: '$2',
});

export const ButtonContainer = Box.withConfig({
  width: 'full',
  marginTop: '$4',
});

export const StyledActionButton = makeUclComponent(ActionButton).withConfig({
  _text: { fontFamily: 'alternative' },
});

export const StyledActionLink = makeUclComponent(ActionLink).withConfig({
  _text: { fontFamily: 'alternative' },
});
