import { useEffect } from 'react';

import { useRoute } from 'hooks/navigation/use-route';
import { usePrevious } from 'hooks/use-previous';
import { useMParticleContext } from 'state/mParticle';
import { useStoreContext } from 'state/store';

// Only used for react-router and WEB
export default function useLogPageView() {
  const { pathname } = useRoute();
  const previousPathname = usePrevious(pathname);
  const { logPageView } = useMParticleContext();
  const { store } = useStoreContext();

  useEffect(() => {
    if (previousPathname !== pathname) {
      logPageView(pathname, store);
    }
  }, [logPageView, pathname, store, previousPathname]);
}
