import { Box, HStack, Header as HeaderUCL, Pressable } from '@rbilabs/universal-components';

import { theme } from 'styles/configure-theme';

export const HeaderBackground = Box.withConfig({
  safeAreaTop: true,
  backgroundColor: {
    base: theme.token('background-active'),
    md: theme.token('background-pattern'),
  },
  zIndex: Styles.zIndex.overlay,
  _web: {
    borderWidth: {
      md: 1,
    },
    borderColor: {
      md: 'blackOpacity.10',
    },
  },
});

export const MaxWidth = Box.withConfig({
  maxWidth: '1000px',
});

export const TopSelectionContainer = MaxWidth.withConfig({
  zIndex: 1,
  paddingY: '$2',
  paddingTop: {
    base: '$4',
    md: '$10',
  },
});

export const HeaderWrapper = Box.withConfig({
  alignItems: 'center',
  justifyContent: 'space-between',
  flexDirection: 'row',
  textAlign: 'center',
  marginX: '$2',
  alignSelf: 'center',
  width: 'full',
});

export const Header = HeaderUCL.withConfig({
  color: {
    base: theme.token('text-reversed'),
    md: theme.token('text-default'),
  },
  fontSize: {
    base: '2xl',
    md: 'md',
  },
  fontWeight: 'bold',
});

export const MaxWidthFullHeight = MaxWidth.withConfig({
  flex: 1,
});

export const ToggleViewButtonWrapper = Box.withConfig({
  justifyContent: 'center',
});

export const ServiceModeCategoryToggleWrapper = Box.withConfig({
  paddingX: '$4',
  paddingBottom: '$4',
  justifyContent: 'center',
  width: 'full',
});

export const ToggleButtonsWrapper = HStack.withConfig({
  borderRadius: 'sm',
  overflow: 'hidden',
  borderColor: theme.token('border-color-input'),
  borderWidth: 0.5,
});

export const ToggleButton = Pressable.withConfig<{ selected: boolean }>(p => ({
  borderColor: theme.token('border-color-input'),
  borderWidth: 0.5,
  flex: '1',
  height: theme.token('height-input'),
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: p.selected ? theme.token('background-checked') : theme.token('background-input'),
  _web: {
    _hover: {
      borderColor: theme.token('border-color-hover'),
      cursor: 'pointer',
    },
  },
}));

export const ToggleButtonLabel = Box.withConfig<{ selected: boolean }>(p => ({
  _text: {
    color: p.selected ? theme.token('text-reversed') : theme.token('text-default'),
    font: theme.token('text-style-form-input'),
  },
}));

export const SearchContainer = Box.withConfig({
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
});
