import React, { useLayoutEffect } from 'react';

import { Box, FormControl, View } from '@rbilabs/universal-components';
import { useIntl } from 'react-intl';

import { DeliveryAddressModal } from 'components/address-modal';
import {
  MapViewUnderNearbyStores__LocatorHeaderToggle__Experiment,
  MapViewUnderNearbyStores__MapView__Experiment,
} from 'experiments/map-view-under-nearby-stores';
import { useReactNavigationFlag } from 'hooks/use-react-navigation-flag';
import { StoreChangeDisclaimer } from 'pages/store-locator/store-change-disclaimer';

import { SearchBar } from '../search-bar';
import { StoreLocatorViews } from '../types';

import { LocatorHeader } from './header';
import { ListView } from './list-view';
import { MapView } from './map-view';
import {
  MaxWidthFullHeight,
  SearchContainer,
  ServiceModeCategoryToggleWrapper,
  ToggleButton,
  ToggleButtonLabel,
  ToggleButtonsWrapper,
  TopSelectionContainer,
} from './store-locator.styled';
import { ToggleViewButton } from './toggle-view-button';
import { IStoreLocatorViewProps } from './types';

export const StoreLocatorView: React.VFC<IStoreLocatorViewProps> = ({
  activeCoordinates,
  errorFavs,
  errorNearby,
  errorRecent,
  handleResetLocation,
  searchingNearby,
  searchingRecent,
  searchingFavs,
  rawIsSearching,
  storesFavs = [],
  storesNearby = [],
  storesRecent = [],

  // Map View
  onPressSearch,

  // View
  currentView,
  onViewChange,

  // Search Bar
  onAddressSelected,
  onSearchBarValueChange,
  searchBarValue,
  shouldFocusSearchBar,

  // Service Mode Category
  hideServiceModeCategories = false,
  onServiceModeCategoryChange,
  selectedServiceModeCategory,
  serviceModeCategoryOptions = [],
  navigation,
}) => {
  const enableReactNativeNavigation = useReactNavigationFlag();
  const { formatMessage } = useIntl();

  useLayoutEffect(() => {
    if (enableReactNativeNavigation) {
      navigation?.setOptions({
        headerRight: () => (
          <MapViewUnderNearbyStores__LocatorHeaderToggle__Experiment>
            <ToggleViewButton currentView={currentView} updateStoreLocatorView={onViewChange} />
          </MapViewUnderNearbyStores__LocatorHeaderToggle__Experiment>
        ),
      });
    }
  }, [currentView, enableReactNativeNavigation, navigation, onViewChange]);

  return (
    <View testID="store-locator-wrapper" flexGrow={1} height="full">
      {!enableReactNativeNavigation && (
        <LocatorHeader currentView={currentView} onViewChange={onViewChange} />
      )}

      <TopSelectionContainer>
        <SearchContainer>
          {!hideServiceModeCategories ? (
            <ServiceModeCategoryToggleWrapper>
              <ToggleButtonsWrapper testID="service-mode-category-toggle">
                {serviceModeCategoryOptions.map(category => {
                  const isSelected = selectedServiceModeCategory === category.value;
                  const accessibilityLabel = `${category.label} ${
                    isSelected ? formatMessage({ id: 'selected' }) : ''
                  }`;

                  return (
                    <ToggleButton
                      key={category.label}
                      onPress={() => onServiceModeCategoryChange(category.value)}
                      testID={`service-mode-${category.value}`}
                      selected={isSelected}
                      accessibilityRole="button"
                    >
                      <ToggleButtonLabel
                        accessibilityLabel={accessibilityLabel}
                        selected={isSelected}
                      >
                        {category.label}
                      </ToggleButtonLabel>
                    </ToggleButton>
                  );
                })}
              </ToggleButtonsWrapper>
            </ServiceModeCategoryToggleWrapper>
          ) : null}
          {selectedServiceModeCategory !== 'DELIVERY' ? (
            <FormControl paddingX="$4" mb="$0" mt="$2">
              <FormControl.Label>
                {formatMessage({ id: 'enterYourDeliveryAddress' })}
              </FormControl.Label>
              <SearchBar
                // @ts-expect-error TS(2322) FIXME: Type '{ autoFocus: boolean; value: string; onInput... Remove this comment to see the full error message
                autoFocus={shouldFocusSearchBar}
                value={searchBarValue}
                onInputValueChange={onSearchBarValueChange}
                onAddressSelected={onAddressSelected}
              />
            </FormControl>
          ) : null}
        </SearchContainer>
      </TopSelectionContainer>

      <View>
        <StoreChangeDisclaimer marginX="$4" marginTop="$2" marginBottom="$4" />
      </View>
      {selectedServiceModeCategory === 'DELIVERY' ? (
        <DeliveryAddressModal />
      ) : currentView === StoreLocatorViews.LIST_VIEW ? (
        <ListView
          serviceModeCategory={selectedServiceModeCategory}
          errorFavs={errorFavs}
          errorNearby={errorNearby}
          errorRecent={errorRecent}
          searchingNearby={searchingNearby}
          searchingRecent={searchingRecent}
          searchingFavs={searchingFavs}
          rawIsSearching={rawIsSearching}
          storesFavs={storesFavs}
          storesNearby={storesNearby}
          storesRecent={storesRecent}
          mapView__experiment={
            <MapViewUnderNearbyStores__MapView__Experiment
              mapView={
                <Box h="full">
                  <MapView
                    serviceModeCategory={selectedServiceModeCategory}
                    activeCoordinates={activeCoordinates}
                    currentViewIsMap
                    handleResetLocation={handleResetLocation}
                    onPressSearch={onPressSearch}
                    storesFavs={storesFavs}
                    storesNearby={storesNearby}
                    storesRecent={storesRecent}
                  />
                </Box>
              }
            />
          }
        />
      ) : (
        <MaxWidthFullHeight display={currentView === StoreLocatorViews.MAP_VIEW ? 'flex' : 'none'}>
          <MapView
            serviceModeCategory={selectedServiceModeCategory}
            activeCoordinates={activeCoordinates}
            currentViewIsMap={currentView === StoreLocatorViews.MAP_VIEW}
            handleResetLocation={handleResetLocation}
            onPressSearch={onPressSearch}
            storesFavs={storesFavs}
            storesNearby={storesNearby}
            storesRecent={storesRecent}
          />
        </MaxWidthFullHeight>
      )}
    </View>
  );
};
