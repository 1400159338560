import React from 'react';

import { Box, Icon } from '@rbilabs/universal-components';

const Badge = Box.withConfig({
  flexDirection: 'row',
  position: 'absolute',
  top: 0,
  left: 0,
  alignItems: 'center',
  backgroundColor: Styles.color.teal,
  height: '$7',
  borderTopLeftRadius: 4,
  paddingLeft: '$2',
  minWidth: 40,

  width: {
    base: '12%',
    md: '9%',
  },
});

export const LockBadge: React.FC<React.PropsWithChildren<unknown>> = () => (
  <Badge>
    <Icon variant="locked" />
  </Badge>
);
