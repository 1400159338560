import ThemeBase from './theme-base';

export default {
  ...ThemeBase,
  iconContainer: {
    ...ThemeBase.iconContainer,
    color: 'nolaDark',
  },
  delivery: {
    ...ThemeBase.delivery,
    icon: 'plkDelivery',
    height: '9',
    width: '10',
  },
  pickup: {
    ...ThemeBase.pickup,
    icon: 'plkPickup',
    height: '9',
    width: '9',
    size: 9,
  },
  catering: {
    ...ThemeBase.catering,
    pickup: {
      ...ThemeBase.catering.pickup,
      icon: 'plkCatering',
    },
    delivery: {
      ...ThemeBase.catering.delivery,
      icon: 'plkCateringDelivery',
      height: '9',
      width: '10',
    },
  },
  badge: {
    color: Styles.color.black,
  },
};
