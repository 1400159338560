import React from 'react';

import { Box, Text } from '@rbilabs/universal-components';
import { FlatList } from 'react-native';

import { WebView } from 'components/web-view';
import { IStaticPage } from 'remote/queries/static-page';
import { hiddenAccessibilityPlatformProps } from 'utils/accessibility';
import { brand } from 'utils/environment';
import {
  AnchorComponentRefType,
  AnchorScrollContainerRefType,
  useAnchorContext,
} from 'utils/static-page/hooks/useAnchorContext';

import AccordionWidget from '../accordionWidget';
import AnchorLinksWidget from '../anchorLinksWidget';
import CallToActionWidget from '../callToActionWidget';
import { DonationWidget } from '../donationWidget';
import HeaderWidget from '../headerWidget';
import ImageWidget from '../imageWidget';
import NutritionExplorerWidget from '../nutrition-explorer-widget';
import { PreviewWidget } from '../preview-widget';
import RewardsCarouselWidget from '../rewards-carousel-widget';
import theme from '../theme';
import VideoWidget from '../videoWidget';
import YouTubeWidget from '../youtubeWidget';

import serializers from './serializers';
import {
  BlockWidgetContainer,
  Header1,
  Header2,
  Header3,
  Header4,
  Header5,
  Paragraph,
  UnknownWidget,
  blockWidgetLinkProps,
  blockWidgetStrongProps,
} from './styled';
import { WidgetType } from './widgetEnums';

export const AnchorWidget = ({ id, ...rest }: { id: string }) => {
  const { registerTarget, formatLocaleAnchorName } = useAnchorContext();
  const formattedName = formatLocaleAnchorName(id);

  return (
    <Box
      ref={(ref: AnchorComponentRefType) => {
        registerTarget(formattedName, ref);
      }}
      position={'relative'}
      {...hiddenAccessibilityPlatformProps}
      {...rest}
    />
  );
};

export const blockContentOverrides = (pageSlug: string) => ({
  h1: (props: any) => <Header1 {...props} pageSlug={pageSlug} />,
  h2: (props: any) => <Header2 {...props} pageSlug={pageSlug} />,
  h3: (props: any) => <Header3 {...props} pageSlug={pageSlug} />,
  h4: (props: any) => <Header4 {...props} pageSlug={pageSlug} />,
  h5: (props: any) => <Header5 {...props} pageSlug={pageSlug} />,
  normal: (props: any) => <Paragraph {...props} pageSlug={pageSlug} />,
});

const Row = Box.withConfig<{ blockTextInversion?: boolean }>(p => ({
  background: p.blockTextInversion
    ? brand() === 'th'
      ? Styles.color.black
      : theme.rowInversionBackgroundColor
    : theme.backgroundColor,
  color: p.blockTextInversion
    ? theme.rowInversionTextColor
    : brand() === 'th'
    ? Styles.color.secondary
    : Styles.color.black,
}));

export const StaticPageContainer: React.FC<React.PropsWithChildren<{
  data: any[];
  currentPage: IStaticPage;
  isScroll: boolean;
  fixedHeightContent?: React.ReactNode;
  webViewLoading?: boolean;
}>> = ({ data, currentPage, isScroll, fixedHeightContent, webViewLoading = false }) => {
  const { registerContainerRef } = useAnchorContext();

  const renderItem = ({ item: widget, index }: { item: any; index: number }) => {
    const type = widget._type;
    const key = `widget${index}`;

    switch (type) {
      case WidgetType.WEB_VIEW:
        return <Row key={key}>{widget.renderWebView()}</Row>;
      case WidgetType.WEB_VIEW_WIDGET:
        return (
          <Row key={key}>
            <WebView {...widget} />
          </Row>
        );
      case WidgetType.REWARDS_CAROUSEL:
        return (
          <Row key={key}>
            <RewardsCarouselWidget {...widget} />
          </Row>
        );
      case WidgetType.HEADER:
        return (
          <Row key={key}>
            <HeaderWidget {...widget} />
          </Row>
        );
      case WidgetType.ANCHOR:
        return (
          <Row key={key} {...widget}>
            {/* @ts-expect-error TS(2322) FIXME: Type '{ className: string; id: any; }' is not assi... Remove this comment to see the full error message */}
            <AnchorWidget className="anchorWidget" id={widget.anchorName?.locale} />
          </Row>
        );
      case WidgetType.ANCHOR_LINKS:
        return (
          <Row key={key}>
            <AnchorLinksWidget widgets={currentPage.widgets} />
          </Row>
        );
      case WidgetType.LOCALE_BLOCK_TEXT:
        return (
          <Row key={key} {...widget}>
            <BlockWidgetContainer
              blockContentOverrides={blockContentOverrides(currentPage.path.current)}
              strongProps={blockWidgetStrongProps}
              linkProps={blockWidgetLinkProps}
              // @ts-expect-error TS(2322) FIXME: Type '{ list: ({ type, children }: { type: string;... Remove this comment to see the full error message
              serializers={serializers}
              content={widget?.localeBlockTextContent?.localeRaw}
            />
          </Row>
        );
      case WidgetType.IMAGE:
        return (
          <Row key={key}>
            <ImageWidget {...widget} />
          </Row>
        );
      case WidgetType.CALL_TO_ACTION:
        return (
          <Row key={key}>
            <CallToActionWidget {...widget} />
          </Row>
        );
      case WidgetType.ACCORDION:
        return (
          <Row key={key}>
            <AccordionWidget {...widget} />
          </Row>
        );
      case WidgetType.PREVIEW:
        return (
          <Row key={key}>
            <PreviewWidget {...widget} />
          </Row>
        );
      case WidgetType.NUTRITION_EXPLORER:
        return (
          <Row key={key} flex={1}>
            <NutritionExplorerWidget {...widget} />
          </Row>
        );
      case WidgetType.VIDEO:
        return (
          <Row key={key}>
            <VideoWidget {...widget} />
          </Row>
        );
      case WidgetType.YOUTUBE:
        return (
          <Row key={key}>
            <YouTubeWidget {...widget} />
          </Row>
        );
      case WidgetType.MULTI_WIDGET:
        const { code } = currentPage?.pageCSS;
        if (widget.widget === 'donationWidget') {
          return (
            <Row key={key}>
              <DonationWidget
                id="96818434-aee1-462f-a4f7-428c583b8396"
                css={code ?? ''}
                {...widget}
              />
            </Row>
          );
        }

        // TODO: Remove multi widget in favor of a real donation widget
        // https://github.com/rbilabs/ctg-whitelabel-app/pull/3608
        if (widget.widget === 'donationWidgetNuggets') {
          return (
            <Row key={key}>
              {/* TODO: RN - Delete this text block */}
              <Text>donation nuggets Widget (not implemented)</Text>
              <DonationWidget
                id="24129188-142d-40cb-aab3-5d19b33d223b"
                css={code ?? ''}
                {...widget}
              />
            </Row>
          );
        }

        return (
          <Row key={key}>
            {/* TODO: RN - Delete this text block */}
            <Text>Unknown Widget (not implemented)</Text>
            <UnknownWidget> Placeholder: {type} </UnknownWidget>
          </Row>
        );
      default:
        return (
          <Row key={key}>
            {/* TODO: RN - Delete this text block */}
            <Text>Unkown Widget (not implemented)</Text>
            <UnknownWidget> Placeholder: {type} </UnknownWidget>
          </Row>
        );
    }
  };

  if (isScroll) {
    return (
      <FlatList
        overScrollMode={webViewLoading ? 'never' : 'auto'}
        showsVerticalScrollIndicator={false}
        ref={ref => {
          registerContainerRef(ref as AnchorScrollContainerRefType);
        }}
        data={data}
        renderItem={renderItem}
      />
    );
  }

  return (
    <Box height="full" width="full">
      {fixedHeightContent}
    </Box>
  );
};
