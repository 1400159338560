import React from 'react';

import { makeUclComponent } from '@rbilabs/universal-components';
import { useIntl } from 'react-intl';

import { ILocation } from '@rbi-ctg/frontend';
import Link, { IInLineLinkProps } from 'components/link';
import { nativeMapsHref } from 'utils/native-actions';

import theme from './theme';

const DirectionsLinkStyled = Link.withConfig({
  color: theme.innerAnchorColor,
  fontWeight: 'bold',
  fontSize: 'md',
  isUnderlined: true,
});

type Props = Omit<IInLineLinkProps, 'to' | 'isInline'> & {
  activeCoordinates: ILocation | null;
  mapPosition: ILocation;
};

export const DirectionsLink = makeUclComponent(
  ({ activeCoordinates, mapPosition, ...rest }: Props) => {
    const { formatMessage } = useIntl();
    return (
      <DirectionsLinkStyled
        {...rest}
        isExternal
        isInline
        to={nativeMapsHref(activeCoordinates, mapPosition)}
      >
        {formatMessage({ id: 'directions' })}
      </DirectionsLinkStyled>
    );
  }
);
