import React from 'react';

import { Center, makeUclComponent } from '@rbilabs/universal-components';
import { QRCodeRenderersOptions } from 'qrcode'; // TODO: RN - Usages of non converted QRCode components expect this options Type
import { default as NativeQRCode, QRCodeProps } from 'react-native-qrcode-svg';

interface IQRCodeProps extends QRCodeProps {
  barcode: string;
  className?: string;
  options: QRCodeRenderersOptions;
  testID?: string;
}
const QRCode: React.FC<React.PropsWithChildren<IQRCodeProps>> = ({
  barcode,
  options,
  testID,
  ...props
}: IQRCodeProps) => {
  const width = options?.width ?? 150;
  const lightColor = options?.color?.light ?? '#fff';
  const darkColor = options?.color?.dark ?? '#000';

  return (
    <Center testID={testID} backgroundColor={lightColor} {...props}>
      <NativeQRCode value={barcode} size={width} backgroundColor={lightColor} color={darkColor} />
    </Center>
  );
};

export default makeUclComponent(QRCode);
