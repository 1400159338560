import {
  Box,
  Header,
  Icon,
  Pressable,
  ScrollView,
  Text,
  VStack,
} from '@rbilabs/universal-components';

import Picture from 'components/picture';

export const UpsellItems = ScrollView.withConfig({
  showsHorizontalScrollIndicator: false,
  horizontal: true,
  height: '90px',
  paddingY: '4px',
  paddingX: '2px',
});

export const UpsellItemButton = Pressable.withConfig({
  width: 'auto',
  paddingX: '16px',
  paddingY: '16px',
  margin: '$0.5',
  marginRight: '$4',
  borderRadius: '8px',
  shadow: '2',
  backgroundColor: Styles.color.cardBackground,
  flex: 1,
  borderWidth: 0,
  borderColor: 'transparent',
  alignItems: 'center',
  justifyContent: 'space-around',
  flexDirection: 'row',
});

export const UpsellItemName = Text.withConfig({
  fontSize: 'md',
  maxWidth: '130px',
  overflow: 'hidden',
  ellipsizeMode: 'tail',
  numberOfLines: 1,
});

export const UpsellItemPrice = Text.withConfig({
  fontSize: 'xs',
  fontWeight: 'bold',
  flexShrink: 0,
});

export const UpsellItemCalories = Text.withConfig({
  fontSize: 'xs',
  opacity: 0.7,
  flexShrink: 0,
});

export const UpsellItemIcon = Icon.withConfig({
  width: '24px',
  height: '24px',
  color: Styles.color.black,
  marginX: 0,
});

export const UpsellSectionContainer = Box;

export const UpsellTitle = Header.withConfig({
  variant: 'headerThree',
});

export const UpsellItemPicture = Picture.withConfig({
  width: '50px',
  height: '50px',
  objectFitContain: true,
});

export const UpsellItemDetails = VStack.withConfig({
  flex: 3,
  marginX: '8px',
});
