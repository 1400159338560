import { PropsWithChildren, createElement, useMemo } from 'react';

import { UclProvider, UclTheme } from '@rbilabs/universal-components';

import { AssetsProvider } from 'state/assets';
import { AuthProvider } from 'state/auth';
import { BranchProvider } from 'state/branch';
import { BrazeProvider } from 'state/braze';
import { DayPartProvider } from 'state/day-part';
import { DeliveryConfirmationProvider } from 'state/delivery-confirmation';
import { ErrorsProvider } from 'state/errors';
import { ExperimentsProvider } from 'state/experiments';
import { FavoritesProvider } from 'state/favorites';
import { FeaturesProvider } from 'state/features';
import { GeolocationProvider } from 'state/geolocation';
import { GlobalStateProvider } from 'state/global-state/provider';
import { GraphQLProvider } from 'state/graphql';
import { HelmetProvider } from 'state/helmet';
import { IntlProvider } from 'state/intl';
import { LDProvider } from 'state/launchdarkly';
import { LoadingProvider } from 'state/loading';
import { LocationProvider } from 'state/location';
import { LoyaltyProvider } from 'state/loyalty';
import { MParticleProvider } from 'state/mParticle';
import { MenuProvider } from 'state/menu';
import { MobileFooterProvider } from 'state/mobile-footer';
import { MobileHeaderNavProvider } from 'state/mobile-header-nav';
import { NetworkProvider } from 'state/network';
import { OffersProvider } from 'state/offers';
import { OrderProvider } from 'state/order';
import { PaymentProvider } from 'state/payment';
// import { PaymentProvider as PaymentProviderV2 } from 'state/payments';
import { PushPermissionProvider } from 'state/push-permissions';
import { ReversedUIProvider } from 'state/reversed-ui';
import { ScrollProvider } from 'state/scroll';
import { ServiceModeProvider } from 'state/service-mode';
import { PageManagerProvider } from 'state/static-page-manager';
import { StoreProvider } from 'state/store';
import { UIProvider } from 'state/ui';

const useProviders = ({
  uclTheme,
  sanityAssets,
  onErrorBoundaryRequestReload,
}: {
  uclTheme: UclTheme;
  sanityAssets: any;
  onErrorBoundaryRequestReload: () => void;
}) => {
  const UclProviderWithTheme = useMemo(
    () => ({ children }: PropsWithChildren<{}>) =>
      createElement(UclProvider, { theme: uclTheme }, children),
    [uclTheme]
  );
  const AssetProviderWithAssets = useMemo(
    () => ({ children }: PropsWithChildren<{}>) =>
      createElement(AssetsProvider, { sanityAssets }, children),
    [sanityAssets]
  );

  const ErrorsProviderWithHandler = useMemo(
    () => ({ children }: PropsWithChildren<{}>) =>
      createElement(ErrorsProvider, { onRequestReload: onErrorBoundaryRequestReload }, children),
    [onErrorBoundaryRequestReload]
  );

  return [
    UclProviderWithTheme,
    AssetProviderWithAssets,
    GlobalStateProvider,
    LDProvider,
    IntlProvider,
    MParticleProvider,
    ExperimentsProvider,
    LocationProvider,
    BranchProvider,
    GraphQLProvider,
    BrazeProvider,
    LoadingProvider,
    ErrorsProviderWithHandler,
    NetworkProvider,
    AuthProvider,
    UIProvider,
    ServiceModeProvider,
    StoreProvider,
    FeaturesProvider,
    DayPartProvider,
    OffersProvider,
    LoyaltyProvider,
    MenuProvider,
    MobileHeaderNavProvider,
    MobileFooterProvider,
    PaymentProvider,
    PageManagerProvider,
    PushPermissionProvider,
    OrderProvider,
    FavoritesProvider,
    GeolocationProvider,
    HelmetProvider,
    DeliveryConfirmationProvider,
    ReversedUIProvider,
    ScrollProvider,
  ];
};

export default useProviders;
