// This mParticleAdapter is used as the adapter layer to talk to the mParticle web SDK
import mParticle from '@mparticle/web-sdk';
import { ILogCommerceEvent } from '@rbilabs/mparticle-client';

import { EventTypes } from './constants';
import {
  IMParticleAdapter,
  IMParticleCallback,
  IMParticleIdentityAdapter,
  IMparticleIdentityApiData,
} from './types';

class MParticleIdentityAdapterWeb implements IMParticleIdentityAdapter {
  static modify(identityApiData: IMparticleIdentityApiData, callback: IMParticleCallback) {
    return mParticle.Identity.modify(identityApiData, callback);
  }
}

class MParticleAdapterWeb implements IMParticleAdapter {
  createProduct(name: string, sku: string, price: number, quantity: number) {
    return mParticle.eCommerce.createProduct(name, sku, price, quantity);
  }

  /**
   * @deprecated Please use the 'logRbiEvent' method from mParticleContext instead of this method.
   * This method should ONLY be used within mParticleContext.
   */
  logEvent(name: string, eventTypes: EventTypes, attributes?: object, customFlags?: object) {
    if (customFlags) {
      mParticle.logEvent(name, eventTypes, attributes, customFlags);
    } else {
      mParticle.logEvent(name, eventTypes, attributes);
    }
  }

  logProductAction({ type, products, attrs, flags, transactionalAttrs }: ILogCommerceEvent) {
    mParticle.eCommerce.logProductAction(type, products, attrs, flags, transactionalAttrs);
  }

  getDeviceId() {
    return mParticle.getDeviceId() || '';
  }

  getSession(): Promise<{ sessionId: string }> {
    const sessionId = mParticle.sessionManager.getSession();
    return Promise.resolve({ sessionId });
  }

  setOptOut(optOut: boolean) {
    mParticle.setOptOut(optOut);
  }

  Identity = MParticleIdentityAdapterWeb;
}

const MParticleAdapter = new MParticleAdapterWeb();

export { MParticleAdapter };
