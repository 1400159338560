import { Text, VStack } from '@rbilabs/universal-components';

import { theme } from 'styles/configure-theme';

export const LocatorTileWrapper = VStack.withConfig<{ highlight?: boolean }>(({ highlight }) => ({
  padding: '$4',
  backgroundColor: theme.token('background-pattern'),
  ...(highlight
    ? {
        borderColor: theme.token('border-color-active'),
        borderWidth: '2px',
      }
    : {
        borderBottomWidth: '1px',
        borderBottomColor: theme.token('border-default'),
      }),
}));

export const LocatorTileTitle = Text.withConfig({
  variant: 'copyOne',
  fontWeight: 'bold',
});
