import { FC } from 'react';

import { StandardMobileHeader } from 'components/app-header/mobile-header';
import { useIsTabletBp } from 'hooks/breakpoints';
import { portalToReplaceHeader } from 'state/mobile-header-nav';

import { LocatorHeaderBase } from './header.base';
import { ILocatorHeaderProps } from './types';

const MobileLocatorHeader: FC<ILocatorHeaderProps> = props => {
  return <LocatorHeaderBase {...props} />;
};

const DesktopLocatorHeader: FC<ILocatorHeaderProps> = portalToReplaceHeader(props => {
  return (
    <>
      <StandardMobileHeader />
      <LocatorHeaderBase {...props} />
    </>
  );
});

export const LocatorHeader: FC<ILocatorHeaderProps> = props => {
  const isTablet = useIsTabletBp();
  return isTablet ? <DesktopLocatorHeader {...props} /> : <MobileLocatorHeader {...props} />;
};
