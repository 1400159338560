import { isString } from 'lodash-es';

import ReactAppboy from 'utils/appboy';
import logger from 'utils/logger';

import { ProductActionTypes } from './constants';
import { IMParticleProduct } from './types';

/**
 * Given the mParticle eCommerce arguments, this method translates those to equivalent Braze events
 * and logs an event to Braze.
 * Here we do our best to emulate the same logic used in the mParticl web SDK to maintain parity on the same
 * event naming and attribute naming schemas so our Braze events match correctly.
 *
 * The mParticle web SDK code which implements similar logic we are trying to model is found here:
 * https://github.com/mParticle/mparticle-web-sdk/blob/acd756b041262e033ec6adead16a7135bacd1b0c/src/ecommerce.js#L441
 *
 * @param mParticleProductActionType the type of action
 * @param products list of Products and information being logged
 * @param attributes list of other attributes to log adittionally
 */
export const logBrazeCommerceEvent = (
  mParticleProductActionType: ProductActionTypes,
  products: IMParticleProduct[],
  attributes: { Currency?: string } | null | undefined
) => {
  if (!attributes) {
    attributes = {};
  }

  if (attributes.Currency) {
    attributes['Currency Code'] = attributes.Currency;
  }

  // 1. Check if the products list is an Array or an object like the web SDK
  // https://github.com/mParticle/mparticle-web-sdk/blob/acd756b041262e033ec6adead16a7135bacd1b0c/src/events.js#L135
  const productList = Array.isArray(products) ? products : [products];
  sanitizeProductList(productList);

  // Purchase and Refund events also log batched events
  // https://github.com/mParticle/mparticle-web-sdk/blob/acd756b041262e033ec6adead16a7135bacd1b0c/src/ecommerce.js#L448-L451
  if (
    mParticleProductActionType === ProductActionTypes.Purchase ||
    mParticleProductActionType === ProductActionTypes.Refund
  ) {
    logBatchedBrazeCommerceEvent(mParticleProductActionType, productList, attributes);
  }

  productList.forEach(product => {
    logSingleBrazeCommerceEvent(mParticleProductActionType, product, attributes);
  });
};

/**
 * https://github.com/mParticle/mparticle-web-sdk/blob/acd756b041262e033ec6adead16a7135bacd1b0c/src/ecommerce.js#L492-L520
 */
const logSingleBrazeCommerceEvent = (
  mParticleProductActionType: ProductActionTypes,
  product: IMParticleProduct,
  attributes: { Currency?: string } | null | undefined
) => {
  const brazeEventName = mParticleEcommerceEventToBrazeEventName(mParticleProductActionType, false);
  const brazeAttributes = {
    ...product,
    ...attributes,
  };

  extractProductAttributes(brazeAttributes, product);
  ReactAppboy.logCustomEvent(brazeEventName, brazeAttributes);

  if (mParticleProductActionType === ProductActionTypes.Purchase) {
    const currencyCode = attributes?.Currency ?? '';
    ReactAppboy.logPurchase(product.Sku, product.Price.toString(), currencyCode, product.Quantity, {
      ...product,
      ...attributes,
    });
  }
};

/**
 * https://github.com/mParticle/mparticle-web-sdk/blob/acd756b041262e033ec6adead16a7135bacd1b0c/src/ecommerce.js#L453-L480
 */
const logBatchedBrazeCommerceEvent = (
  mParticleProductActionType: ProductActionTypes,
  products: IMParticleProduct[],
  attributes: object | null | undefined
) => {
  const brazeEventName = mParticleEcommerceEventToBrazeEventName(mParticleProductActionType, true);
  const brazeAttributes = {
    ...attributes,
  };

  brazeAttributes['Product Count'] = products ? products.length : 0;
  ReactAppboy.logCustomEvent(brazeEventName, brazeAttributes);
};

/**
 * https://github.com/mParticle/mparticle-web-sdk/blob/acd756b041262e033ec6adead16a7135bacd1b0c/src/events.js#L137
 */
const sanitizeProductList = (productList: IMParticleProduct[]) => {
  productList.forEach(product => {
    if (product.TotalAmount) {
      product.TotalAmount = sanitizeAmount(product.TotalAmount);
    }
    if (product.Position) {
      product.Position = sanitizeAmount(product.Position);
    }
    if (product.Price) {
      product.Price = sanitizeAmount(product.Price);
    }
    if (product.Quantity) {
      product.Quantity = sanitizeAmount(product.Quantity);
    }
  });
};

/**
 * https://github.com/mParticle/mparticle-web-sdk/blob/acd756b041262e033ec6adead16a7135bacd1b0c/src/ecommerce.js#L554
 */
const sanitizeAmount = (amount: string | number) => {
  if (isString(amount)) {
    return parseNumber(parseFloat(amount));
  }
  return parseNumber(amount);
};

/**
 * https://github.com/mParticle/mparticle-web-sdk/blob/acd756b041262e033ec6adead16a7135bacd1b0c/src/helpers.js#L459
 */
const parseNumber = (value: number): number => {
  if (isNaN(value) || !isFinite(value)) {
    return 0;
  }
  return isNaN(value) ? 0 : value;
};

/**
 * Translates the mParticle Product Action type to the equivalent Braze custom event name for logging
 * https://github.com/mParticle/mparticle-web-sdk/blob/acd756b041262e033ec6adead16a7135bacd1b0c/src/ecommerce.js#L136
 * @param mParticleProductActionType
 * @param batched boolean for whether or not this event represents a single product or multiple products
 */
const mParticleEcommerceEventToBrazeEventName = (
  mParticleProductActionType: ProductActionTypes,
  batched: boolean
) => {
  const brazeEventName = mParticleToBrazeMapping.get(mParticleProductActionType);
  return `eCommerce - ${brazeEventName} - ${batched ? 'Total' : 'Item'}`;
};

// Get expanded names
// Based off the mParticle web SDK implementation
// https://github.com/mParticle/mparticle-web-sdk/blob/acd756b041262e033ec6adead16a7135bacd1b0c/dist/mparticle.js#L636
const mParticleToBrazeMapping = new Map<ProductActionTypes, string>([
  [ProductActionTypes.AddToCart, 'add_to_cart'],
  [ProductActionTypes.RemoveFromCart, 'remove_from_cart'],
  [ProductActionTypes.Checkout, 'checkout'],
  [ProductActionTypes.CheckoutOption, 'checkout_option'],
  [ProductActionTypes.Click, 'click'],
  [ProductActionTypes.ViewDetail, 'view_detail'],
  [ProductActionTypes.Purchase, 'purchase'],
  [ProductActionTypes.Refund, 'refund'],
  [ProductActionTypes.AddToWishlist, 'add_to_wishlist'],
  [ProductActionTypes.RemoveFromWishlist, 'remove_from_wishlist'],
]);

/**
 * https://github.com/mParticle/mparticle-web-sdk/blob/acd756b041262e033ec6adead16a7135bacd1b0c/src/ecommerce.js#L142
 */
const extractProductAttributes = (brazeAttributes: any, product: IMParticleProduct) => {
  if (product.CouponCode) {
    brazeAttributes['Coupon Code'] = product.CouponCode;
  }
  if (product.Brand) {
    brazeAttributes.Brand = product.Brand;
  }
  if (product.Category) {
    brazeAttributes.Category = product.Category;
  }
  if (product.Name) {
    brazeAttributes.Name = product.Name;
  }
  if (product.Sku) {
    brazeAttributes.Id = product.Sku;
  }
  if (product.Price) {
    brazeAttributes['Item Price'] = product.Price;
  }
  if (product.Quantity) {
    brazeAttributes.Quantity = product.Quantity;
  }
  if (product.Position) {
    brazeAttributes.Position = product.Position;
  }
  if (product.Variant) {
    brazeAttributes.Variant = product.Variant;
  }
  brazeAttributes['Total Product Amount'] = product.TotalAmount || 0;
};

/**
 * Try to set the key to a default user attribute supported by Braze
 * If the key is not supported, we set it on the custom User attributes field
 * Supported Braze Attributes
 * https://www.braze.com/docs/developer_guide/platform_integration_guides/react_native/analytics/#default-user-attributes
 * First Name
 * Last Name
 * Gender
 * Date of Birth
 * Home City
 * Country
 * Phone Number
 * Language
 * Email
 * Twitter Data
 * Facebook Data
 */
export const setBrazeUserAttribute = (key: string, value: string) => {
  const brazeUser = window.rbiAppboy ? window.rbiAppboy.getUser() : ReactAppboy;

  try {
    switch (key) {
      case '$FirstName':
        return brazeUser.setFirstName(value);
      case '$LastName':
        return brazeUser.setLastName(value);
      case '$City':
        return brazeUser.setHomeCity(value);
      case '$Mobile':
        return brazeUser.setPhoneNumber(value);
      default:
        return brazeUser.setCustomUserAttribute(key, value);
    }
  } catch (error) {
    // @ts-expect-error TS(2345) FIXME: Argument of type 'unknown' is not assignable to pa... Remove this comment to see the full error message
    logger.error(error);
  }
};
