import { Text } from '@rbilabs/universal-components';

import Link from 'components/link';

export const DeliveryTermsText = Text.withConfig({
  fontSize: 'xs',
  textAlign: 'center',
});

export const TermsLink = Link.withConfig({
  textTransform: 'capitalize',
  isUnderlined: true,
});
