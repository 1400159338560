import React from 'react';

import { HStack, Text } from '@rbilabs/universal-components';
import { useIntl } from 'react-intl';

import LoyaltyPointsIcon from 'components/icons/loyalty-points-icon';
import { Halal } from 'components/ucl/svgs/Halal';
import { IRestaurantNode } from 'generated/graphql-gateway';
import { useDistanceText } from 'pages/store-locator/new-ui/store-card/hooks/use-distance-text';
import { useOpenClosedText } from 'pages/store-locator/new-ui/store-card/hooks/use-open-closed-text';
import { HalalContainer } from 'pages/store-locator/new-ui/store-card/store-card.styled';

import { SHOW_LOYALTY_ICON } from '../constants';

import { LocatorTileInfoButton } from './locator-tile-info-button';

interface ILocatorTileCaptionProps {
  restaurant: IRestaurantNode;
}

const CaptionText = Text.withConfig({
  fontSize: 'xs',
});
const Bullet = () => <Text> &bull; </Text>;

export const LocatorTileCaption = ({ restaurant }: ILocatorTileCaptionProps) => {
  const { formatMessage } = useIntl();
  const { text: openCloseText } = useOpenClosedText(restaurant);
  const distanceText = useDistanceText({ restaurant });

  const isHalal = Boolean(restaurant.isHalal);
  const hasLoyalty = Boolean(restaurant.hasLoyalty);

  return (
    <HStack>
      {/* caption */}
      {distanceText ? <CaptionText>{distanceText}</CaptionText> : null}
      {distanceText && openCloseText ? <Bullet /> : null}
      {isHalal && (
        <>
          <HalalContainer>
            {
              //TODO: RN - replace by Icon variant="halal" from UCL
            }
            <Halal title={formatMessage({ id: 'isHalalTitle' })} />
          </HalalContainer>
          <Bullet />
        </>
      )}
      {openCloseText && (
        <HStack accessible>
          <CaptionText>{openCloseText}</CaptionText>
          <Bullet />
        </HStack>
      )}
      {SHOW_LOYALTY_ICON && hasLoyalty && (
        <HStack alignItems="center">
          <LoyaltyPointsIcon />
          <Bullet />
        </HStack>
      )}
      <LocatorTileInfoButton restaurant={restaurant} />
    </HStack>
  );
};
