import React, { useState } from 'react';

import { Radio } from '@rbilabs/universal-components';
import { useIntl } from 'react-intl';
import { FlatList } from 'react-native';

import ActionButton, { ActionButtonVariants } from 'components/action-button';
import { DeliveryTerms } from 'components/address-modal/delivery-terms';
import { StoreChangeDisclaimer } from 'pages/store-locator/store-change-disclaimer';

import { ModalHeading, ModalTitle } from '../styled';

import DeliveryAddressItem from './delivery-address-item';
import {
  AddressList,
  AddressListContainer,
  ButtonContainer,
  DeliveryInfoContainer,
  ModalContent,
} from './styled';
import { IDeliveryAddressesProps } from './types';

const DeliveryAddresses: React.FC<React.PropsWithChildren<IDeliveryAddressesProps>> = ({
  addresses,
  onCreate,
  setAddress,
  isLoading = false,
}) => {
  const { formatMessage } = useIntl();
  const [selected, setSelected] = useState('');

  const onSelectAddress = async () => {
    const address = addresses[+selected];

    const selectedAddressComponents = {
      addressLine1: address.addressLine1,
      addressLine2: address.addressLine2 || undefined,
      city: address.city,
      state: address.state || '',
      streetNumber: address.streetNumber || '',
      route: address.route || '',
      zip: address.zip || '',
      country: address.country || '',
    };

    let addressCoordinates;
    if (address.latitude && address.longitude) {
      addressCoordinates = {
        lat: address.latitude,
        lng: address.longitude,
      };
    }

    const selectedPhoneNumber = address.phoneNumber;
    setAddress({
      address: selectedAddressComponents,
      phoneNumber: selectedPhoneNumber,
      coordinates: addressCoordinates,
    });
  };

  const DeliveryAddressesButtons = () => (
    <ButtonContainer>
      <ActionButton
        testID="select-delivery-address"
        disabled={isLoading || selected === ''}
        isLoading={isLoading}
        onPress={onSelectAddress}
        fullWidth
        mb="$4"
      >
        {formatMessage({ id: 'deliverHere' })}
      </ActionButton>
      <ActionButton
        testID="create-delivery-address"
        disabled={isLoading}
        onPress={onCreate}
        fullWidth
        variant={ActionButtonVariants.OUTLINE}
      >
        {formatMessage({ id: 'deliveryAddNewAddress' })}
      </ActionButton>
    </ButtonContainer>
  );

  return (
    <ModalContent testID="delivery-addresses">
      <ModalHeading>
        <ModalTitle>{formatMessage({ id: 'deliverySelectYourAddress' })}</ModalTitle>
      </ModalHeading>
      <AddressList>
        <AddressListContainer>
          <Radio.Group
            name="delivery-addresses-radio"
            value={selected.toString()}
            onChange={value => setSelected(value)}
          >
            <FlatList
              style={{ flex: 1, width: '100%' }}
              data={addresses}
              keyExtractor={(item, index) => index.toString()}
              renderItem={({ item, index }) => (
                <DeliveryAddressItem address={item} value={index.toString()} />
              )}
            />
          </Radio.Group>
        </AddressListContainer>
      </AddressList>
      <DeliveryInfoContainer>
        <StoreChangeDisclaimer />
        <DeliveryTerms />
      </DeliveryInfoContainer>
      <DeliveryAddressesButtons />
    </ModalContent>
  );
};

export default DeliveryAddresses;
