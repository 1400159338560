import React, { useCallback, useEffect, useRef, useState } from 'react';

import { convertCountryStringToIsoCountryCode2Char } from '@rbilabs/common';
import { isEqual } from 'lodash-es';
import { useIntl } from 'react-intl';

import { ILocation } from '@rbi-ctg/frontend';
import ChangeRegionModal from 'components/language-selector-modal';
import Picture from 'components/picture';
import { VisuallyHidden } from 'components/ucl/visually-hidden';
import { useFeatureDisclaimers } from 'hooks/use-feature-disclaimers';
import { useLocale } from 'state/intl';
import { FormValidationState } from 'utils/form';
import { IPlaceAddress } from 'utils/geolocation';
import logger from 'utils/logger';

import { CHECK_REGION_MATCH_BEFORE_SELECTING_RESTAURANT } from '../constants';
import { ModalContent } from '../styled';
import { IDeliveryAddressFormProps, IPhoneAddressValidation } from '../types';

import DeliveryAddressFormFooter from './delivery-address-form-footer';
import DeliveryAddressFormHeader from './delivery-address-form-header';
import DeliveryAddressFormInput from './delivery-address-form-input';
import { AdditionalInformation, DeliveryLogoContainer } from './styled';

export const isCompleteAddress = (address: IPlaceAddress): boolean => {
  const { addressLine1, city, state, zip } = address;
  return !!(addressLine1 && city && state && zip);
};

const DeliveryAddressForm: React.FC<React.PropsWithChildren<IDeliveryAddressFormProps>> = ({
  instructions,
  user,
  setCreateAddressView,
  getDetailsPlaceData,
  showDeliveryAddresses,
  setAddress,
  isLoading,
}) => {
  const { formatMessage } = useIntl();
  const { featureDisclaimers } = useFeatureDisclaimers();
  const { deliveryLogo } = featureDisclaimers || {};
  const { region } = useLocale();
  const unitRef = useRef<HTMLInputElement>(null);
  const deliveryAddressInputRef = useRef<HTMLInputElement>(null);
  const [message, setMessage] = useState('');
  const [placeId, setPlaceId] = useState('');

  const [validation, setValidation] = useState<IPhoneAddressValidation>({
    phoneNumber: FormValidationState.PRISTINE,
    address: FormValidationState.PRISTINE,
  });
  const [addressComponents, setAddressComponents] = useState<IPlaceAddress | null>(null);
  const [coordinates, setCoordinates] = useState<ILocation | undefined>();
  const [showChangeRegionModal, setShowChangeRegionModal] = useState<boolean>(false);

  const [addressString, setAddressString] = useState('');
  const [deliveryInstructions, setDeliveryInstructions] = useState(instructions || '');

  const phoneNumber = user?.details?.phoneNumber || '';

  const getAddressRegion = useCallback(
    () => convertCountryStringToIsoCountryCode2Char(addressComponents?.country!),
    [addressComponents]
  );

  const changeRegionModalRegionFilter = useCallback(
    (regionForFilter: string) => {
      return getAddressRegion() === regionForFilter;
    },
    [getAddressRegion]
  );

  const handleSubmit = async () => {
    setMessage('');

    if (!addressComponents || validation.address === FormValidationState.INVALID) {
      setMessage(formatMessage({ id: 'addressError' }));

      if (deliveryAddressInputRef.current) {
        deliveryAddressInputRef.current.focus();
      }
      return;
    }

    if (CHECK_REGION_MATCH_BEFORE_SELECTING_RESTAURANT && getAddressRegion() !== region) {
      setShowChangeRegionModal(true);
      return;
    }

    setAddress({
      address: addressComponents,
      deliveryInstructions,
      phoneNumber,
      coordinates,
      placeId,
    });
  };

  useEffect(() => {
    if (!placeId) {
      return;
    }

    getDetailsPlaceData(placeId)
      .then(placeData => {
        if (!isEqual(placeData.address, addressComponents)) {
          setDeliveryInstructions('');
        }

        if (!placeData.address.addressLine1) {
          setValidation({ ...validation, address: FormValidationState.INVALID });
          setMessage(formatMessage({ id: 'addressError' }));
          return;
        }

        setCoordinates(placeData.coordinates);
        setAddressComponents(placeData.address);
      })
      .catch(error => {
        logger.error({ error, message: 'Error using selected address from predictions' });
      });
  }, [formatMessage, getDetailsPlaceData, placeId]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <ModalContent>
        <DeliveryAddressFormHeader />
        <DeliveryAddressFormInput
          setAddressString={setAddressString}
          setPlaceId={setPlaceId}
          setValidation={setValidation}
          setMessage={setMessage}
          setAddressComponents={setAddressComponents}
          unitRef={unitRef}
          validation={validation}
          setCoordinates={setCoordinates}
          addressString={addressString}
          setValue={setAddressString}
          message={message}
          deliveryAddressInputRef={deliveryAddressInputRef}
          data-private
          aria-invalid={!!message}
        />
        {!!message && (
          <VisuallyHidden
            role="alert"
            accessibilityLabel={formatMessage({ id: 'thereIsAnErrorInThisForm' })}
          />
        )}
        <AdditionalInformation>
          {formatMessage({ id: 'additionalAddressInformation' })}
        </AdditionalInformation>
        <DeliveryAddressFormFooter
          submitAddress={handleSubmit}
          setCreateAddressView={setCreateAddressView}
          showDeliveryAddresses={showDeliveryAddresses}
          isDisabled={!addressComponents?.addressLine1 || !!message}
          isLoading={isLoading}
        />
        {deliveryLogo?.locale && (
          <DeliveryLogoContainer>
            <Picture image={deliveryLogo?.locale} alt={formatMessage({ id: 'deliveryLogoAlt' })} />
          </DeliveryLogoContainer>
        )}
      </ModalContent>
      {showChangeRegionModal && (
        <ChangeRegionModal
          redirectToCurrentLocation
          heading={formatMessage({ id: 'deliveryChangeRegionHeading' })}
          regionFilter={changeRegionModalRegionFilter}
          onModalDismiss={() => setShowChangeRegionModal(false)}
          primaryText={formatMessage(
            { id: 'deliveryChangeRegionPrimaryText' },
            { userRegion: region, restaurantRegion: getAddressRegion() }
          )}
          secondaryText={formatMessage({ id: 'locatorChangeRegionSelection' })}
          disclaimer={formatMessage({ id: 'deliveryChangeRegionDisclaimer' })}
        />
      )}
    </>
  );
};

export default DeliveryAddressForm;
