import { IsoCountryCode2Char } from '@rbilabs/common';

import { IPoints } from '@rbi-ctg/frontend';

type isoCountryCode2Char = Exclude<
  IsoCountryCode2Char,
  | IsoCountryCode2Char.ID
  | IsoCountryCode2Char.JP
  | IsoCountryCode2Char.TH
  | IsoCountryCode2Char.TW
  | IsoCountryCode2Char.SG
  | IsoCountryCode2Char.MY
>;

const mapDefaults: Record<isoCountryCode2Char | 'default', IPoints> = {
  /**
   * (CA)
   */
  [IsoCountryCode2Char.CA]: {
    lat: 49.76773,
    lng: -96.8097,
    zoom: 4,
  },
  /**
   * (DE)
   */
  [IsoCountryCode2Char.DE]: {
    lat: 51.1633733,
    lng: 10.44768691,
    zoom: 4,
  },
  /**
   * (NZ)
   */
  [IsoCountryCode2Char.NZ]: {
    lat: -36.848461,
    lng: 174.763336,
    zoom: 4,
  },
  /**
   * (ZA)
   */
  [IsoCountryCode2Char.ZA]: {
    lat: 28.99346,
    lng: 25.07909,
    zoom: 4,
  },
  /**
   * (CH)
   */
  [IsoCountryCode2Char.CH]: {
    lat: 46.801111,
    lng: 8.226667,
    zoom: 4,
  },
  /**
   * (GB)
   */
  [IsoCountryCode2Char.GB]: {
    lat: 54.00366,
    lng: -2.547855,
    zoom: 4,
  },
  /**
   * (US)
   */
  [IsoCountryCode2Char.US]: {
    lat: 39.8283459,
    lng: -98.5794797,
    zoom: 4,
  },
  /**
   * (NL)
   */
  [IsoCountryCode2Char.NL]: {
    lat: 52.35,
    lng: 4.916667,
    zoom: 4,
  },
  /**
   * (AQ)
   */
  [IsoCountryCode2Char.AQ]: {
    lat: 36.09127,
    lng: -115.173691,
    zoom: 4,
  },
  /**
   * (KR)
   */
  [IsoCountryCode2Char.KR]: {
    lat: 37.5326,
    lng: 127.024612,
    zoom: 4,
  },
  /**
   * (ES)
   */
  [IsoCountryCode2Char.ES]: {
    lat: 40.4,
    lng: -3.683333,
    zoom: 4,
  },
  /**
   * (FR)
   */
  [IsoCountryCode2Char.FR]: {
    lat: 48.8566,
    lng: 2.3522,
    zoom: 4,
  },
  /**
   * (PT)
   */
  [IsoCountryCode2Char.PT]: {
    lat: 38.7223,
    lng: 9.1393,
    zoom: 4,
  },
  /**
   * (AT)
   */
  [IsoCountryCode2Char.AT]: {
    lat: 48.2,
    lng: 16.366667,
    zoom: 4,
  },
  /**
   * (CZ)
   */
  [IsoCountryCode2Char.CZ]: {
    lat: 50.083333333333336,
    lng: 14.466667,
    zoom: 4,
  },
  /**
   * (PL)
   */
  [IsoCountryCode2Char.PL]: {
    lat: 52.25,
    lng: 21,
    zoom: 4,
  },
  /**
   * (RO)
   */
  [IsoCountryCode2Char.RO]: {
    lat: 44.43333333333333,
    lng: 26.1,
    zoom: 4,
  },
  default: {
    lat: 44.967243,
    lng: -103.771556,
    zoom: 4,
  },
};

export default mapDefaults;
