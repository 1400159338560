import React, { FC } from 'react';

import { useIntl } from 'react-intl';

import { StaticPageEmbedded } from 'pages/static';

import { Container } from './loyalty-not-enrolled.styled';

const currentPageOverrideCss = `
#hero {
  margin-top: 0;
  max-height: 600px;
}
#action-bar {
  position: sticky;
  bottom: 0;
}`;

// Render Popeyes Rewards static page.
// Special use case for users that are not signed up on Loyalty.
// Please refer to LYL-2702.
const LoyaltyNotEnrolled: FC<React.PropsWithChildren<unknown>> = () => {
  const { formatMessage } = useIntl();

  return (
    <Container>
      <StaticPageEmbedded
        pathname={formatMessage({ id: 'routes.popeyesRewards' })}
        currentPageOverrideCss={currentPageOverrideCss}
      />
    </Container>
  );
};

export default LoyaltyNotEnrolled;
