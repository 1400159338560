import React, { createContext, useCallback, useContext, useState } from 'react';

import { IBaseProps } from '@rbi-ctg/frontend';
import { IEncryptionResult as IOrbitalEncryptionResult } from 'components/payments/integrations/orbital/components/encryption/types';
import { IEncryptionResult as IWorldpayEncryptionResult } from 'components/payments/integrations/worldpay/components/encryption/types';
import {
  IGetEncryptionDetailsMutation,
  IPrepaidsMergeInput,
  UserAccountsDocument,
  useAddCreditAccountMutation,
  useGetEncryptionDetailsMutation,
} from 'generated/rbi-graphql';
import useEffectOnce from 'hooks/use-effect-once';
import useErrorModal from 'hooks/use-error-modal';
import { useAuthContext } from 'state/auth';
import { useLocale } from 'state/intl';
import { PaymentFieldVariations } from 'state/launchdarkly/variations';
import { useMParticleContext } from 'state/mParticle';
import { ThreeDSType } from 'state/payment/hooks/types';
import LocalStorage, { StorageKeys } from 'utils/local-storage';
import { IAdyenPaymentState, IPaymentPayload, IPaymentState } from 'utils/payment';

import usePayment from './hooks/use-payment';
import {
  IAddPaymentMethodOptions,
  IPaymentDetails,
  IPaymentMethod,
  IReloadPrepaidCard,
} from './types';

interface ICurrentBalance {
  currentBalance: number;
  fdAccountId?: string | null;
}

export interface IPaymentContext {
  addPaymentMethod: (
    method: IPaymentPayload,
    options?: IAddPaymentMethodOptions,
    threeDSChallengeTokenResponse?: string | null,
    encryptionResult?: IOrbitalEncryptionResult | IWorldpayEncryptionResult
  ) => Promise<string>;
  checkoutPaymentMethod: IPaymentMethod | undefined;
  checkoutPaymentMethodId: string;
  canUseApplePay: boolean;
  canUseGooglePay: boolean;
  cleanThreeDSFlow: () => void;
  clearPaymentDetails(): void;
  defaultPaymentMethodId: string;
  defaultReloadPaymentMethodId: string;
  deletePaymentMethod: (accountId: string) => Promise<void>;
  getBalanceFromPaymentMethods: (prepaidPaymentMethod: IPaymentMethod) => number;
  getCounter?: () => Promise<void>;
  getEncryptionDetails: () => Promise<
    Pick<
      IGetEncryptionDetailsMutation['encryptionDetails'],
      'fdPublicKey' | 'fdApiKey' | 'fdAccessToken' | 'fdCustomerId' | 'algorithm'
    >
  >;
  getPaymentMethods: () => Promise<void>;
  getPrepaidPaymentMethod: () => IPaymentMethod | null;
  getPrepaidCardNumber: () => string | null;
  hasGetPaymentMethodsError: boolean;
  isAdyen: boolean;
  isFirstData: boolean;
  isVrPayment: boolean;
  isOrbital: boolean;
  isWorldpay: boolean;
  loading: boolean | undefined;
  mergePrepaidCardBalances: (input: IPrepaidsMergeInput) => Promise<ICurrentBalance>;
  paymentMethods: IPaymentMethod[];
  paymentDetails: IPaymentDetails;
  paymentProcessor: string | null | undefined;
  prepaidReloadPaymentMethodId: string;
  reloadPrepaidCard: (input: IReloadPrepaidCard) => Promise<number | undefined>;
  setCheckoutPaymentMethodId: (accountId: string) => void;
  setDefaultPaymentMethodId: (accountId: string) => void;
  setDefaultReloadPaymentMethodId: (accountId: string) => void;
  setPrepaidReloadPaymentMethodId: (accountId: string) => void;
  setSelected?: (fdAccountId: string) => void;
  storePaymentDetails: (data: IPaymentDetails) => void;
  threeDS: {
    iframeContent?: string | null;
    transactionId?: string | null;
    activeFlow?: ThreeDSType | null;
    acsUrl?: string | null;
    challengeRequest?: string | null;
    isChallengeRequest: () => boolean;
    isThreeDSMethodFlow: () => boolean;
    setThreeDSChallengeTokenResponse: (threeDSChallengeTokenResponse: string) => void;
    threeDSChallengeTokenResponse: string | null;
  };
  transformPaymentValues: ({
    paymentValues,
    paymentFieldVariations,
  }: {
    paymentValues: IPaymentState | IAdyenPaymentState;
    paymentFieldVariations: PaymentFieldVariations;
  }) => IPaymentPayload;
  encryptionResult: IOrbitalEncryptionResult | IWorldpayEncryptionResult | undefined;
  setEncryptionResult: (
    result: IOrbitalEncryptionResult | IWorldpayEncryptionResult | undefined
  ) => void;
}

export const PaymentContext = createContext<IPaymentContext>({} as IPaymentContext);
export const usePaymentContext = () => useContext(PaymentContext);

const clearPersistedPaymentDetails = (): void => {
  LocalStorage.setItem(StorageKeys.PAYMENT, {});
};

const persistPaymentDetails = (paymentDetails: IPaymentDetails): void => {
  LocalStorage.setItem(StorageKeys.PAYMENT, paymentDetails);
};

const retrievePersistedPaymentDetails = (): IPaymentDetails | null => {
  return LocalStorage.getItem(StorageKeys.PAYMENT);
};

export function PaymentProvider(props: IBaseProps) {
  const { feCountryCode } = useLocale();
  const { updateUserInfo, user, loading: userLoading } = useAuthContext();
  const [getEncryptionDetailsMutation] = useGetEncryptionDetailsMutation();
  const [addCreditAccountMutation] = useAddCreditAccountMutation({
    awaitRefetchQueries: true,
    refetchQueries: [{ query: UserAccountsDocument, variables: { feCountryCode } }],
  });
  const mParticle = useMParticleContext();

  const [paymentDetails, setPaymentDetails] = useState({});

  const [ErrorDialog, openErrorDialog] = useErrorModal({
    modalAppearanceEventMessage: 'Error: Payment Error',
  });

  const storePaymentDetails = useCallback((data: IPaymentDetails): void => {
    setPaymentDetails(data);
    persistPaymentDetails(data);
  }, []);

  const clearPaymentDetails = useCallback(() => {
    setPaymentDetails({});
    clearPersistedPaymentDetails();
  }, []);

  useEffectOnce(() => {
    const values = retrievePersistedPaymentDetails();
    if (values) {
      setPaymentDetails(values);
    }
  });

  const {
    addPaymentMethod,
    canUseApplePay,
    canUseGooglePay,
    checkoutPaymentMethod,
    checkoutPaymentMethodId,
    defaultPaymentMethodId,
    defaultReloadPaymentMethodId,
    deletePaymentMethod,
    getEncryptionDetails,
    getBalanceFromPaymentMethods,
    getPaymentMethods,
    getPrepaidCardNumber,
    getPrepaidPaymentMethod,
    hasGetPaymentMethodsError,
    isAdyen,
    isFirstData,
    isVrPayment,
    isOrbital,
    isWorldpay,
    isChallengeRequest,
    isThreeDSMethodFlow,
    loading,
    mergePrepaidCardBalances,
    paymentMethods,
    paymentProcessor,
    prepaidReloadPaymentMethodId,
    reloadPrepaidCard,
    setCheckoutPaymentMethodId,
    setDefaultPaymentMethodId,
    setDefaultReloadPaymentMethodId,
    setPrepaidReloadPaymentMethodId,
    threeDSAcsUrl,
    threeDSActiveFlow,
    threeDSChallengeRequest,
    threeDSIframeContent,
    threeDSTransactionId,
    transformPaymentValues,
    cleanThreeDSFlow,
    setThreeDSChallengeTokenResponse,
    threeDSChallengeTokenResponse,
    encryptionResult,
    setEncryptionResult,
  } = usePayment({
    getEncryptionDetailsMutation,
    mParticle,
    openErrorDialog,
    user,
    updateUserInfo,
    addCreditAccountMutation,
    userLoading,
  });

  return (
    <PaymentContext.Provider
      value={{
        addPaymentMethod,
        canUseApplePay,
        canUseGooglePay,
        checkoutPaymentMethod,
        checkoutPaymentMethodId,
        cleanThreeDSFlow,
        clearPaymentDetails,
        defaultPaymentMethodId,
        defaultReloadPaymentMethodId,
        deletePaymentMethod,
        getPaymentMethods,
        getBalanceFromPaymentMethods,
        getPrepaidPaymentMethod,
        getPrepaidCardNumber,
        getEncryptionDetails,
        hasGetPaymentMethodsError,
        isAdyen,
        isFirstData,
        isVrPayment,
        isOrbital,
        isWorldpay,
        loading,
        mergePrepaidCardBalances,
        paymentMethods,
        paymentProcessor,
        paymentDetails,
        prepaidReloadPaymentMethodId,
        reloadPrepaidCard,
        setCheckoutPaymentMethodId,
        setDefaultPaymentMethodId,
        setDefaultReloadPaymentMethodId,
        setPrepaidReloadPaymentMethodId,
        storePaymentDetails,
        threeDS: {
          iframeContent: threeDSIframeContent,
          transactionId: threeDSTransactionId,
          activeFlow: threeDSActiveFlow,
          acsUrl: threeDSAcsUrl,
          challengeRequest: threeDSChallengeRequest,
          isChallengeRequest,
          isThreeDSMethodFlow,
          setThreeDSChallengeTokenResponse,
          threeDSChallengeTokenResponse,
        },
        transformPaymentValues,
        encryptionResult,
        setEncryptionResult,
      }}
    >
      {props.children}

      <ErrorDialog />
    </PaymentContext.Provider>
  );
}

export default PaymentContext.Consumer;
