import React, { Suspense, memo, useEffect } from 'react';

import { Box, View } from '@rbilabs/universal-components';
import { isNil } from 'lodash-es';
import { useIntl } from 'react-intl';
import { Navigate } from 'react-router-dom';
import { isFalse } from 'utils';

import useLogPageView from 'components/layout/use-log-page-view';
import { QuestLibrary } from 'components/quests/quests-library';
import SetLoading from 'components/set-loading';
import { TopServiceModeNotification } from 'components/top-service-mode-notification';
import { useIsTopServiceModeEnabled } from 'experiments/service-mode/use-is-top-servie-mode-enabled';
import useAuthRedirects from 'hooks/auth/use-auth-redirects';
import { useEffectOnUrlChange } from 'hooks/use-effect-on-url-change';
// import { useSetUserLocale } from 'hooks/use-set-user-locale';
import { AccountDrawer } from 'pages/account/account-drawer';
import { LoyaltyOfferDetail } from 'pages/loyalty/loyalty-offers/loyalty-offer-detail';
import LoyaltyOptInModal from 'pages/loyalty/loyalty-opt-in-modal';
import { LaunchDarklyFlag, useFlag } from 'state/launchdarkly';
import { Route, Routes, useMatch } from 'state/location';
import { useLoyaltyContext } from 'state/loyalty';
import { useIsLoyaltyEnabled } from 'state/loyalty/hooks/use-is-loyalty-enabled';
import { useOrderContext } from 'state/order';
import { useScrollContext } from 'state/scroll';
import { useUIContext } from 'state/ui';
import { getAllLocalizedVersionsOfRoute, routes } from 'utils/routing';

import brandRoutes from './brand-routes';
import { CookieBannerContainer } from './cookie-banner-container';
import { HelmetHTML } from './helmet-html';
// import { useLayoutLanguageSelectorModal } from './use-layout-language-selector-modal';
import { ScrollContainer } from './scroll-container';
import { LazyRoute, lazyWithFallback } from './util';

const Cart: LazyRoute = lazyWithFallback(() => import('pages/cart'));
const MainMenu: LazyRoute = lazyWithFallback(() => import('pages/main-menu'));
const MenuContent: LazyRoute = lazyWithFallback(() => import('pages/menu-content'));
const Home: LazyRoute = lazyWithFallback(() => import('pages/home'));
const Account: LazyRoute = lazyWithFallback(() => import('pages/account'));
const CommPreferences: LazyRoute = lazyWithFallback(() => import('pages/account/comm-preferences'));
const AccountInfo: LazyRoute = lazyWithFallback(() => import('pages/account/account-info'));
const AccountDelete: LazyRoute = lazyWithFallback(() => import('pages/account/account-delete'));
const AccountRequestInfo: LazyRoute = lazyWithFallback(() =>
  import('pages/account/account-request-info')
);
const PaymentMethods: LazyRoute = lazyWithFallback(() => import('pages/account/payment-methods'));
const AccountPaymentMethods: LazyRoute = lazyWithFallback(() =>
  import('pages/account/payments/payment-methods')
);
const Diagnostics: LazyRoute = lazyWithFallback(() => import('pages/diagnostics'));
const AccountOrders: LazyRoute = lazyWithFallback(() => import('pages/account/account-orders'));
const Offers: LazyRoute = lazyWithFallback(() => import('pages/offers'));
const LoyaltyOffers: LazyRoute = lazyWithFallback(() => import('pages/loyalty/loyalty-offers'));
const LoyaltyRewards: LazyRoute = lazyWithFallback(() => import('pages/loyalty/loyalty-rewards'));
const LoyaltyDashboard: LazyRoute = lazyWithFallback(() =>
  import('pages/loyalty/loyalty-dashboard')
);
const LoyaltySurprise: LazyRoute = lazyWithFallback(() => import('pages/loyalty/loyalty-surprise'));
const LoyaltyInRestaurantRedemption: LazyRoute = lazyWithFallback(() =>
  import('pages/loyalty/loyalty-in-restaurant-redemption')
);
const LoyaltyClaimPoints: LazyRoute = lazyWithFallback(() =>
  import('pages/loyalty/loyalty-claim-points')
);
const LoyaltyLinkCard: LazyRoute = lazyWithFallback(() =>
  import('pages/loyalty/loyalty-link-card')
);
const SignupHome: LazyRoute = lazyWithFallback(() => import('pages/authentication/sign-up-home'));

const AuthChallengeScreenJwt: LazyRoute = lazyWithFallback(() =>
  import('pages/authentication/auth-email-jwt')
);
const ConfirmJwt: LazyRoute = lazyWithFallback(() => import('pages/authentication/confirm-jwt'));
const SignOut: LazyRoute = lazyWithFallback(() => import('pages/authentication/sign-out'));
const StoreLocator: LazyRoute = lazyWithFallback(() => import('pages/store-locator'));
const OrderConfirmation: LazyRoute = lazyWithFallback(() =>
  import('pages/order-confirmation/order-confirmation')
);
const StaticPage: LazyRoute = lazyWithFallback(() => import('pages/static'));
const NotFound: LazyRoute = lazyWithFallback(() => import('pages/not-found'));
const Pypestream: LazyRoute = lazyWithFallback(() => import('pages/pypestream'));
const SupportV2CategoriesPage: LazyRoute = lazyWithFallback(() =>
  import('pages/support-v2/support-v2-categories-page')
);
const SupportV2CategoryPage: LazyRoute = lazyWithFallback(() =>
  import('pages/support-v2/support-v2-category-page')
);
const SupportV2FormPage: LazyRoute = lazyWithFallback(() =>
  import('pages/support-v2/support-v2-form-page')
);
const SupportV2OrdersListPage: LazyRoute = lazyWithFallback(() =>
  import('pages/support-v2/support-v2-orders-list-page')
);
const ReportOrderIssuePage: LazyRoute = lazyWithFallback(() => import('pages/report-order-issue'));
const ReportOrderNeverArrivedPage: LazyRoute = lazyWithFallback(() =>
  import('pages/report-order-never-arrived')
);
const ReportOrderItemsMissingPage: LazyRoute = lazyWithFallback(() =>
  import('pages/report-order-items-missing')
);
const OfferActivatePage: LazyRoute = lazyWithFallback(() => import('pages/offer-activate'));
const SendGiftPage: LazyRoute = lazyWithFallback(() => import('pages/send-gift'));
const History = lazyWithFallback(() => import('pages/account/history'));
const FavoriteEditorPage = lazyWithFallback(() => import('pages/favorite-editor'));
const AddCreditCardModalEntryPoint = lazyWithFallback(() =>
  import('components/payments/entry-points/add-credit-card-modal')
);
const StoreInfoModalContainer: LazyRoute = lazyWithFallback(() =>
  import('components/store-info-modal')
);

const LayoutContainer = Box.withConfig({
  background: Styles.color.background,
  flexGrow: 1,
  _web: { flex: 1 },
});

const ContentContainer = Box.withConfig({
  background: Styles.color.background,
  flex: 1,
});

const RouterWrapper = Box.withConfig({
  background: Styles.color.background,
  flexGrow: 1,
  _web: { flex: 1 },
});

// OrderContext changes a lot, and we should isolate the effect of re-rendering
// into isolation. We do not want the OrderContext to cause a re-render of the entire layout.
const ClearServerOrderContainer = () => {
  const { clearServerOrder } = useOrderContext(); // @todo type order context

  useEffect(() => {
    // reset the server order when a user navigate into the menu no matter what
    clearServerOrder();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return null;
};

const ContentRoutingContainer = () => {
  // useGoogleAnalytics();
  // TODO: RN - web - may need to add this back for web, but removing for now
  // useSetUserLocale();

  const { formatMessage } = useIntl();
  const loyaltyEnabled = useIsLoyaltyEnabled();
  const { claimPointsEnabled, loyaltySurpriseOffersEnabled } = useLoyaltyContext();

  const enableSignUpFlag = useFlag(LaunchDarklyFlag.ENABLE_SIGN_UP);
  const enableSignUp = isNil(enableSignUpFlag) || enableSignUpFlag;
  const forceAuthOnHomepageFlag = useFlag(LaunchDarklyFlag.FORCE_HOMEPAGE_AUTH);
  const isBaseRoute = useMatch(routes.base);
  const forceAuthOnHomepage = forceAuthOnHomepageFlag && enableSignUp && isBaseRoute;

  useAuthRedirects({ skip: !forceAuthOnHomepage });

  return (
    <>
      <ClearServerOrderContainer />
      <ContentRouting
        formatMessage={formatMessage}
        claimPointsEnabled={claimPointsEnabled}
        loyaltyEnabled={loyaltyEnabled}
        loyaltySurpriseOffersEnabled={loyaltySurpriseOffersEnabled}
      />
    </>
  );
};

type ContentRoutingProps = {
  formatMessage: ReturnType<typeof useIntl>['formatMessage'];
  claimPointsEnabled: boolean;
  loyaltyEnabled: boolean;
  loyaltySurpriseOffersEnabled: boolean;
};

const ContentRouting = memo(
  ({
    formatMessage,
    claimPointsEnabled,
    loyaltyEnabled,
    loyaltySurpriseOffersEnabled,
  }: ContentRoutingProps) => {
    const enableOrderSupport = useFlag(LaunchDarklyFlag.ENABLE_ORDER_SUPPORT);
    const enableAccountDeletePage = useFlag(LaunchDarklyFlag.ENABLE_ACCOUNT_DELETE_PAGE);

    const enableRequestMyInformation = useFlag(LaunchDarklyFlag.ENABLE_REQUEST_MY_INFORMATION);
    const enableCommPrefFlag = useFlag(LaunchDarklyFlag.ENABLE_COMMUNICATION_PREFERENCES);
    const enableCommunicationPreferences = !isFalse(enableCommPrefFlag);

    const enableOfferGifting = useFlag(LaunchDarklyFlag.ENABLE_OFFER_GIFTING);

    const enableGuestAndMultiStepSupportForm = useFlag(
      LaunchDarklyFlag.ENABLE_GUEST_AND_MULTI_STEP_SUPPORT_PAGE
    );

    const enablePypestream = useFlag(LaunchDarklyFlag.ENABLE_PYPESTREAM);

    const enableSignUpFlag = useFlag(LaunchDarklyFlag.ENABLE_SIGN_UP);
    const enableSignUp = isNil(enableSignUpFlag) || enableSignUpFlag;

    const enableRewardPageStandAlone = useFlag(LaunchDarklyFlag.ENABLE_REWARD_PAGE_STAND_ALONE);

    const enableRewardsStaticPage = useFlag(LaunchDarklyFlag.ENABLE_REWARDS_STATIC_PAGE);
    const enableOffersStaticPage = useFlag(LaunchDarklyFlag.ENABLE_OFFERS_STATIC_PAGE);
    const enableQuests = useFlag(LaunchDarklyFlag.ENABLE_QUEST_TILES);
    const enableNewPaymentsFrontend = useFlag(LaunchDarklyFlag.ENABLE_NEW_PAYMENTS_FRONTEND);
    const isOffersV2Enabled = useFlag(LaunchDarklyFlag.ENABLE_LOYALTY_OFFERS_V2_FE);

    return (
      <>
        {/* Base Routes */}
        <ContentContainer>
          <RouterWrapper>
            <Routes>
              <Route path={'/:staticPagePath'} element={<StaticPage />} />
              <Route path={routes.notFound404} element={<NotFound />} />
              <Route path={routes.confirmJwt} element={<ConfirmJwt />} />
              {loyaltyEnabled && (
                <Route
                  path={`${formatMessage({ id: 'routes.loyaltyDashboard' })}`}
                  element={<LoyaltyDashboard />}
                />
              )}
              {loyaltySurpriseOffersEnabled && (
                <Route
                  path={`${formatMessage({ id: 'routes.loyaltyHome' })}/${formatMessage({
                    id: 'surprise',
                  })}`}
                  element={<LoyaltySurprise />}
                />
              )}
              <Route
                path={formatMessage({ id: 'routes.redemptionInRestaurant' })}
                element={<LoyaltyInRestaurantRedemption />}
              />
              <Route
                path={formatMessage({ id: 'routes.linkPhysicalCard' })}
                element={<LoyaltyLinkCard />}
              />
              {claimPointsEnabled && (
                <Route
                  path={`${formatMessage({ id: 'routes.claimPointsBase' })}/*`}
                  element={<LoyaltyClaimPoints />}
                />
              )}
              {enableSignUp && (
                <>
                  <Route path={formatMessage({ id: 'routes.signUp' })} element={<SignupHome />} />
                  {getAllLocalizedVersionsOfRoute('signUp').map((path, index) => (
                    <Route path={path} key={`sign-up-${index}`} element={<SignupHome />} />
                  ))}
                  <Route path={formatMessage({ id: 'routes.signIn' })} element={<SignupHome />} />
                  {getAllLocalizedVersionsOfRoute('signIn').map((path, index) => (
                    <Route path={path} key={`sign-in-${index}`} element={<SignupHome />} />
                  ))}
                  <Route path={routes.confirmOtp} element={<SignupHome />} />
                  <Route path={formatMessage({ id: 'routes.account' })} element={<Account />} />
                  {getAllLocalizedVersionsOfRoute('account').map((path, index) => (
                    <Route path={path} key={`account-${index}`} element={<Account />} />
                  ))}
                </>
              )}
              <Route path={routes.authChallengeJwt} element={<AuthChallengeScreenJwt />} />
              <Route path={routes.signOut} element={<SignOut />} />
              <Route path={routes.menu} element={<MainMenu />} />
              <Route path={routes.browseMenu} element={<MainMenu />} />

              {/*
                MenuContent handles the following routes:

                /menu/favorites
                /menu/recent-items
                /menu/section-:id
                /menu/picker-:id
                /menu/combo-:id
                /menu/item-:id
                /menu/* (Item Not Found)

              */}
              <Route path={`${routes.menu}/:id`} element={<MenuContent />} />
              <Route path={`${routes.browseMenu}/:id`} element={<MenuContent />} />

              <Route path={routes.base} element={<Home />} />
              {!enableOffersStaticPage && (
                <Route path={`${routes.offers}/*`} element={<Offers />} />
              )}
              {enableRewardPageStandAlone && (
                <Route
                  path={formatMessage({ id: 'routes.loyaltyHome' })}
                  element={<LoyaltyDashboard />}
                />
              )}

              {!enableRewardsStaticPage && (
                <>
                  <Route
                    path={`${formatMessage({ id: 'routes.loyaltyOfferList' })}`}
                    element={<LoyaltyOffers />}
                  />
                  <Route
                    path={`${formatMessage({ id: 'routes.loyaltyOfferList' })}/:offerEngineId`}
                    element={isOffersV2Enabled ? <LoyaltyOfferDetail /> : <LoyaltyOffers />}
                  />
                  <Route
                    path={formatMessage({ id: 'routes.loyaltyRewardList' })}
                    element={<LoyaltyRewards />}
                  />
                  <Route
                    path={`${formatMessage({ id: 'routes.loyaltyRewardList' })}/:rewardId`}
                    element={<LoyaltyRewards />}
                  />
                </>
              )}

              <Route path={routes.info} element={<AccountInfo />} />
              <Route
                path={`${formatMessage({ id: 'routes.rewardsHistory' })}`}
                element={<History />}
              />
              {enableCommunicationPreferences && (
                <Route path={routes.communication} element={<CommPreferences />} />
              )}
              <Route path={routes.orders} element={<AccountOrders />} />
              <Route path={`${routes.orders}/:rbiOrderId`} element={<AccountOrders />} />
              {enableAccountDeletePage && (
                <Route
                  path={formatMessage({ id: 'routes.accountDelete' })}
                  element={<AccountDelete />}
                />
              )}
              {enableRequestMyInformation && (
                <Route
                  path={formatMessage({ id: 'routes.accountRequestInfo' })}
                  element={<AccountRequestInfo />}
                />
              )}
              <Route
                path={`${routes.payment}/*`}
                element={enableNewPaymentsFrontend ? <AccountPaymentMethods /> : <PaymentMethods />}
              />
              {enableNewPaymentsFrontend && (
                <Route path={routes.addCard} element={<AddCreditCardModalEntryPoint />} />
              )}
              <Route path={`${routes.diagnostics}/*`} element={<Diagnostics />} />
              {enableOrderSupport && (
                <Route
                  path={`${routes.reportOrderIssue}/:orderId`}
                  element={<ReportOrderIssuePage />}
                />
              )}
              {enableOrderSupport && (
                <Route
                  path={`${routes.missingOrder}/:orderId`}
                  element={<ReportOrderNeverArrivedPage />}
                />
              )}
              {enableOrderSupport && (
                <Route
                  path={`${routes.missingItems}/:orderId`}
                  element={<ReportOrderItemsMissingPage />}
                />
              )}
              {enableOfferGifting && (
                <>
                  <Route
                    path={formatMessage({ id: 'routes.sendGift' })}
                    element={<SendGiftPage />}
                  />
                </>
              )}
              <Route
                path={`${routes.offerActivate}/:tokenId/:offerId`}
                element={<OfferActivatePage />}
              />
              {enablePypestream ? (
                // show pypestream and redirect all other support links to pypestream
                <>
                  <Route path={routes.support} element={<Pypestream />} />
                  <Route
                    path={`${routes.support}/*`}
                    element={<Navigate replace to={routes.support} />}
                  />
                </>
              ) : enableGuestAndMultiStepSupportForm ? (
                // general support requests
                <>
                  {/* // general support requests */}
                  <Route path={routes.supportForm} element={<SupportV2FormPage />} />
                  <Route path={routes.supportOrders} element={<SupportV2OrdersListPage />} />
                  <Route path={routes.supportCategories} element={<SupportV2CategoriesPage />} />
                  <Route path={routes.supportCategory} element={<SupportV2CategoryPage />} />
                  <Route path={routes.support} element={<SupportV2CategoriesPage />} />
                </>
              ) : null}

              <Route path={routes.favoriteEditor} element={<FavoriteEditorPage />} />

              {/* We only want brand-specific routes to be available for those brands */}
              {/* `brandRoutes` can be thought of a Map of routes (string) to Components (React component) */}
              {Object.entries(brandRoutes).map(([path, Component]) => (
                <Route
                  path={formatMessage({ id: path })}
                  element={<Component key={path} />}
                  key={path}
                />
              ))}
              {enableQuests && <Route path={routes.questLibrary} element={<QuestLibrary />} />}
            </Routes>
          </RouterWrapper>
        </ContentContainer>
      </>
    );
  }
);

type LayoutProps = {
  formatMessage: ReturnType<typeof useIntl>['formatMessage'];
  enableTopServiceMode: boolean;
};

const Layout = memo(({ formatMessage, enableTopServiceMode }: LayoutProps) => {
  const { isAccountDrawerOpen, setIsAccountDrawerOpen } = useUIContext();

  const handleAccountDrawerClose = () => {
    setIsAccountDrawerOpen(false);
  };

  return (
    <ScrollContainer>
      <LayoutContainer>
        <Suspense fallback={<SetLoading />}>
          <Routes>
            <Route path={`${routes.cart}/*`} element={<Cart />} />
            <Route
              path={`${routes.orderConfirmation}/:rbiOrderId`}
              element={<OrderConfirmation />}
            />
            <Route path={`${routes.storeLocator}/*`} element={<StoreLocator />} />
            <Route
              path={`${formatMessage({ id: 'routes.storeLocator' })}/*`}
              element={<StoreLocator />}
            />
            <Route path={`${routes.store}/:id`} element={<StoreInfoModalContainer />} />
            {/* Regular routes (with normal nav, header, footer, etc) */}
            <Route path="*" element={<ContentRoutingContainer />} />
          </Routes>
        </Suspense>
      </LayoutContainer>

      <View>
        <CookieBannerContainer />
        {enableTopServiceMode && <TopServiceModeNotification />}
      </View>

      {/* Modals and things that don't render "In flow" */}
      <HelmetHTML />
      <LoyaltyOptInModal />
      <AccountDrawer isOpen={isAccountDrawerOpen} onClose={handleAccountDrawerClose} />
    </ScrollContainer>
  );
});

export default function LayoutContainerComponent() {
  const { scrollTo } = useScrollContext();
  const enableTopServiceMode = useIsTopServiceModeEnabled();

  // Track all page views on mParticle
  useLogPageView();

  useEffectOnUrlChange(() => {
    scrollTo({ animated: false });
  });

  const { formatMessage } = useIntl();

  return <Layout formatMessage={formatMessage} enableTopServiceMode={enableTopServiceMode} />;
}
