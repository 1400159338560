import React from 'react';

import delv from 'dlv';
import { Platform } from 'react-native';

import { ISanityImage } from '@rbi-ctg/menu';
import { Helmet } from 'components/helmet';
import { fullBrandName } from 'utils/environment';

interface IStoreMeta {
  phoneNumber: string;
  city: string;
  state: string;
  postalCode: string;
  streetAddress: string;
  atCopy: string;
  image?: ISanityImage;
}

interface ILdJsonRestaurant {
  '@context': 'http://schema.org';
  '@type': 'Restaurant';
  address: {
    '@type': 'PostalAddress';
    addressLocality: string;
    addressRegion: string;
    postalCode: string;
    streetAddress: string;
  };
  name: string;
  telephone: string;
  url: string;
  image?: string;
}

const StoreMeta: React.FC<React.PropsWithChildren<IStoreMeta>> = ({
  phoneNumber,
  city,
  state,
  postalCode,
  streetAddress,
  atCopy,
  image,
}) => {
  if (Platform.OS !== 'web') {
    return null;
  }

  const ldJsonRestauranData: ILdJsonRestaurant = {
    '@context': 'http://schema.org',
    '@type': 'Restaurant',
    address: {
      '@type': 'PostalAddress',
      addressLocality: city,
      addressRegion: state,
      postalCode,
      streetAddress,
    },
    name: `${fullBrandName()} ${atCopy} ${streetAddress}`,
    telephone: phoneNumber,
    url: window.location.href,
  };

  if (image) {
    ldJsonRestauranData.image = delv(image, 'asset.url');
  }

  return (
    <Helmet>
      <title>{streetAddress}</title>
      <script type="application/ld+json">{JSON.stringify(ldJsonRestauranData)}</script>
    </Helmet>
  );
};

export default StoreMeta;
