import { convertCountryStringToIsoCountryCode2Char } from '@rbilabs/common';

import { SupportedRegions } from '@rbi-ctg/frontend';
import { IRestaurantNode } from 'generated/rbi-graphql';

export const getRestaurantCountry2Char = (restaurant: IRestaurantNode) =>
  convertCountryStringToIsoCountryCode2Char(restaurant?.physicalAddress?.country || '');

export const restaurantCountryMatchesRegion = (
  restaurant: IRestaurantNode,
  region: SupportedRegions
) => getRestaurantCountry2Char(restaurant) === region;
