import React, { useCallback, useMemo, useRef } from 'react';

import { Box, IconVariant, ScrollView, VStack } from '@rbilabs/universal-components';
import { isNil } from 'lodash-es';
import { useIntl } from 'react-intl';
import { useSafeAreaInsets } from 'react-native-safe-area-context';

import ActionButton from 'components/action-button';
import { Helmet } from 'components/helmet';
import LoadingAnimation from 'components/loading-animation';
import LoadingContainer from 'components/loading-animation/loading-container';
import { SAFE_AREA_TOP_INSET } from 'components/modal';
import ModalScreen from 'components/modal/modal-screen';
import Picture from 'components/picture';
import StaticMap from 'components/static-map';
import { useStoreClosedActionSheet } from 'components/store-closed-action-sheet';
import { IRestaurantNode, useGetRestaurantQuery } from 'generated/rbi-graphql';
import { useGetRestaurantsQuery } from 'generated/sanity-graphql';
import { useConfigValue } from 'hooks/configs/use-config-value';
import { useNavigation } from 'hooks/navigation/use-navigation';
import { useRoute } from 'hooks/navigation/use-route';
import { useSelectRestaurant } from 'hooks/store-card';
import useErrorModal from 'hooks/use-error-modal';
import { useReactNavigationFlag } from 'hooks/use-react-navigation-flag';
import { useServiceModeStatus } from 'hooks/use-service-mode-status';
import { useGeolocation } from 'state/geolocation';
import { useFlag } from 'state/launchdarkly';
import { useOrderContext } from 'state/order';
import { hiddenAccessibilityPlatformProps } from 'utils/accessibility';
import { LaunchDarklyFlag } from 'utils/launchdarkly';
import {
  getUSState,
  mergeRestaurantData,
  readableTimeInterval,
  useIsMobileOrderingAvailable,
} from 'utils/restaurant';
import { TWELVE_HOUR_TIME_PARSE_FORMAT } from 'utils/restaurant/constants';
import { routes } from 'utils/routing';
import { getStoreSEOSlug } from 'utils/slugify';

import { DirectionsLink } from './directions-link';
import StoreMeta from './meta';
import {
  Address,
  Amenity,
  AmenityIcon,
  Background,
  ButtonContainer,
  CtaWrapper,
  FeatureIcon,
  HeaderContainer,
  Inner,
  InnerHeader,
  InnerText,
  PhoneNumber,
  SpacingLine,
  StoreHourContainer,
} from './styled';
import theme from './theme';
import { IHoursProps, IStoreInfoModalContainerProps, IStoreInfoModalProps } from './types';

const Hours = ({ title, hours }: IHoursProps) => {
  const { formatMessage } = useIntl();
  const timeFormatConfig = useConfigValue({
    key: 'timeFormat',
    defaultValue: TWELVE_HOUR_TIME_PARSE_FORMAT,
  });
  const hoursOfOperation = readableTimeInterval({
    fallbackMessage: formatMessage({ id: 'hoursOfOperationFallbackMessage' }),
    open24HoursMessage: formatMessage({ id: 'open24Hours' }),
    timeFormat: timeFormatConfig,
  });

  const storeHoursSummary: [string, string][] = [
    ['monday', 'mon'],
    ['tuesday', 'tue'],
    ['wednesday', 'wed'],
    ['thursday', 'thr'],
    ['friday', 'fri'],
    ['saturday', 'sat'],
    ['sunday', 'sun'],
  ];

  if (!hours) {
    return null;
  }

  return (
    <Box>
      <InnerHeader variant="headerThree" marginY="$2">
        {title}
      </InnerHeader>
      {storeHoursSummary.map(([messageId, abbreviatedDay]) => {
        const openingHours = hours[`${abbreviatedDay}Open`];
        const closingHours = hours[`${abbreviatedDay}Close`];
        return (
          <StoreHourContainer key={messageId}>
            <InnerText>{formatMessage({ id: messageId })}</InnerText>
            <InnerText>{hoursOfOperation(openingHours, closingHours)}</InnerText>
          </StoreHourContainer>
        );
      })}
    </Box>
  );
};

export const StoreInfoModal = ({
  disableCanonical = false,
  mParticleEventData,
  onDismiss,
  showOrderCTA = true,
  store,
}: IStoreInfoModalProps) => {
  const {
    name: storeName,
    phoneNumber,
    restaurantImage,
    latitude: lat,
    longitude: lng,
    physicalAddress,
  } = store;
  const { current: position } = useRef({ lat, lng });
  const { activeCoordinates } = useGeolocation();
  const { pathname } = useRoute();
  const { formatMessage } = useIntl();
  const { fetchingPosData } = useOrderContext();
  const {
    restaurantCanBeSelected,
    serviceModeStatus,
    allServiceModesUnavailable,
  } = useServiceModeStatus(store);
  const enableOrderingFlag = useFlag(LaunchDarklyFlag.ENABLE_ORDERING);
  const enableAmenitiesSection = useFlag(LaunchDarklyFlag.ENABLE_AMENITIES_SECTION);
  const enableHideOrderHereButton = useFlag(
    LaunchDarklyFlag.ENABLE_HIDE_STORE_MODAL_ORDER_HERE_BUTTON
  );
  const enableShowAddress2BeforeCity = useFlag(LaunchDarklyFlag.ENABLE_SHOW_ADDRESS2_BEFORE_CITY);
  const enableOrdering = isNil(enableOrderingFlag) || enableOrderingFlag;
  const enableReactNativeNavigation = useReactNavigationFlag();

  const { selectRestaurant } = useSelectRestaurant({ restaurant: store });
  const { top: topInset } = useSafeAreaInsets();

  const streetAddress = physicalAddress?.address1;
  const address2 = physicalAddress?.address2;
  const formattedCity = physicalAddress?.city;
  const state = getUSState(physicalAddress?.stateProvince);
  const postalCode = physicalAddress?.postalCode?.split('-')[0];
  const shouldShowWaysToOrderSection =
    useIsMobileOrderingAvailable(store) || !allServiceModesUnavailable;

  const canonicalUrl = [`${routes.store}`, getStoreSEOSlug(store)].join('/');
  const isStoreLocatorPage = pathname.includes(routes.store);

  const shouldShowDineIn = serviceModeStatus.EAT_IN.capable && !serviceModeStatus.EAT_IN.disabled;
  const shouldShowTakeout =
    serviceModeStatus.TAKEOUT.capable && !serviceModeStatus.TAKEOUT.disabled;
  const shouldShowDriveThru =
    serviceModeStatus.DRIVE_THRU.capable && !serviceModeStatus.DRIVE_THRU.disabled;
  const shouldShowCurbside =
    serviceModeStatus.CURBSIDE.capable && !serviceModeStatus.CURBSIDE.disabled;

  const showAddress2 = enableShowAddress2BeforeCity && address2;

  const hasCatering =
    serviceModeStatus.CATERING_DELIVERY.available || serviceModeStatus.CATERING_PICKUP.available;

  const amenitiesBreakdown: [IconVariant, boolean, string, string][] = [
    ['driveThru', serviceModeStatus.DRIVE_THRU.available, '16px', 'driveThru'],
    ['mobile', useIsMobileOrderingAvailable(store), '22px', 'mobileOrdering'],
    ['delivery', serviceModeStatus.DELIVERY.available, '22px', 'delivery'],
    ['curbside', serviceModeStatus.CURBSIDE.available, '22px', 'curbside'],
    ['catering', hasCatering, '20px', 'catering'],
  ];

  const storeHoursBreakdown = [
    [shouldShowDineIn || shouldShowTakeout, 'dineInHours', 'diningRoomHours'],
    [shouldShowDriveThru, 'driveThruHours'],
    [shouldShowCurbside, 'curbsideHours'],
  ];

  const orderButtonEnabled = restaurantCanBeSelected && !fetchingPosData;

  const amenitiesSection = useMemo(() => {
    if (!store.amenities?.length || !enableAmenitiesSection) {
      return null;
    }

    const amenitiesWithIconSrc = store.amenities.filter(amenity => amenity?.icon?.asset?.url);

    if (!amenitiesWithIconSrc.length) {
      return null;
    }

    return (
      <VStack testID="restaurant-amenities">
        <InnerHeader variant="headerThree" testID="in-store-amenities">
          {formatMessage({ id: 'inStoreAmenities' })}
        </InnerHeader>
        {amenitiesWithIconSrc.map(amenity => {
          const name = amenity?.name?.locale ?? '';
          return (
            <Amenity key={name}>
              <FeatureIcon src={amenity?.icon?.asset?.url} alt={name} />
              <InnerText>{name}</InnerText>
            </Amenity>
          );
        })}
      </VStack>
    );
  }, [enableAmenitiesSection, formatMessage, store]);

  const headerOffsetProps = enableReactNativeNavigation
    ? {}
    : {
        marginTop: -SAFE_AREA_TOP_INSET,
        _ios: { marginTop: -topInset },
      };

  const { navigate } = useNavigation();
  const {
    requestScheduleFutureOrderingConfirmation,
    storeClosedActionSheet,
  } = useStoreClosedActionSheet({
    restaurant: (store as unknown) as IRestaurantNode,
  });
  const handleOnSelectStore = useCallback(async () => {
    if (!(await requestScheduleFutureOrderingConfirmation())) {
      navigate(routes.storeLocator);
      return;
    }
    selectRestaurant();
    // In case we have unavailable cart entries, we need to return to the StoreLocator so the unavailable dialog can be triggered
    if (enableReactNativeNavigation) {
      onDismiss?.();
    }
  }, [
    requestScheduleFutureOrderingConfirmation,
    selectRestaurant,
    enableReactNativeNavigation,
    navigate,
    onDismiss,
  ]);

  return (
    <>
      {!disableCanonical && (
        <Helmet testID="canonical" link={[{ rel: 'canonical', href: canonicalUrl }]} />
      )}
      {/* TODO: RN - define width: Styles.layout.smallerSectionMaxWidth for large devices */}

      <ModalScreen
        backgroundColor={theme.modalBackground}
        onDismiss={onDismiss}
        testID="store-info-modal"
        mParticleEventData={mParticleEventData}
        showFixedHeader
        header={
          <HeaderContainer {...headerOffsetProps}>
            {restaurantImage ? (
              <Picture image={restaurantImage} alt={storeName} />
            ) : (
              <StaticMap initialZoomIOS={8000} position={position} />
            )}
          </HeaderContainer>
        }
        footer={
          showOrderCTA &&
          enableOrdering && (
            <ButtonContainer>
              <CtaWrapper>
                {!enableHideOrderHereButton && (
                  <ActionButton
                    isLoading={fetchingPosData}
                    testID="store-modal-order-here"
                    fullWidth
                    disabled={!orderButtonEnabled}
                    onPress={handleOnSelectStore}
                  >
                    {formatMessage({ id: 'orderHere' })}
                  </ActionButton>
                )}
              </CtaWrapper>
            </ButtonContainer>
          )
        }
      >
        <>
          {isStoreLocatorPage && (
            <StoreMeta
              atCopy={formatMessage({ id: 'at' })}
              streetAddress={streetAddress}
              city={formattedCity}
              state={state}
              postalCode={postalCode}
              phoneNumber={phoneNumber}
              image={restaurantImage}
            />
          )}

          <ScrollView>
            <Background>
              <Inner>
                <InnerHeader variant="headerTwo" testID="address" nativeID="modal-heading">
                  {streetAddress.toLowerCase()}
                </InnerHeader>
                <Box>
                  <DirectionsLink activeCoordinates={activeCoordinates} mapPosition={position} />
                  <Address>
                    {showAddress2 && <Address testID="address2">{address2}, </Address>}
                    {`${formattedCity}${state ? ` ${state}` : ''}, ${postalCode}`}
                  </Address>
                  {phoneNumber && (
                    <PhoneNumber testID="phoneNumber" to={`tel:${phoneNumber}`}>
                      {phoneNumber}
                    </PhoneNumber>
                  )}
                </Box>
                <SpacingLine marginTop="$6" bottom="$3" />
                {enableOrdering && (
                  <>
                    {shouldShowWaysToOrderSection && (
                      <InnerHeader variant="headerThree">
                        {formatMessage({ id: 'waysToOrder' })}
                      </InnerHeader>
                    )}
                    {amenitiesBreakdown.map(
                      ([icon, shouldShow, height, messageId]) =>
                        shouldShow && (
                          <Amenity key={messageId}>
                            <AmenityIcon
                              variant={icon}
                              width="40px"
                              height={height}
                              {...hiddenAccessibilityPlatformProps}
                            />
                            <InnerText>{formatMessage({ id: messageId })}</InnerText>
                          </Amenity>
                        )
                    )}
                    {shouldShowWaysToOrderSection && (
                      <SpacingLine marginTop="$1" marginBottom="$4" />
                    )}
                  </>
                )}

                {amenitiesSection}
                {storeHoursBreakdown.map(
                  // @ts-expect-error TS(2345) FIXME: Argument of type '([shouldShow, messageId, hours]:... Remove this comment to see the full error message
                  ([shouldShow, messageId, hours = messageId]: [boolean, string, string]) =>
                    shouldShow && (
                      <Hours
                        key={messageId}
                        title={formatMessage({ id: messageId })}
                        hours={store[hours]}
                      />
                    )
                )}
              </Inner>
            </Background>
          </ScrollView>
          {storeClosedActionSheet}
        </>
      </ModalScreen>
    </>
  );
};

const StoreInfoModalContainer = (props: IStoreInfoModalContainerProps) => {
  const { params } = useRoute<{ id?: string; storeNumber?: string }>();
  const { id: storeIdFromUrl, storeNumber: storeNumberFromUrl } = params;
  const enableReactNativeNavigation = useReactNavigationFlag();

  const id = props.storeId || storeIdFromUrl;
  const storeNumber = props.storeNumber || storeNumberFromUrl;

  const [ErrorDialog] = useErrorModal({
    init: true,
    modalAppearanceEventMessage: 'Error: Fetching Restaurant Info Failure',
  });
  const getRestaurantFromSanity = useGetRestaurantsQuery({
    skip: !storeNumber && !id,
    variables: {
      filter: {
        _id: id,
        number: storeNumber,
      },
      limit: 1,
    },
  });
  const secondQueryVariables = useMemo(
    () => ({
      storeId: storeNumber || getRestaurantFromSanity?.data?.allRestaurants[0].number,
    }),
    [storeNumber, getRestaurantFromSanity]
  );
  const getRestaurantFromRBI = useGetRestaurantQuery({
    fetchPolicy: 'cache-and-network',
    skip: !secondQueryVariables.storeId,
    variables: secondQueryVariables,
  });

  const { restaurant, error, loading } = useMemo(
    () => ({
      restaurant:
        getRestaurantFromRBI.data &&
        getRestaurantFromSanity.data &&
        getRestaurantFromSanity.data.allRestaurants.length !== 0
          ? mergeRestaurantData({
              rbiRestaurant: getRestaurantFromRBI.data.restaurant,
              sanityStore: getRestaurantFromSanity.data.allRestaurants[0] as any,
            })
          : undefined,
      error: getRestaurantFromRBI.error || getRestaurantFromSanity.error || undefined,
      loading: getRestaurantFromRBI.loading || getRestaurantFromSanity.loading,
    }),
    [getRestaurantFromRBI, getRestaurantFromSanity]
  );

  const { goBack } = useNavigation();

  const onDismiss = useCallback(() => {
    goBack();
  }, [goBack]);

  if (error) {
    return <ErrorDialog />;
  }

  if (loading || !restaurant) {
    return enableReactNativeNavigation ? (
      <LoadingContainer>
        <LoadingAnimation />
      </LoadingContainer>
    ) : null;
  }

  return (
    <StoreInfoModal
      {...props}
      onDismiss={onDismiss}
      mParticleEventData={{ modalAppearanceEventMessage: 'Store Details' }}
      store={restaurant}
    />
  );
};

export default StoreInfoModalContainer;
