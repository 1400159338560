import { Box, Text, VStack } from '@rbilabs/universal-components';

export const Container = VStack.withConfig({
  alignItems: 'center',
  marginX: 'auto',
  maxWidth: {
    base: '280px',
    md: '500px',
  },
});

export const ActionsContainer = VStack.withConfig({
  marginY: '$8',
  width: 'full',
  alignItems: 'center',
});

export const ActionButtonWrapper = Box.withConfig<{ show: boolean }>(p => ({
  display: p.show ? 'flex' : 'none',
  marginTop: '$4',
}));

export const P = Text.withConfig({
  textAlign: 'center',
});
