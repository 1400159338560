import React from 'react';

import { HStack, Text, VStack } from '@rbilabs/universal-components';
import { useIntl } from 'react-intl';

import ActionButton, { ActionButtonSizes, ActionButtonVariants } from 'components/action-button';
import { StoreCardError } from 'pages/store-locator/new-ui/store-card/components';
import { useServiceModeText } from 'pages/store-locator/new-ui/store-card/hooks/use-service-mode-text';

import { LocatorTileCaption } from './components/locator-tile-caption';
import { LocatorTileCTAs } from './components/locator-tile-ctas';
import { LocatorTileTitle, LocatorTileWrapper } from './locator-tile.styled';
import type { ILocatorTileViewProps } from './types';

export const LocatorTileView = ({
  restaurant,
  isSelected,
  isMobileOrderingAvailable,
  onPressButton,
}: ILocatorTileViewProps) => {
  const { formatMessage } = useIntl();
  const serviceModeText = useServiceModeText({ restaurant });

  return (
    <LocatorTileWrapper highlight={isSelected}>
      <HStack justifyContent="space-between">
        <VStack space={1}>
          <LocatorTileTitle>{restaurant.physicalAddress?.address1}</LocatorTileTitle>
          <LocatorTileCaption restaurant={restaurant} />
          <Text fontSize="xs">{serviceModeText}</Text>
        </VStack>

        <LocatorTileCTAs restaurant={restaurant} />
      </HStack>

      {isMobileOrderingAvailable ? (
        <ActionButton
          alignSelf="flex-end"
          size={ActionButtonSizes.SMALL}
          onPress={onPressButton}
          variant={isSelected ? ActionButtonVariants.PRIMARY : ActionButtonVariants.OUTLINE}
        >
          {isSelected
            ? formatMessage({ id: 'continueOrder', description: '' })
            : formatMessage({ id: 'selectStore', description: '' })}
        </ActionButton>
      ) : (
        <StoreCardError
          message={formatMessage({ id: 'mobileOrderingUnavailable', description: '' })}
        />
      )}
    </LocatorTileWrapper>
  );
};
