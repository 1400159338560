import React from 'react';
import type { ReactNode } from 'react';

import { Box, BrandLogo, HStack, Icon, Image, Text, VStack } from '@rbilabs/universal-components';
import { useIntl } from 'react-intl';
import { ImageBackground, StyleSheet, View } from 'react-native';

import ActionButton, { ActionButtonVariants } from 'components/action-button';
import { useBrandLogoVariant } from 'hooks/use-brand-logo-variant';
import { useAuthContext } from 'state/auth';
import { LaunchDarklyFlag, useFlag } from 'state/launchdarkly';

import type { ExperimentVariant } from '../types';

function BackgroundLayer() {
  return (
    <View style={StyleSheet.absoluteFill}>
      <ImageBackground
        source={require('./bricks.png')}
        style={StyleSheet.absoluteFill}
        imageStyle={{ opacity: 0.2 }}
      />

      <View style={{ position: 'absolute', bottom: 0, height: 315, left: 0, right: 0 }}>
        <ImageBackground source={require('./plank.jpg')} style={[StyleSheet.absoluteFill]} />

        <Image
          source={require('./sandwich.png')}
          alt="Sandwich"
          resizeMode="contain"
          width="100%"
          position="relative"
          top={-250}
        />
      </View>
    </View>
  );
}

function Greeting() {
  const { isAuthenticated, user } = useAuthContext();

  if (isAuthenticated && user) {
    return (
      <Text variant="hero" fontSize={40} lineHeight={40} textAlign="center">
        Welcome back,{'\n'}
        <Text color="token.text-active" lineHeight={40}>
          {user.details.name}
        </Text>
        .
      </Text>
    );
  }

  return (
    <Text variant="hero" fontSize={40} lineHeight={40} textAlign="center">
      Order & pay{'\n'}
      <Text color="token.text-active" lineHeight={40}>
        online
      </Text>
      .
    </Text>
  );
}

function ForegroundLayer(props: { children: ReactNode }) {
  const { brandLogoWordmarkVariant } = useBrandLogoVariant();

  return (
    <View style={StyleSheet.absoluteFill}>
      <BrandLogo marginTop="56px" marginX="auto" variant={brandLogoWordmarkVariant} width="230px" />

      <Box marginTop="56px">
        <Greeting />
      </Box>

      {props.children}
    </View>
  );
}

function SingleOrderingCtaTreatment() {
  const { formatMessage } = useIntl();

  return (
    <>
      <HStack space="$2" position="absolute" bottom="112px" left="$4" right="$4">
        <ActionButton to={formatMessage({ id: 'routes.storeLocator' })} flexGrow={2}>
          Start Order
        </ActionButton>
        <ActionButton
          flexGrow={1}
          leftIcon={<Icon variant="qr" size="4" />}
          variant={ActionButtonVariants.OUTLINE_REVERSED}
          to={formatMessage({ id: 'routes.redemptionInRestaurant' })}
        >
          Scan
        </ActionButton>
      </HStack>
    </>
  );
}

function SeparateOrderingCtasTreatment() {
  const { formatMessage } = useIntl();

  const isCateringEnabled = useFlag(LaunchDarklyFlag.ENABLE_CATERING);
  const isDeliveryEnabled = useFlag(LaunchDarklyFlag.ENABLE_DELIVERY);

  return (
    <VStack space="$2" position="absolute" bottom="$13.5" left="$4" right="$4">
      <ActionButton
        leftIcon={<Icon variant="cart" size="4" />}
        variant={ActionButtonVariants.INVERSE}
        to={formatMessage({ id: 'routes.storeLocator' })}
      >
        Pick Up Order
      </ActionButton>
      {isDeliveryEnabled && (
        <ActionButton
          leftIcon={<Icon variant="delivery" size="4" />}
          variant={ActionButtonVariants.INVERSE}
          to={formatMessage({ id: 'routes.storeLocator.address' })}
        >
          Delivery Order
        </ActionButton>
      )}
      {isCateringEnabled && (
        <ActionButton
          leftIcon={<Icon variant="catering" size="4" />}
          variant={ActionButtonVariants.INVERSE}
          to={formatMessage({ id: 'routes.storeLocator.serviceModeCatering' })}
        >
          Catering Order
        </ActionButton>
      )}
    </VStack>
  );
}

function getTreatment(experimentVariant: ExperimentVariant) {
  switch (experimentVariant) {
    case 'single-ordering-cta': {
      return <SingleOrderingCtaTreatment />;
    }

    case 'separate-ordering-ctas': {
      return <SeparateOrderingCtasTreatment />;
    }

    default: {
      throw new Error(`Treatment component not defined for ${experimentVariant}`);
    }
  }
}

type LandingPageProps = {
  experimentVariant: ExperimentVariant;
};

export function LandingPage(props: LandingPageProps) {
  return (
    <View style={{ position: 'relative', flexGrow: 1 }}>
      <BackgroundLayer />
      <ForegroundLayer>{getTreatment(props.experimentVariant)}</ForegroundLayer>
    </View>
  );
}
