import { HeartBeatSvg } from 'components/svgs/heart-beat';

import { CluckNorrisArrowRightSvg } from '../../svgs/cluck-norris-arrow-right';
import { PopeyesStreetSignSvg } from '../../svgs/popeyes-street-sign';
import { StarsSvg } from '../../svgs/stars';

export const DEFAULT_ICON = StarsSvg;
export const NEARBY_ERROR_ICON = StarsSvg;
export const NEARBY_NO_STORES_ICON = StarsSvg;
export const NEARBY_NO_LOCATION_ICON = PopeyesStreetSignSvg;
export const FAVORITES_ERROR_ICON = StarsSvg;
export const FAVORITES_NO_AUTH_ICON = HeartBeatSvg;
export const FAVORITES_NO_STORES_ICON = HeartBeatSvg;
export const RECENT_ERROR_ICON = StarsSvg;
export const RECENT_NO_AUTH_ICON = CluckNorrisArrowRightSvg;
export const RECENT_NO_STORES_ICON = CluckNorrisArrowRightSvg;
