import { Geography } from '@rbilabs/common';
import { useIntl } from 'react-intl';

import { IRestaurantNode } from 'generated/rbi-graphql';
import { useGeolocation } from 'state/geolocation';
import { useLocale } from 'state/intl';
import { readableDistanceFromStore } from 'utils/restaurant';

interface UserDistanceTextOptions {
  restaurant: IRestaurantNode;
}

export const useDistanceText = ({
  restaurant: { latitude, longitude },
}: UserDistanceTextOptions) => {
  const { activeCoordinates } = useGeolocation();
  const { formatMessage } = useIntl();
  const { region } = useLocale();

  let distanceText;

  if (activeCoordinates && latitude && longitude) {
    const distance = Geography.coordinateDistance(
      { latitude: activeCoordinates.lat, longitude: activeCoordinates.lng },
      {
        latitude,
        longitude,
      }
    );
    distanceText = readableDistanceFromStore(distance, region, formatMessage);
  }

  return distanceText;
};
