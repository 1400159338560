import { useCallback, useMemo, useState } from 'react';

import { useIntl } from 'react-intl';

import { IRestaurantNode } from 'generated/rbi-graphql';
import { useNavigation } from 'hooks/navigation/use-navigation';
import { useDefaultServiceMode, useSelectRestaurant } from 'hooks/store-card';
import { useServiceModeStatus } from 'hooks/use-service-mode-status';
import useStoreCardButtonOptions from 'hooks/use-store-card-button-options';
import { actions, useAppDispatch } from 'state/global-state';
import { useLocale } from 'state/intl';
import { LaunchDarklyFlag, useFlag } from 'state/launchdarkly';
import { StoreCardButtonOptions } from 'state/launchdarkly/variations';
import { CustomEventNames, EventTypes, useMParticleContext } from 'state/mParticle';
import { useMenuContext } from 'state/menu';
import { useOrderContext } from 'state/order';
import { ServiceMode, useServiceModeContext } from 'state/service-mode';
import { useStoreContext } from 'state/store';
import { EventName, emitEvent } from 'utils/event-hub';
import { AttributeName } from 'utils/performance';
import { useIsMobileOrderingAvailable } from 'utils/restaurant';
import { routes } from 'utils/routing';
import { ServiceModeCategory } from 'utils/service-mode';

import { useServiceModeCategory } from '../../hooks/use-service-mode-category';
import { ACTION_BUTTON_ICONS } from '../constants/action-button-icons';
import { CHECK_REGION_MATCH_BEFORE_SELECTING_RESTAURANT } from '../constants/check-region-match';
import { restaurantCountryMatchesRegion } from '../store-actions-utils';
import { IActionOption } from '../types';

interface IUseStoreActionOptions {
  (options: {
    restaurant: IRestaurantNode;
    setShowChangeRegionModal: (showModal: boolean) => void;
    validateRestaurantSelection?: () => Promise<boolean>;
  }): IActionOption[];
}

export const useStoreActionOptions: IUseStoreActionOptions = ({
  restaurant,
  setShowChangeRegionModal,
  validateRestaurantSelection,
}) => {
  const { selectRestaurant } = useSelectRestaurant({
    restaurant,
  });
  const { selectedStaticMenuItemId } = useMenuContext();
  const { fetchingPosData } = useOrderContext();
  const storeCardButtonOptions = useStoreCardButtonOptions();
  const { logRBIEvent } = useMParticleContext();
  const { navigate } = useNavigation();
  const { formatMessage } = useIntl();
  const { region } = useLocale();
  const dispatch = useAppDispatch();
  const storeSelection2_0Enabled = useFlag(LaunchDarklyFlag.ENABLE_STORE_SELECTION_2_0);
  const isMobileOrderingAvailable = useIsMobileOrderingAvailable(restaurant);
  const { store: currentStore } = useStoreContext();

  const { availablePickupServiceModes } = useServiceModeStatus(restaurant);

  const { setServiceMode } = useServiceModeContext();
  const defaultServiceMode = useDefaultServiceMode({ restaurant });
  const [selectedButtonOption, setSelectedButtonOption] = useState(StoreCardButtonOptions.INFO);
  const isRestaurantOpen = Boolean(availablePickupServiceModes.length);

  const { serviceModeCategory } = useServiceModeCategory();

  const logRBIStoreSelected = useCallback(() => {
    logRBIEvent({
      name: CustomEventNames.BUTTON_CLICK_STORE_SELECTED,
      type: EventTypes.Other,
      attributes: {
        restaurantId: restaurant._id || '',
        restaurantAddress: restaurant.physicalAddress?.address1 || '',
        restaurantCity: restaurant.physicalAddress?.city || '',
        restaurantZip: restaurant.physicalAddress?.postalCode || '',
        restaurantState: restaurant.physicalAddress?.stateProvince || '',
        restaurantCountry: restaurant.physicalAddress?.country || '',
        storeId: restaurant.storeId || '',
      },
    });
  }, [logRBIEvent, restaurant]);

  /**
   * DEFINE BUTTON CLICK HANDLERS
   */
  const onInfoClick = useCallback(() => {
    setSelectedButtonOption(StoreCardButtonOptions.INFO);
    navigate(`${routes.store}/${restaurant._id}`);
  }, [navigate, restaurant._id]);

  const onOffersClick = useCallback(() => {
    setSelectedButtonOption(StoreCardButtonOptions.OFFERS);
    emitEvent(EventName.RESTAURANT_SELECTED_ON_OFFERS, {
      isStoreChange: currentStore?._id !== restaurant?._id,
      attributes: [
        {
          name: AttributeName.RESTAURANT_ID,
          value: restaurant.id || '',
        },
      ],
    });

    selectRestaurant({
      callback: () => {
        setServiceMode(defaultServiceMode);
        navigate(routes.offers);
      },
    });
  }, [
    currentStore?._id,
    defaultServiceMode,
    navigate,
    restaurant?._id,
    restaurant.id,
    selectRestaurant,
    setServiceMode,
  ]);

  const onOrderClick = useCallback(async () => {
    const validated = validateRestaurantSelection ? await validateRestaurantSelection() : true;
    if (!validated) {
      return;
    }
    setSelectedButtonOption(StoreCardButtonOptions.ORDER);

    // Set shouldRefetchOffers to true on restaurant selection
    dispatch(actions.loyalty.setShouldRefetchOffers(true));

    // Only TH requires us to check for region match so even if the restaurant
    // is in a different region for another brand we are assuming that is okay
    if (
      CHECK_REGION_MATCH_BEFORE_SELECTING_RESTAURANT &&
      !restaurantCountryMatchesRegion(restaurant, region)
    ) {
      setShowChangeRegionModal(true);
    } else {
      emitEvent(EventName.RESTAURANT_SELECTED_ON_ORDER, {
        selectedStaticMenuItemId,
        isStoreChange: currentStore?._id !== restaurant?._id,
        attributes: [
          {
            name: AttributeName.RESTAURANT_ID,
            value: restaurant.id || '',
          },
        ],
      });

      logRBIStoreSelected();

      if (serviceModeCategory === ServiceModeCategory.CATERING) {
        selectRestaurant({
          requestedServiceMode: ServiceMode.CATERING_PICKUP,
        });
        return;
      }
      selectRestaurant();
    }
  }, [
    dispatch,
    restaurant,
    region,
    setShowChangeRegionModal,
    selectedStaticMenuItemId,
    currentStore?._id,
    logRBIStoreSelected,
    serviceModeCategory,
    selectRestaurant,
  ]);

  /**
   * MAP BUTTON OPTIONS TO CLICK HANDLER
   */
  const actionButtonClickHandlers = useMemo(
    () => ({
      [StoreCardButtonOptions.INFO]: onInfoClick,
      [StoreCardButtonOptions.OFFERS]: onOffersClick,
      [StoreCardButtonOptions.ORDER]: onOrderClick,
    }),
    [onInfoClick, onOffersClick, onOrderClick]
  );

  /**
   * MAP BUTTON OPTIONS TO TEXT
   */
  const actionButtonText = useMemo(() => {
    const shouldUseMenuText =
      storeSelection2_0Enabled &&
      restaurant.hasMobileOrdering &&
      isRestaurantOpen &&
      !isMobileOrderingAvailable;

    return {
      [StoreCardButtonOptions.INFO]: formatMessage({ id: 'info' }),
      [StoreCardButtonOptions.OFFERS]: formatMessage({ id: 'offers' }),
      [StoreCardButtonOptions.ORDER]: shouldUseMenuText
        ? formatMessage({ id: 'menu' })
        : formatMessage({ id: 'order' }),
    };
  }, [
    formatMessage,
    isMobileOrderingAvailable,
    isRestaurantOpen,
    restaurant.hasMobileOrdering,
    storeSelection2_0Enabled,
  ]);

  /**
   * CREATE STORE ACTION OPTIONS
   */
  return useMemo(() => {
    const getDisabled = (option: StoreCardButtonOptions) => {
      switch (option) {
        case StoreCardButtonOptions.ORDER:
        case StoreCardButtonOptions.OFFERS:
          return true;
        default:
          return false;
      }
    };

    const getLoading = (option: StoreCardButtonOptions) => {
      return fetchingPosData && selectedButtonOption === option;
    };

    return storeCardButtonOptions.map(option => {
      return {
        id: option,
        text: actionButtonText[option],
        onClick: actionButtonClickHandlers[option],
        icon: ACTION_BUTTON_ICONS[option],
        disabled: getDisabled(option),
        loading: getLoading(option),
      };
    });
  }, [
    actionButtonClickHandlers,
    actionButtonText,
    fetchingPosData,
    selectedButtonOption,
    storeCardButtonOptions,
  ]);
};
