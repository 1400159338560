import { useCallback, useRef } from 'react';

import { NavigationState } from '@react-navigation/native';

import { getActiveRoute } from 'hooks/navigation/utils';
import { useMParticleContext } from 'state/mParticle';
import { useStoreContext } from 'state/store';
import { EventName, emitEvent } from 'utils/event-hub';
import { routes } from 'utils/routing';

/**
 * Returns a callback that logs a page view event to mParticle anytime a navigation state change occurs.
 *
 * The callback is meant to be attached to a onStateChange listener from ReactNavigation.
 * It uses the onStateChange signature to extract the updated navigation state, and infers the topMost (active) route.
 */
export const useLogNavigation = () => {
  // TODO: expose this ref to all components to avoid recalculations on useRoute hook
  // Start value is undefined so first evaluation of `previousRouteName !== currentRouteName` should trigger an initial log for home page
  const routeNameRef = useRef<string>();
  const { logPageView } = useMParticleContext();
  const { store } = useStoreContext();

  return useCallback<(args: { data: { state: NavigationState } }) => void>(
    args => {
      const state = args?.data?.state;
      if (!state) {
        return;
      }

      const activeRoute = getActiveRoute(state);

      const previousRouteName = routeNameRef.current;
      const currentRouteName = activeRoute?.pathname || routes.base;

      // Some navigation states does not return a valid page name to log (e.g. some deeplinks / intermediate nested navigators)
      const isValidRouteName = currentRouteName.startsWith('/');

      if (previousRouteName !== currentRouteName && isValidRouteName) {
        emitEvent(EventName.NAVIGATION_STATE_CHANGED, { pathname: currentRouteName });
        logPageView(currentRouteName, store);

        // Save the current route name for later comparison
        routeNameRef.current = currentRouteName;
      }
    },
    [logPageView, store]
  );
};
