import React from 'react';

import { Box } from '@rbilabs/universal-components';
import { useIntl } from 'react-intl';
import { Platform } from 'react-native';

import { ICartEntry } from '@rbi-ctg/menu';
import ActionButton from 'components/action-button';
import Modal, { ModalContent, ModalSize } from 'components/modal';
import Picture from 'components/picture';
import { Props as DialogProps } from 'hooks/use-dialog-modal';
import { useUIContext } from 'state/ui';

import {
  Container,
  Header,
  Item,
  ItemContainer,
  ItemName,
  ModalMessage,
  TileImageWrapper,
} from './styled';
import theme from './theme';

export interface IRemoveUnavailableItemsModalProps {
  unavailableCartEntries: ICartEntry[];
}

export const RemoveUnavailableItemsModal: React.FC<React.PropsWithChildren<
  DialogProps<IRemoveUnavailableItemsModalProps>
>> = ({ onConfirm, onDismiss, unavailableCartEntries }) => {
  const { formatMessage } = useIntl();
  const { buildImageUrl } = useUIContext();

  const message = formatMessage({ id: 'someItemsAreUnavailableInSelectedStore' });

  const buttons = [
    { label: formatMessage({ id: 'removeItems' }), onClick: onConfirm },
    {
      label: formatMessage({ id: 'cancel' }),
      onClick: onDismiss,
      variant: theme.emptyCartBtnVariant,
    },
  ];

  return (
    <Modal
      allowsDismiss={false}
      mParticleEventData={{
        modalAppearanceEventMessage: 'Items in order unavailable at selected store',
        modalMessage: message,
      }}
      onDismiss={onDismiss}
      size={ModalSize.REGULAR}
      // @ts-expect-error TS(2339) FIXME: Property 'modalBackgroundColor' does not exist on ... Remove this comment to see the full error message
      backgroundColor={theme.modalBackgroundColor}
      isFullHeightContent={Platform.OS === 'android'}
      header={
        <Header nativeID="modal-heading" testID="modal-heading" paddingTop="$0">
          {formatMessage({ id: 'itemsUnavailable' })}
        </Header>
      }
    >
      <ModalContent>
        <Container>
          <ModalMessage testID="modal-message">{message}</ModalMessage>
          <ItemContainer>
            {unavailableCartEntries.map((cartEntry, index) => (
              <Item key={`${cartEntry.cartId}-${index}`} $isFirstChild={index === 0}>
                {cartEntry.image && (
                  <TileImageWrapper>
                    <Picture
                      alt=""
                      image={cartEntry.image}
                      size={'full'}
                      buildImageUrl={buildImageUrl}
                    />
                  </TileImageWrapper>
                )}
                <ItemName>{cartEntry.name}</ItemName>
              </Item>
            ))}
          </ItemContainer>
          <Box>
            {buttons.map(button => (
              <ActionButton
                key={button.label}
                fullWidth
                onPress={button.onClick}
                variant={button.variant}
                marginTop="$4"
              >
                {button.label}
              </ActionButton>
            ))}
          </Box>
        </Container>
      </ModalContent>
    </Modal>
  );
};
