import React from 'react';

export enum MarkerTypes {
  User = 'user',
  StoreActive = 'storeActive',
  StoreInActive = 'storeInActive',
  StoreDisabled = 'storeDisabled',
  StoreFavOpen = 'storeFavOpen',
  StoreFavClosed = 'storeFavClosed',
  Destination = 'destination',
  Driver = 'driver',
}

export interface IMarkerInput {
  type: MarkerTypes;
  location: CenterPoint;
  onPress?: () => void;
}

export interface IMarker extends IMarkerInput {
  key: string;
  icon: React.ReactNode;
  preventMarkerPress: boolean;
}

export type MarkersMap = {
  [key: string]: IMarker;
};

type MapEventListener = (...args: any[]) => void;

export interface IUseMapArgs {
  initialZoomAndroid?: number;
  initialZoomIOS?: number;
  eventListeners?: {
    [key: string]: MapEventListener | Array<MapEventListener>;
  };
  position?: CenterPoint;
  disableControls?: boolean;
}

export type PanToFn = (
  newPosition: {
    lat?: number | null;
    lng?: number | null;
  } | null
) => Promise<void>;

export type FitAndCenterFromCoordsFn = (coordsArray: CenterPoint[]) => void;

export type CenterPoint = {
  lat: number;
  lng: number;
};

export interface ILocation extends CenterPoint {
  accuracy?: number;
}

export type IUseMapHook = (
  args?: IUseMapArgs
) => {
  center: CenterPoint;
  createMarker: (args: IMarkerInput) => void;
  fitAndCenterFromCoords: FitAndCenterFromCoordsFn;
  map: React.ReactNode;
  panTo: PanToFn;
  zoom: number;
  resetZoom: () => void;
};
