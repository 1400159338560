import { useCallback, useMemo } from 'react';

import { useIntl } from 'react-intl';

import { IRestaurantNode } from 'generated/rbi-graphql';
import { useServiceModeStatus } from 'hooks/use-service-mode-status';
import { useFlag } from 'state/launchdarkly';
import { useServiceModeContext } from 'state/service-mode';
import { ServiceMode } from 'state/service-mode/types';
import { LaunchDarklyFlag } from 'utils/launchdarkly';
import { useGetRestaurantAvailabilityFn, useIsMobileOrderingAvailable } from 'utils/restaurant';
import { PICKUP_SERVICE_MODES, ServiceModeCategory } from 'utils/service-mode';

interface UseErrorMessageOptions {
  serviceModeCategory?: ServiceModeCategory;
  restaurant: IRestaurantNode;
}

export const useMessage = ({ restaurant, serviceModeCategory }: UseErrorMessageOptions) => {
  const { formatMessage } = useIntl();
  const { serviceModeStatus } = useServiceModeStatus(restaurant);
  const getRestaurantAvailability = useGetRestaurantAvailabilityFn();
  const isMobileOrderingAvailable = useIsMobileOrderingAvailable(restaurant);
  const { serviceMode } = useServiceModeContext();

  const isRestaurantAvailable = getRestaurantAvailability(restaurant);
  const isRestaurantOpen = PICKUP_SERVICE_MODES.some(
    serviceMode => serviceModeStatus[serviceMode].available
  );
  const enableFutureOrdering = useFlag(LaunchDarklyFlag.ENABLE_FUTURE_ORDERING);

  const isNotAvailable = useCallback(
    (serviceMode: ServiceMode): boolean => {
      const status = serviceModeStatus[serviceMode];

      if (!status) {
        return true;
      }

      return !status.available && !status.disabled;
    },
    [serviceModeStatus]
  );

  return useMemo(() => {
    const isCatering = serviceModeCategory && serviceModeCategory === ServiceModeCategory.CATERING;
    const isCateringNotAvailableAndEnabled =
      isCatering && isNotAvailable(ServiceMode.CATERING_PICKUP);
    if (restaurant.hasMobileOrdering && !isRestaurantOpen && !enableFutureOrdering) {
      return formatMessage({ id: 'locationClosed' });
    }

    if (!restaurant.hasMobileOrdering) {
      return formatMessage({ id: 'storeDoesNotHaveMobileOrdering' });
    }

    if (!isMobileOrderingAvailable || !isRestaurantAvailable) {
      return formatMessage({ id: 'mobileOrderingUnavailable' });
    }

    if (isCateringNotAvailableAndEnabled) {
      return formatMessage({ id: 'storeDoesNotHaveCateringOrders' });
    }

    return;
  }, [
    serviceModeCategory,
    serviceMode,
    isNotAvailable,
    restaurant.hasMobileOrdering,
    isRestaurantOpen,
    enableFutureOrdering,
    isMobileOrderingAvailable,
    isRestaurantAvailable,
    formatMessage,
  ]);
};
