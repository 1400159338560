import * as React from 'react';

import { Box, Text } from '@rbilabs/universal-components';
import { useIntl } from 'react-intl';

import { Halal } from 'components/ucl/svgs/Halal';
import { useRoute } from 'hooks/navigation/use-route';
import { routes } from 'utils/routing';

import {
  StoreCardActions,
  StoreCardCaption,
  StoreCardDetails,
  StoreCardError,
  StoreCardSeparator,
} from './components';
import {
  Body2,
  Container,
  FadeIn,
  HalalContainer,
  PrimaryText,
  StoreCardActionsContainer,
} from './store-card.styled';
import { StoreCardViewProps } from './types';

export const StoreCardView: React.VFC<StoreCardViewProps> = ({
  isFavorite = false,
  isUpdatingFavorites = false,
  onCardClick,
  onDiagnosticsClick,
  onFavoriteClick,
  storeDetails,
  isSelected,
  isSingleCardUI,
}) => {
  const { formatMessage } = useIntl();
  const { pathname } = useRoute();

  const isFavoriteSearch = pathname === routes.signUp;
  const storeAvailability = storeDetails.openText ?? storeDetails.closedText;
  const storeCaptionAccessibility = storeDetails.isHalal
    ? formatMessage({ id: 'isHalalTitle' })
    : storeAvailability;

  return (
    <FadeIn>
      <Container
        onPress={onCardClick}
        accessible={false}
        isSingleCardUI={isSingleCardUI}
        _web={{ cursor: onCardClick ? 'pointer' : 'default' }}
      >
        <StoreCardDetails
          dd-action-name={`${storeDetails.title} open accordion`}
          title={storeDetails.title}
          availability={storeCaptionAccessibility}
          caption={
            <StoreCardCaption
              prefix={storeDetails.distanceText}
              suffix={
                <>
                  {storeDetails.isHalal && (
                    <>
                      <HalalContainer>
                        {
                          //TODO: RN - replace by Icon variant="halal" from UCL
                        }
                        <Halal title={formatMessage({ id: 'isHalalTitle' })} />
                      </HalalContainer>
                      <Text> &bull; </Text>
                    </>
                  )}
                  <Box accessible>
                    {storeDetails.openText ? (
                      <Body2>{storeDetails.openText}</Body2>
                    ) : (
                      <PrimaryText>{storeDetails.closedText}</PrimaryText>
                    )}
                  </Box>
                </>
              }
            />
          }
          body={storeDetails.body}
          errorMessage={
            storeDetails.errorMessage ? (
              <StoreCardError message={storeDetails.errorMessage} />
            ) : null
          }
          isSelected={isSelected}
        />

        {!isSingleCardUI && <StoreCardSeparator />}

        <StoreCardActionsContainer
          zIndex="1"
          py="$1"
          px="$2"
          alignItems={isSingleCardUI ? 'flex-start' : 'center'}
        >
          <StoreCardActions
            dd-action-name={`${storeDetails.title} ${isFavorite ? 'unfavorite' : 'favorite'}`}
            isFavorite={isFavorite}
            favoriteIcon={isFavorite ? 'favoriteFill' : 'favorite'}
            onDiagnosticsClick={!isFavoriteSearch ? onDiagnosticsClick : undefined}
            onFavoritesClick={!isFavoriteSearch ? onFavoriteClick : onCardClick}
            isUpdatingFavorites={isUpdatingFavorites}
          />
        </StoreCardActionsContainer>
      </Container>
    </FadeIn>
  );
};
